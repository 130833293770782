import i18next from 'i18next';
import generateGuid from './guidGenerator';
import elementTypeEnum from './elementTypeEnum';

export default function createNewElement(typeId, order, page) {
    const element = generateDefaultElement(order, page);
    element.type = typeId;
    return decorateElement(element);
}

import store from '@/store';
import * as uuid from 'uuid';

export function generateId() {
    if (!store.state.survey.hasNewDictionary) return undefined;

    return uuid.v4();
}

export function generateOption() {
    if (!store.state.survey.hasNewDictionary) return '';

    return {
        value: '',
        id: uuid.v4(),
    };
}

export function generateRange(range, weight, id = null) {
    if (!store.state.survey.hasNewDictionary) {
        return {
            range: range,
            weight: weight
        };
    }
    return {
        range: range,
        weight: weight,
        id: id ?? weight
    };
}

function generateDefaultElement(order, page) {
    return {
        guid: generateGuid(),
        page: page,
        order: order,
        title: '',
        help: '',
        picture_filename: null,
        video_url: null,
        options: [],
        option_urls: [],
        options_id: null,
        is_fillable: 1,
        is_required: 1,
        is_visible: 1,
        max_checkins: null,
        has_other_option: null,
        other_option_label: null,
        has_dkna_option: null,
        dkna_option_label: null,
        dkna_option_value: null,
        has_random_rotation: null,
        range_start: null,
        range_start_label: null,
        range_end: null,
        range_end_label: null,
        ranges: [],
        is_multiple_enabled: false,
        is_matrix_vertically_restricted: false,
        condition_groups: [],
        display_type: null,
        answer_quotas: [],
        options_parent_question_guid: null,
        use_selected_answers: true,
        search_only_options: false,
        media_position: null,
        media_size: null,
        options_from_file: false
    };
}

function decorateElement(element) {
    switch (element.type) {
    case elementTypeEnum.EXPLANATION:
        return {
            ...element,
            is_fillable: 0
        };
    case elementTypeEnum.CHECKBOX:
        return {
            ...element,
            max_checkins: 1,
            has_other_option: 0,
            other_option_label: '',
            has_dkna_option: 0,
            dkna_option_label: '',
            has_random_rotation: 0,
            options: [
                generateOption()
            ],
            option_urls: [null],
        };
    case elementTypeEnum.DATE:
        return {
            ...element,
            range_start: '',
            range_end: ''
        };
    case elementTypeEnum.DROPDOWN:
        return {
            ...element,
            options: [
                generateOption()
            ],
            option_urls: [null],
            has_random_rotation: 0,
            search_only_options: false,
            options_from_file: false
        };
    case elementTypeEnum.RADIO:
        return {
            ...element,
            has_other_option: 0,
            other_option_label: '',
            has_dkna_option: 0,
            dkna_option_label: '',
            has_random_rotation: 0,
            options: [
                generateOption(),
            ],
            option_urls: [null],
            answer_quotas: [
                { limit: 100, target: 'thank-you' }
            ]
        };
    case elementTypeEnum.SCALE:
        return {
            ...element,
            ranges: [
                generateRange('0', 0),
                generateRange('10', 10)
            ],
            display_type: 'radio',
            has_dkna_option: false,
            dkna_option_label: '',
            dkna_option_value: -1,
            is_reversed: false,
            range_labels_always_shown: false,
            is_decimal_scale: false,
            decimal_precision: null,
            slider_has_default_value: true,
            hide_value: false,
            colors: []
        };
    case elementTypeEnum.NPS:
        return {
            ...element,
            title: i18next.t('SURVEY_NPS_QUESTION', 'How likely is it that you would recommend our service to a friend or colleague?'),
            ranges: [
                generateRange(i18next.t('SURVEY_NPS_VERY_UNLIKELY', 'Very unlikely'), 0),
                generateRange(i18next.t('SURVEY_NPS_VERY_LIKELY', 'Very likely'), 10)
            ],
            display_type: 'radio',
            is_reversed: false
        };
    case elementTypeEnum.CSAT:
        return {
            ...element,
            title: i18next.t('SURVEY_CSAT_QUESTION', 'How would you rate your overall satisfaction with the service you received?'),
            ranges: [
                generateRange(i18next.t('SURVEY_CSAT_VERY_UNSATISFIED', 'Very unsatisfied'), 1),
                generateRange(i18next.t('SURVEY_CSAT_UNSATISFIED', 'Unsatisfied'), 2),
                generateRange(i18next.t('SURVEY_CSAT_NEUTRAL', 'Neutral'), 3),
                generateRange(i18next.t('SURVEY_CSAT_SATISFIED', 'Satisfied'), 4),
                generateRange(i18next.t('SURVEY_CSAT_VERY_SATISFIED', 'Very satisfied'), 5)
            ],
            display_type: 'text',
            is_reversed: false,
            display_direction: 'horizontal',
            range_labels_always_shown: false,
        };
    case elementTypeEnum.CES_5_SCALE:
        return {
            ...element,
            title: i18next.t('SURVEY_CES_QUESTION', 'How easy did we make it for you to solve your issue?'),
            ranges: [
                generateRange(i18next.t('SURVEY_CES_VERY_DIFFICULT', 'Very difficult'), 1),
                generateRange(i18next.t('SURVEY_CES_DIFFICULT', 'Difficult'), 2),
                generateRange(i18next.t('SURVEY_CES_NEUTRAL', 'Neutral'), 3),
                generateRange(i18next.t('SURVEY_CES_EASY', 'Easy'), 4),
                generateRange(i18next.t('SURVEY_CES_VERY_EASY', 'Very easy'), 5)
            ],
            display_type: 'text',
            is_reversed: false,
            display_direction: 'horizontal',
            range_labels_always_shown: false,
        };
    case elementTypeEnum.NUMBER:
        return {
            ...element,
            ranges: [
                { range: 'min', weight: null },
                { range: 'max', weight: null },
            ]
        };
    case elementTypeEnum.RATING:
        return {
            ...element,
            display_type: 'star',
            rating_labels: [],
            has_dkna_option: false,
            dkna_option_label: '',
            dkna_option_value: -1
        };
    case elementTypeEnum.MATRIX:
        return {
            ...element,
            is_multiple_enabled: false,
            is_matrix_vertically_restricted: false,
            options: [generateOption(), generateOption(), generateOption()],
            option_urls: [null, null, null],
            has_dkna_option: 0,
            dkna_option_label: '',
            has_random_rotation: 0,
            ranges: [
                generateRange('', 1, generateId()),
                generateRange('', 2, generateId()),
                generateRange('', 3, generateId()),
                generateRange('', 4, generateId()),
                generateRange('', 5, generateId())
            ]
        };
    case elementTypeEnum.ORDER:
        return {
            ...element,
            options: [generateOption(), generateOption(), generateOption()],
            option_urls: [null, null, null],
            has_dkna_option: 0,
            dkna_option_label: '',
            has_random_rotation: 0,
            ranges: [
                generateRange('1', 1, generateId()),
                generateRange('2', 2, generateId()),
                generateRange('3', 3, generateId())
            ]
        };
    case elementTypeEnum.PICTURE:
        return {
            ...element,
            is_multiple_enabled: false,
            has_random_rotation: 0,
            option_urls: [],
            options_with_urls: []
        };
    case elementTypeEnum.TEXTAREA:
    default:
        return element;
    }
}
