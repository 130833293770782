import { RouteConfig } from 'vue-router';

const User = () => import('@/components/settings/User.vue');
const Team = () => import('@/components/settings/Team.vue');
const PlanDetails = () => import('@/components/settings/PlanDetails.vue');
const PlanUsage = () => import('@/components/settings/PlanUsage.vue');
const ActivityFeed = () => import('@/components/settings/ActivityFeed.vue');
const ApiTokens = () => import('@/components/settings/apiTokens/ApiTokens.vue');
const EmailProviderRoot = () => import('@/components/settings/email-provider/EmailProviderRoot.vue');
const UserManagement = () => import('@/settings/components/userManagement/UserManagement.vue');
const DataSecuritySettings = () => import('@/settings/components/dataSecurity/DataSecuritySettings.vue');
const SurveyTrackingSettings = () => import('@/settings/components/surveyTracking/SurveyTrackingSettings.vue');
const ApiIntegrations = () => import('@/settings/components/apiIntegrations/Integrations.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: 'user',
    },
    {
        path: 'user',
        component: User
    },
    {
        path: 'team',
        component: Team
    },
    {
        path: 'user-management',
        component: UserManagement
    },
    {
        path: 'plan-details',
        component: PlanDetails
    },
    {
        path: 'planusage',
        component: PlanUsage
    },
    {
        path: 'activityfeed',
        component: ActivityFeed,
    },
    {
        path: 'apitokens',
        component: ApiTokens,
    },
    {
        path: 'email-provider',
        component: EmailProviderRoot,
    },
    {
        path: 'api-integrations',
        component: ApiIntegrations,
    },
    {
        path: 'data-security',
        component: DataSecuritySettings,
    },
    {
        path: 'survey-tracking',
        component: SurveyTrackingSettings,
    }
];

export default routes;
