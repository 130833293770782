var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',_vm._l((_vm.row),function(cell,columnNumber){return _c('td',{key:columnNumber,staticClass:"align-middle border-b p-2 truncate",class:{
            'bg-primary-300': _vm.isPrimaryColumn(columnNumber),
            'border-primary-700': _vm.isPrimaryColumn(columnNumber),
            'border-neutral-600': !_vm.isPrimaryColumn(columnNumber),
            'pt-5': _vm.isFirst,
            'hidden': _vm.emptyColumns.includes(columnNumber),
        },staticStyle:{"min-width":"70px","max-width":"210px"},attrs:{"data-test-id":'cell-' + _vm.rowNumber + '-' + columnNumber}},[_c('span',{class:_vm.isDisabled ? 'text-neutral-700' : '',attrs:{"title":cell}},[(_vm.isFirst && !_vm.isDataTextHidden)?_c('label',{staticClass:"block text-small font-bold mb-1",class:{ 'opacity-0': columnNumber !== 0 },staticStyle:{"margin-top":"-10px"}},[_vm._v(" "+_vm._s(_vm.$t('ANALYSIS.DATA_TO_ANALYZE'))+" ")]):_vm._e(),_vm._v(" "+_vm._s(cell)+" ")])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }