<template>
    <div class="relative">
        <label class="pl-2 mb-2 text-default text-black block">
            {{ $t('CUSTOM_LABELS.CATEGORY', 'Category') }}
            <z-icon
                v-tooltip.top="$t('CUSTOM_LABELS.CATEGORY_HELP_TEXT', 'You can group your custom labels.')"
                icon="question-circle"
            />
        </label>
        <div
            class="select border border-neutral-800 hover:border-neutral-900 text-default rounded px-2 flex items-center cursor-default justify-between h-8"
            @click="categorySettingsVisible = !categorySettingsVisible"
        >
            <span class="truncate">
                {{ categoryName }}
            </span>
            <z-icon
                class="text-2xl"
                icon="angle-down"
            />
        </div>
        <category-settings
            v-if="categorySettingsVisible"
            :categories="categories"
            :selected-id="categoryId"
            @categorySelected="setCategoryId($event)"
            @newCategoryAdded="createNewCategory($event)"
            @deleteCategory="deleteCategory($event)"
            @editCategory="editCategory($event)"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import CategorySettings from './CustomLabelsCategorySettings.vue';
import ZIcon from '@/components/ui/Icon.vue';

import { toaster } from '@/utils/toaster';
import confirm from '@/utils/confirmPopup/';
import i18next from 'i18next';

import * as categoriesApi from '@/custom-labels/api/custom-label-categories';

export default defineComponent({
    components: {
        CategorySettings,
        ZIcon,
    },
    model: {
        prop: 'categoryId',
        event: 'update:category-id'
    },
    props: {
        categoryId: { type: Number, required: false },
    },
    setup(props, context) {
        const categories = ref<any>([]);
        const categorySettingsVisible = ref(false);

        async function getCategories() {
            categories.value = await categoriesApi.getCategories();
        }

        function setCategoryId(category: any) {
            context.emit('update:category-id', category ? category.id : null );
            categorySettingsVisible.value = false;
        }

        async function createNewCategory(newCategory) {
            try {
                await categoriesApi.createCategory(newCategory);
                getCategories();
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
        }

        async function deleteCategory(categoryToDelete) {
            const isConfirmed = await confirm.destruction(
                i18next.t('CUSTOM_LABELS.CATEGORY_DELETE_POPUP_TITLE', 'Delete category'),
                deletePopupText(categoryToDelete),
            );
            if (!isConfirmed) {
                return;
            }
            await categoriesApi.deleteCategory(categoryToDelete);
            getCategories();
        }

        async function editCategory({ id, name }) {
            try {
                await categoriesApi.updateCategory(id, name);
                getCategories();
            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
        }

        function deletePopupText(categoryToDelete) {
            return i18next.t('CUSTOM_LABELS.CATEGORY_DELETE_POPUP_TEXT', {
                category: categoryToDelete.name,
                interpolation: { escapeValue: false },
                defaultValue: '{{category}} custom label category will be deleted, all of the previously added custom labels will be removed from this category.'
            });
        }

        onMounted(() => getCategories());

        const categoryName = computed(() => categories.value.find(category => category.id === props.categoryId)?.name);

        return {
            categories,
            categorySettingsVisible,
            categoryName,
            setCategoryId,
            createNewCategory,
            deleteCategory,
            editCategory,
        };
    },
});
</script>
