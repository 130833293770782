import LoadingScreen from './LoadingScreen';

export default {
    install(Vue) {
        const root = new Vue({
            render: createElement => createElement(LoadingScreen)
        });
        root.$mount(document.body.appendChild(document.createElement('div')));
        const loadingScreen = root.$children[0];
        Vue.prototype.$load = {
            start() {
                loadingScreen.show();
            },
            stop() {
                loadingScreen.hide();
            },
            setColor(color) {
                loadingScreen.setColor(color);
            },
            while(p) {
                loadingScreen.show();
                return Promise.resolve(p)
                    .then(resolved => {
                        loadingScreen.hide();
                        return resolved;
                    }).catch(e => {
                        loadingScreen.hide();
                        throw e;
                    });
            },
            isVisible() {
                return loadingScreen.isVisible;
            },
        };
    },
};
