import globals from '@/utils/globals';
const enabled = Boolean(parseInt(globals.getGTMEnabled()));
window.dataLayer = window.dataLayer || [];

export default {
    events: {
        SURVEY_CREATED: 'survey-created',
        SURVEY_COPIED: 'survey-copied',
        SURVEY_ACTIVATED: 'survey-activated',
        SURVEY_PAUSED: 'survey-paused',
        SURVEY_UNIQUE_LINK_UPDATED: 'survey-unique-link-updated',
        SURVEY_LOGO_UPDATED: 'survey-link-updated',
        SURVEY_CUSTOM_LABEL_ADDED: 'survey-link-updated',
        SURVEY_CUSTOM_LABEL_REMOVED: 'survey-link-updated',
        SURVEY_CONDITION_ADDED: 'survey-condition-added',
        SURVEY_SHARED_VIA_EMAIL: 'survey-shared-via-email',
        SURVEY_FONT_CHANGED: 'survey-font-changed',
        SURVEY_COLORS_CHANGED: 'survey-colors-changed',
        EXCEL_ANALYSIS_INIT: 'excel-analysis-init',
        EXCEL_ANALYSIS_STARTED: 'excel-analysis-started',
        EXCEL_REANALYSIS_INIT: 'excel-reanalysis-started',
        EXCEL_REANALYSIS_STARTED: 'excel-reanalysis-started',
        EXCEL_APPEND_INIT: 'excel-reanalysis-started',
        EXCEL_APPEND_STARTED: 'excel-reanalysis-started',
        DOWNLOAD_SURVEY_EXCEL: 'download-survey-excel',
        DOWNLOAD_SURVEY_SPSS: 'download-survey-spss',
        DATA_SOURCE_DELETED: 'data-source-deleted',
        DATA_SOURCE_RESTORED: 'data-source-restored',
        USER_EMAIL_VERIFIED_SHORT_CODE: 'user-email-verified-short-code',
        USER_INVITED: 'user-invited',
        USER_DELETED: 'user-deleted',
        INVITED_USER_JOINED: 'invited-user-joined',
        INVITED_USER_DELETED: 'invited-user-deleted',
        USER_CHANGED_NAME: 'user-changed-name',
        USER_CHANGED_LANGUAGE: 'user-changed-language',
        USER_CHANGED_PROFILE_PICTURE: 'user-changed-profile-picture',
        USER_REQUESTED_NEW_PASSWORD: 'user-requested-new-password',
        GROUP_DELETED: 'group-deleted',
        GROUP_SURVEY_STYLE_UPDATED: 'group-survey-style-updated',
        CUSTOM_LABEL_UPDATED: 'custom-label-updated',
        CUSTOM_LABEL_CREATED: 'custom-label-created',
        CUSTOM_LABEL_CREATED_FROM_LABELS: 'custom-label-created-from-labels',
        CUSTOM_LABEL_DELETED: 'custom-label-deleted',
        CES_EVENT: 'CesEvent',
        RNPS_EVENT: 'RnpsEvent',
        ALERT_PRESETS_OPENED: 'alertPresetsOpened',
        ALERT_CREATED_FROM_PRESET: 'alertCreatedFromPreset',
        ALERT_EDITED_FROM_PRESET: 'alertEditedFromPreset',
    },
    categories: {
        SURVEY_CREATED: 'survey',
        SURVEY_COPIED: 'survey',
        SURVEY_ACTIVATED: 'survey',
        SURVEY_PAUSED: 'survey',
        SURVEY_UNIQUE_LINK_UPDATED: 'survey',
        SURVEY_LOGO_UPDATED: 'survey',
        SURVEY_CUSTOM_LABEL_ADDED: 'survey',
        SURVEY_CUSTOM_LABEL_REMOVED: 'survey',
        SURVEY_CONDITION_ADDED: 'survey',
        SURVEY_SHARED_VIA_EMAIL: 'survey',
        SURVEY_FONT_CHANGED: 'survey',
        SURVEY_COLORS_CHANGED: 'survey',
        EXCEL_ANALYSIS_INIT: 'excel',
        EXCEL_ANALYSIS_STARTED: 'excel',
        EXCEL_REANALYSIS_INIT: 'excel',
        EXCEL_REANALYSIS_STARTED: 'excel',
        EXCEL_APPEND_INIT: 'excel',
        EXCEL_APPEND_STARTED: 'excel',
        DOWNLOAD_SURVEY_EXCEL: 'survey',
        DOWNLOAD_SURVEY_SPSS: 'survey',
        DATA_SOURCE_DELETED: 'data-source',
        USER_REGISTERED: 'user',
        USER_EMAIL_VERIFIED_SHORT_CODE: 'user',
        USER_INVITED: 'user',
        USER_DELETED: 'user',
        INVITED_USER_JOINED: 'user',
        INVITED_USER_DELETED: 'user',
        USER_CHANGED_NAME: 'user',
        USER_CHANGED_LANGUAGE: 'user',
        USER_CHANGED_PROFILE_PICTURE: 'user',
        USER_REQUESTED_NEW_PASSWORD: 'user',
        GROUP_DELETED: 'group',
        GROUP_SURVEY_STYLE_UPDATED: 'group',
        CUSTOM_LABEL_UPDATED: 'custom-label',
        CUSTOM_LABEL_CREATED: 'custom-label',
        CUSTOM_LABEL_CREATED_FROM_LABELS: 'custom-label',
        CUSTOM_LABEL_DELETED: 'custom-label',
        CES_EVENT: 'internal-embed',
        RNPS_EVENT: 'internal-embed',
        ALERTING: 'alerting',
    },
    track(event, category, properties = {}) {
        if (!enabled) {
            return;
        }
        try {
            window.dataLayer.push({
                event: event,
                eventCategory: category,
                ...properties,
            });
        } catch (e) {
            console.warn('GTM error: ' + e.getMessage());
        }
    },
};
