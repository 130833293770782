import {api} from '@/api/index.js';
import {Dataset} from '@/domain/dataset/DatasetInterface';

export const  initInputAPI = async (): Promise<Dataset> => {
    const { data } = await api.post('/datasets');
    return data;
};

export const  getInputAPI = async (id): Promise<Dataset> => {
    const { data } = await api.get('/datasets/' + id);
    return data;
};

export const saveInputAPI = async (inputAPI, columns, customLabels): Promise<Dataset> => {
    const {data} = await api.put('/datasets/' + inputAPI.id, {
        name: inputAPI.name,
        languageId: inputAPI.language_id,
        accent: inputAPI.accent,
        lowercase: inputAPI.lowercase,
        topicRecognition: inputAPI.topic_recognition,
        textSplitting: inputAPI.text_splitting,
        customLabelIds: customLabels,
        columns: columns,
        auto_translation: inputAPI.auto_translation,
        auto_translation_with_html: inputAPI.auto_translation_with_html
    });
    return data;
};

export const reanalyzeData = async (id, from) => {
    const { data } = await api.post('/datasets/' + id + '/reanalyze', { from });
    return data;
};

