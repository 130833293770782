import statusEnum from '@/domain/dataSource/statusEnum';
import { dimensionTypeId } from '@/domain/dataset/dimensionTypeId';

export interface HomeDatasetsState {
    datasets: Dataset[],
    archivedDatasets: Dataset[],
    query: string,
    filters: Filter[],
    orderBy: 'desc'|'asc',
    datasetsLoading: boolean,
    isArchiveView: boolean,
    openPermissionPopup: string | null,
    accessRequests: string[],
    limit: number,
    firstLoading: boolean,
}

export interface Dataset {
[x: string]: unknown;
    id: string,
    legacy_id: number,
    type: number,
    name: string,
    is_archived: boolean,
    rows: number,
    analyzed_rows: number,
    header: number,
    language_id: number,
    lowercase: boolean,
    accent: boolean,
    topic_recognition: boolean,
    auto_translation: boolean,
    auto_translation_with_html: boolean,
    text_splitting: number,
    user_group_id: number,
    created_at: string,
    updated_at: string,
    last_analysis_at: string,
    is_analysis_running: boolean,
    is_reanalysis_running: boolean,
    analysis_offset: number,
    dimension_definitions: DimensionDefinition[],
    permissions: Permissions,
    permissionLevel: PermissionEnum,
    isActive: boolean,
    creatorId: number | null,
    creatorName: string,
    status: statusEnum,
    isShareEnabled: boolean,
    shareToken: string | null,
    ipAddresses: string[] | null,
    tags: string[],
    hasActiveEmbed: boolean | null,
    parsedRows: number | null,
    hasNewDictionary: boolean,
    quotaIssue: string | null,
    quotaIssueAmount: number | null,
    inputTranslation: boolean,
    hasCampaign: true | null,
}

export interface User {
    id: number | null,
    name: string
}

export interface Filter {
    type: FilterType,
    label?: string,
    value: any,
}

export enum FilterType {
    ACCESS = 'access',
    DATASET = 'dataset',
    CUSTOM_LABEL = 'custom label',
    TAG = 'tag',
    CREATOR = 'creator',
    QUERY = 'query',
    EMBED = 'embed',
    CAMPAIGN = 'campaign',
}

export interface DimensionDefinition {
    id: string,
    name?: string,
    legacy_id: number,
    type: dimensionTypeId,
    is_metadata: boolean,
    is_visible: boolean,
    is_active: boolean,
    is_used: boolean,
    customLabels: number[],
}

export interface Permissions {
    groups: Permission|[],
    users: Permission|[]
}

export interface Permission {
    [key: number]: PermissionEnum
}

export enum PermissionEnum {
    NONE = 0,
    VIEW = 1,
    EXPORT = 2,
    EDIT = 3,
    OWNER = 4
}

export interface AccessRequest {
    id: number,
    userId: number,
    userGroupId: number,
    datasetId: string
}

export enum DatasetTypeEnum {
    EXCEL = 1,
    SURVEY = 2,
    EMAIL = 3,
    API = 4,
    NMI = 5,

    WEBSITE_CRAWLER = 8,

    PDF_EXTRACTOR = 9,

    DOMO_INTEGRATION = 10,

}

export enum CampaignFilterType {
    HAS_CAMPAIGN = 'has-campaign',
    NO_CAMPAIGN = 'no-campaign'
}
