import api from '@/api';
import * as customLabelsApi from '@/custom-labels/api/custom-labels';
import * as categoriesApi from '@/custom-labels/api/custom-label-categories';
import gtm from '@/utils/gtm';
import uniq from 'lodash/uniq';
import i18next from 'i18next';
import languages from '@/domain/analysisLanguageEnum.js';
import dayjs from 'dayjs';

import { computed, reactive } from 'vue';

import { setLoading } from '@/utils/loading/index.js';

import { makePopupActions, popupState } from './popup';
import { uploadState, uploadGetters, uploadActions } from './upload';

import { CustomLabelState, CustomLabel, Category, CLResponseTypeEnum } from './types';

export const state = reactive<CustomLabelState>({
    customLabels: [],
    users: [],
    categories: [],
    sort: {
        by: 'created_at',
        direction: 'asc'
    },
    searchQuery: null,
    languageIdFilter: 0,
    dateFilter: {
        date: '',
        start: '',
        end: '',
    },
    filters: [],
    popup: popupState,
    upload: uploadState,
});

const getters = {
    customLabels: computed(() => {
        let customLabels = [...state.customLabels];

        if (state.searchQuery) {
            const query = state.searchQuery.toLowerCase();
            customLabels = customLabels.filter(label => label.name.toLowerCase().includes(query) || label.synonymsList.map(s => s.toLowerCase()).includes(query));
        }

        if (state.languageIdFilter) {
            customLabels = customLabels.filter(label => label.languages.includes(state.languageIdFilter));
        }

        if (state.dateFilter.date && state.dateFilter.start && state.dateFilter.end) {
            customLabels = customLabels.filter(label => label.created_at.split('T')[0] >= state.dateFilter.start && label.created_at.split('T')[0] <= state.dateFilter.end);
        }

        if (state.filters.length > 0) {
            for (const filter of state.filters) {
                if (filter.type === i18next.t('CUSTOM_LABELS.TAG', 'Custom label tag')) {
                    customLabels = customLabels.filter(label => label.tags.includes(filter.filter));
                }
                else if (filter.type === i18next.t('CUSTOM_LABELS.CATEGORY', 'Custom label category')) {
                    customLabels = customLabels.filter(label => label.category_id === filter.filter.id);
                }
                else if (filter.type === i18next.t('LABELS.AUTHOR', 'Author')) {
                    customLabels = customLabels.filter(label => label.creator_id === filter.filter.id);
                }
            }
        }

        customLabels = customLabels.sort((a,b) => {
            if (state.sort.by === 'synonyms') {
                return state.sort.direction === 'asc' ? a.synonymsList.length - b.synonymsList.length : b.synonymsList.length - a.synonymsList.length;
            } else if (state.sort.by === 'author') {
                if (!a.creator && !b.creator) {
                    return 0;
                } else if (!a.creator && !!b.creator) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                } else if (!!a.creator && !b.creator) {
                    return state.sort.direction === 'asc' ? -1 : 1;
                } else {
                    if (a.creator > b.creator) {
                        return state.sort.direction === 'asc' ? 1 : -1;
                    } else if (a.creator < b.creator) {
                        return state.sort.direction === 'asc' ? -1 : 1;
                    }
                }
                return 0;
            } else if (state.sort.by === 'languages') {
                return state.sort.direction === 'asc' ? a.languages.length - b.languages.length : b.languages.length - a.languages.length;
            } else {
                if (a[state.sort.by] > b[state.sort.by]) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                    // @ts-ignore
                }  else if (a[state.sort.by] < b[state.sort.by]) {
                    return state.sort.direction === 'asc' ? -1 : 1;
                }
                return 0;
            }
        });

        return customLabels;
    }),
    sort: computed(() => state.sort),
    allTags: computed(() => {
        const tags = state.customLabels.reduce((acc, cur) => acc.concat(cur.tags), [] as string[]);

        return uniq(tags).sort();
    }),
    customLabelIdMap: computed((): Record<number, CustomLabel> => state.customLabels.reduce((map, cl) => ({ ...map, [cl.id]: cl }),{})),
    categoryIdMap: computed((): Record<number, Category> => state.categories.reduce((map, c) => ({ ...map, [c.id]: c }), {})),
    getCustomLabelCategoryName: computed(() => (id) => {
        const cl = getters.customLabelIdMap.value[id];
        if (!cl || !cl.category_id) return null;
        return cl.category;
    }),
    getCustomLabelCategoryIdByName: computed(() => (categoryName) => {
        return state.categories.find(c => c.name.includes(categoryName))?.id || null;
    }),
    checkPhraseSets: (customLabelIds: number[], languageId: number) => computed(() => {
        if (languageId === languages.MIXED) {
            return true;
        }

        const selectedLabels = customLabelIds.map(id => state.customLabels.find(label => label.id === id)).filter(Boolean) || [];

        return selectedLabels.every(label => label!.languages.length === 0 || label!.languages.includes(languageId));
    }),
    getUpdatedClsSinceLastAnalysis: (customLabelIds: number[], lastAnalysisAt: string|null) => computed(() => {
        if (lastAnalysisAt == null) {
            return [];
        }
        const updatedCls: number[] = [];
        customLabelIds.forEach((clId: number) => {
            const cl = getters.customLabelIdMap.value[clId] as CustomLabel;
            if (cl && dayjs(cl.updated_at).isAfter(dayjs(lastAnalysisAt))) {
                updatedCls.push(clId);
            }
        });
        return updatedCls;
    }),
    categoriesWithNoCustomLabels: computed(() => state.categories
        .filter(category => state.customLabels.filter(cl => cl.category_id === category.id).length === 0)
        .map(category => category.id)
    ),
};

const actions = {
    async init(responseType: number|null = null) {
        setLoading(true);
        await Promise.all([
            actions.getCustomLabels(responseType),
            actions.getUsers(),
        ]);
        setLoading(false);
    },
    async getCustomLabels(responseType: number|null = null) {
        // Backend returns with chronologically sorted phrase sets as an Object
        const response = await customLabelsApi.getCustomLabels(responseType);
        let categories = [] as Category[];
        if (state.categories.length === 0) {
            categories = await actions.getCategories();
        } else {
            categories = state.categories;
        }
        let customLabels = [] as CustomLabel[];
        customLabels = response.map(cl => ({
            ...cl,
            phraseSets: cl.phraseSets ? Object.values(cl.phraseSets) : [],
            filtersList: cl.filters?.split(',').filter(item => item !== '') || [],
            tags: cl.tags || [], // @ts-ignore
            category: categories.find(c => c.id === cl.category_id)?.name
        }));
        state.customLabels = customLabels;
        return customLabels;
    },
    async getCustomLabel(id: number) {
        // Backend returns with chronologically sorted phrase sets as an Object
        const cl = await customLabelsApi.getCustomLabel(id);
        let categories = [] as Category[];
        if (state.categories.length === 0) {
            categories = await actions.getCategories();
        } else {
            categories = state.categories;
        }
        return {
            ...cl,
            phraseSets: cl.phraseSets ? Object.values(cl.phraseSets) : [],
            filtersList: cl.filters?.split(',').filter(item => item !== '') || [],
            tags: cl.tags || [], // @ts-ignore
            category: categories.find(c => c.id === cl.category_id)?.name
        } as CustomLabel;
    },
    async getUsers() {
        let users = [];
        users = await api.getGroupUsers();
        state.users = users;
        return users;
    },
    async getCategories() {
        const categories = await categoriesApi.getCategories();
        state.categories = categories;
        return categories;
    },
    updateSort(by: 'created_at' | 'name' | 'synonyms' | 'languages' | 'author', direction: 'asc' | 'desc') {
        state.sort = { by, direction };
    },
    updateSearchQuery(query: string) {
        state.searchQuery = query;
    },
    resetSearchQuery() {
        state.searchQuery = null;
    },
    updateLanguageIdFilter(id: string) {
        state.languageIdFilter = parseInt(id);
    },
    updateDateFilter(value) {
        state.dateFilter.date = value;

        if (value) {
            let start, end;
            if (value === 'last7days') {
                end = new Date();
                start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
            }
            if (value === 'lastmonth') {
                end = new Date();
                start = new Date(new Date().setDate(1));
            }
            if (value === 'prevmonth') {
                const now = new Date();
                start = new Date(now.setMonth(now.getMonth()-1, 1));
                end = new Date(new Date().setDate(0));
            }

            if (value !== 'setdate') {
                state.dateFilter.end = `${end.getFullYear()}-${end.getMonth() < 10 ? '0' : ''}${end.getMonth()+1}-${end.getDate() < 10 ? '0' : ''}${end.getDate()}`;
                state.dateFilter.start = `${start.getFullYear()}-${start.getMonth() < 10 ? '0' : ''}${start.getMonth()+1}-${start.getDate() < 10 ? '0' : ''}${start.getDate()}`;
            } else {
                state.dateFilter.end = '';
                state.dateFilter.start = '';
            }
        }
    },
    updateDateIntervalFilters(type: 'start'|'end', value: string) {
        state.dateFilter[type] = value;
    },
    updateFilters(filters: any[]) {
        state.filters = filters;
    },
    async downloadCustomLabels(ids?: number[]) {
        return customLabelsApi.exportCustomLabels(ids);
    },
    async deleteCustomLabels(ids: number[]) {
        setLoading(true);
        try {
            await customLabelsApi.deleteCustomLabels(ids);
            gtm.track(gtm.events.CUSTOM_LABEL_DELETED, gtm.categories.CUSTOM_LABEL_DELETED, { ids });
        } catch (error) {
            setLoading(false);
            throw error;
        }
        await actions.getCustomLabels(CLResponseTypeEnum.CREATOR_AND_SIMPLE_PHRASE_SET);

        setLoading(false);
    },
};

export default function useCustomLabelStore() {
    return {
        state,
        getters: {
            ...getters,
            ...uploadGetters,
        },
        actions: {
            ...actions,
            ...makePopupActions(state, actions),
            ...uploadActions,
        }
    };
}
