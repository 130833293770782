import { lightenHexBasedOnLightness, darkenHex } from './color';

let primaryColor = '#1cc6ba';
let backgroundColor = '#ffffff';
let textColor = '#282828';

export let getPrimaryColor = () => primaryColor;
export let getBackgroundColor = () => backgroundColor;
export let getTextColor =() => textColor;

export default function (newPrimaryColor, isDarkMode) {
    if (!newPrimaryColor) return;

    backgroundColor = isDarkMode ? '#282828' : '#ffffff';
    textColor = isDarkMode ? '#ffffff' : '#282828';
    primaryColor = newPrimaryColor;

    if (!document || !document.documentElement || !document.documentElement.style || !document.documentElement.style.setProperty) {
        return;
    }

    document.documentElement.style.setProperty('--color-primary-100', lightenHexBasedOnLightness(newPrimaryColor, 0.9));
    document.documentElement.style.setProperty('--color-primary-200', lightenHexBasedOnLightness(newPrimaryColor, 0.8));
    document.documentElement.style.setProperty('--color-primary-300', lightenHexBasedOnLightness(newPrimaryColor, 0.6));
    document.documentElement.style.setProperty('--color-primary-400', lightenHexBasedOnLightness(newPrimaryColor, 0.5));
    document.documentElement.style.setProperty('--color-primary-500', lightenHexBasedOnLightness(newPrimaryColor, 0.4));
    document.documentElement.style.setProperty('--color-primary-600', lightenHexBasedOnLightness(newPrimaryColor, 0.2));
    document.documentElement.style.setProperty('--color-primary-700', newPrimaryColor);
    document.documentElement.style.setProperty('--color-primary-800', darkenHex(newPrimaryColor, 0.3));
    document.documentElement.style.setProperty('--color-primary-900', darkenHex(newPrimaryColor, 0.6));

    if(isDarkMode) {
        document.documentElement.style.setProperty('--color-bg', '#282828');
        document.documentElement.style.setProperty('--color-black', '#fff');
        document.documentElement.style.setProperty('--color-white', '#282828');
        document.documentElement.style.setProperty('--color-shadow', '0, 0, 0');
        document.documentElement.style.setProperty('--color-neutral-100', '#34343A');
        document.documentElement.style.setProperty('--color-neutral-200', '#424454');
        document.documentElement.style.setProperty('--color-neutral-300', '#52586D');
        document.documentElement.style.setProperty('--color-neutral-400', '#606A81');
        document.documentElement.style.setProperty('--color-neutral-500', '#838DA5');
        document.documentElement.style.setProperty('--color-neutral-600', '#9FA7BC');
        document.documentElement.style.setProperty('--color-neutral-700', '#BEC5D7');
        document.documentElement.style.setProperty('--color-neutral-800', '#D8DCE8');
        document.documentElement.style.setProperty('--color-neutral-900', '#E9EAF2');
        document.documentElement.style.setProperty('--color-neutral-1000', '#F2F3F8');
    }
}
