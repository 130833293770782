import {state} from '@/dashboard/common/store/commonStore';
import {RouteConfig} from 'vue-router';

const Overview = () => import('./Overview.vue');

export default {
    path: ':datasetId/overview',
    component: Overview,
    props: true,
    name: 'overview',
    query: state.query
} as RouteConfig;
