<template>
    <excel-analysis
        v-if="reanalysisSettings"
        is-reanalysis
        :reanalysis-id="reanalysisSettings.id"
        :dataset-id="reanalysisSettings.datasetId"
        :initial-name="reanalysisSettings.name"
        :initial-sheets="reanalysisSettings.sheets"
        :initial-accent-replace="reanalysisSettings.accent"
        :initial-lowercase="reanalysisSettings.lowercase"
        :initial-topic-recognition="reanalysisSettings.topicRecognition"
        :initial-keyword-ids="reanalysisSettings.keywords"
        :initial-text-splitting-type="reanalysisSettings.textSplitting"
        :initial-translation="reanalysisSettings.autoTranslation"
        :initial-translation-with-html="reanalysisSettings.autoTranslationWithHtml"
        :initial-input-translation="reanalysisSettings.inputTranslation"
        :account-manager-email="accountManagerEmail || undefined"
        :last-analysis-at="reanalysisSettings.lastAnalysisAt"
        :plan="plan || undefined"
        :can-use-unique-id="hasNewDictionary"
    />
</template>

<script lang="ts">
import { defineComponent, onUnmounted, toRefs } from 'vue';

import useExcelAnalysisStore from '@/excel-analysis/store';
import useSubscriptionStore from '@/subscription/store';

import ExcelAnalysis from '../components/ExcelAnalysis.vue';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
    components: {
        ExcelAnalysis,
    },
    setup() {
        const router = useRouter();
        const { state, actions } = useExcelAnalysisStore();
        const { reanalysisSettings, hasNewDictionary } = toRefs(state);

        const { accountManagerEmail, plan } = toRefs(useSubscriptionStore().state);

        if (!reanalysisSettings.value) {
            Sentry.addBreadcrumb({ message: 'Redirected, because lack of reanalysisSettings' });
            router.replace('/');
        }

        onUnmounted(() => {
            actions.resetReanalysisSettings();
            actions.resetHasNewDictionary();
        });

        return {
            reanalysisSettings,
            accountManagerEmail,
            plan,
            hasNewDictionary,
        };
    },
});
</script>
