import axios from 'axios';
import globals from '@/utils/globals';
import i18next from 'i18next';
import survey from './survey';
import dashboard from './dashboard';
import auth from './auth';
import feature from './feature';
import BusinessError from '@/domain/errors/BusinessError';

export const api = axios.create({ baseURL: globals.getApiBaseUrl() });

export default {
    setAccessToken(accessToken) {
        api.defaults.headers = {'Authorization': `Bearer ${accessToken}`};
    },
    survey: survey(api),
    dashboard: dashboard(api),
    auth: auth(api),
    feature: feature(api),


    async uploadExcelToAppend({file, dataSourceId}) {
        const formData = new FormData();
        formData.append('file', file);
        const {data} = await api.post(`/portal/project/prepareAppend/${dataSourceId}`, formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
        return data;
    },
    async appendExcel({dataSourceId, fileName, headerRows, newRows, sheetIndex, columnPairs, downsize, delimiter}) {
        await api.post(`/portal/project/startAppend/${dataSourceId}`, {
            fileName: fileName,
            headerRows: parseInt(headerRows),
            newRows: parseInt(newRows),
            sheetIndex: sheetIndex,
            columnPairs: columnPairs,
            downsize: downsize,
            ...(!!delimiter && {delimiter: delimiter})
        });
    },
    async reanalyzeExcel({dataSourceId, headerRows, accent, lowercase, topicRecognition, textSplitting, autoTranslation, autoTranslationWithHtml, name, languageId, keywordIds, columns}) {
        await api.post(
            '/portal/excel-analysis/reanalyze/' + dataSourceId,
            {
                headerRows,
                accent,
                lowercase,
                name,
                languageId,
                keywordIds,
                columns,
                topicRecognition,
                textSplitting,
                auto_translation: autoTranslation,
                auto_translation_with_html:autoTranslationWithHtml
            }
        );
    },
    async analyzeExcel({sheetName, headerRows, rows, columns, accent, lowercase, topicRecognition, textSplitting, autoTranslation, autoTranslationWithHtml, inputTranslation, name, languageId, customLabelIds, file, shouldDownSize, delimiter = null}) {
        const formData = new FormData();
        formData.append('sheetName', JSON.stringify(sheetName));
        formData.append('headerRows', headerRows);
        formData.append('rows', rows);
        formData.append('columns', JSON.stringify(columns));
        formData.append('accent', accent);
        formData.append('lowercase', lowercase);
        formData.append('topicRecognition', topicRecognition);
        formData.append('textSplitting', textSplitting);
        formData.append('autoTranslation', autoTranslation);
        formData.append('autoTranslationWithHtml', autoTranslationWithHtml);
        formData.append('inputTranslation', inputTranslation);
        formData.append('name', name);
        formData.append('languageId', languageId);
        formData.append('customLabels', JSON.stringify(customLabelIds));
        formData.append('file', file);
        if(delimiter) {
            formData.append('delimiter', delimiter);
        }
        if (shouldDownSize) {
            formData.append('downsize', 1);
        }
        await api.post('/portal/excel-analysis', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    async checkIfFileParseable({sheetName, file, dateColumns, columnCount, delimiter = null}) {
        const formData = new FormData();
        formData.append('sheetName', JSON.stringify(sheetName));
        formData.append('file', file);
        formData.append('dateColumns', JSON.stringify(dateColumns));
        formData.append('columnCount', columnCount);
        if(delimiter) {
            formData.append('delimiter', delimiter);
        }
        const { data } = await api.post('/portal/checkIfFileParseable', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return data;
    },
    async translateDatasetThroughReanalysis(datasetId, autoTranslation = null, after = null, autoTranslationWithHtml = null) {
        const {data} = await api.post('/datasets/translateRecords/' + datasetId, { autoTranslation, autoTranslationWithHtml, after });
        return data;
    },
    async recognizeLanguage(text) {
        const {data} = await api.post('/portal/excel-analysis/recognize-language', text);
        return data;
    },
    async getDatasets(offset = 0, limit = 100, isArchived = false) {
        const {data} = await api.get(`/datasets?offset=${offset}&limit=${limit}&isArchived=${isArchived ? 1 : 0}`);
        return data;
    },
    async saveDataset(datasetId, requestData) {
        const {data} = await api.put(`/datasets/${datasetId}`, requestData);
        return data;
    },
    async getAssetsByOwner(ownerId) {
        const { data } = await api.get('/assets/' + ownerId);
        return data;
    },
    async assignOwnershipRights(previousOwnerId, newOwnerId) {
        const { data } = await api.post(`assets/${previousOwnerId}/assign-owner-rights`, {newOwnerId});
        return data;
    },
    async getUserGroupData() {
        const {data} = await api.get('/vue/getUserGroupData');
        return data;
    },
    async initSurvey() {
        const {data} = await api.post('/portal/surveyforms/create', {
            title: i18next.t('GLOBAL.NEW_SURVEYFORM'),
        });
        return data;
    },
    async getSurveyByDatasetId(datasetId) {
        const {data} = await api.get(`/vue/getSurveyByDatasetId/${datasetId}`);
        return data;
    },
    async copySurvey(surveyId) {
        const {data} = await api.post('/portal/surveyforms/copy/' + surveyId);
        return data;
    },
    async deleteDataset(datasetId) {
        await api.post(`/vue/deleteproject/${datasetId}`);
    },
    async renameAnalysis(id, title) {
        await api.post(`/vue/renameanalysis/${id}`, {title});
    },
    async getSampleColumns(dataSourceId) {
        const {data} = await api.get(`/portal/excel-analysis/sample-data/${dataSourceId}`);
        return data;
    },
    async archive(id) {
        await api.post(`/datasets/${id}/archive`);
    },
    async restore(id) {
        await api.post(`/datasets/${id}/restore`);
    },
    async copyDataset(id) {
        const { data } = await api.post(`/datasets/${id}/copy`);
        return data;
    },
    async reanalyzeDataset(id) {
        const { data } = await api.post('/datasets/' + id + '/reanalyze');
        return data;
    },
    async setCustomLabelIds(datasetId, dimensionId, customLabelIds) {
        await api.put(`/datasets/${datasetId}/dimension-definitions/${dimensionId}/custom-labels`, { customLabelIds });
    },
    async setDictionary(datasetId, dimensionId, dictionary) {
        const { data } = await api.put(`/datasets/${datasetId}/dimension-definitions/${dimensionId}/dictionary`, { dictionary });
        return data;
    },
    async setDatasetPermissions(datasetId, permissions) {
        await api.post(`/datasets/${datasetId}/permissions`, { permissions });
    },
    async setDatasetShare(datasetId, enabled) {
        await api.post(`/datasets/${datasetId}/share`, { enabled });
    },
    async setDatasetIpAddresses(datasetId, ipAddresses) {
        await api.post(`/datasets/${datasetId}/ip`, { ipAddresses });
    },
    async getAccessRequestsForDataset(datasetId) {
        const { data } = await api.get(`/datasets/${datasetId}/access-requests`);
        return data;
    },
    async acceptAccessRequest(datasetId, id, permissionLevel) {
        const { data } = await api.post(`/datasets/${datasetId}/access-requests/${id}/accept`, { permissionLevel });
        return data;
    },
    async rejectAccessRequest(datasetId, id) {
        await api.delete(`/datasets/${datasetId}/access-requests/${id}`);
    },
    async requestAccess(datasetId) {
        await api.post(`/datasets/${datasetId}/access-requests`);
    },
    async getAccessRequests() {
        const { data } = await api.get('/access-requests');
        return data;
    },
    async getDatasetAccessRequests(datasetId) {
        const { data } = await api.get(`/datasets/${datasetId}/access-requests`);
        return data;
    },

    //SETTINGS
    /* User settings */
    async getUserData() {
        const {data} = await api.get('/portal/settings/getUserData');
        return data;
    },
    async resetPassword() {
        await api.post('/portal/settings/resetPassword');
    },
    async changeEmail(password, newEmail) {
        try {
            await api.post('/portal/settings/initEmailChange', { password, newEmail });
        } catch (e) {
            if (e.response  && e.response.status === 401) {
                throw new BusinessError(i18next.t('SETTINGS.MFA_WRONG_PASSWORD', 'Wrong password, please try again!'));
            } else {
                throw e;
            }
        }
    },
    async setUserAvatar(originalImageSource, croppedImageSource) {
        const {data} = await api.post('/portal/settings/editAvatar', {originalImageSource, croppedImageSource});
        return data;
    },

    /* Team settings */
    async getOwnGroupData() {
        const {data} = await api.get('/portal/settings/getGroupData');
        return data;
    },
    async editGroupData(groupData, groupId) {
        await api.post(`/portal/settings/editGroupData/${groupId}`, { groupData });
    },
    async deleteGroup(groupId) {
        await api.delete(`/portal/settings/groups/${groupId}`);
    },
    async editGroupAvatar(originalImageSource, croppedImageSource) {
        const {data} = await api.post('/portal/settings/editGroupAvatar', {originalImageSource, croppedImageSource});
        return data;
    },
    async deleteGroupAvatar() {
        const {data} = await api.post('/portal/settings/removeGroupAvatar');
        return data;
    },
    async getBrandSettings() {
        const {data} = await api.get('/portal/settings/getbrandingsettings');
        return data;
    },
    async saveBrandedSurveySettings(settings) {
        const {data} = await api.post('/portal/settings/savesurveybrandingsettings', settings);
        return data;
    },
    async saveBrandingSettings(brand_logo, brand_color, brand_email_from, brand_email_salutation) {
        const { data } = await api.post('/portal/settings/savebrandingsettings', {
            logo_url: brand_logo,
            primary_color: brand_color,
            email_from: brand_email_from,
            email_salutation: brand_email_salutation
        });
        return data;
    },
    async saveBrandLogo(file) {
        const formData = new FormData();
        formData.append('file', file);
        const { data } = await api.post('/portal/settings/uploadBrandLogo', formData);
        return data;
    },
    async deleteBrandLogo() {
        const { data } = await api.post('/portal/settings/deleteBrandLogo');
        return data;
    },

    /* Image Editor */
    async getImage(url) {
        // we use axios not the api here since we will communicate with azure and we dont need the bearer token
        const {data} = await axios.get(url, {responseType: 'blob', withCredentials: false});
        return data;
    },

    /* Activity feed */
    async getNotifications() {
        // TODO! LOL :D
        return 0;
    },
    async notificationsWereWatched() {
        await api.post('/vue/notificationsWereWatched');
    },

    /* Quota and billing */
    async getSubscription() {
        const { data } = await api.get('/subscription');
        return data;
    },
    async getTextAnalysisUsageBreakdown() {
        const {data} = await api.get('/portal/settings/getCurrentTextAnalysisUsageBreakdown');
        return data;
    },

    /* Tokens */
    async getTokens() {
        const {data} = await api.get('/portal/settings/getTokens');
        return data;
    },
    async addToken({ can_write, name }) {
        const {data} = await api.post('/portal/settings/addApiToken', { can_write, name });
        return data;
    },
    async deleteToken(tokenId) {
        const {data} = await api.post('/portal/settings/deleteApiToken/' + tokenId);
        return data;
    },
    async saveDataSecuritySettings(settings) {
        const {data} = await api.put('subscription/sensitive-data-settings', settings);
        return data;
    },

    async getSuperAdmins() {
        const {data} = await api.get('/admin/getsuperadmins');
        return data;
    },
    async getRunningAnalyses () {
        const {data} = await api.get('admin/getRunningAnalyses');
        return data;
    },
    async getPlans() {
        const {data} = await api.get('/admin/getplans');
        return data;
    },
    async getGroups() {
        const {data} = await api.get('/admin/changedefault');
        return data;
    },
    async createNewDemoGroup(demoGroupData) {
        const {data} = await api.post('/admin/createDemoGroup', {demoGroupData});
        return data;
    },
    async setDefaultGroup(groupId) {
        const {data} = await api.post('/admin/changedefault', {groupId});
        return data;
    },
    async getGroupStats(postData) {
        const {data} = await api.post('/admin/list/usergroups', {...postData});
        return data;
    },
    async getActivities(nextPageUrl) {
        const url = nextPageUrl || '/activity-logs';
        const {data} = await api.get(url);
        return data;
    },
    async getGroupActivities(nextPageUrl) {
        const url = nextPageUrl ? nextPageUrl + '&withGroupId=true' : '/activity-logs?withGroupId=true';
        const {data} = await api.get(url);
        return data;
    },
    async getGroupData(groupId) {
        const {data} = await api.get('/admin/getgroupdata/' + groupId);
        return data;
    },
    async getAnnouncements() {
        const {data} = await api.get('/getAnnouncements');
        return data;
    },
    async saveAnnouncement({announcement, picture}) {
        const formData = new FormData();
        formData.append('picture', picture);
        formData.append('id', announcement.id);
        formData.append('title', announcement.title);
        formData.append('text', announcement.text);
        formData.append('more_url', announcement.more_url);
        formData.append('picture_url', announcement.picture_url);
        formData.append('tags', announcement.tags);
        await api.post('/admin/saveAnnouncement', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    async createNewAnnouncement({announcement, picture}) {
        const formData = new FormData();
        formData.append('picture', picture);
        formData.append('title', announcement.title);
        formData.append('text', announcement.text);
        formData.append('more_url', announcement.more_url);
        formData.append('picture_url', announcement.picture_url);
        formData.append('tags', announcement.tags);
        await api.post('/admin/createNewAnnouncement', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        });
    },
    async deleteAnnouncement(id) {
        await api.post('/admin/deleteAnnouncement', { id });
    },
    async setAnnouncementsLastSeen() {
        await api.post('/vue/announcementsWereWatched');
    },
    async copyCustomerSurvey({production ,uniqueName, name}) {
        await api.post('/admin/copySurvey', { production, uniqueName, name});
    },
    async getMembers() {
        const {data} = await api.get('/vue/getmembers');
        return data;
    },
    async resendInvitation(invitedMemberDetails) {
        const {data} = await api.post('/vue/resendinvitation', {invitedMemberDetails: invitedMemberDetails});
        return data;
    },
    async deleteMember(userId) {
        await api.delete(`/users/${userId}`);
    },
    async deleteInvitedMember(userId) {
        await api.delete(`/invitedMember/${userId}`);
    },
    async cleanTrainingData(lang, texts) {
        const { data } = await api.post('/admin/cleanTrainingTexts', { texts, lang });
        return data;
    },
    async requestUpgrade(groupId, feature) {
        const { data } = await api.post(`/portal/settings/requestUpgrade/${groupId}`, { trigger: feature });
        return data;
    },
    async saveNMIToken(token) {
        const { data } = await api.post('/portal/settings/createOrUpdateNMIConnection', { token: token });
        return data;
    },
    async deleteNMIToken() {
        const { data } = await api.post('/portal/settings/deleteNMIConnection');
        return data;
    },
    async saveCustomToken(token) {
        await api.post('/portal/settings/storeCustomToken', { token });
    },
    async deleteCustomToken() {
        await api.post('/portal/settings/deleteCustomToken');
    },
    async scanWebsite(url) {
        const { data } = await api.post('/portal/settings/scan-website', { url });
        return data;
    },
    async sendInstructionEmail(email) {
        await api.post('/portal/settings/send-instruction-email', { email });
    },

    /* Access groups */
    async getAccessGroups() {
        const { data } = await api.get('/accessGroups');
        return data;
    },
    async updateAccessGroup (id, permissionLevel, name) {
        const {data} = await api.put('/accessGroups/' + id, { permissionLevel, name });
        return data;
    },
    async deleteAccessGroup (id) {
        const {data} = await api.delete('/accessGroups/' + id);
        return data;
    },
    async createAccessGroup (name, permissionLevel) {
        const {data} = await api.post('/accessGroups', { permissionLevel, name });
        return data;
    },

    /* New users */

    async getGroupUsers () {
        const {data} = await api.get('/user');
        return data;
    },
    async getSuperadmins () {
        const {data} = await api.get('/user?allSuperadmins=true');
        return data;
    },
    async updateGroupUser (user) {
        const {data} = await api.put('/user/' + user.id, user);
        return data;
    },
    async deleteGroupUser (id) {
        const {data} = await api.delete('/user/' + id);
        return data;
    },
    async deleteSuperAdmin (memberToDelete, newId) {
        const {data} = await api.delete('/user/' + memberToDelete.id + '?selected=' + newId);
        return data;
    },
    async deleteInvitedUser (id) {
        const {data} = await api.delete('/user/invited/' + id);
        return data;
    },
    async inviteNewMember (newUser) {
        const {data} = await api.post('/user', newUser);
        return data;
    },
    async resendInvitationToUser (id) {
        const invitedMemberDetails = { id: id };
        const {data} = await api.post('/vue/resendinvitation', { invitedMemberDetails: invitedMemberDetails });
        return data;
    },
};
