import i18next from 'i18next';
import {api} from '@/api/index.js';
import {EmailConnector} from '@/email-connector/common/emailConnectorInterface';

export const createNewEmailProcessor = async (emailConnector : EmailConnector|undefined = undefined): Promise<EmailConnector> =>  {
    const {data} = await api.post('/email-processor', emailConnector || {
        name: i18next.t('GLOBAL.NEW_EMAIL_PROCESSOR_NAME', 'Email Processor'),
        email_parsing: true,
        customLabelIds: [],
        semanticSettings: {
            language_id: 1,
            accent: true,
            lowercase: true,
            topic_recognition: true,
            opinion_recognition: true,
            keyword_recognition: true,
            relation_recognition: true,
            text_splitting: 1,
            auto_translation: false,
            auto_translation_with_html: false
        },
    });
    return data;
};
export const getEmailProcessorByDatasetId = async (datasetId: string) => {
    const {data} = await api.get('/email-processor?dataset_id='+datasetId);
    return data;
};

export const getEmailProcessorDetails = async (id: string) => {
    const {data} = await api.get('/email-processor/' + id);
    return data;
};

export const saveEmailProcessorDetails = async (emailProcessorDetails): Promise<EmailConnector> => {
    const {data} = await api.put('/email-processor/' + emailProcessorDetails.id, emailProcessorDetails);
    return data;
};

export const reanalyzeEmails = async (id, from) => {
    const {data} = await api.post('/email-processor/' + id + '/reanalyze', { from });
    return data;
};

export const reanalyzeEmailsWithoutParsingChange = async (datasetId, from) => {
    const { data } = await api.post('/datasets/' + datasetId + '/reanalyze', { from });
    return data;
};
