import i18next from 'i18next';

const elementTypeEnum = {
    EXPLANATION: 1,
    TEXT: 2,
    TEXTAREA: 3,
    CHECKBOX: 4,
    DATE: 5,
    DROPDOWN: 6,
    RADIO: 7,
    SCALE: 8,
    NUMBER: 9,
    META: 10,
    RATING: 11,
    MATRIX: 12,
    FILE: 13,
    PICTURE: 14,
    NPS: 15,
    CSAT: 16,
    ORDER: 17,
    CES_5_SCALE: 19,
    CES_7_SCALE: 20,
};
export default elementTypeEnum;

export const standardElements = Object.keys(elementTypeEnum)
    .filter(typeName => typeName !== 'NEW_PAGE')
    .filter(typeName => typeName !== 'META')
    .filter(typeName => typeName !== 'CES_7_SCALE')
    .map((typeName) => {
        return elementTypeEnum[typeName];
    });

export const getTitle = (type) => {
    switch (type) {
    case elementTypeEnum.EXPLANATION:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTBLOCK');
    case elementTypeEnum.TEXT:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_INPUT_TEXT');
    case elementTypeEnum.TEXTAREA:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTAREA');
    case elementTypeEnum.CHECKBOX:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHECKBOX');
    case elementTypeEnum.DATE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_DATE');
    case elementTypeEnum.DROPDOWN:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHOOSER');
    case elementTypeEnum.RADIO:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_RADIOBUTTON');
    case elementTypeEnum.SCALE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_SCALE');
    case elementTypeEnum.NUMBER:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_NUMBER');
    case elementTypeEnum.RATING:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_RATING');
    case elementTypeEnum.MATRIX:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_MATRIX');
    case elementTypeEnum.FILE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_FILE');
    case elementTypeEnum.PICTURE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_PICTURE');
    case elementTypeEnum.NPS:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_NPS', 'NPS® Question');
    case elementTypeEnum.CSAT:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CSAT', 'CSAT Question');
    case elementTypeEnum.ORDER:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_ORDER', 'Order type question');
    case elementTypeEnum.CES_5_SCALE:
    case elementTypeEnum.CES_7_SCALE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CES', 'CES Question');
    default:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTBLOCK');
    }
};

export const getDescription = (type) => {
    switch (type) {
    case elementTypeEnum.EXPLANATION:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTBLOCK_DESCRIPTION');
    case elementTypeEnum.TEXT:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_INPUT_TEXT_DESCRIPTION');
    case elementTypeEnum.TEXTAREA:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTAREA_DESCRIPTION');
    case elementTypeEnum.CHECKBOX:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHECKBOX_DESCRIPTION');
    case elementTypeEnum.DATE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_DATE_DESCRIPTION');
    case elementTypeEnum.DROPDOWN:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHOOSER_DESCRIPTION');
    case elementTypeEnum.RADIO:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CHOOSER_DESCRIPTION');
    case elementTypeEnum.SCALE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_SCALE_DESCRIPTION');
    case elementTypeEnum.NUMBER:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_NUMBER_DESCRIPTION');
    case elementTypeEnum.RATING:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_RATING_DESCRIPTION');
    case elementTypeEnum.MATRIX:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_MATRIX_DESCRIPTION');
    case elementTypeEnum.FILE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_FILE_DESCRIPTION');
    case elementTypeEnum.PICTURE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_PICTURE_DESCRIPTION');
    case elementTypeEnum.NPS:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_NPS_DESCRIPTION', 'Calculates the NPS® and provides specific chart visualization based on the score given by the survey respondent. Promoters range from 10-9, Passives 8-7, and Detractors 6-0. The NPS® is calculated by the percentage of Promoters minus the percentage of Detractors.');
    case elementTypeEnum.CSAT:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CSAT_DESCRIPTION', 'Calculates the Customer Satisfaction Score and provides specific chart visualization based on the score given by the survey respondent. Satisfied range from 5-4, Neutral 3, and Dissatisfied 2-1.');
    case elementTypeEnum.ORDER:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_ORDER_DESCRIPTION', 'Respondents can rank items based on importance or preference. You can see what do people really consider the most important.');
    case elementTypeEnum.CES_5_SCALE:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_CES_DESCRIPTION', 'Customer Effort Score (CES) reflects the amount of effort a customer had to exert to use a product or service, find the information they needed, or get an issue resolved. This is a 1-7 or 1-5 scale, the calculation is Easy(%) - Difficult(%).');
    default:
        return i18next.t('SURVEYFORMS.FORM_ELEMENT_TYPE_TEXTBLOCK_DESCRIPTION');
    }
};

export const getColor = (type) => {
    switch (type) {
    case elementTypeEnum.EXPLANATION:
        return '#FF8900';
    case elementTypeEnum.TEXT:
        return '#FFDE00';
    case elementTypeEnum.TEXTAREA:
        return '#96DC2A';
    case elementTypeEnum.CHECKBOX:
        return '#28D942';
    case elementTypeEnum.DATE:
        return '#2DDBE4';
    case elementTypeEnum.DROPDOWN:
        return '#3FC0FA';
    case elementTypeEnum.RADIO:
        return '#4285F5';
    case elementTypeEnum.SCALE:
        return '#405BF2';
    case elementTypeEnum.NUMBER:
        return '#773EF5';
    case elementTypeEnum.RATING:
        return '#C239D9';
    case elementTypeEnum.MATRIX:
        return '#EA35AC';
    case elementTypeEnum.FILE:
        return '#CD2727';
    case elementTypeEnum.PICTURE:
        return '#CC6D00';
    default:
        return '#4edcd4';
    }
};

export const getIcon = (type) => {
    switch (type) {
    case elementTypeEnum.EXPLANATION:
        return 'align-left';
    case elementTypeEnum.TEXT:
        return 'comment-minus';
    case elementTypeEnum.TEXTAREA:
        return 'comment-plus';
    case elementTypeEnum.CHECKBOX:
        return 'check-square';
    case elementTypeEnum.DATE:
        return 'calendar';
    case elementTypeEnum.DROPDOWN:
        return 'list';
    case elementTypeEnum.RADIO:
        return 'dot-circle';
    case elementTypeEnum.SCALE:
        return 'ellipsis-h-alt';
    case elementTypeEnum.NUMBER:
        return 'hashtag';
    case elementTypeEnum.RATING:
        return 'star';
    case elementTypeEnum.MATRIX:
        return 'table';
    case elementTypeEnum.FILE:
        return 'file-upload';
    case elementTypeEnum.PICTURE:
        return 'camera';
    case elementTypeEnum.NPS:
        return 'tachometer';
    case elementTypeEnum.CSAT:
        return 'smile';
    case elementTypeEnum.ORDER:
        return 'sort';
    case elementTypeEnum.CES_5_SCALE:
        return 'head-side-brain';
    default:
        return 'align-left';
    }
};
