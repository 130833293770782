export default {
    updateId(state, id) {
        state.id = id;
    },
    updateName(state, name) {
        state.name = name !== null ? name : state.name;
    },
    updateLanguageId(state, language_id) {
        state.language_id = parseInt(language_id);
    },
    updateUILanguageId(state, ui_language_id) {
        state.ui_language_id = parseInt(ui_language_id);
    },
    updateDescription(state, description) {
        state.description = description !== null ? description : state.description;
    },
    updateSensitiveDataSettings(state, sensitive_data_settings) {
        state.sensitive_data_settings = sensitive_data_settings;
    },
    updateAvatar(state, avatar) {
        state.avatar = avatar !== null ? avatar : state.avatar;
    },
    updateAuth0Connection(state, auth0Connection) {
        state.auth0Connection = auth0Connection;
    },
    setImgSource(state, imgSource) {
        state.imgSource = imgSource;
    },
    setImgCropped(state, imgCropped) {
        state.imgCropped = imgCropped;
    },
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    updateColors(state, colors) {
        state.primary_color = colors.primary_color;
        state.text_color = colors.text_color;
        state.background_color = colors.background_color;
    },
    updateFonts(state, fontSettings) {
        state.title_font_name = fontSettings.title_font_name;
        state.title_font_weight = fontSettings.title_font_weight;
        state.title_font_size = fontSettings.title_font_size;
        state.question_font_name = fontSettings.question_font_name;
        state.question_font_weight = fontSettings.question_font_weight;
        state.question_font_size = fontSettings.question_font_size;
        state.answer_font_name = fontSettings.answer_font_name;
        state.answer_font_weight = fontSettings.answer_font_weight;
        state.answer_font_size = fontSettings.answer_font_size;
    },
    updateLayoutSpacing(state, spacing) {
        state.layout_spacing = spacing;
    },
    updatePrivacyPolicy(state, {title, url}) {
        state.privacy_policy_title = title;
        state.privacy_policy_url = url;
    },
    setSurveyBrandingSettings(state, surveyData) {
        state.primary_color = surveyData.primary_color;
        state.text_color = surveyData.text_color;
        state.background_color = surveyData.background_color;
        state.title_font_name = surveyData.title_font_name;
        state.title_font_weight = surveyData.title_font_weight;
        state.title_font_size = surveyData.title_font_size;
        state.question_font_name = surveyData.question_font_name;
        state.question_font_weight = surveyData.question_font_weight;
        state.question_font_size = surveyData.question_font_size;
        state.answer_font_name = surveyData.answer_font_name;
        state.answer_font_weight = surveyData.answer_font_weight;
        state.answer_font_size = surveyData.answer_font_size;
        state.layout_spacing = surveyData.layout_spacing;
        state.privacy_policy_title = surveyData.privacy_policy_title;
        state.privacy_policy_url = surveyData.privacy_policy_url;
    },
    setBrandLogo(state, logo) {
        state.brand_logo = logo;
    },
    setBrandColor(state, color) {
        state.brand_color = color;
    },
    setBrandEmailSalutation(state, goodbye) {
        state.brand_email_salutation = goodbye;
    },
    setOrgLevelExcludedEntities(state, entities) {
        state.orgLevelExcludedEntities = entities;
    },
    updateNMIToken(state, token) {
        state.nmi_token = token;
    },
    updateCustomToken(state, token) {
        state.custom_token = token;
    },
};
