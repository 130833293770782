import i18next from 'i18next';

export default function getDimensionDisplayName(name : string) : string {
    if (name.includes('Url Parameter')) return name.replace('Url Parameter: ','');
    switch(name) {
    case 'Filling time':
        return i18next.t('DASHBOARD.METADATA_FILLING_TIME', 'Minutes spent on survey');
    case 'Completed':
        return i18next.t('DASHBOARD.METADATA_COMPLETED', 'Completion');
    case 'Platform':
        return i18next.t('DASHBOARD.METADATA_OS', 'Operating System');
    case 'Device Type':
        return i18next.t('DASHBOARD.FILLER_STATS.DEVICE');
    case 'Browser':
        return i18next.t('DASHBOARD.FILLER_STATS.BROWSER');
    case 'Country':
        return i18next.t('DASHBOARD.METADATA_COUNTRY', 'Country');
    default:
        return name;
    }
}
