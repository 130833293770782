import { reactive } from 'vue';
import useCommonStore from '@/dashboard/common/store/commonStore';
import { getFilters } from '@/dashboard/common/store/filterStore';
import { AggregationDimensionType, AggregationMeasureSubType, AggregationMeasureType, getAggregation } from '@/dashboard/api/aggregationApi';
import {getTopRatedTopics} from '@/dashboard/api/datasetApi';
import useDataserieStore from '../../common/store/dataseriesStore';

import {datasetTypeId} from '@/domain/dataset/datasetTypeId';
import { Filter, FilterTypeEnum, OperatorTypeEnum} from '@/dashboard/common/types/filterInterface';

export interface Rating {
    rating: number,
    mentionNumber: number,
    posMentionNumber: number,
    negMentionNumber: number,
    neuMentionNumber: number,
    avgOpinionIndex: number,
    avgUpvoteNumber: number | null,
}

export interface EntityRatingStat {
    entityId: number,
    entityType: 'custom-label' | 'label',
    mentionNumber: number,
    avgOpinionIndex: number,
    avgUpvoteNumber: number | null,
    avgRating: number,
    ratingDistribution: { [rating: number]: number },
}

export interface TopRatedTopic {
    datetime: number,
    id: number,
    name: string,
    type: string,
    categoryName: string | null,
    opinionIndex: number,
    mentionNumber: number,
    ratingScore: string,
}

export interface TopRatedTopics {
    best: {
        [date: string]: TopRatedTopic[]
    },
    worst: {
        [date: string]: TopRatedTopic[]
    }
}

interface ReviewState {
    ratingDistribution: {
        [ds: string]: Rating[]
    },
    isRatingDistributionLoading: boolean,
    entityRatingStats: {
        [ds: string]: EntityRatingStat[]
    },
    isEntityRatingStatsLoading: boolean,
    topRatedTopics: {
        [ds: string]: TopRatedTopics[]
    },
    isTopRatedTopicsLoading: boolean,
}

export const initialState = reactive<ReviewState>({
    ratingDistribution: { default: [] },
    isRatingDistributionLoading: false,
    entityRatingStats: { default: [] },
    isEntityRatingStatsLoading: false,
    isTopRatedTopicsLoading: false,
    topRatedTopics: { default: [] },
});

export const makeActions = (state) => {
    const initReviews = async () => {
        state.isRatingDistributionLoading = true;

        if (useCommonStore().state.dataset.type !== datasetTypeId.nmi) return;

        const hasUpvoteDimension = !!useCommonStore().state.dataset.dimension_definitions.find(dd => dd.id === 'review_upvote');
        const selectedDataseries = useDataserieStore().getters.selectedDataseries.value;

        // No rating is stored as 0, so we need to filter those out
        const ratingFilter = [{
            dimensionId: 'star_rating_score',
            isMultiSelect: false,
            type: FilterTypeEnum.dimension,
            operator: OperatorTypeEnum.gt,
            value: 0
        }];
        const filters = [...getFilters(), ...ratingFilter] as Filter[];

        state.isRatingDistributionLoading = true;
        state.isTopRatedTopicsLoading = true;
        let ratingDistribution = {};

        if (selectedDataseries.length === 0) {
            ratingDistribution = { default: await getRatingDistribution(state.columnId, hasUpvoteDimension, filters) };
        }
        else {
            const ratingDistributions = await Promise.all(selectedDataseries.map((ds, index) => getRatingDistribution(state.columnId, hasUpvoteDimension, [...ds.filters, ...ratingFilter])));
            ratingDistribution = selectedDataseries.reduce((dataseries, ds, index) => {
                dataseries[ds.id!] = ratingDistributions[index];
                return dataseries;
            }, {});
        }

        state.isRatingDistributionLoading = false;

        // @ts-ignore
        if (Object.values(ratingDistribution)[0].length < 1) return;

        state.ratingDistribution = ratingDistribution;

        state.isEntityRatingStatsLoading = true;
        let entityRatingStats = {};

        if (selectedDataseries.length === 0) {
            entityRatingStats = { default: await getEntityRatingStats(state.columnId, hasUpvoteDimension, filters) };
        }
        else {
            const entityRatingStatDistributions = await Promise.all(selectedDataseries.map((ds, index) => getEntityRatingStats(state.columnId, hasUpvoteDimension, [...ds.filters, ...ratingFilter])));
            entityRatingStats = selectedDataseries.reduce((dataseries, ds, index) => {
                dataseries[ds.id!] = entityRatingStatDistributions[index];
                return dataseries;
            }, {});
        }

        state.entityRatingStats = entityRatingStats;
        state.isEntityRatingStatsLoading = false;

        await getTopRatedTopicsChartData();
    };
    const resetReviews = () => {
        state.ratingDistribution = { default: [] };
        state.isRatingDistributionLoading = false;
        state.entityRatingStats = { default: [] };
        state.isEntityRatingStatsLoading = false;
        state.isTopRatedTopicsLoading = false;
        state.topRatedTopics = { default: [] };
    };
    const getTopRatedTopicsChartData = async (onlyCls = false) => {
        state.isTopRatedTopicsLoading = true;
        let topRatedTopics = {};
        const datasetId = useCommonStore().state.dataset.id;
        const selectedDataseries = useDataserieStore().getters.selectedDataseries.value;
        if (selectedDataseries.length === 0) {
            const result = await getTopRatedTopics(datasetId, state.columnId, onlyCls, [getFilters()]);
            topRatedTopics = { default: result[0] };
        }
        else {
            const dsFilters = selectedDataseries.map(ds => ds.filters);
            const topRatedTopicsDistributions = await getTopRatedTopics(datasetId, state.columnId, onlyCls, dsFilters);
            topRatedTopics = selectedDataseries.reduce((dataseries, ds, index) => {
                dataseries[ds.id!] = topRatedTopicsDistributions[index];
                return dataseries;
            }, {});
        }
        state.isTopRatedTopicsLoading = false;
        state.topRatedTopics = topRatedTopics;
    };
    return {
        initReviews,
        resetReviews,
        getTopRatedTopicsChartData
    };
};

async function getRatingDistribution(verbatimDimensionId: string, hasUpvote: boolean, filters: Filter[]): Promise<Rating[]> {
    const basicMeasures = [
        { name: 'avg_opinion_index', type: AggregationMeasureType.avg, subType: AggregationMeasureSubType.opinionIndex, dimensionId: verbatimDimensionId },
        { name: 'mention_number', type: AggregationMeasureType.count, subType: AggregationMeasureSubType.value },
        { name: 'pos_mention_number', type: AggregationMeasureType.posCount, subType: AggregationMeasureSubType.opinionIndex, dimensionId: verbatimDimensionId },
        { name: 'neg_mention_number', type: AggregationMeasureType.negCount, subType: AggregationMeasureSubType.opinionIndex, dimensionId: verbatimDimensionId },
        { name: 'neu_mention_number', type: AggregationMeasureType.neuCount, subType: AggregationMeasureSubType.opinionIndex, dimensionId: verbatimDimensionId },
    ];
    const upvoteMeasure = hasUpvote ? [{ name: 'avg_upvote', type: AggregationMeasureType.avg, dimensionId: 'review_upvote' }] : [];

    const response = await getAggregation(
        useCommonStore().state.dataset.id,
        [{ name: 'rating', type: AggregationDimensionType.value, dimensionId: 'star_rating_score' }],
        [...basicMeasures, ...upvoteMeasure],
        filters,
    );

    return response.map(r => ({
        rating: parseFloat(r['rating']),
        avgOpinionIndex: parseFloat(r['avg_opinion_index']),
        mentionNumber: parseInt(r['mention_number']),
        posMentionNumber: parseInt(r['pos_mention_number']),
        negMentionNumber: parseInt(r['neg_mention_number']),
        neuMentionNumber: parseInt(r['neu_mention_number']),
        avgUpvoteNumber: hasUpvote ? parseFloat(r['avg_upvote']) : null,
    }));
}


async function getEntityRatingStats(verbatimDimensionId: string, hasUpvote: boolean, filters: Filter[]): Promise<EntityRatingStat[]> {
    const basicMeasures = [
        { name: 'mention_number', type: AggregationMeasureType.count, subType: AggregationMeasureSubType.value },
        { name: 'avg_opinion_index', type: AggregationMeasureType.avg, subType: AggregationMeasureSubType.opinionIndex, dimensionId: verbatimDimensionId },
        { name: 'avg_rating', type: AggregationMeasureType.avg, dimensionId: 'star_rating_score' }
    ];
    const upvoteMeasure = hasUpvote ? [{ name: 'avg_upvote', type: AggregationMeasureType.avg, dimensionId: 'review_upvote' }] : [];

    const response = await getAggregation(
        useCommonStore().state.dataset.id,
        [{ name: 'entity', type: AggregationDimensionType.entities, dimensionId: verbatimDimensionId }],
        [...basicMeasures, ...upvoteMeasure],
        filters,
    );

    const stats = response.map(r => ({
        entityId: r['entity-id'],
        entityType: r['entity-type'],
        mentionNumber: parseInt(r['mention_number']),
        avgOpinionIndex: parseFloat(r['avg_opinion_index']),
        avgRating: parseFloat(r['avg_rating']),
        avgUpvoteNumber: hasUpvote ? parseFloat(r['avg_upvote']) : null,
        ratingDistribution: {}
    }));

    return addEntityRatingDistribution(stats, verbatimDimensionId, filters);
}

async function addEntityRatingDistribution(entityRatingStats: EntityRatingStat[], verbatimDimensionId: string, filters: Filter[]): Promise<EntityRatingStat[]> {
    const response = await getAggregation(
        useCommonStore().state.dataset.id,
        [
            { name: 'entity', type: AggregationDimensionType.entities, dimensionId: verbatimDimensionId },
            { name: 'score', type: AggregationDimensionType.value, dimensionId: 'star_rating_score' },
        ],
        [{ name: 'count', type: AggregationMeasureType.count, subType: AggregationMeasureSubType.value }],
        filters,
    );

    response.forEach(d => {
        const entity = entityRatingStats.find(e => d['entity-id'] === e.entityId && d['entity-type'] === e.entityType);
        if (entity) {
            entity.ratingDistribution[parseInt(d.score)] = d.count;
        }
    });

    return entityRatingStats;
}
