import { RouteConfig } from 'vue-router';
const NewWebCrawlerPage = () => import('../pages/NewWebsiteCrawlerPage.vue');
const ReanalyzePage = () => import('../pages/ReanalyzePage.vue');
const AppendPage = () => import('../pages/AppendPage.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/',
    },
    {
        path: 'new',
        component: NewWebCrawlerPage,
    },
    {
        path: 'reanalyze/:id',
        component: ReanalyzePage,
    },
    {
        path: 'append-url/:id',
        component: AppendPage,
        props: { mode: 'url' },
    },
    {
        path: 'append-content/:id',
        component: AppendPage,
        props: { mode: 'content' },
    },
];

export default routes;
