// import * as XLSX from 'xlsx';
import { isFirstRowHeader, getDimensionTypeForColumn, verbatimColumnFallback, recognizeColumnLanguage } from './excelSettings.ts';
import dimensionTypeEnum from '../dimensionTypeEnum';
import { CsvDelimiterEnum, getCsvDelimiterCharacter } from '@/excel-analysis/store';

export default async ({
    file,
    guessDimensionTypes = true,
    limit = 100,
    defaultType = null,
    delimiter = CsvDelimiterEnum.SEMICOLON,
    setDateFormatOnRead = false,
    noVerbatimColumn = false,
}) => {
    const response = {
        sheets: {},
        name: generateNameFromFileName(file.name),
    };
    const isCsv = file.type === 'text/csv';

    let separator = undefined;
    if (isCsv) {
        separator = getCsvDelimiterCharacter(delimiter);
    }

    const data = await file.arrayBuffer();

    const promise = new Promise((resolve) => {
        const worker = new Worker('/worker.js');

        worker.addEventListener('message', ({ data }) => {
            worker.terminate();
            resolve(data);
        });

        worker.postMessage({
            task: 'read',
            file: data,
            separator,
            setDateFormatOnRead
        });
    });

    const workbook = await promise;

    const promises = workbook.SheetNames.map((sheetName, index) => {
        return new Promise((resolve) => {
            const rowNumber = getLastRow(workbook.Sheets[sheetName]);
            const worker = new Worker('/worker.js');

            worker.addEventListener('message', async ({ data }) => {
                const sheetData = data;
                const columns = getSampleColumns(sheetData, rowNumber);
                if (columns) {
                    response.sheets[sheetName] = {
                        rows: rowNumber,
                        index: index, // object functions can mess up order which can cause problems with sheet index, so it's better to add it here
                        ...await getSheetConfig(columns, guessDimensionTypes, defaultType, noVerbatimColumn)
                    };
                }
                worker.terminate();
                resolve(null);
            });

            worker.postMessage({
                task: 'json',
                sheet: workbook.Sheets[sheetName],
                limit,
                rowNumber
            });
        });
    });

    await Promise.all(promises);
    response.activeSheet = workbook.Workbook && workbook.Workbook.WBView && workbook.Workbook.WBView[0] && workbook.Workbook.WBView[0].activeTab ?
        workbook.SheetNames[workbook.Workbook.WBView.length > 0 ? workbook.Workbook.WBView[0].activeTab : 0]:
        workbook.SheetNames[0];
    if (!response.sheets[response.activeSheet]) {
        response.activeSheet = Object.keys(response.sheets)[0];
    }

    return response;
};

function generateNameFromFileName(fileName) {
    // remove .xlsx
    const withoutExtension = fileName.split('.').slice(0, -1).join('.');
    //all _ characters are replaced by space
    const withoutUnderscore = withoutExtension.split('_').join(' ');
    //first character is capitalized
    return withoutUnderscore.charAt(0).toUpperCase() + withoutUnderscore.slice(1);
}

function getSampleColumns(sheetData, rowNumber) {
    if (!sheetData.length) {
        return null;
    }
    const columns = [];
    sheetData.forEach((row, rowIndex) => row.forEach((cell, column) => {
        if (!Array.isArray(columns[column])) {
            columns[column] = [];
        }
        columns[column][rowIndex] = cell;
    }));
    const longestColumnRowNumber = columns.reduce((max, current) => Math.max(max, current.length), 0);
    for (let i = 0; i < columns.length; i++) {
        if (columns[i] === undefined) {
            columns[i] = Array(rowNumber).fill('');
            continue;
        }
        for (let j = 0; j < longestColumnRowNumber; j++) {
            columns[i][j] = columns[i][j] === undefined ? '' : columns[i][j];
        }
    }
    return columns;
}

async function getSheetConfig(sampleColumns, guessDimensionTypes, defaultType, noVerbatimColumn = false) {
    const sheetConfig = {
        headerRows: isFirstRowHeader(sampleColumns.map(column => column[0])) ? 1 : 0,
        columnData: sampleColumns,
    };

    if (guessDimensionTypes) {
        sheetConfig.columnTypes = sampleColumns.map(column => getDimensionTypeForColumn(column, noVerbatimColumn));

        if (
            sheetConfig.columnTypes.every(columnType => columnType !== dimensionTypeEnum.VERBATIM && columnType !== dimensionTypeEnum.URL)
            && !noVerbatimColumn
        ) {
            sheetConfig.columnTypes[verbatimColumnFallback(sampleColumns)] = dimensionTypeEnum.VERBATIM;
        }

        const verbatimColumnIndex = sheetConfig.columnTypes.findIndex(columnType => columnType === dimensionTypeEnum.VERBATIM);
        if (verbatimColumnIndex >= 0) {
            sheetConfig.languageId = await recognizeColumnLanguage(sampleColumns[verbatimColumnIndex]);
        }
    } else {
        sheetConfig.columnTypes = sampleColumns.map(() => defaultType || null);
    }

    if (sheetConfig.headerRows === 1) {
        sheetConfig.columnNames = sampleColumns.map(column => column[0]);
    } else {
        sheetConfig.columnNames = sampleColumns.map((_, index) => `Column ${index + 1}`);
    }

    return sheetConfig;
}

function getLastRow(sheet) {
    let maxRow = 0;
    for (const cell in sheet ) {
        if (!cell.match(/^[A-Z]+\d+/) || !sheet[cell].v) {
            continue;
        }
        const currentRow = parseInt(cell.match(/\d+$/)[0]);
        maxRow = currentRow > maxRow ? currentRow : maxRow;
    }
    return maxRow;
}
