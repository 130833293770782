export default {
    demoGroupData: state => {
        return {
            name: state.name,
            account_manager_id: state.account_manager_id,
            language_id: state.language_id,
            ui_language_id: state.ui_language_id,
            demoPlanQuota: state.demoPlanQuota,
            logoSettings: {
                logo_source: state.logoSource,
                logo_cropped: state.logoCropped,
            },
            fontSettings: {
                title_font_name: state.title_font_name,
                title_font_weight: state.title_font_weight,
                title_font_size: state.title_font_size,
                question_font_name: state.question_font_name,
                question_font_weight: state.question_font_weight,
                question_font_size: state.question_font_size,
                answer_font_name: state.answer_font_name,
                answer_font_weight: state.answer_font_weight,
                answer_font_size: state.answer_font_size
            },
            colorSettings: state.colors,
            layoutSettings: {
                spacing: state.layout_spacing
            },
        };
    },
};
