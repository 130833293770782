<template>
    <div class="main text-neutral-900 space-y-1 mt-2">
        <z-tag
            v-for="tagText in alphabetOrderedTagList"
            :key="tagText"
            deletable
            class="mr-1 mb-1"
            @remove="removeTag(tagText)"
        >
            {{ tagText }}
        </z-tag>
        <z-input
            v-model="inputValue"
            type="text"
            :placeholder="tagList.length > 0 ? '' : placeholder"
            :disabled="disabled"
            @keyup.enter.prevent="addTags"
            @blur="addTags"
            @focus="$emit('focus')"
        />
    </div>
</template>

<script>
export default {
    props: {
        tagList: { type: Array, default: () => [] },
        placeholder: { type: String, default: null },
        tagType: { type: String, default: null },
        disabled: { type: Boolean, default: false },
    },
    data() {
        return {
            inputValue: '',
        };
    },
    computed: {
        alphabetOrderedTagList() {
            return [...this.tagList].sort((a,b) => a.localeCompare(b));
        }
    },
    methods: {
        removeTag: function(tagTextToDelete) {
            this.$emit('removeTag', tagTextToDelete);
        },
        addTags: function() {
            let newTags = [];
            if(this.tagType === 'email') {
                newTags = (this.inputValue || '').split(/,|;| /).map(tag => tag.trim()).filter(tag => tag !== '');
            } else {
                newTags = (this.inputValue || '').split(/,/).map(tag => tag.trim()).filter(tag => tag !== '');
            }
            this.$emit('input', newTags);
            this.inputValue = null;
        },
    },
};
</script>
<style lang="less" scoped>
    .main {
        overflow: hidden;
        font-size: 14px;
        border-radius: 4px;

        input {
            float: left;
            margin-top: 6px;
            font-size: 12px;
            width: 100%;
            padding: 5px;
        }

        .form-element {
            border-bottom: unset;
        }
    }
</style>
