import i18next from 'i18next';

const features = {
    // dataset quotas
    TEXT_ANALYSIS: 'text_analysis',
    SURVEY: 'survey',
    SURVEY_RESPONSE: 'survey_response',
    SURVEY_VERBATIM: 'survey_verbatim',
    EXCEL_UPLOAD: 'excel_upload',
    FILE_UPLOAD_VERBATIM: 'file_verbatim',
    EMAIL_CONNECTOR: 'email_connector',
    EMAIL_RECORD: 'email_record',
    API_CONNECTOR: 'api_connector',
    API_VERBATIM: 'api_verbatim',
    API_RESPONSE: 'api_response',
    NMI_INTEGRATION: 'nmi_integration',
    NMI_RECORD: 'nmi_record',
    WEBSITE_CRAWLER: 'website_crawler',
    WEBSITE_CRAWLER_RECORD: 'website_crawler_record',
    AUDIENCE: 'audience',
    PDF_EXTRACTOR: 'pdf_extractor',
    PDF_RECORD: 'pdf_record',
    DOMO_INTEGRATION: 'domo_integration',
    DOMO_VERBATIM: 'domo_verbatim',
    DOMO_RESPONSE: 'domo_response',

    // advanded features
    USER: 'user',
    CUSTOM_LABEL: 'custom_labels',
    CUSTOM_LOOK: 'custom_look',
    WHITELABEL: 'whitelabel',
    IFRAME_DASHBOARD_EMBED: 'iframe_dashboard_embed',
    TWO_STEP_VERIFICATION: 'two_step_verification',
    ACCESS_GROUP: 'access_group',
    MULTILANG_SURVEY: 'multilang_survey',
    CUSTOM_SUBDOMAIN: 'custom_subdomain',
    DATASET_TRANSLATION: 'dataset_translation',

    // export features
    SPSS_OUTPUT: 'spss_output',
    EXCEL_OUTPUT: 'excel_output',
    API_ACCESS: 'api_access',
    TABLEAU_INTEGRATION: 'tableau_integration',
};

export const getDisplayName = (feature) => {
    switch (feature) {
    case features.TEXT_ANALYSIS:
        return i18next.t('SETTINGS.FEATURES_TEXT_ANALYSIS', 'Text analysis');
    case features.USER:
        return i18next.t('SETTINGS.FEATURES_USER', 'User');
    case features.SURVEY:
        return i18next.t('SETTINGS.FEATURES_SURVEY', 'Survey');
    case features.SURVEY_VERBATIM:
        return i18next.t('SETTINGS.FEATURES_SURVEY_VERBATIM', 'Survey verbatim');
    case features.SURVEY_RESPONSE:
        return i18next.t('SETTINGS.FEATURES_SURVEY_RESPONSE', 'Survey response');
    case features.EXCEL_UPLOAD:
        return i18next.t('SETTINGS.FEATURES_EXCEL_UPLOAD', 'Excel upload');
    case features.FILE_UPLOAD_VERBATIM:
        return i18next.t('SETTINGS.FEATURES_FILE_UPLOAD_VERBATIM', 'File upload verbatim');
    case features.SPSS_OUTPUT:
        return i18next.t('SETTINGS.FEATURES_SPSS_OUTPUT', 'SPSS output');
    case features.EXCEL_OUTPUT:
        return i18next.t('SETTINGS.FEATURES_EXCEL_OUTPUT', 'Excel output');
    case features.CUSTOM_LABEL:
        return i18next.t('SETTINGS.FEATURES_CUSTOM_LABEL', 'Custom label');
    case features.CUSTOM_LOOK:
        return i18next.t('SETTINGS.FEATURES_CUSTOM_LOOK', 'Custom look');
    case features.TWO_STEP_VERIFICATION:
        return i18next.t('SETTINGS.FEATURES_TWO_STEP_VERIFICATION', 'Two step verification');
    case features.API_ACCESS:
        return i18next.t('SETTINGS.FEATURES_API_ACCESS', 'API access');
    case features.WHITELABEL:
        return i18next.t('SETTINGS.FEATURES_WHITELABEL', 'Whitelabel');
    case features.EMAIL_CONNECTOR:
        return i18next.t('SETTINGS.FEATURES_EMAIL_CONNECTOR', 'Email connector');
    case features.EMAIL_RECORD:
        return i18next.t('SETTINGS.FEATURES_EMAIL_RECORD', 'Email record');
    case features.API_CONNECTOR:
        return i18next.t('SETTINGS.FEATURES_API_CONNECTOR', 'API connector');
    case features.API_VERBATIM:
        return i18next.t('SETTINGS.FEATURES_API_VERBATIM', 'API verbatim');
    case features.NMI_INTEGRATION:
        return i18next.t('SETTINGS.FEATURES_NMI_INTEGRATION', 'NMI integration');
    case features.NMI_RECORD:
        return i18next.t('SETTINGS.FEATURES_NMI_RECORD', 'NMI record');
    case features.API_RESPONSE:
        return i18next.t('SETTINGS.FEATURES_API_RESPONSE', 'API connector response');
    case features.ACCESS_GROUP:
        return i18next.t('SETTINGS.FEATURES_ACCESS_GROUP', 'Advanced access control');
    case features.TABLEAU_INTEGRATION:
        return i18next.t('SETTINGS.FEATURES_TABLEAU_INTEGRATION', 'Tableau integration');
    case features.MULTILANG_SURVEY:
        return i18next.t('SETTINGS.FEATURES_MULTILANG_SURVEY', 'Multilingual survey');
    case features.CUSTOM_SUBDOMAIN:
        return i18next.t('SETTINGS.FEATURES_CUSTOM_SUBDOMAIN', 'Custom subdomain for shared dashboard');
    case features.DATASET_TRANSLATION:
        return i18next.t('SETTINGS.FEATURES_DATASET_TRANSLATION', 'Dataset translation');
    case features.IFRAME_DASHBOARD_EMBED:
        return i18next.t('SETTINGS.FEATURES_IFRAME_DASHBOARD_EMBED', 'Iframe dashboard embed');
    case features.WEBSITE_CRAWLER:
        return i18next.t('SETTINGS.FEATURES_WEBSITE_CRAWLER', 'Website crawler');
    case features.WEBSITE_CRAWLER_RECORD:
        return i18next.t('SETTINGS.FEATURES_WEBSITE_CRAWLER_RECORD', 'Website crawler record');
    case features.AUDIENCE:
        return i18next.t('SETTINGS.FEATURES_AUDIENCE', 'Audience');
    case features.PDF_EXTRACTOR:
        return i18next.t('SETTINGS.FEATURES_PDF_EXTRACTOR', 'PDF extractor');
    case features.PDF_RECORD:
        return i18next.t('SETTINGS.FEATURES_PDF_RECORD', 'PDF extractor record');
    case features.DOMO_INTEGRATION:
        return i18next.t('SETTINGS.FEATURES_DOMO_INTEGRATION', 'Domo Integration');
    case features.DOMO_VERBATIM:
        return i18next.t('SETTINGS.FEATURES_DOMO_VERBATIM', 'Domo Integration verbatim');
    case features.DOMO_RESPONSE:
        return i18next.t('SETTINGS.FEATURES_DOMO_RESPONSE', 'Domo Integration record');
    default:
        return feature;
    }
};

export const isFeatureDeactivable = (featureName) => {
    switch (featureName) {
    case features.USER:
    case features.SURVEY_RESPONSE:
        return false;
    default:
        return true;
    }
};

export const isFeatureLimitable = (featureName) => {
    switch (featureName) {
    case features.TEXT_ANALYSIS:
    case features.USER:
    case features.SURVEY:
    case features.SURVEY_RESPONSE:
    case features.DATASET_TRANSLATION:
        return true;
    default:
        return false;
    }
};

export const isVerbatimFeature = (featureName) => {
    switch (featureName) {
    case features.API_VERBATIM:
    case features.DOMO_VERBATIM:
    case features.SURVEY_VERBATIM:
    case features.FILE_UPLOAD_VERBATIM:
        return true;
    default:
        return false;
    }
};

export const getColor = (type) => {
    switch (type) {
    case features.FILE_UPLOAD_VERBATIM:
        return '#66d96b';
    case features.SURVEY_RESPONSE:
    case features.SURVEY_VERBATIM:
        return '#42b3ff';
    case features.EMAIL_RECORD:
        return '#eeaa22';
    case features.API_VERBATIM:
    case features.API_RESPONSE:
        return '#f06060';
    case features.NMI_RECORD:
        return '#007bff';
    case features.WEBSITE_CRAWLER_RECORD:
        return '#0e7069';
    case features.PDF_RECORD:
        return '#df9500';
    case features.DOMO_VERBATIM:
    case features.DOMO_RESPONSE:
        return '#4edcd4';
    default:
        return '#606A81';
    }
};

export const isFeatureRenewable = (featureName) => {
    switch (featureName) {
    case features.TEXT_ANALYSIS:
    case features.SURVEY_RESPONSE:
        return true;
    default:
        return false;
    }
};

export default features;
