import useCommonStore from '../store/commonStore';

import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';

export default function getChartData(response: any[], dictionary: any, type?: number) {
    if (!dictionary || dictionary.length === 0) {
        return response.map(el => ({
            ...el,
            name: type === dimensionTypeId.DATE ? parseInt(el.id) : el.id,
        }));
    }

    if (dictionary && !dictionary.questions) {
        return Object.keys(dictionary).map(key => {
            const value = response.find(el => el.id == key) ? response.find(el => el.id == key).value : 0;

            return {
                id: key,
                name: dictionary[key],
                value
            };
        });
    }

    if (dictionary && dictionary.questions) {
        if (!useCommonStore().state.dataset.hasNewDictionary) {
            return Object.values(dictionary.questions).map((question, index) => {
                let row = response.find(row => row.id == index);

                if (!row) {
                    row = {
                        id: index,
                        name: dictionary.questions[index],
                        values: Object.keys(dictionary.ranges).map((key) => ({
                            id: key,
                            name: dictionary.ranges[key].range,
                            value: 0
                        }))
                    };
                }
                else {
                    row.name = dictionary.questions[index];
                    row.values = Object.keys(dictionary.ranges).map((key) => {
                        // Because of special keys like 999 (DKNA), 1000 (other), key should be used instead of index
                        const value = row.values.find(value => value.id == key) ?
                            row.values.find(value => value.id == key).value :
                            0;

                        return {
                            id: key,
                            name: dictionary.ranges[key].range,
                            value
                        };
                    });
                }

                return {
                    ...row,
                    name: question
                };
            });
        }
        else {
            return Object.entries(dictionary.questions).map(([id, question]) => {
                const row = response.find(row => row.id === id);

                const values = dictionary.ranges.map((range) => {
                    return {
                        id: range.id,
                        name: range.range,
                        value: row?.values.find(v => v.id === range.id)?.value ?? 0,
                    };
                });

                return {
                    ...row,
                    id: id,
                    name: question,
                    values,
                };
            });
        }
    }

    return [];
}
