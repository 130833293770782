<template>
    <loading-screen v-if="isLoading && !oldLoadingScreenIsVisible" />
</template>

<script>
import LoadingScreen from '@/components/ui/LoadingScreen.vue';
import { isLoading } from './index.js';

export default {
    components: { LoadingScreen },
    setup() {
        return { isLoading,  };
    },
    computed: {
        oldLoadingScreenIsVisible() {
            return this.$load.isVisible();
        }
    },
};
</script>
