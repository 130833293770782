<template>
    <div class="coffee-page w-full h-full pt-56 pl-10">
        <div class="w-96">
            <div v-html="$t('ANALYSIS.ANALYSIS_STARTED')" />
            <br>
            <router-link to="/">
                <z-button>{{ $t('ANALYSIS.ANALYSIS_STARTED_BUTTON') }}</z-button>
            </router-link>
        </div>
    </div>
</template>
<script>
import globals from '@/utils/globals';
import {onMounted} from 'vue';
import getCookie from '@/utils/getCookie';
import gtm from '@/utils/gtm';


export default {
    components: {

    },
    setup() {
        onMounted(() => {
            if(getCookie('isNeticleUser') === 'false') {
                gtm.track(gtm.events.RNPS_EVENT, gtm.categories.RNPS_EVENT);
            }
        });
    },
    data() {
        return {
            siteIndex: globals.getSelfBaseUrl(),
        };
    },
};
</script>
<style scoped lang="less">
    .coffee-page {
        background-image: url('../../assets/images/coffee.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .coffee-text {
        width: 60%;
    }
</style>
