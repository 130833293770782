import {reactive, readonly, computed} from 'vue';
import makeGetters from './getters';
import makeActions from './actions';
import {State, Stat, DetailedStat} from './statInterface';

export type AnalyticsState = State<{
    isLoading: boolean,
    answers: number,
    stats: Stat[],
    completedDimension: Stat | null,
    submittedAtDimension: { default: Stat } | null,
    avgFillingDimension: Stat | null,
    furtherCharts: { default: DetailedStat[]}
}>

export const state: AnalyticsState = reactive({
    isLoading: true,
    answers: 0,
    stats: [],
    completedDimension: null,
    submittedAtDimension: null,
    avgFillingDimension: null,
    furtherCharts: { default: [] }
});

export const getters = {...makeGetters()};
export const actions = {...makeActions(state)};

export default function useAnalyticsStore() {
    return {
        state: readonly(state),
        getters,
        actions
    };
}
