var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",staticStyle:{"top":"4px","left":"4px"},attrs:{"data-test-id":"excel-sheets"}},_vm._l((_vm.sheetNames),function(sheetName,i){return _c('span',{key:sheetName,staticClass:"cursor-pointer rounded-t leading-8 shadow mr-1 mb-1 text-default",class:{
            active: _vm.value === sheetName,
            'bg-primary-700': _vm.value === sheetName,
            'text-white': _vm.value === sheetName,
            'font-bold': _vm.value === sheetName,
            'bg-bg': _vm.value !== sheetName,
            'text-neutral-700': _vm.value !== sheetName,
        },staticStyle:{"padding":"4px 20px 4px 10px"},attrs:{"data-test-id":'excel-sheet-' + i},on:{"click":function($event){return _vm.$emit('input', sheetName)}}},[_vm._v(" "+_vm._s(sheetName.charAt(0).toUpperCase() + sheetName.slice(1))+" ")])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }