function getDefaultState() {
    return {
        options: [],
        pendingOptionsFromFile: [],
    };
}

export default {
    namespaced: true,
    state: getDefaultState,
    mutations: {
        addPendingOption(state, { guid, index }) {
            state.options.push({ guid, index });
        },
        deletePendingOption(state, { guid, index }) {
            state.options = state.options
                .filter(option => option.guid !== guid || option.index !== index)
                .map(option => {
                    if (guid !== option.guid) return option;

                    if (index < option.index) {
                        return {
                            ...option,
                            index: option.index - 1
                        };
                    }
                });
        },
        resetPendingOptions(state) {
            state.options = [];
        },
        addPendingOptionsFromFile(state, { guid, options }) {
            state.pendingOptionsFromFile.push({ guid, options });
        },
        resetPendingOptionsFromFile(state) {
            state.pendingOptionsFromFile = [];
        },
        reset(state) {
            const defaultState = getDefaultState();

            for (const key in defaultState) {
                state[key] = defaultState[key];
            }
        },
    },
    actions: {
        async savePendingOptionsFromFile({ state, commit, dispatch }) {
            const promises = state.pendingOptionsFromFile.map(element => {
                return dispatch('survey/saveOptionsFromFile', { dimensionId: element.guid, options: element.options }, { root: true });
            });
            await Promise.all(promises);
            commit('resetPendingOptionsFromFile');
        }
    }
};
