<template>
    <div v-if="false" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import useSubscriptionStore from '../store';

export default defineComponent({
    setup() {
        const { state, getters } = useSubscriptionStore();
        return { state, getters };
    },
});
</script>
