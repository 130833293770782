import {Filter} from '@/dashboard/common/types/filterInterface';

export interface RafiStoreState {
    initialized: boolean,
    rafiSpecificDataset: boolean,
    activeTab: string,
    isLoading: boolean,
    leftFilter: Filter|null,
    dateDimensionId: string|null,
    ratingDimensionId: string|null,
    verbatimDimensionId: string|null,
    versionDimensionId: string|null,
    interactionCustomLabelIds: number[],
    designAndPerformanceCustomLabelIds: number[],
    feesCustomLabelIds: number[],
    response: TransformedResponse[],
    ratingsByDate: any[],
    ratingsByDateAndTheme: any[],
    ratingsByVersion: any[],
    themesByMonths: any[],
    themesByVersions: any[],
    distributionOfThemes: any[],
    ratingsByThemes: any[],
    distributionOfCustomLabels: any[],
    ratingsByCustomLabels: any[],
    recentResponse: TransformedResponse[],
    recentDistributionOfCustomLabels: any[],
    recentRatingsByCustomLabels: any[],
}

export interface AggregationResponse {
    customlabel: number,
    rating: string|null,
    version: string|null,
    date: string|null,
    value: number
}

export interface TransformedResponse {
    customLabelId: number,
    customLabelName: string,
    customLabelCategoryName: string,
    version: string|null,
    date: number|null,
    month: string|null,
    rating: number|null,
    polarity: string|null,
    ratingSum: number|null,
    value: number,
}

/**
 * Chart data types
 **/

export interface SingleDiscreteDistribution {
    name: string|number,
    value: number
}

export interface RatingBasedStackedDistribution {
    dimensionValueName1: string,
    dimensionValueName2: string,
    value: number,
    ratingSum: number,
    category?: string
}

export interface StackedDistribution {
    name: string|number,
    values: { name: string, value: number}[]
}

/**
* Rafi-specific enums
**/


export enum rafiXDimensionType {
    DATE='date',
    VERSION='version',
    CUSTOM_LABEL='customlabel',
    CUSTOM_LABEL_CATEGORY='category',
}

export enum rafiYDimensionType {
    RATING='rating',
    CUSTOM_LABEL_CATEGORY='category',
    VALUE='value',
}

export enum rafiTheme {
    INTERACTION='Interactions',
    DESIGN_AND_PERFORMANCE='Design & Performance',
    FEES='Fees',
    GENERAL_MOOD='General Mood',
}
