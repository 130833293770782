<template>
    <svg
        class="loading-spinner"
        width="16"
        height="16"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.5 0.5C4.0625 0.5 0.5 4.09375 0.5 8.5C0.5 12.9375 4.0625 16.5 8.5 16.5C12.9062 16.5 16.5 12.9375 16.5 8.5C16.5 4.09375 12.9062 0.5 8.5 0.5ZM8.5 15C4.90625 15 2 12.0938 2 8.5C2 4.9375 4.90625 2 8.5 2C12.0625 2 15 4.9375 15 8.5C15 12.0938 12.0625 15 8.5 15Z"
            fill="currentColor"
            :class="circleColorClass"
        />
        <path
            d="M16.5 8.5C16.5 9.65625 16.25 10.75 15.8125 11.7188C15.625 12.125 15.125 12.3125 14.75 12.0938C14.4062 11.9062 14.2812 11.5 14.4062 11.1562C14.7812 10.3438 15 9.4375 15 8.5C15 5.15625 12.4062 2.375 9.125 2.0625C8.75 2 8.5 1.6875 8.5 1.3125C8.5 0.84375 8.875 0.5 9.34375 0.5625C13.3438 1 16.5 4.375 16.5 8.5Z"
            fill="currentColor"
            :class="thirdColorClass"
        />
    </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        primary: { type: Boolean, default: false },
        destructive: { type: Boolean, default: false },
    },
    setup(props) {
        const thirdColorClass = computed(() => {
            if (props.destructive) {
                return props.primary ? 'text-white' : 'text-red-500';
            }
            return props.primary ? 'text-white' : 'text-primary-700';
        });
        const circleColorClass = computed(() => {
            if (props.destructive) {
                return props.primary ? 'text-red-300' : 'text-red-200';
            }
            return props.primary ? 'text-primary-500' : 'text-primary-300';
        });

        return {
            thirdColorClass,
            circleColorClass,
        };
    },
});
</script>

<style scoped>
.loading-spinner {
    animation: rotation 2s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
</style>
