import { reactive } from 'vue';
import { CommonStoreState } from '@/dashboard/common/types/commonInterface';
import makeGetters from '@/dashboard/common/store/commonGetters';
import makeActions from '@/dashboard/common/store/commonActions';

export const state = reactive<CommonStoreState>({
    initialized: false,
    isPrintView: false,
    dataset: {
        id: '',
        legacy_id: 0,
        type: 1,
        name: '',
        rows: 0,
        analyzed_rows: 0,
        language_id: 0,
        languages: [],
        lowercase: false,
        accent: false,
        topic_recognition: false,
        text_splitting: 1,
        user_group_id: 0,
        created_at: '',
        updated_at: '',
        dimension_definitions: [],
        permissionLevel: 0,
        last_analysis_at: null,
        is_analysis_running: false,
        auto_translation: false,
        auto_translation_with_html: false,
        inputTranslation: false,
        hasFilterableCampaigns: false,
    },
    labelTranslationEnabled: false,
    labelDistributions: {},
    userGroupData: {},
    tableStorageInfo: {},
    colorScale: [],
    dashboardSettings: null,
    excludedEntities: [],
    query: undefined,
    accessRequestAlreadySent: false,
    showCLQuickReanalysisInfoBox: true,
    allSources: [],
    campaigns: []
});

export const getters = makeGetters(state);
export const actions = makeActions(state);

export default function useCommonStore() {
    return {
        state,
        getters,
        actions
    };
}
