<template>
    <div class="text-area-container">
        <label
            v-if="$slots.label || label"
            class="label"
            :for="id"
        >
            <slot name="label">
                {{ label }}
            </slot>
        </label>
        <textarea
            :id="id"
            ref="textarea"
            :value="value"
            :rows="rows"
            :placeholder="placeholder"
            v-bind="$attrs"
            :class="{
                'error': !!errorMessage,
                'no-resize': !isResizable
            }"
            :style="style"
            :maxlength="maxLength"
            @input="$emit('input', $event.target.value)"
            @mouseover="isHovered = true"
            @mouseleave="isHovered = false"
            @focus="isActive = true"
            @focusout="isActive = false"
            v-on="listeners"
        />
        <div
            v-if="$slots.optional"
            class="optional"
        >
            <slot name="optional" />
        </div>
        <div
            v-if="$slots.errorMessage || errorMessage"
            class="errorMessage"
        >
            <slot name="errorMessage">
                {{ errorMessage }}
            </slot>
        </div>
    </div>
</template>

<script lang="ts">
import {computed, ref, onMounted, nextTick, onBeforeUnmount, defineComponent} from 'vue';
import { hex2rgb } from '@/utils/color';

export default defineComponent({
    props:  {
        label: { type: String, default: null },
        errorMessage: { type: String, default: null },
        rows: { type: Number, default: 1 },
        value: { type: String, default: null },
        isAutosize: { type: Boolean, default: false },
        isResizable: { type: Boolean, default: true },
        placeholder: { type: String, default: null },
        primaryColor: { type: String, default: null },
        textColor: { type: String, default: null },
        backgroundColor: { type: String, default: null },
        id: {type: String, default: () => 'textArea-' + Math.random()},
        maxLength: { type: Number, default: null },
    },
    setup(props, context) {
        const isHovered = ref(false);
        const isActive = ref(false);
        const listeners = computed(() => {
            // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
            const { input, ...listeners } = context.listeners;
            return listeners;
        });

        const primaryColor = computed(() => props.primaryColor || 'var(--color-primary-700)');
        const backgroundColor = computed(() => props.backgroundColor || 'var(--color-white)');
        const textColor = computed(() => props.textColor || 'var(--color-black)');

        const borderColor = computed(() => {
            if (isActive.value) return primaryColor.value;
            else if (isHovered.value) return `rgba(${props.textColor ? hex2rgb(props.textColor).join(',') : '0,0,0'}, 0.6)`;
            else if (props.errorMessage) return '#f06060';

            return `rgba(${props.textColor ? hex2rgb(props.textColor).join(',') : '0,0,0'}, 0.3)`;
        });

        const style = computed(() => ({
            backgroundColor: backgroundColor.value,
            color: textColor.value,
            borderColor: borderColor.value,
        }));

        const textarea = ref();

        function focus() {
            textarea.value.focus();
        }

        onMounted(async () => {
            if (!props.isAutosize) {
                return;
            }
            textarea.value.addEventListener('input', resizeTextarea);
            await nextTick();
            textarea.value.setAttribute('style', 'height:' + (textarea.value.scrollHeight) + 'px;overflow-y:hidden;');
        });

        onBeforeUnmount(() => {
            textarea.value.removeEventListener('input', resizeTextarea);
        });

        function resizeTextarea (event) {
            event.target.style.height = 'auto';
            event.target.style.height = (event.target.scrollHeight) + 'px';
        }

        return { textarea, isHovered, isActive, listeners, focus, style };
    }
});
</script>
<style scoped>
    .label {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        padding-left: 8px;
        color: #282828;
        font-size: 14px;
    }
    textarea {
        width: 100%;
        border-radius: 0.25rem;
        border: 1px solid #838da5;
        padding: 8px;
        color: #282828;
        outline: none;
        line-height: 16px;
        overflow: hidden;
        font-size: 14px;
    }
    textarea:disabled {
        opacity: 0.75;
        cursor: not-allowed;
    }
    textarea::placeholder {
        color: #838da5;
    }
    .no-resize {
        resize: none;
    }
    .errorMessage {
        color: #f56565;
        font-size: 14px;
    }
    .optional {
        color: #838da5;
        text-align: right;
    }
    .error {
        border: 1px solid #f56565;
    }
</style>
