<template>
    <div
        class="toggle-container text-default leading-none"
        :style="style"
        @click="toggle()"
    >
        <label
            :for="id"
            :style="disabled ? 'cursor: not-allowed' : 'cursor: pointer'"
            :data-test-id="dataTestId"
        >
            <span
                class="slider rounded"
                :class="{ isOn }"
                :style="sliderStyle"
            />
            <span
                class="label-text"
                :style="labelTextStyle"
            >
                <slot>
                    {{ labelText }}
                </slot>
            </span>
            <input
                class="hidden"
                type="checkbox"
                disabled
                :checked="isOn"
            >
        </label>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

export default defineComponent({
    props: {
        id: { type: String, default: () => `toggle-${Math.random() * 1000000}` },
        labelText: { type: String, default: null },
        value: { type: [Boolean, Number], default: false },
        disabled: { type: Boolean, default: false },
        dataTestId: { type: String, default: null },
        textColor: { type: String, default: '#282828' },
    },
    setup(props, context) {
        const isOn = computed({
            get() {
                return props.value === true || props.value == 1;
            },
            set(newValue) {
                context.emit('input', newValue);
            }
        });

        const style = computed(() => ({
            'color': 'var(--color-primary-700)',
        }));

        const sliderStyle = computed(() => ({
            backgroundColor: isOn.value ? 'var(--color-primary-700)' : 'var(--color-neutral-600)',
        }));

        const labelTextStyle = computed(() => {
            if (!isOn.value && props.disabled && props.labelText) {
                return 'color: ' + 'var(--color-neutral-600)';
            }
            return 'color: ' + props.textColor;
        });

        const toggle = () => {
            if (props.disabled) return;

            isOn.value = !isOn.value;
        };

        return {
            isOn,
            style,
            sliderStyle,
            labelTextStyle,
            toggle,
        };
    },
});
</script>

<style scoped>
    .toggle-container {
        color: #282828;
    }

    label {
        position: relative;
        display: inline-block;
        height: 14px;
    }

    .label-text {
        margin-left: 32px;
        height: 16px;
    }

    .slider {
        width: 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        transition-duration: 100ms;
        height: 14px;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 10px;
        width: 10px;
        left: 2px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 2px;
    }

    .isOn.slider:before {
        transform: translateX(10px);
    }
</style>
