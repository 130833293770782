<template>
    <div
        class="p-2 border-2 rounded"
        :class="{
            'bg-primary-200 border-primary-700': type === 'info',
            'bg-yellow-100 border-yellow-500': type === 'warning',
            'bg-red-100 border-red-500': type === 'error'
        }"
    >
        <slot />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        type: {
            type: String,
            default: 'info',
            validator: (value: string): boolean => ['info', 'warning', 'error'].includes(value),
        }
    },
});
</script>
