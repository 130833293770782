import i18next from 'i18next';

export enum NotificationType {
    ANSWER  = 1,
    HOURLY = 2,
    DAILY = 3,
    WEEKDAYS = 4,
    WEEKLY = 5,
    NONE = 6
}

export enum FillingProgressPosition {
    TOP = 'top',
    BOTTOM = 'bottom',
    BOTH = 'both'
}

export const getDisplayNameForFillingProgressPosition = (position) => {
    switch (position) {
    case FillingProgressPosition.TOP:
        return i18next.t('SURVEYFORMS.PROGRESS_BAR_POSITION_TOP', 'Top');
    case FillingProgressPosition.BOTTOM:
        return i18next.t('SURVEYFORMS.PROGRESS_BAR_POSITION_BOTTOM', 'Bottom');
    case FillingProgressPosition.BOTH:
        return i18next.t('SURVEYFORMS.PROGRESS_BAR_POSITION_BOTH', 'Bottom and top');
    }
};

export enum LayoutSpacing {
    SPACIOUS = 'spacious',
    COMPACT = 'compact',
    COZY = 'cozy'
}

export const getDisplayNameForLayoutSpacing = (spacing) => {
    switch (spacing) {
    case LayoutSpacing.SPACIOUS:
        return i18next.t('SURVEYFORMS.LAYOUT_SPACING_SPACIOUS', 'Spacious');
    case LayoutSpacing.COMPACT:
        return i18next.t('SURVEYFORMS.LAYOUT_SPACING_COMPACT', 'Compact');
    case LayoutSpacing.COZY:
        return i18next.t('SURVEYFORMS.LAYOUT_SPACING_COZY', 'Cozy');
    }
};

export enum QuestionTagType {
    NONE = 'none',
    REQUIRED = 'required',
    OPTIONAL = 'optional'
}

export const getDisplayNameForQuestionTagType = (type) => {
    switch (type) {
    case QuestionTagType.REQUIRED:
        return i18next.t('SURVEYFORMS.QUESTION_TAG_TYPE_REQUIRED', 'Tag required');
    case QuestionTagType.OPTIONAL:
        return i18next.t('SURVEYFORMS.QUESTION_TAG_TYPE_OPTIONAL', 'Tag optional');
    }
};

export enum urlParameterTypes {
    UNIQUE = 'unique',
    COLLECTOR = 'collector',
}
