import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import { LayoutSpacing } from '@/survey/store/types';

const state = {
    loading: false,
    name: null,
    language_id: 1,
    ui_language_id: 1,
    groups: [],
    defaultGroupId: 0,
    superadmins: [],
    plans: [],
    account_manager_id: 1,
    demoPlanQuota: null,
    logoSource: null,
    logoCropped: null,
    background_color: '#ffffff',
    title_font_name: 'Open Sans',
    title_font_weight: '400',
    title_font_size: 'text-3xl',
    question_font_name: 'Open Sans',
    question_font_weight: '400',
    question_font_size: 'text-xl',
    answer_font_name: 'Open Sans',
    answer_font_weight: '400',
    answer_font_size: 'text-lg',
    colors: {
        primary_color: '#1cc6ba',
        text_color: '#212529',
        background_color: '#ffffff',
    },
    layout_spacing: LayoutSpacing.SPACIOUS
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
