import { RouteConfig } from 'vue-router';

const Builder = () => import('@/components/surveyBuilder/pages/Builder.vue');
const Preview = () => import('@/components/surveyBuilder/pages/Preview.vue');
const Publish = () => import('@/components/surveyBuilder/pages/Publish.vue');
const Translations = () => import('@/components/surveyBuilder/pages/Translations.vue');
const LanguagesTab = () => import('@/survey/components/translations/tabs/LanguagesTab.vue');
const TranslationTab = () => import('@/survey/components/translations/tabs/TranslationTab.vue');
const OverviewTab = () => import('@/survey/components/translations/tabs/OverviewTab.vue');
const QrCodeExcelUpload = () => import('@/survey/components/publish/Share/QrCodeExcelUpload.vue');
const EncodedUrlExcelUpload = () => import('@/survey/components/publish/Share/EncodedUrlExcelUpload.vue');

const routes: RouteConfig[] = [
    {
        path: ':id',
        redirect: ':id/editor',
    },
    {
        path: ':id/editor',
        component: Builder,
        name: 'editor'
    },
    {
        path: ':id/preview',
        component: Preview,
        name: 'preview',
    },
    {
        path: ':id/publish/qr-code/upload',
        component: QrCodeExcelUpload,
        name: 'qr-code/upload'
    },
    {
        path: ':id/publish/encoded-url/upload',
        component: EncodedUrlExcelUpload,
        name: 'encoded-url/upload'
    },
    {
        path: ':id/publish',
        name: 'publish',
        component: Publish
    },
    {
        path: ':id/translations',
        name: 'translations',
        component: Translations,
        children: [
            {
                path: 'languages',
                name: 'languages-tab',
                component: LanguagesTab,
            },
            {
                path: 'translation',
                name: 'translation-tab',
                component: TranslationTab,
            },
            {
                path: 'overview',
                name: 'overview-tab',
                component: OverviewTab,
            },
        ]
    }
];

export default routes;
