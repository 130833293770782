<template>
    <div class="available-labels">
        <h1 class="my-1 text-sm">
            {{ $t('KEYWORD.AVAILABLE_CUSTOM_LABELS', 'Available Custom Labels') }} ({{ availableLabels.length }})
        </h1>
        <search
            :selected-labels="selectedLabels"
            :available-labels="availableLabels"
            :filter="filter"
            :disabled="availableLabels.length === 0"
            @add-label="$emit('add-label', $event)"
            @update-filter="$emit('update-filter', $event)"
        />
        <div>
            <v-popover
                v-for="label in availableLabelsSliced"
                :key="label.id"
                trigger="hover"
                placement="top"
                :disabled="label.tags.length === 0"
                class="inline"
            >
                <z-tag
                    addable
                    class="mb-1"
                    @click="handleClick(label.id)"
                >
                    {{ label.name + (label.category ? ` (${label.category})` : '') }}
                </z-tag>
                <template slot="popover">
                    <fa-icon :icon="['fal', 'hashtag']" /> {{ label.tags.join(', ') }}
                </template>
            </v-popover>
            <span
                v-if="!fullList && availableLabels.length > 10"
                class="cursor-pointer text-sm"
                data-test-id="show-more"
                @click="showMore"
            >
                +{{ availableLabels.length - 10 }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, computed, ref} from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Search from './Search.vue';
import { CustomLabel } from '../../store/types';

export default defineComponent({
    components: {
        'fa-icon': FontAwesomeIcon,
        Search,
    },
    props: {
        availableLabels: { type: Array as () => Array<CustomLabel>, required: true },
        selectedLabels: { type: Array, required: true },
        filter: { type: Object as () => { value: string | null, type: 'tag' | 'category' | 'creator' | null }, required: true },
    },
    setup(props, context) {
        const fullList = ref(false);

        const availableLabelsSliced = computed(() => props.availableLabels.slice(0, fullList.value ? undefined : 10));

        function handleClick(id: number) {
            context.emit('add-label', id);
        }

        function showMore() {
            fullList.value = true;
        }

        return {
            handleClick,
            fullList,
            availableLabelsSliced,
            showMore,
        };
    }
});
</script>
