<template>
    <div
        v-if="visible"
        class="overlay bg-bg opacity-75"
        data-test-id="loading-screen"
    >
        <loading-animation :color="color" />
    </div>
</template>

<script>
import LoadingAnimation from '@/components/ui/LoadingAnimation';
export default {
    name: 'LoadingScreen',
    components: {
        LoadingAnimation,
    },
    data() {
        return {
            visible: false,
            color: 'var(--color-primary-700)',
        };
    },
    computed: {
        isVisible() {
            return this.visible;
        },
    },
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        },
        setColor(color) {
            this.color = color;
        }
    },
};
</script>

<style lang="less" scoped>
    .overlay {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
</style>
