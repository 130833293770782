import api from '@/api';
import * as customLabelCategoriesApi from '@/custom-labels/api/custom-label-categories.ts';
import {createNewEmailProcessor, getEmailProcessorByDatasetId} from '@/email-connector/api/emailProcessor';
import {initInputAPI} from '@/input-api/api';
import excelParser from '@/domain/excel/excelParser';
import gtm from '@/utils/gtm';
import BusinessError from '@/domain/errors/BusinessError';
import i18next from 'i18next';
import PQueue from 'p-queue';

const queue = new PQueue({ concurrency: 6 });

import { getDatasets, getAccessRequests } from '@/datasets/store';

export default {
    async init({ dispatch, commit }) {
        commit('setLoading', 1);
        queue.clear();
        const promises = [];
        promises.push(queue.add(() => getAccessRequests()));
        promises.push(queue.add(() => dispatch('getCategories')));
        promises.push(queue.add(() => dispatch('getUserGroupData')));
        await Promise.all(promises);
        commit('setLoading', 0);
    },
    async getCategories({ commit }) {
        const categories = await customLabelCategoriesApi.getCategories();
        commit('setCategories', categories);
    },
    async getUserGroupData({ commit }) {
        const userGroupData = await api.getUserGroupData();
        commit('setUserGroupData', userGroupData[0]);
        commit('setTableStorageInfo', userGroupData[1]);
    },
    async initializeSurvey({commit}) {
        commit('setLoading', true);
        const newSurvey = await api.initSurvey();
        gtm.track(gtm.events.SURVEY_CREATED, gtm.categories.SURVEY_CREATED);
        if(newSurvey){
            return '/survey/' + newSurvey.dataset_id;
        }
    },
    async startEmailProcessing({ commit }) {
        commit('setLoading', true);
        const newEmailProcessor = await createNewEmailProcessor();
        if (newEmailProcessor) {
            window.location.href ='/email-connector/' + newEmailProcessor.id + '/editor?new=true';
        }
    },
    async editEmailProcessor({commit}, datasetId) {
        commit('setLoading', true);
        const emailProcessors = Object.values(await getEmailProcessorByDatasetId(datasetId));
        if(emailProcessors[0]){
            window.location.href = '/email-connector/' + emailProcessors[0].id;
        }
    },
    async uploadExcel({ commit }, file){
        const response = await excelParser({ file: file, guessDimensionTypes: true });
        if (!response.activeSheet || !response.sheets || !Object.keys(response.sheets).length) {
            throw new BusinessError(i18next.t('ANALYSIS.EMPTY_FILE_ERROR', 'Uploaded file must have at least one not empty sheet.'));
        }
        commit('startExcelAnalysis', {
            name: response.name,
            sheets: response.sheets,
            activeSheet: response.activeSheet,
            file,
        });
        gtm.track(gtm.events.EXCEL_ANALYSIS_INIT, gtm.categories.EXCEL_ANALYSIS_INIT);
    },
    async append({ commit }, dataSourceId) {
        commit('append', dataSourceId);
        gtm.track(gtm.events.EXCEL_APPEND_INIT, gtm.categories.EXCEL_APPEND_INIT);
    },
    async analysisStarted({ commit }) {
        commit('analysisStarted');
        await getDatasets();
        commit('setLoading', 0);
    },
    async initInputAPI({ commit }) {
        commit('setLoading', true);
        return await initInputAPI();
    },
};
