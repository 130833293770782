import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';
import useCustomLabelStore from '@/custom-labels/store';
import {AggregationResponse,TransformedResponse,rafiXDimensionType,rafiYDimensionType,SingleDiscreteDistribution,RatingBasedStackedDistribution,StackedDistribution} from './rafiTypes';
import dayjs from 'dayjs';
import i18next from 'i18next';
import {getters} from '@/dashboard/Rafi/store/rafiStore';

const { getters: customLabelGetters } = useCustomLabelStore();

export function transformRafiData(response: AggregationResponse[], versionDictionary: string[]): TransformedResponse[] {
    const transformedResponse = [ ...response ];
    return transformedResponse.map(item => {
        const customLabel = customLabelGetters.customLabels.value.find(cl => cl.id === item.customlabel);
        return {
            customLabelId: item.customlabel,
            customLabelName: customLabel ? customLabel.name : i18next.t('DASHBOARD.NO_CUSTOM_LABEL_RECOGNIZED', 'No Custom Label Recognized'),
            customLabelCategoryName: customLabelGetters.getCustomLabelCategoryName.value(item.customlabel) ?? i18next.t('DASHBOARD.RAFI_NO_CL_CATEGORY', 'General Mood'),
            version: item.version ? getDimensionName(parseInt(item.version), dimensionTypeId.CATEGORY, versionDictionary) : null,
            date: item.date ? parseInt(item.date) : null,
            month: item.date ? dayjs.unix(parseInt(item.date)).format('YYYY/MM') : null,
            rating: item.rating ? parseInt(item.rating) : null,
            polarity: item.rating ? getDimensionName(parseInt(item.rating), dimensionTypeId.NUMBER) : null,
            ratingSum: item.rating ? item.value * parseInt(item.rating) : null,
            value: item.value,
        };
    });
}

function getDimensionName (dimensionValueId: number, dimensionType: dimensionTypeId, dictionary: string[] = []) {
    // RATING
    if (dimensionType === dimensionTypeId.NUMBER) {
        switch (dimensionValueId) {
        case 1:
        case 2:
            return 'negative';
        case 3:
            return 'neutral';
        case 4:
        case 5:
            return 'positive';
        default:
            return null;
        }
    }
    // VERSION
    if (dimensionType === dimensionTypeId.CATEGORY && dictionary) {
        return dictionary[dimensionValueId];
    }
    return null;
}

export function aggregateResponse(response: TransformedResponse[], dimensionX: rafiXDimensionType, dimensionY: rafiYDimensionType): []|SingleDiscreteDistribution[]|RatingBasedStackedDistribution[]|StackedDistribution[] {
    const prop = getDimensionXVariable(dimensionX);

    // Single discrete distribution chart
    if (dimensionY === rafiYDimensionType.VALUE) {
        let aggregated = [] as SingleDiscreteDistribution[];
        response.forEach(d => {
            const found = aggregated.find(c => c.name === d[prop]);
            if (found) {
                aggregated = aggregated.map(c => {
                    if (c.name === d[prop]) {
                        return { ...c, value: c.value + d.value };
                    }
                    return c;
                });
            } else {
                if (d[prop] !== null) {
                    aggregated.push({ name: d[prop]!, value: d.value });
                }
            }
        });
        return sortByX(aggregated, dimensionX, 'name');
    }

    // Rating based stacked chart
    if (dimensionY === rafiYDimensionType.RATING) {
        let aggregated = [] as RatingBasedStackedDistribution[];
        response.forEach(d => {
            const found = aggregated.find(c => c.dimensionValueName1 === d[prop] && c.dimensionValueName2 === d.polarity && c.category === d.customLabelCategoryName);
            if (found) {
                aggregated = aggregated.map(c => {
                    if (c.dimensionValueName1 === d[prop] && c.dimensionValueName2 === d.polarity && c.category === d.customLabelCategoryName) {
                        return { ...c, value: c.value + d.value, ratingSum: c.ratingSum + d.ratingSum! };
                    }
                    return c;
                });
            } else {
                if (d[prop] !== null && d.polarity !== null) {
                    aggregated.push({
                        dimensionValueName1: d[prop]!,
                        dimensionValueName2: d.polarity,
                        value: d.value,
                        ratingSum: d.ratingSum!,
                        category: d.customLabelCategoryName ?? undefined
                    });
                }
            }
        });
        return sortByX(aggregated, dimensionX, 'dimensionValueName1');
    }

    // Grouped dimension chart
    if (dimensionY === rafiYDimensionType.CUSTOM_LABEL_CATEGORY) {
        let aggregated = [] as StackedDistribution[];
        response.forEach(d => {
            const found = aggregated.find(c => c.name === d[prop]);
            if (found) {
                aggregated = aggregated.map(c => {
                    if (c.name === d[prop]) {
                        const values =  c.values.find(v => v.name === d.customLabelCategoryName) ?
                            c.values.map(v => {
                                if (v.name === d.customLabelCategoryName) return { ...v, value: v.value + d.value };
                                return v;
                            }) :
                            [...c.values, { name: d.customLabelCategoryName!, value: d.value }];
                        return { ...c, values: values };
                    }
                    return c;
                });
            } else {
                if (d[prop] !== null) {
                    aggregated.push({ name: d[prop]!, values: [{ name: d.customLabelCategoryName!, value: d.value }] });
                }
            }
        });
        return sortByX(aggregated, dimensionX, 'name');
    }

    return [];
}

function sortByX(aggregations: SingleDiscreteDistribution[]|RatingBasedStackedDistribution[]|StackedDistribution[], dimensionX: rafiXDimensionType, property: string) {
    if (dimensionX === rafiXDimensionType.DATE) {
        return aggregations.sort(
            (a, b) => dayjs(a[property], 'YYYY/MM').unix() - dayjs(b[property], 'YYYY/MM').unix()
        );
    } else if (dimensionX === rafiXDimensionType.VERSION) {
        // Version is a string that is 'Unknown' OR starts with a number but has multiple dots and sometimes letters
        return aggregations.sort((a, b) => a[property] - b[property]);
    } else if (dimensionX === rafiXDimensionType.CUSTOM_LABEL) {
        return aggregations.sort((a, b) => {
            const delta = b.value - a.value;
            if (delta === 0) {
                if ('dimensionValueName1' in a) {
                    return a.dimensionValueName1.localeCompare(b.dimensionValueName1);
                }
                return a.name.localeCompare(b.name);
            }
            return delta;
        });
    }
    return aggregations;
}

function getDimensionXVariable(dimensionX: rafiXDimensionType) {
    switch(dimensionX) {
    case rafiXDimensionType.CUSTOM_LABEL:
        return 'customLabelName';
    case rafiXDimensionType.CUSTOM_LABEL_CATEGORY:
        return 'customLabelCategoryName';
    case rafiXDimensionType.DATE:
        return 'month';
    case rafiXDimensionType.VERSION:
        return 'version';
    }
}

export function getDimensionsDataForSummaryCharts(data: {dimensionValueId1: number, dimensionValueId2: number, value: number}[], dimensionX: rafiXDimensionType, dimensionY: rafiYDimensionType) {
    const baseData = [ ...data ];
    let mapped = [] as {version: string|null, month: string|null, rating: number, polarity: string|null, ratingSum: number, value: number}[];
    mapped = baseData.map(item => {
        return {
            version: dimensionX === rafiXDimensionType.VERSION ? getDimensionName(item.dimensionValueId2, dimensionTypeId.CATEGORY, getters.getVersionDictionary.value) : null,
            month: dimensionX === rafiXDimensionType.DATE ? dayjs.unix(item.dimensionValueId2).format('YYYY/MM') : null,
            rating: item.dimensionValueId1,
            polarity: getDimensionName(item.dimensionValueId1, dimensionTypeId.NUMBER),
            ratingSum: item.value * item.dimensionValueId1,
            value: item.value,
        };
    });
    let aggregated = [] as RatingBasedStackedDistribution[];
    const prop = getDimensionXVariable(dimensionX);
    mapped.forEach(d => {
        const found = aggregated.find(c => c.dimensionValueName1 === d[prop] && c.dimensionValueName2 === d.polarity);
        if (found) {
            aggregated = aggregated.map(c => {
                if (c.dimensionValueName1 === d[prop] && c.dimensionValueName2 === d.polarity) {
                    return { ...c, value: c.value + d.value, ratingSum: c.ratingSum + d.ratingSum! };
                }
                return c;
            });
        } else {
            if (d[prop] !== null && d.polarity !== null) {
                aggregated.push({
                    dimensionValueName1: d[prop]!,
                    dimensionValueName2: d.polarity,
                    value: d.value,
                    ratingSum: d.ratingSum!
                });
            }
        }
    });
    return sortByX(aggregated, dimensionX, 'dimensionValueName1');
}

export function getTotal(data: readonly any[]) {
    const baseData = [ ...data ];
    let aggregated = [] as RatingBasedStackedDistribution[];
    baseData.forEach(d => {
        const found = aggregated.find(c => c.dimensionValueName1 === d.dimensionValueName1 && c.dimensionValueName2 === d.dimensionValueName2);
        if (found) {
            aggregated = aggregated.map(c => {
                if (c.dimensionValueName1 === d.dimensionValueName1 && c.dimensionValueName2 === d.dimensionValueName2) {
                    return { ...c, value: c.value + d.value, ratingSum: c.ratingSum + d.ratingSum! };
                }
                return c;
            });
        } else {
            aggregated.push(d);
        }
    });
    return aggregated;
}
