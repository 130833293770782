export enum datasetTypeId {
    excel = 1,
    survey = 2,
    email = 3,
    api = 4,
    nmi = 5,
    website_crawler = 8,
    pdf_extractor = 9,
    domo_integration = 10,
}
