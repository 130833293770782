export default {
    saveFile: (state, { response, file }) => {
        state.name = response.name;
        state.activeSheet = response.activeSheet;
        state.sheets = response.sheets;
        state.file = file;
    },
    setLastAnalysisAt: (state, lastAnalysisAt) => {
        state.lastAnalysisAt = lastAnalysisAt;
    },
    setName: (state, name) => {
        state.name = name;
    },
    setId: (state, id) => {
        state.id = id;
    },
    setActiveSheet: (state, activeSheet) => {
        state.activeSheet = activeSheet;
    },
    setSheets: (state, sheets) => {
        state.sheets = sheets;
    },
    setAnalysisSetting(state, { setting, value }) {
        state.analysisSettings = {
            ...state.analysisSettings,
            [setting]: value
        };
    },
    setAnalysisSettings(state, settings) {
        state.analysisSettings = settings;
    },
    setSelectedCustomLabelIds(state, ids) {
        state.selectedCustomLabelIds = ids;
    },
    setOriginalCustomLabelIds(state, ids) {
        state.originalCustomLabelIds = ids;
    },
    setColumns(state, columns) {
        state.columns = columns.map((column, index) => ({ ...column, columnNumber: index + 1 }));
    },
    setOriginalUploadedColumns(state, columns) {
        state.originalUploadedColumns = columns.map((column, index) => ({ ...column, columnNumber: index + 1 }));
    },
    setConfigs(state, configs) {
        state.configs = configs;
    },
    reset: (state) => {
        state.name = '';
        state.lastAnalysisAt = '';
        state.activeSheet = '';
        state.sheets = {};
        state.analysisSettings = {
            languageId: 1,
            textSplitting: 1,
            accent: true,
            lowercase: true,
            topicRecognition: true,
        };
        state.selectedCustomLabelIds = [];
        state.originalCustomLabelIds = [];
        state.file = null;
        state.columns = [];
        state.configs = {};
    },
};
