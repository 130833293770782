import { computed } from 'vue';
import { SubscriptionState } from './types';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';

const PHP_INT_MAX = 2147483647;

export default function makeGetters(state: SubscriptionState) {
    const canUseFeature = computed(() => (featureName: string, value: number = 0): boolean => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === featureName);
        if (!usage) return false;
        if (usage.max_value >= PHP_INT_MAX || usage.max_value === null) return true;
        return (usage.max_value - usage.value - value) >= 0;
    });

    const isFeatureOn = computed(() => (featureName: string): boolean => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === featureName);
        if (!usage) return false;
        return true;
    });

    const isAccessGroupsPlanFeatureEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.ACCESS_GROUP));

    const textAnalysisLimit = computed(() => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === planFeatureTypeEnum.TEXT_ANALYSIS);
        if (!usage) {
            return -1;
        }
        if (usage.max_value >= PHP_INT_MAX) {
            return null;
        }
        return usage.max_value;
    });

    const remainingTextAnalysis = computed(() => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === planFeatureTypeEnum.TEXT_ANALYSIS);
        if (!usage || usage.max_value >= PHP_INT_MAX) {
            return null;
        }
        return usage.max_value - usage.value;
    });

    const remainingDatasetTranslationQuota = computed(() => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === planFeatureTypeEnum.DATASET_TRANSLATION);
        if (!usage || usage.max_value >= PHP_INT_MAX) {
            return null;
        }
        return usage.max_value - usage.value;
    });

    const isUsedTAQuotaOver90Percent = computed(() => {
        if (textAnalysisLimit.value === null || remainingTextAnalysis.value === null) {
            return false;
        }
        return remainingTextAnalysis.value <= (textAnalysisLimit.value * 0.1);
    });

    const textAnalysisResetsAt = computed(() => {
        const usage = state.planFeatureUsages.find(usage => usage.feature === planFeatureTypeEnum.TEXT_ANALYSIS);
        if (!usage) {
            return null;
        }
        return usage.resets_at;
    });

    const getAccessGroupMembers = computed(() => (id: number): string => {
        return state.users
            .filter(u => u.accessGroups.includes(id))
            .reduce((accU, currU, i) => i === 0 ? currU.fullName : accU + ', ' + currU.fullName, '');
    });

    const getAccessGroupMemberNumber = computed(() => (id: number): number => state.users.filter(u => u.accessGroups.includes(id)).length);

    const featureQuotaLimit = (feature) => computed(() => {
        const textAnalysisUsage = state.planFeatureUsages.find(u => u.feature === planFeatureTypeEnum.TEXT_ANALYSIS)!;
        const featureUsage = state.planFeatureUsages.find(u => u.feature === feature)!;

        if (textAnalysisUsage.max_value === PHP_INT_MAX) {
            return featureUsage.max_value === PHP_INT_MAX ? undefined : featureUsage.max_value;
        }
        return textAnalysisUsage.max_value;
    });

    const featureUsedQuota = (feature) => computed(() => {
        const textAnalysisUsage = state.planFeatureUsages.find(u => u.feature === planFeatureTypeEnum.TEXT_ANALYSIS)!;
        const featureUsage = state.planFeatureUsages.find(u => u.feature === feature)!;

        if (textAnalysisUsage.max_value === PHP_INT_MAX) {
            return featureUsage.value;
        }
        return textAnalysisUsage.value;
    });

    return {
        canUseFeature,
        textAnalysisLimit,
        remainingTextAnalysis,
        isUsedTAQuotaOver90Percent,
        textAnalysisResetsAt,
        getAccessGroupMembers,
        getAccessGroupMemberNumber,
        isAccessGroupsPlanFeatureEnabled,
        isFeatureOn,
        remainingDatasetTranslationQuota,
        featureQuotaLimit,
        featureUsedQuota,
    };
}
