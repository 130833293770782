export default {
    updateName(state, name) {
        state.name = name !== null ? name : state.name;
    },
    updateAccountManagerId(state, id) {
        state.account_manager_id = id !== null ? id : state.account_manager_id;
    },
    updateLanguageId(state, language_id) {
        state.language_id = parseInt(language_id);
    },
    updateUILanguageId(state, ui_language_id) {
        state.ui_language_id = parseInt(ui_language_id);
    },
    updateDemoPlanQuota(state, demoPlanQuota) {
        state.demoPlanQuota = demoPlanQuota;
    },
    updateColors(state, colors) {
        state.colors = colors;
    },
    updateFonts(state, fontSettings) {
        state.title_font_name = fontSettings.title_font_name;
        state.title_font_weight = fontSettings.title_font_weight;
        state.title_font_size = fontSettings.title_font_size;
        state.question_font_name = fontSettings.question_font_name;
        state.question_font_weight = fontSettings.question_font_weight;
        state.question_font_size = fontSettings.question_font_size;
        state.answer_font_name = fontSettings.answer_font_name;
        state.answer_font_weight = fontSettings.answer_font_weight;
        state.answer_font_size = fontSettings.answer_font_size;
    },
    updateLayoutSpacing(state, spacing) {
        state.layout_spacing = spacing;
    },
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    setGroups(state, groups) {
        state.groups = groups;
    },
    setDefaultGroup(state, groupId) {
        state.defaultGroupId = groupId;
    },
    setSuperAdmins(state, superadmins) {
        state.superadmins = superadmins;
    },
    setPlans(state, plans) {
        state.plans = plans;
    },
    setLogoSource(state, imgSource) {
        state.logoSource = imgSource;
    },
    setLogoCropped(state, imgCropped) {
        state.logoCropped = imgCropped;
    },
};
