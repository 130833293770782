<template>
    <div>
        <custom-label-popup
            v-if="canManageCustomLabels && popup.open"
            :title="popup.mode === 'edit' ? $t('KEYWORD.EDIT_TITLE') : $t('KEYWORD.ADD_TITLE')"
            :button-text="buttonText"
            :keyword-details="popup.customLabel"
            :manual-custom-label-create="manualCustomLabelCreate"
            @closePopupBar="closePopup"
            @keywordHasBeenAddedOrEdited="onSaveCustomLabel"
        />
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, toRefs } from 'vue';
import CustomLabelPopup from './CustomLabelPopup.vue';

import useCustomLabelStore from '../../store/';

import { toaster } from '@/utils/toaster';
import useUserStore from '@/user/store';
import i18next from 'i18next';

export default defineComponent({
    components: {
        CustomLabelPopup
    },
    props: {
        manualCustomLabelCreate: { type: Boolean, default: false },
    },
    setup(props) {
        const { state, actions } = useCustomLabelStore();
        const { canManageCustomLabels } = useUserStore().getters;

        const { popup } = toRefs(state);

        const { closePopup, saveCustomLabel } = actions;

        async function onSaveCustomLabel(label) {
            try {
                const mode = await saveCustomLabel(label);
                if (mode === 'edit') {
                    toaster.success(i18next.t('KEYWORD.UPDATE_SUCCESS'));
                } else {
                    toaster.success(i18next.t('KEYWORD.ADD_SUCCESS'));
                }
            } catch {
                toaster.error('Something went wrong while saving...');
            }
        }

        const buttonText = computed(() => {
            if (props.manualCustomLabelCreate) return i18next.t('CUSTOM_LABEL.MANUAL_CUSTOM_LABEL.CREATE_AND_ADD', 'Create and Add to Selected');

            return popup.value.mode === 'edit' ? i18next.t('GLOBAL.SAVE') : i18next.t('KEYWORD.CREATE');
        });

        return {
            canManageCustomLabels, popup, closePopup, onSaveCustomLabel, buttonText
        };
    }
});
</script>
