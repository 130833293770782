import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    currentAnnouncement: {
        id: null,
        title: null,
        text: null,
        more_url: null,
        picture_url: null,
    },
    announcements: []
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};