import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = {
    loading: false,
    statsData: [],
    query: '',
    filters: {
        accessLevel: '',
        managerId: '',
        date: '',
        start: '',
        end: '',
    },
    sort: {
        by: 'name',
        direction: 'asc',
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
