export default {
    getSelfBaseUrl() {
        const baseUrl = process.env.BASE_URL || '';
        return baseUrl.replace(/,+$/, '');
    },
    getApiBaseUrl() {
        return process.env.VUE_APP_API_BASE_URL.replace(/,+$/, '');
    },
    getFillerBaseUrl() {
        return process.env.VUE_APP_FILLER_BASE_URL.replace(/,+$/, '');
    },
    getFillerHost() {
        const baseUrl = process.env.FILLER_HOST || 'surveyfiller';
        return baseUrl.replace(/,+$/, '');
    },
    getFillerBasePath() {
        const basePath = process.env.VUE_APP_FILLER_BASE_PATH || '/';
        return basePath.replace(/,+$/, '');
    },
    getAuth0Domain() {
        return process.env.VUE_APP_AUTH0_DOMAIN;
    },
    getAuth0Client() {
        return process.env.VUE_APP_AUTH0_CLIENT_ID;
    },
    getAuth0Audience() {
        return process.env.VUE_APP_AUTH0_AUDIANCE;
    },
    getAuth0Localstorage() {
        return process.env.VUE_APP_AUTH0_LOCALSTORAGE;
    },
    getGTMEnabled() {
        return process.env.VUE_APP_GTM_ENABLED;
    },
    getSimpleLocalizeId() {
        return '4903ea1596f847a19240a45af31f2f81';
    },
    getSurveySyncTimeout() {
        return process.env.VUE_APP_SURVEY_SYNC_TIMEOUT || 2000;
    },
    getPort() {
        return process.env.PORT || 8080;
    },
    getEnvironment() {
        return process.env.VUE_APP_ENVIRONMENT || 'local';
    },
    getSentryRelease() {
        return process.env.VUE_APP_SENTRY_RELEASE || '1.0';
    },
    getSentryDNS() {
        // Do not send logs in local environment
        // https://github.com/getsentry/sentry-laravel/issues/204#issuecomment-468601494
        return this.getEnvironment() !== 'local' ?
            'https://53a930baa09e4aa9b0e55f9d40580057@o515752.ingest.sentry.io/5621088' :
            null;
    },
    getEmailDomain() {
        return process.env.VUE_APP_EMAIL_DOMAIN || 'parse.zurvey.io';
    },
    getEmbedBaseUrl() {
        return process.env.VUE_APP_EMBED_BASE_URL || 'https://embed.zurvey.io';
    }
};
