import Vue from 'vue';
import Vuex from 'vuex';

import home from '@/home/store';
import settings from '@/settings/store';
import admin from '@/admin/store';
import survey from '@/survey/store/survey';
import websiteCrawler from '@/website-crawler/store';
import pdfExtractor from '@/pdf-extractor/store';
import domoIntegration from '@/domo-integration/store';

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        home,
        settings,
        admin,
        survey,
        websiteCrawler,
        pdfExtractor,
        domoIntegration,
    },
});

export default store;
