export default (api) => {
    return {
        async getInvitedUserByToken(token) {
            const { data } = await api.get(`/invitedmember/${token}`);
            return data;
        },
        async activateInvitation({ token, firstName, lastName, password, passwordConfirmation }) {
            await api.post('/joinmember', {
                token, firstName, lastName, password, password_confirmation: passwordConfirmation
            });
        },
        async sendMFARegistration(phoneNumber) {
            await api.post('/mfa/sendRegistrationCode', { phoneNumber });
        },
        async registerMFA(phoneNumber, code) {
            await api.post('/mfa/register', { phoneNumber, code });
        },
        async disableMFA(password) {
            await api.post('/mfa/disable', { password });
        },
        async startMFA(token) {
            const { data } = await api.post('/mfa', { token }, { withCredentials: true });
            return data;
        },
        async verifyMfa({ auth0UserId, authyId, code }) {
            const { data } = await api.post('/mfa/verify', { auth0UserId, authyId, code }, { withCredentials: true });
            return data;
        },
        async exchangeSharedToken(datasetId, sharedToken) {
            const { data } = await api.post(`/shared-dataset-token/${datasetId}`, { token: sharedToken });
            return data;
        }
    };
};
