export default {
    setCategories(state, categories) {
        state.categories = categories;
    },
    setUserGroupData(state, data) {
        state.userGroupData = data;
    },
    setTableStorageInfo(state, data) {
        state.tableStorageData = data;
    },
    setLoading(state, loading) {
        state.loading = loading;
    },
    startExcelAnalysis(state, { file, name, sheets, activeSheet }) {
        state.uploadedExcel = {
            file,
            name,
            sheets,
            activeSheet,
        };
    },
    resetUploadExcel(state) {
        state.uploadedExcel = null;
    },
    append(state, dataSourceId) {
        state.appendedDataSourceId = dataSourceId;
    },
    resetAppendedDataSourceId(state) {
        state.appendedDataSourceId = null;
    },
};
