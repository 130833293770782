export default {
    setAnnouncements(state, announcements) {
        state.announcements = announcements;
    },
    updateAnnouncement(state, announcement) {
        state.currentAnnouncement = announcement;
    },
    setCurrentAnnouncement(state, id) {
        if (id) {
            state.currentAnnouncement = state.announcements.find(announcement => announcement.id === id);
        } else{
            state.currentAnnouncement = {
                id: null,
                title: null,
                text: null,
                more_url: null,
                picture_url: null,
            };
        }
    },
};