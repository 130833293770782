import api from '@/api';

export default {
    async getSuperAdmins({ commit }) {
        const response = await api.getSuperAdmins();
        commit('setSuperAdmins', response);
    },
    async getPlans({ commit }) {
        const response = await api.getPlans();
        commit('setPlans', response);
    },
    async createNewDemoGroup({ getters }) {
        try {
            return await api.createNewDemoGroup(getters.demoGroupData);
        } catch (error) {
            if (error.response && error.response.status === 422) {
                throw new Error(Object.values(error.response.data.errors)[0][0]);
            }
            throw error;
        }
    },
    async setDefaultGroup({ state, dispatch }) {
        const data = await api.setDefaultGroup(state.defaultGroupId);
        if (data !== 'success') {
            throw new Error('Something went wrong while changing groups, please try again');
        }
        dispatch('refresh');
    },
    async loadGroupSettings({ commit, dispatch }){
        commit('setLoading', true);
        try {
            dispatch('getSuperAdmins');
            dispatch('getPlans');
            const response = await api.getGroups();
            commit('setGroups', response);
        } catch (e) {
            throw new Error('Something went wrong while getting group details, please try again');
        } finally {
            commit('setLoading', false);
        }
    },
    async refresh() {
        window.location.href = '/admin';
    },
};
