import {api} from '@/api/index.js';
import {Dataset} from '@/domain/dataset/DatasetInterface';
import {Source} from '@/dashboard/common/types/commonInterface';

export const getDataset = async (id: string): Promise<Dataset> => {
    const { data } = await api.get(`datasets/${id}`);
    return data as Dataset;
};

export const checkIpAdresses = async (id: string): Promise<boolean> => {
    const { data } = await api.get(`datasets/${id}/checkIps`);
    return data;
};

export const getSources = async (): Promise<Source[]> => {
    const { data } = await api.get('nmi-integration/allSources');
    return data;
};
