import { api } from '@/api/index.js';

export async function getCategories() {
    const { data } = await api.get('/custom-label-categories');

    return data;
}

export async function getCategory(id: number) {
    const { data } = await api.get(`/custom-label-categories/${id}`);

    return data;
}

export async function createCategory(name: string) {
    const { data } = await api.post('/custom-label-categories', { name });

    return data;
}

export async function updateCategory(id: number, name: string) {
    const { data } = await api.put(`/custom-label-categories/${id}`, { name });

    return data;
}

export async function deleteCategory(id: number) {
    const { data } = await api.delete(`/custom-label-categories/${id}`);

    return data;
}