export interface EntitiesState {
    allEntities: { [ds: string]: Entity[] },
    isAllEntitiesLoading: boolean,
    filteredAllEntities: { [ds: string]: FilteredEntity[] },
    entityRelations: { [ds: string]: Relation[]},
    phraseRelations: { [ds: string]: Record<string, PhraseRelations>},
    isRelationsLoading: boolean,
    topAllEntityCorrelations: { [ds: string]: {} },
    topAllEntityCorrelationsLoading: boolean,
    topAllEntityTimeSeries: any[],
    topAllEntityTimeSeriesLoading: boolean,
    usedCustomLabelCategories: { [ds: string]: CustomLabelCategory[] },
    usedCustomLabelCategoriesInitialized: boolean,
    phrases: { [ds: string]: Phrase[] },
    phrasesLoading: boolean,
    isPhraseRelationsLoading: boolean,
    isDriversLoading: boolean,
    topDrivers: { posPhrases: Driver[], negPhrases: Driver[]},
}

export interface Entity {
    id: number,
    name: string,
    engName: string|null,
    categoryName: string|null,
    type: EntityTypeEnum,
    mentionNumber: number,
    negativeMentionNumber: number,
    positiveMentionNumber: number,
    opinionIndex: number,
    posMention: string,
    neuMention: string,
    negMention: string,
    correlation?: number,
    isSelected?: boolean,
    isShown?: boolean
}

export interface Phrase {
    id: number,
    name: string,
    engName: string|null,
    type: string,
    mentionNumber: number,
    negativeMentionNumber: number,
    positiveMentionNumber: number,
    opinionIndex: number
}

export interface FilteredEntity {
    id: number,
    name: string,
    engName: string|null,
    categoryName: string|null,
    type: string,
    mentionNumber: number,
    negativeMentionNumber: number,
    positiveMentionNumber: number,
    originalMentionNumber: number,
    opinionIndex: number,
    posMention: string,
    neuMention: string,
    negMention: string,
    correlation?: number,
    isSelected?: boolean,
    isShown?: boolean
}

export interface Relation {
    id: number,
    name: string,
    engName: string|null,
    categoryName: string|null,
    type: EntityTypeEnum,
    correlation: number,
    relations: EntityRelation[]
}

export interface EntityRelation {

    relatedEntityId: string,
    relatedEntityName: string,
    relatedEntityEngName: string|null,
    relatedEntityCategory: string|null,
    relatedEntityType: EntityTypeEnum,
    correlation: number,
    mentionNumber: number,
    posMention: string,
    neuMention: string,
    negMention: string,
    opinionIndex: number,
    positiveMentionNumber: number,
    negativeMentionNumber: number
}

export interface PhraseRelations {
    posPhrases: Record<string, PhraseRelation>,
    negPhrases: Record<string, PhraseRelation>
}

export interface PhraseRelation {
    id: number,
    correlation: number,
    mentionNumber: number,
    opinionIndex: number,
    positiveMentionNumber: number,
    negativeMentionNumber: number
}

export interface Date {
    datetime: number,
    opinionIndex: number,
    mentionNumber: number,
    positiveMentionNumber: number,
    neutralMentionNumber: number,
    negativeMentionNumber: number,
    dates: any[],
}

export enum EntityTypeEnum {
    customLabel = 'customLabel',
    label = 'label',
    topic = 'topic',
    attribute = 'attribute',
    location = 'location',
    brand = 'brand',
    emotion = 'emotion',
    person = 'person',
    phrase = 'phrase',
}

// Custom label relation things should have the same structure as previous keyword
export interface FilteredCustomLabel {
    id: number,
    name: string,
    category_id: number,
    count: number,
    positive_mention_number: number,
    negative_mention_number: number,
    correlation: number|undefined,
    opinion_index:number,
    entities: {name:string, eng_name: string, mention_number: number, type: string}[]
}

export interface CustomLabelCategory {
    id: number,
    name: string,
    mention_number: number,
    color: string,
    is_selected?: boolean,
    is_shown?: boolean
}

export interface Driver {
    entityId: number,
    entityType: EntityTypeEnum,
    representativePhrase: string,
    unaccentedRepPhrase: string,
    stemmedRepPhrase: string,
    isRepPhraseAccented: boolean,
    mentionNumber: number,
    entityName?: string,
    entityEngName?: string,
    driver?: string,
}
