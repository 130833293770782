<template>
    <div class="px-1">
        <div
            class="flex justify-between select-none"
            :class="{
                'border-neutral-400 border-t': hasBorderTop,
                'cursor-pointer': !hideIcon,
            }"
            @click="toggle()"
        >
            <div
                class="font-bold my-3 text-sm"
                v-html="(!isAccordionOpened && closedText) || text"
            />
            <template v-if="!hideIcon">
                <icon
                    v-if="!isAccordionOpened"
                    :icon="iconClosed"
                    class="text-neutral-800 text-sm my-4"
                />
                <icon
                    v-if="isAccordionOpened"
                    :icon="iconOpened"
                    class="text-neutral-800 text-sm my-4"
                />
            </template>
        </div>
        <slot v-if="isAccordionOpened" />
    </div>
</template>

<script lang="ts">
import Icon from '@/components/ui/Icon.vue';
import {defineComponent, ref} from 'vue';

export default defineComponent({
    components: { Icon },
    props: {
        iconOpened: { type: String, default: 'chevron-up'},
        iconClosed: { type: String, default: 'chevron-down'},
        type: { type: String, default: 'fal' },
        hasBorderTop: { type: Boolean, default: true},
        text: { type: String, required: true},
        closedText: { type: String, default: null },
        isOpenByDefault: { type: Boolean, default: false},
        hideIcon: { type: Boolean, default: false },
    },
    setup(props, context) {
        const isAccordionOpened = ref(props.isOpenByDefault);

        const toggle = (val?: boolean, force?: boolean): void => {
            if (props.hideIcon && !force) return;

            if (val !== undefined) {
                isAccordionOpened.value = val;
            }
            else {
                isAccordionOpened.value = !isAccordionOpened.value;
            }

            context.emit('changed', isAccordionOpened.value);
        };

        return { isAccordionOpened, toggle };
    }
});
</script>
