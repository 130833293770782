// - Man, I stole you code
// - It's not my code
export default (cookieName) => {
    const cookie = [];
    document.cookie.split(';').forEach(function(el) {
        const [key,value] = el.split('=');
        cookie[key.trim()] = value;
    });
    return cookie[cookieName];
};
