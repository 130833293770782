import { reactive } from 'vue';

import levels from '@/domain/accessLevelEnum';

import {UserState} from './types';

import makeActions from './actions';
import makeGetters from './getters';

export const userState = reactive<UserState>({
    isGuestUser: false,
    isLoggedIn: false,
    accessToken: null,
    id: null,
    firstName: null,
    lastName: null,
    name: null,
    pictureUrl: null,
    pictureOriginalUrl: null,
    email: null,
    emailVerified: null,
    perm: null,
    langCode: 'en',
    groupId: null,
    groupName: null,
    isActive: false,
    announcementsLastSeenAt: null,
    mfaId: null,
    mfaPhoneNumber: null,
    logoUrl: null,
    primaryColor: '#1cc6ba',
    isDarkMode: false,
    isEnterpriseConnection: false,
    accessLevel: levels.PRODUCTION,
    accessedFeatures: [],
    groupPlanPublic: null,
    canCreateDatasets: false,
    canManageCustomLabels: false,
    canManageAudiences: false,
    isAdmin: false,
    isSuperAdmin: false,
    hasAccess: true,
});

const actions = makeActions(userState);
const getters = makeGetters(userState);

export default function useUserStore() {
    return {
        state: userState,
        actions,
        getters,
    };
}
