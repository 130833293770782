import PopupWrapper from '@/plugins/ConfirmPopup/PopupWrapper';

export default class Plugin {
    constructor(Vue) {
        this.Vue = Vue;
        this.mounted = false;
        this.$root = {}; // The root component
    }

    mountIfNotMounted() {
        if (this.mounted === true) {
            return;
        }
        const node = document.createElement('div');
        document.querySelector('body').appendChild(node);

        const PopupWrapperConstructor = this.Vue.extend(PopupWrapper);
        const Vm = new PopupWrapperConstructor();
        this.$root = Vm.$mount(node);

        this.mounted = true;
    }

    open(title, body, type) {
        this.mountIfNotMounted();
        return new Promise(resolve => {
            this.$root.$props.isVisible = true;
            this.$root.$props.title = title;
            this.$root.$props.body = body;
            this.$root.$props.type = type;
            this.$root.$props.callback = (isAccepted) => {
                resolve(isAccepted);
                this.$root.$props.isVisible = false;
            };
        });
    }

    default(title, body) {
        return this.open(title, body, 'default');
    }

    destruction(title, body) {
        return this.open(title, body, 'destructive');
    }

    notification(title, body) {
        return this.open(title, body, 'notification');
    }

    static install(Vue) {
        Vue.confirmDialog = new Plugin(Vue);
        Object.defineProperties(Vue.prototype, {
            $confirmDialog: {
                get () {
                    return Vue.confirmDialog;
                }
            }
        });
    }
}
