import { reactive } from 'vue';
import useCommonStore from '@/dashboard/common/store/commonStore';
import { dimensionTypeId } from '@/domain/dataset/dimensionTypeId';
import { getFilters } from '@/dashboard/common/store/filterStore';
import { DimensionDefinition } from '@/domain/dataset/DatasetInterface';
import { AggregationDimensionType, AggregationMeasureSubType, AggregationMeasureType, getAggregation } from '@/dashboard/api/aggregationApi';

import useDataserieStore from '../../common/store/dataseriesStore';
import { Filter } from '@/dashboard/common/types/filterInterface';

interface CxState {
    nps: {
        [cx: string]: {
            [ds: string]: CxEntity[]
        }
    },
    isNpsLoading: boolean,
    csat: {
        [cx: string]: {
            [ds: string]: CxEntity[]
        }
    },
    isCsatLoading: boolean,
}

export interface CxEntity {
    entityId: number,
    entityType: 'custom-label' | 'label',
    avgScore: number,
    correlation: number,
    sumOpinionIndex: number,
    avgOpinionIndex: number,
    mentionNumber: number,
    posMentionNumber: number,
    negMentionsNumber: number,
    neutMentionNumber: number,
    distribution: { 0: number, 1: number, 2: number, 3: number, 4: number, 5: number, 6: number, 7: number, 8: number, 9: number, 10: number } | { 1: number, 2: number, 3: number, 4: number, 5: number },
}

export const initialState = reactive<CxState>({
    nps: {},
    isNpsLoading: false,
    csat: {},
    isCsatLoading: false,
});

export const makeActions = (state) => ({
    async initNps() {
        const npsDimensions = useCommonStore().state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.NPS);
        if (!npsDimensions) return;
        state.isNpsLoading = true;

        const selectedDataseries = useDataserieStore().getters.selectedDataseries.value;

        const nps = {};

        for (const npsDimension of npsDimensions) {
            if (selectedDataseries.length === 0) {
                const npsEntities = await getCxEntityStats(npsDimension as DimensionDefinition, state.columnId, getFilters());

                nps[npsDimension.id] = {
                    default: await addCxDistribution(npsDimension as DimensionDefinition, state.columnId, npsEntities, getFilters())
                };
            }
            else {
                const npsEntities = await Promise.all(selectedDataseries.map(ds => getCxEntityStats(npsDimension as DimensionDefinition, state.columnId, ds.filters)));
                const npsDistributions = await Promise.all(selectedDataseries.map((ds, index) => addCxDistribution(npsDimension as DimensionDefinition, state.columnId, npsEntities[index], ds.filters)));

                nps[npsDimension.id] = selectedDataseries.reduce((dataseries, ds, index) => {
                    dataseries[ds.id!] = npsDistributions[index];

                    return dataseries;
                }, {});
            }
        }

        state.nps = nps;

        state.isNpsLoading = false;
    },
    async initCsat() {
        const csatDimensions = useCommonStore().state.dataset.dimension_definitions.filter(d => d.type === dimensionTypeId.CSAT);
        if (!csatDimensions) return;
        state.isCsatLoading = true;

        const selectedDataseries = useDataserieStore().getters.selectedDataseries.value;

        const csat = {};

        for (const csatDimension of csatDimensions) {
            if (selectedDataseries.length === 0) {
                const csatEntities = await getCxEntityStats(csatDimension as DimensionDefinition, state.columnId, getFilters());

                csat[csatDimension.id] = {
                    default: await addCxDistribution(csatDimension as DimensionDefinition, state.columnId, csatEntities, getFilters())
                };
            }
            else {
                const csatEntities = await Promise.all(selectedDataseries.map(ds => getCxEntityStats(csatDimension as DimensionDefinition, state.columnId, ds.filters)));
                const csatDistributions = await Promise.all(selectedDataseries.map((ds, index) => addCxDistribution(csatDimension as DimensionDefinition, state.columnId, csatEntities[index], ds.filters)));

                csat[csatDimension.id] = selectedDataseries.reduce((dataseries, ds, index) => {
                    dataseries[ds.id!] = csatDistributions[index];

                    return dataseries;
                }, {});
            }
        }

        state.csat = csat;

        state.isCsatLoading = false;
    },
    resetCxState: () => {
        state.nps = {};
        state.isNpsLoading = false;
        state.csat = {};
        state.isCsatLoading = false;
    },
});

async function getCxEntityStats(cxDimension: DimensionDefinition, verbatimDimensionId: string, filters: Filter[]): Promise<CxEntity[]> {
    const response = await getAggregation(
        useCommonStore().state.dataset.id,
        [{ name: 'entity', type: AggregationDimensionType.entities, dimensionId: verbatimDimensionId }],
        [
            { name: 'avg_score', type: AggregationMeasureType.avg, dimensionId: cxDimension.id },
            { name: 'entity_opinion_index', type: AggregationMeasureType.sum, subType: AggregationMeasureSubType.entityOpinionIndex, dimensionId: verbatimDimensionId },
            { name: 'entity_mentions', type: AggregationMeasureType.count, subType: AggregationMeasureSubType.value },
            { name: 'entity_pos_mentions', type: AggregationMeasureType.posSum, subType: AggregationMeasureSubType.entityMentionNumber, dimensionId: verbatimDimensionId },
            { name: 'entity_neg_mentions', type: AggregationMeasureType.negSum, subType: AggregationMeasureSubType.entityMentionNumber, dimensionId: verbatimDimensionId },
            { name: 'entity_neu_mentions', type: AggregationMeasureType.neuSum, subType: AggregationMeasureSubType.entityMentionNumber, dimensionId: verbatimDimensionId },
            {
                name: 'correlation',
                type: AggregationMeasureType.correlation,
                correlationType1: AggregationMeasureSubType.value,
                correlationDimensionId1: cxDimension.id,
                correlationType2: AggregationMeasureSubType.entityOpinionIndex,
                correlationDimensionId2: verbatimDimensionId
            },
        ],
        filters,
    );

    return response.map(r => ({
        entityId: r['entity-id'],
        entityType: r['entity-type'],
        avgScore: parseFloat(r['avg_score']),
        correlation: parseFloat(r['correlation']).toFixed(2),
        sumOpinionIndex: parseInt(r['entity_opinion_index']),
        avgOpinionIndex: parseInt(r['entity_opinion_index']) / parseInt(r['entity_mentions']),
        mentionNumber: parseInt(r['entity_mentions']),
        posMentionNumber: parseInt(r['entity_pos_mentions']),
        negMentionsNumber: parseInt(r['entity_neg_mentions']),
        neutMentionNumber: parseInt(r['entity_neu_mentions']),
        distribution: cxDimension.type === dimensionTypeId.NPS ?
            { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0 } :
            { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 },
    }));
}

async function addCxDistribution(cxDimension: DimensionDefinition, verbatimDimensionId: string, cxEntityStats: CxEntity[], filters: Filter[]): Promise<CxEntity[]> {
    const response = await getAggregation(
        useCommonStore().state.dataset.id,
        [
            { name: 'entity', type: AggregationDimensionType.entities, dimensionId: verbatimDimensionId },
            { name: 'score', type: AggregationDimensionType.value, dimensionId: cxDimension.id },
        ],
        [{ name: 'count', type: AggregationMeasureType.count, subType: AggregationMeasureSubType.value }],
        filters,
    );

    response.forEach(d => {
        const cxEntity = cxEntityStats.find(e => d['entity-id'] === e.entityId && d['entity-type'] === e.entityType);
        if (cxEntity) {
            cxEntity.distribution[Math.round(parseFloat(d.score))] = cxEntity.distribution[Math.round(parseFloat(d.score))] + d.count;
        }
    });
    return cxEntityStats;
}
