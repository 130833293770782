<template>
    <div
        class="loading-animation rounded-full"
        :style="{'background': color, width: `${size}px`, height: `${size}px`}"
    />
</template>

<script>
export default {
    name: 'LoadingAnimation',
    props: {
        color: { type: String, default: 'var(--color-primary-700)' },
        size: { type: Number, default: 64 },
    },
};
</script>

<style>
.loading-animation {
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
        -webkit-transform: scale(1.0);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 100% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
          opacity: 0;
      }
}
</style>
