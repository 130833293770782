<template>
    <thead>
        <tr>
            <th
                v-for="({type}, index) in columns"
                :key="index"
                class="text-sm text-neutral-700 font-normal text-left p-2 space-y-2"
                :class="{
                    'bg-primary-300': type === 2
                }"
            >
                <div>
                    {{ $t('PDF_EXTRACTOR.DIMENSION_TYPE', 'Dimension type') }}
                </div>
                <z-input
                    class="block"
                    :value="getTypeName(type)"
                    disabled
                />
            </th>
        </tr>
        <tr class="border-b">
            <th
                v-for="({name, type}, index) in columns"
                :key="index"
                class="text-sm text-neutral-700 font-normal text-left p-2 space-y-2"
                :class="{
                    'bg-primary-300': type === 2
                }"
            >
                <div>
                    {{ $t('PDF_EXTRACTOR.DIMENSION_NAME', 'Dimension name') }}
                </div>
                <z-input
                    class="block"
                    :value="name"
                    disabled
                />
            </th>
        </tr>
    </thead>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import i18next from 'i18next';

export default defineComponent({
    props: {
        columnTypes: { type: Array, default: () => [] },
        columnNames: { type: Array, default: () => [] },
    },
    setup(props) {
        const getTypeName = (type: number): string => {
            return i18next.t(`ANALYSIS.COLUMN_OPTION${type.toString()}`);
        };

        const columns = computed(() => props.columnTypes.map((type, index) => ({ type, name: props.columnNames[index] })));

        return { getTypeName, columns };
    },
});
</script>
