import { api } from '@/api';

import { Dataset } from '@/datasets/store/types';

export const getDataset = async (datasetId: string): Promise<Dataset> => {
    const { data } = await api.get(`/datasets/${datasetId}`);

    return data;
};

export const reanalyze = async (datasetId: string): Promise<void> => {
    await api.post('/datasets/' + datasetId + '/reanalyze');
};
