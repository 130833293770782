<template>
    <label
        class="cursor-pointer inline-flex items-center relative"
        :class="{ 'justify-center': !(labelText || $slots.default)}"
        :for="id"
    >
        <div class="checkbox_input leading-none flex items-center">
            <input
                :id="id"
                type="radio"
                :checked="checked === value"
                :data-test-id="dataTestId"
                @change="$emit('change', value)"
            >
            <span class="checkbox_control">
                <svg
                    height="1em"
                    width="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="11.5"
                        :stroke="color"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7Z"
                        :fill="checked === value ? color : 'transparent'"
                    />
                </svg>
            </span>
        </div>
        <div
            v-if="labelText || $slots.default"
            :class="[paddingSize]"
            class="pr-0 py-0"
        >
            <slot>
                {{ labelText }}  <!--When no slot is specified in the parent, this fallback text will appear-->
            </slot>
        </div>
    </label>
</template>

<script>
export default {
    model: {
        prop: 'checked',
        event: 'change'
    },
    props: {
        id: { type: String, default: () => `radio-${Math.random() * 1000000}` },
        value: { type: [Number, String, Boolean], required: true },
        checked: { type: [Number, String, Boolean], required: false, default: '' },
        labelText: { type: String, required: false, default: '' },
        primaryColor: { type: String, required: false, default: '' },
        paddingSize: { type: String, default: 'p-2' },
        dataTestId: { type: String, default: '' },
    },
    computed: {
        color() {
            return this.primaryColor || 'var(--color-primary-700)';
        },
    },
};
</script>

<style scoped lang="less">
.checkbox_control {
    svg path {
        transition: transform 0.1s ease-in 25ms;
        transform: scale(0);
        transform-origin: center;
    }
}

.checkbox_input {
    input {
        display: none;

        &:checked + .checkbox_control svg path {
            transform: scale(1);
        }

        &:disabled + .checkbox_control {
            color: var(--disabled);
        }
    }
}
</style>
