import { RouteConfig } from 'vue-router';

const GroupSettings = () => import('@/admin/components/GroupSettings.vue');
const GroupStatistics = () => import('@/admin/components/GroupStatistics/GroupStatistics.vue');
const SearchGroups = () => import('@/admin/components/GroupStatistics/SearchGroups.vue');
const ActivityFeed = () => import('@/admin/components/ActivityFeed.vue');
const Group = () => import('@/admin/components/Group.vue');
const Announcements = () => import('@/admin/components/Announcements.vue');
const CopySurvey = () => import( '@/admin/components/CopySurvey.vue');
const Superadmins = () => import(  '@/admin/components/Superadmins.vue');

const RunningAnalyses = () => import(  '@/admin/components/RunningAnalyses.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: 'groupsettings',
    },
    {
        path: 'groupsettings',
        component: GroupSettings,
    },
    {
        path: 'groupstatistics',
        components: {
            default: GroupStatistics,
            nav: SearchGroups
        },
    },
    {
        path: 'activityfeed',
        component: ActivityFeed,
    },
    {
        path: 'group/:id',
        component: Group,
    },
    {
        path: 'announcements',
        component: Announcements,
    },
    {
        path: 'copy-survey',
        component: CopySurvey,
    },
    {
        path: 'running-analyses',
        component: RunningAnalyses,
    },
    {
        path: 'superadmins',
        component: Superadmins,
    },
];

export default routes;
