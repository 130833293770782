<template>
    <div
        v-if="suggestions.length > 0"
        class="tag-suggestions"
    >
        <z-tag
            v-for="tag in suggestions"
            :key="tag"
            class="mr-1 mt-1"
            addable
            @click="handleClick(tag)"
        >
            {{ tag }}
        </z-tag>
    </div>
    <div
        v-else-if="suggestions.length === 0 && query"
        class="text-neutral-700 text-default p-2"
    >
        {{ $t('CUSTOM_LABELS.NO_TAGS_FOUND', { query, defaultValue: 'query not found: {{ query }\}' }) }}
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

import useCustomLabelStore from '@/custom-labels/store/';

export default defineComponent({
    props: {
        query: { type: String, required: true },
        tags: { type: Array, required: true }
    },
    setup(props, context) {
        const { getters } = useCustomLabelStore();
        const { allTags } = getters;

        function handleClick(tag: string) {
            context.emit('add-tag', tag);
        }

        const suggestions = computed(() => {
            if (!props.query) {
                return [];
            } else {
                return allTags.value.filter(tag => {
                    return tag.toLowerCase().includes(props.query.toLowerCase()) && !props.tags.includes(tag);
                });
            }
        });

        return {
            suggestions,
            handleClick,
            allTags
        };
    }
});
</script>
