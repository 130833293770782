import orderBy from 'lodash/orderBy';
import map from 'lodash/map';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { getFilters } from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';
import { EntitiesState, EntityTypeEnum, FilteredCustomLabel, FilteredEntity } from '../utils/entityTypes';
import { computed, reactive } from 'vue';

import * as customLabelCategoryApi from '@/custom-labels/api/custom-label-categories';
import sortBy from 'lodash/sortBy';
import useDataseriesStore from '@/dashboard/common/store/dataseriesStore';
import languages from '@/domain/analysisLanguageEnum';
import { dimensionTypeId } from '@/domain/dataset/dimensionTypeId';

const getInitialState = (): EntitiesState => ({
    allEntities: { default: [] },
    isAllEntitiesLoading: true,
    filteredAllEntities: { default: [] },
    entityRelations: { default: [] },
    phraseRelations: { default: {} },
    isRelationsLoading: true,
    topAllEntityCorrelations: { default: {} },
    topAllEntityCorrelationsLoading: true,
    topAllEntityTimeSeries: [],
    topAllEntityTimeSeriesLoading: true,
    usedCustomLabelCategories: { default: [] },
    usedCustomLabelCategoriesInitialized: false,
    phrases: { default: [] },
    phrasesLoading: true,
    isPhraseRelationsLoading: true,
    isDriversLoading: true,
    topDrivers: { posPhrases: [], negPhrases: [] },
});

export const initialState = reactive<EntitiesState>(getInitialState());

export const makeActions = (state, getters) => ({
    async getAllEntities() {
        state.isAllEntitiesLoading = true;

        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        // When CLs are deleted from group, their ids are deleted from dimension but not from records, so those need to filtered out
        const dimensionCLs = commonState.dataset.dimension_definitions.find(dd => dd.id === state.columnId)?.customLabels || [];

        if (selectedDataseries.length === 0) {
            const response = await datasetApi.getAllEntities(commonState.dataset.id, state.columnId, [getFilters()]) || [];
            const validResponse = response[0].filter(e => e.type !== EntityTypeEnum.customLabel || dimensionCLs.includes(parseInt(e.id)));
            // If entities array is empty, it is the initial load, we should load all entities
            // Or if a new CL is added during quick reanalysis, we should extend it
            if (!state.allEntities.default || !state.allEntities.default.length || state.allEntities.default.length < response[0].length) {
                state.allEntities = {
                    default: validResponse.map(entity => ({ ...entity, isSelected: false, isShown: true }))
                };
            }
            validResponse.forEach((entity) => {
                const originEntity = state.allEntities.default?.find(originalEntity => originalEntity.name === entity.name);
                if (!originEntity) {
                    Sentry.addBreadcrumb({ message: 'Could not find entity in original entities with name: ' + entity.name });
                }
                // modifying the original response is not the best coding pattern...
                entity.originalMentionNumber = originEntity ? originEntity.mentionNumber : entity.mentionNumber;
            });

            state.filteredAllEntities = {
                default: orderBy(validResponse, ['mention_number', 'id'], ['desc'])
            };
        }
        else {
            const dsFilters = selectedDataseries.map(ds => ds.filters);
            const responses = await datasetApi.getAllEntities(commonState.dataset.id, state.columnId, dsFilters);

            const allEntities = {};
            const filteredAllEntities = {};

            selectedDataseries.forEach((ds, index) => {
                if (!allEntities[ds.id!] || !allEntities[ds.id!].length || allEntities[ds.id!].length < responses[index].length) {
                    const validResponse = responses[index].filter(e => e.type !== EntityTypeEnum.customLabel || dimensionCLs.includes(parseInt(e.id)));
                    allEntities[ds.id!] = validResponse.map(entity => ({ ...entity, isSelected: false, isShown: true }));
                }

                responses[index].forEach((entity) => {
                    const originEntity = allEntities[ds.id!]?.find(originalEntity => originalEntity.name === entity.name);
                    if (!originEntity) {
                        Sentry.addBreadcrumb({ message: 'Could not find entity in original entities with name: ' + entity.name });
                    }
                    // modifying the original response is still not the best coding pattern...
                    entity.originalMentionNumber = originEntity ? originEntity.mentionNumber : entity.mentionNumber;
                });

                filteredAllEntities[ds.id!] = orderBy(responses[index], ['mention_number', 'id'], ['desc']);
            });

            state.allEntities = allEntities;
            state.filteredAllEntities = filteredAllEntities;
        }

        state.isAllEntitiesLoading = false;
    },
    async getMentions(labels) {
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const mentions = await datasetApi.getMentions(commonState.dataset.id, state.columnId, getFilters(), labels);
            state.allEntities = {
                default: state.allEntities.default.map(e => Object.keys(mentions).some(m => m === e.id) ? { ...e, ...Object.entries(mentions).find(m => m[0] === e.id)![1] } : e)
            };
            state.filteredAllEntities = {
                default: state.filteredAllEntities.default.map(e => Object.keys(mentions).some(m => m === e.id) ? { ...e, ...Object.entries(mentions).find(m => m[0] === e.id)![1] } : e)
            };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getMentions(commonState.dataset.id, state.columnId, ds.filters, labels)));

            const allEntities = {};
            const filteredAllEntities = {};

            selectedDataseries.forEach((ds, index) => {
                allEntities[ds.id!] = state.allEntities[ds.id!]?.map(e => Object.keys(responses[index]).some(m => m === e.id) ? { ...e, ...Object.entries(responses[index]).find(m => m[0] === e.id)![1] } : e) || [];

                filteredAllEntities[ds.id!] = state.filteredAllEntities[ds.id!]?.map(e => Object.keys(responses[index]).some(m => m === e.id) ? { ...e, ...Object.entries(responses[index]).find(m => m[0] === e.id)![1] } : e) || [];
            });

            state.allEntities = allEntities;
            state.filteredAllEntities = filteredAllEntities;
        }
    },
    async getRelationMentions() {
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const mentions = await datasetApi.getRelationMentions(commonState.dataset.id, state.columnId, getFilters());
            mentions.forEach(m => {
                const entityIndex = state.entityRelations.default.findIndex(e => e.id == m.id && e.name === m.name);
                if (entityIndex !== -1) {
                    const relatedEntityIndex = state.entityRelations.default[entityIndex].relations.findIndex(e => e.relatedEntityId == m.relatedEntityId && e.relatedEntityName === m.relatedEntityName);
                    if (relatedEntityIndex !== -1) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { id, name, relatedEntityId, relatedEntityName, ...mentions } = m;
                        state.entityRelations.default[entityIndex].relations[relatedEntityIndex] = Object.assign(state.entityRelations.default[entityIndex].relations[relatedEntityIndex], mentions);
                    }
                }
            });
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getRelationMentions(commonState.dataset.id, state.columnId, ds.filters)));

            selectedDataseries.forEach((ds, index) => {
                responses[index].forEach(m => {
                    const entityIndex = state.entityRelations[ds.id!].findIndex(e => e.id == m.id && e.name === m.name);
                    if (entityIndex !== -1) {
                        const relatedEntityIndex = state.entityRelations[ds.id!][entityIndex].relations.findIndex(e => e.relatedEntityId == m.relatedEntityId && e.relatedEntityName === m.relatedEntityName);
                        if (relatedEntityIndex !== -1) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const { id, name,relatedEntityId, relatedEntityName, ...mentions } = m;
                            state.entityRelations[ds.id!][entityIndex].relations[relatedEntityIndex] = Object.assign(state.entityRelations[ds.id!][entityIndex].relations[relatedEntityIndex], mentions);
                        }
                    }
                });
            });
        }

    },
    async getPhrases() {
        state.phrasesLoading = true;
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            state.phrases = {
                default: await datasetApi.getPhrases(commonState.dataset.id, state.columnId, getFilters())
            };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getPhrases(commonState.dataset.id, state.columnId, ds.filters)));

            const phrases = {};

            selectedDataseries.forEach((ds, index) => {
                phrases[ds.id!] = responses[index];
            });

            state.phrases = phrases;
        }

        state.phrasesLoading = false;
    },
    async getAllRelations() {
        state.isRelationsLoading = true;
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            state.entityRelations = {
                default: await datasetApi.getEntityRelations(commonState.dataset.id, state.columnId, getFilters())
            };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getEntityRelations(commonState.dataset.id, state.columnId, ds.filters)));

            const entityRelations = {};

            selectedDataseries.forEach((ds, index) => {
                entityRelations[ds.id!] = responses[index];
            });

            state.entityRelations = entityRelations;
        }
        state.isRelationsLoading = false;
    },
    async getPhraseRelations() {
        state.isPhraseRelationsLoading = true;
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            state.phraseRelations = {
                default: await datasetApi.getPhraseRelations(commonState.dataset.id, state.columnId, getters.entityIds.value.default, getFilters())
            };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getPhraseRelations(commonState.dataset.id, state.columnId, getters.entityIds.value[ds.id!], ds.filters)));

            const phraseRelations = {};

            selectedDataseries.forEach((ds, index) => {
                phraseRelations[ds.id!] = responses[index];
            });

            state.phraseRelations = phraseRelations;
        }

        state.isPhraseRelationsLoading = false;
    },
    async getTopDrivers() {
        state.isDriversLoading = true;

        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        // This chart is only displayed when these conditions are met
        const customLabelIds = commonState.dataset.dimension_definitions.find(dd => dd.id === state.columnId)?.customLabels || [];
        if (
            selectedDataseries.length === 0 &&
            commonState.dataset.language_id !== languages.MIXED &&
            (commonState.dataset.topic_recognition || customLabelIds.length > 0)
        ) {
            state.topDrivers = await datasetApi.getTopDrivers(commonState.dataset.id, state.columnId, getFilters());
        }

        state.isDriversLoading = false;
    },
    async getTopAllEntityCorrelations() {
        state.topAllEntityCorrelationsLoading = true;
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            state.topAllEntityCorrelations = {
                default: await datasetApi.getTopAllEntityCorrelations(commonState.dataset.id, state.columnId, getFilters(), map(getters.filteredLabels.value.default?.slice(0, 75) || [], 'name'))
            };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getTopAllEntityCorrelations(commonState.dataset.id, state.columnId, ds.filters, map(getters.filteredLabels.value[ds.id!]?.slice(0, 100) || [], 'name'))));

            const topAllEntityCorrelations = {};

            selectedDataseries.forEach((ds, index) => {
                topAllEntityCorrelations[ds.id!] = responses[index];
            });

            state.topAllEntityCorrelations = topAllEntityCorrelations;
        }

        state.topAllEntityCorrelationsLoading = false;
    },
    async getTopAllEntityTimeSeries() {
        state.topAllEntityTimeSeriesLoading = true;
        const dateDimensions = commonState.dataset.dimension_definitions.filter(dd => dd.type === dimensionTypeId.DATE) || [];
        if (dateDimensions.length === 0) {
            return;
        }

        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const response = await datasetApi.getTopAllEntityTimeSeries(commonState.dataset.id, state.columnId, getFilters(), map(getters.filteredLabels.value.default?.slice(0, 10) || [], 'name'));

            state.topAllEntityTimeSeries = response.map(ts => ({
                name: ts.name,
                column: ts.column,
                dates: {
                    default: ts.dates
                }
            }));
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getTopAllEntityTimeSeries(commonState.dataset.id, state.columnId, ds.filters, map(getters.filteredLabels.value[ds.id!]?.slice(0, 10) || [], 'name'))));

            const names = Object.values(responses).flat().reduce((names: string[], ts: any) => {
                if (!names.includes(ts.name)) {
                    names.push(ts.name);
                }

                return names;
            }, []);

            state.topAllEntityTimeSeries = names.map(name => {
                return {
                    name,
                    column: responses.flat().find(ts => ts.name === name)?.column,
                    dates: selectedDataseries.reduce((dates, ds, index) => {
                        dates[ds.id!] = responses[index].find(r => r.name === name)?.dates || [];

                        return dates;
                    }, {})
                };
            });
        }
        state.topAllEntityTimeSeriesLoading = false;
    },
    async getUsedCustomLabelCategories() {
        const dimensionCLs = commonState.dataset.dimension_definitions.find(dd => dd.id === state.columnId)?.customLabels || [];
        if (dimensionCLs.length === 0) {
            return;
        }

        //We need this "if" to mark if it is the first init or not. Because of the load function, this function always rerun.
        if (!state.usedCustomLabelCategoriesInitialized) {
            const response = await customLabelCategoryApi.getCategories();

            const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

            if (selectedDataseries.length === 0) {
                //We need this "if" to check if there is any categories
                if (!state.usedCustomLabelCategories.default?.length) {
                    state.usedCustomLabelCategories = {
                        default: response
                            .filter(category => state.allEntities.default?.some(cl => cl.categoryName === category.name))
                            .map(c => ({
                                id: c.id,
                                name: c.name,
                                mention_number: state.allEntities.default?.reduce((sum, currentCL) => currentCL.categoryName === c.name ? sum + currentCL.mentionNumber : sum, 0) || 0,
                                color: 'rgba(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + (Math.random() * (1 - 0.5) + 0.5).toFixed(3) + ')',
                                is_selected: false,
                                is_shown: true
                            }))
                    };
                }
            }
            else {
                const usedCustomLabelCategories = {};

                selectedDataseries.forEach(ds => {
                    if (!state.usedCustomLabelCategories[ds.id!]?.length) {
                        usedCustomLabelCategories[ds.id!] = response
                            .filter(category => state.allEntities[ds.id!]?.some(cl => cl.categoryName === category.name))
                            .map(c => ({
                                id: c.id,
                                name: c.name,
                                mention_number: state.allEntities[ds.id!]?.reduce((sum, currentCL) => currentCL.categoryName === c.name ? sum + currentCL.mentionNumber : sum, 0) || 0,
                                color: 'rgba(' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + Math.floor(Math.random() * 255) + ',' + (Math.random() * (1 - 0.5) + 0.5).toFixed(3) + ')',
                                is_selected: false,
                                is_shown: true
                            }));
                    }
                });

                state.usedCustomLabelCategories = usedCustomLabelCategories;
            }

            state.usedCustomLabelCategoriesInitialized = true;
        }
    },
    resetUsedCustomLabelCategoriesInitialized() {
        state.usedCustomLabelCategoriesInitialized = false;
        state.usedCustomLabelCategories = { default: [] };
    },
    resetAllEntitiesState: () => Object.entries(getInitialState()).forEach(([key, value]) => state[key] = value),
});

export const makeGetters = (state: EntitiesState) => ({
    entityIds: computed<{ [ds: string]: number[] }>(() => {
        const selectedDataseries = Object.keys(state.allEntities);

        if (selectedDataseries.length === 0) {
            return { default: state.allEntities.default?.slice(0, 25).map(e => e.id) || [] };
        }

        const ids = {};

        for (const ds of selectedDataseries) {
            ids[ds] = state.allEntities[ds]?.slice(0, 25).map(e => e.id) || [];
        }

        return ids;
    }),
    filteredLabels: computed<{ [ds: string]: FilteredEntity[] }>(() => {
        const selectedDataseries = Object.keys(state.filteredAllEntities);
        if (selectedDataseries.length === 0) {
            return { default: state.filteredAllEntities.default?.filter(e => e.type !== EntityTypeEnum.customLabel) || [] };
        }

        const labels = {};

        for (const ds of selectedDataseries) {
            labels[ds] = state.filteredAllEntities[ds]?.filter(e => e.type !== EntityTypeEnum.customLabel) || [];
        }

        return labels;
    }),
    filteredCustomLabels: computed<{ [ds: string]: FilteredCustomLabel[] }>(() => {
        const selectedDataseries = Object.keys(state.filteredAllEntities);

        if (selectedDataseries.length === 0) {
            return {
                default: state.filteredAllEntities.default?.filter(e => e.type === EntityTypeEnum.customLabel).map((cl) => {
                    const clRelation = state.entityRelations.default?.find(r => cl.name === r.name && cl.id === r.id);
                    const topRelatedEntities = sortBy(clRelation?.relations, 'mentionNumber').reverse()?.slice(0, 5) || [];
                    return {
                        id: cl.id,
                        name: cl.name,
                        category_id: state.usedCustomLabelCategories.default?.find(c => c.name === cl.categoryName)?.id,
                        count: cl.mentionNumber,
                        positive_mention_number: cl.positiveMentionNumber,
                        negative_mention_number: cl.negativeMentionNumber,
                        posMention: cl.posMention,
                        neuMention: cl.neuMention,
                        negMention: cl.negMention,
                        opinion_index: cl.opinionIndex,
                        correlation: state.topAllEntityCorrelations.default?.[cl.id],
                        type: cl.type,
                        entities: topRelatedEntities
                    };
                }) || []
            };
        }

        const customlabels = {};

        for (const ds of selectedDataseries) {
            customlabels[ds] = state.filteredAllEntities[ds]?.filter(e => e.type === EntityTypeEnum.customLabel).map((cl) => {
                const clRelation = state.entityRelations[ds]?.find(r => cl.name === r.name && cl.id === r.id);
                const topRelatedEntities = sortBy(clRelation?.relations, 'mentionNumber').reverse()?.slice(0, 5) || [];

                return {
                    id: cl.id,
                    name: cl.name,
                    category_id: state.usedCustomLabelCategories[ds]?.find(c => c.name === cl.categoryName)?.id,
                    count: cl.mentionNumber,
                    positive_mention_number: cl.positiveMentionNumber,
                    negative_mention_number: cl.negativeMentionNumber,
                    posMention: cl.posMention,
                    neuMention: cl.neuMention,
                    negMention: cl.negMention,
                    opinion_index: cl.opinionIndex,
                    correlation: state.topAllEntityCorrelations[ds]?.[cl.id],
                    type: cl.type,
                    entities: topRelatedEntities
                };
            }) || [];
        }

        return customlabels;
    }),
    topEntityTimeSeries: computed(() => {
        return state.topAllEntityTimeSeries.map(ts => {
            const datesWithLabels = {};

            for (const ds in ts.dates) {
                datesWithLabels[ds] = {};

                for (const date in ts.dates[ds]) {
                    datesWithLabels[ds][date] = (ts.dates[ds][date] || [])
                        .filter(date => date.type === EntityTypeEnum.label)
                        .map((date) => {
                            return {
                                datetime: date.datetime,
                                entity_name: date.name,
                                entity_eng_name: date.engName,
                                opinion_index: date.opinionIndex,
                                mention_number: date.mentionNumber
                            };
                        });
                }
            }

            return {
                name: ts.name,
                column: ts.column,
                dates: datesWithLabels,
            };
        });
    }),
    customLabelTimeSeries: computed(() => {
        return state.topAllEntityTimeSeries.map(ts => {
            const datesWithCustomLabels = {};

            for (const ds in ts.dates) {
                datesWithCustomLabels[ds] = {};

                for (const date in ts.dates[ds]) {
                    datesWithCustomLabels[ds][date] = (ts.dates[ds][date] || [])
                        .filter(date => date.type === EntityTypeEnum.customLabel)
                        .map((date) => {
                            return {
                                datetime: date.datetime,
                                keyword_name: date.name,
                                id: date.id,
                                opinion_index: date.opinionIndex,
                                mention_number: date.mentionNumber
                            };
                        });
                }
            }

            return {
                name: ts.name,
                column: ts.column,
                dates: datesWithCustomLabels,
            };
        });
    }),
});
