import mutations from './mutations.js';
import actions from './actions.js';

const state = {
    loading: false,
    isDownloadPopupShown: false,
    selectedDataSource: null,
    uploadedExcel: null,
    appendedDataSourceId: null,
    trialEndsAt: null,
    categories: [],
    userGroupData: [],
    tableStorageData: [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
