import { api } from '@/api/index.js';

export default {
    namespaced: true,
    state: () => ({
        table: null,
        file: null,
        response: null
    }),
    mutations: {
        setTable: (state, data) => state.table = data,
        setSheets: (state, sheets) => {
            state.table = {
                ...state.table,
                sheets,
            };
        },
        setFile: (state, file) => state.file = file,
        setResponse: (state, response) => state.response = response,
        reset: (state) => {
            state.table = null;
            state.file = null;
            state.response = null;
        },
    },
    actions: {
        generateEncodedURLs: async ({ state }, { id, sheetIndex, headerRows, columns }) => {
            const formData = new FormData();
            formData.append('sheetIndex', sheetIndex);
            formData.append('headerRows', headerRows);
            formData.append('columns', JSON.stringify(columns));
            formData.append('file', state.file);
            const { data } =  await api.post(`vue/survey/${id}/generateEncodedURLs`, formData, {
                headers: {'Content-Type': 'multipart/form-data'},
                responseType: 'blob'
            });
            return data;
        }
    }
};
