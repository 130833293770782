var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fixed flex items-center justify-center top-0 left-0 bottom-0 right-0",staticStyle:{"z-index":"998"},style:({ 'background-color': _vm.hasBackdrop && 'rgb(0,0,0,.5)' }),on:{"mousewheel":function($event){$event.stopPropagation();},"scroll":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"fixed top-0 bottom-0 left-0 right-0 bg-transparent z-40",on:{"click":_vm.clickOut}}),_c('scrollbar',{staticClass:"rounded bg-white text-black p-4 shadow-depth-300 z-50",class:[
            _vm.widthClass ? _vm.widthClass : '',
            _vm.heightClass ? _vm.heightClass : '',
            _vm.stickyHeader ? 'pt-0' : ''
        ],staticStyle:{"max-height":"calc(100vh - 32px)"},attrs:{"stop-propagation":""}},[(_vm.title || _vm.$slots.header || _vm.isClosable || _vm.hasMorePages)?_c('div',{class:{
                '-m-4 mb-0 p-4 sticky top-0 bg-bg': _vm.stickyHeader,
                'mb-3': !_vm.stickyHeader
            }},[(_vm.hasMorePages)?_c('z-icon',{staticClass:"cursor-pointer float-left mt-1 mr-1",attrs:{"icon":"chevron-left","data-test-id":"popup-go-back"},on:{"click":function($event){return _vm.$emit('go-back')}}}):_vm._e(),(_vm.isClosable)?_c('z-icon',{staticClass:"cursor-pointer float-right ml-4 mb-4",attrs:{"icon":"times","data-test-id":"close-popup"},on:{"click":function($event){return _vm.$emit('close')}}}):_vm._e(),_vm._t("header",function(){return [_c('div',{staticClass:"text-base text-black font-bold break-all",class:{
                        'capitalize': !_vm.dontCapitalizeTitle
                    },domProps:{"innerHTML":_vm._s(_vm.title)}})]})],2):_vm._e(),_c('div',[_vm._t("default",function(){return [_c('span',{staticClass:"text-black text-default",domProps:{"innerHTML":_vm._s(_vm.text)}})]})],2),_c('div',{staticClass:"footer"},[_vm._t("footer")],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }