import {api} from '@/api/index.js';
import {Filter, FilterSet} from '@/dashboard/common/types/filterInterface';

export const getAggregation = async (datasetId: string, dimensions: AggregationDimension[], measures: AggregationMeasure[], filters: Filter[] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations`, { dimensions, measures, filters });
    return data;
};

export interface AggregationDimension {
    name: string,
    dimensionId: string,
    type: AggregationDimensionType
}

export interface AggregationMeasure {
    name: string,
    type: AggregationMeasureType,
    subType?: AggregationMeasureSubType,
    dimensionId?: string,
    correlationDimensionId1?: string,
    correlationDimensionId2?: string,
    correlationType1?: AggregationMeasureSubType,
    correlationType2?: AggregationMeasureSubType,
}

export enum AggregationDimensionType {
    value='value',
    entities='entities',
    customLabel='customLabel',
}

export enum AggregationMeasureType {
    count='count',
    distinct='distinct',
    sum='sum',
    avg='avg',
    posSum='posSum',
    negSum='negSum',
    neuSum='neuSum',
    correlation='correlation',
    posCount='posCount',
    negCount='negCount',
    neuCount='neuCount',
}

export enum AggregationMeasureSubType {
    value='value',
    opinionIndex='opinionIndex',
    entityMentionNumber='entityMentionNumber',
    entityOpinionIndex='entityOpinionIndex',
}
