import { RouteConfig } from 'vue-router';

import UploadExcel from './pages/UploadExcel.vue';
import ReanalyzeExcel from './pages/ReanalyzeExcel.vue';
import AppendExcel from './pages/AppendExcel.vue';
import Finished from './pages/Finished.vue';

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/'
    },
    {
        name: 'upload-excel',
        path: 'upload',
        component: UploadExcel
    },
    {
        name: 'reanalyze-excel',
        path: 'reanalyze',
        component: ReanalyzeExcel
    },
    {
        name: 'append-excel',
        path: 'append',
        component: AppendExcel
    },
    {
        name: 'finished-page',
        path: 'finished',
        component: Finished
    },
];

export default routes;
