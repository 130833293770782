import type { Ref } from 'vue';

import { api } from '@/api/index.js';
import { ref } from 'vue';
import { SurveyEmailTemplate, NewSurveyEmailTemplate, Recipient } from '@/survey/api/types';

/**
* Embed settings
**/
export async function getSurveyEmbedSettings(userGroupId, datasetId) {
    const { data } = await api.get('/survey-embed-settings?datasetId=' + datasetId);
    return data;
}
export async function createSurveyEmbedSettings(embedSettings) {
    const { data } = await api.post('/survey-embed-settings', embedSettings);
    return data;
}
export async function deleteSurveyEmbedSettings(id) {
    const { data } = await api.delete('/survey-embed-settings/' + id);
    return data;
}
export async function updateSurveyEmbedSettings(embedSettings) {
    const { data } = await api.put('/survey-embed-settings/' + embedSettings.id, embedSettings);
    return data;
}

/**
 * Templates
 **/

export async function getSurveyEmailTemplates(): Promise<SurveyEmailTemplate[]> {
    const { data } = await api.get('/survey-email-templates');
    return data;
}
export async function getSurveyEmailTemplate(templateId: number): Promise<SurveyEmailTemplate> {
    const { data } = await api.post('/survey-email-templates/' + templateId);
    return data;
}
export async function createSurveyEmailTemplate(template: NewSurveyEmailTemplate): Promise<SurveyEmailTemplate> {
    const { data } = await api.post('/survey-email-templates', template);
    return data;
}
export async function updateSurveyEmailTemplate(templateId: number, template: NewSurveyEmailTemplate): Promise<SurveyEmailTemplate> {
    const { data } = await api.put('/survey-email-templates/' + templateId, template);
    return data;
}
export async function deleteSurveyEmailTemplate(id: number): Promise<void> {
    await api.delete('/survey-email-templates/' + id);
}
export async function sendSurveyEmails(templateId: number, surveyId: number, recipients: Recipient[], isTest: boolean = false): Promise<void> {
    await api.post(`/survey-email-templates/${templateId}/sendEmails/${surveyId}` + (isTest ? '?test=true' : ''), { recipients });
}

// Multilang

export async function setIsMultiLang(surveyId: number, isMultiLang: boolean): Promise<void> {
    await api.post(`/vue/survey/${surveyId}/setIsMultiLang`, { isMultiLang });
}

export async function saveTranslations(surveyId: number, { draftTranslations, liveTranslations, activeLanguages, publishedAt }): Promise<void> {
    await api.post(`/vue/survey/${surveyId}/saveTranslations`, { draftTranslations, liveTranslations, activeLanguages, publishedAt });
}

interface SurveyEmailTemplateState {
    surveyEmailTemplates: Ref<SurveyEmailTemplate[]>;
    surveyEmailTemplatesToSaveLater: Ref<SurveyEmailTemplate[]>;
}

const state: SurveyEmailTemplateState = {
    surveyEmailTemplates: ref([]),
    surveyEmailTemplatesToSaveLater: ref([]),
};

const findSurveyEmailTemplates = async (refreshCachedData = false): Promise<SurveyEmailTemplate[]> => {
    if (refreshCachedData || !state.surveyEmailTemplates.value.length) {
        const templates = await getSurveyEmailTemplates();
        state.surveyEmailTemplates.value = templates;

        return templates;
    }

    return state.surveyEmailTemplates.value;
};

const reloadSurveyEmailTemplates = async (): Promise<SurveyEmailTemplate[]> => {
    return findSurveyEmailTemplates(true);
};

function byTemplateId(this: number, template: SurveyEmailTemplate): boolean {
    return template.id === this;
}

const removeSurveyEmailTemplate = async (id: number): Promise<void> => {
    await deleteSurveyEmailTemplate(id);
    state.surveyEmailTemplates.value = state.surveyEmailTemplates.value.filter(t => t.id !== id);
};

const createSurveyEmailTemplateNow = async (fields: NewSurveyEmailTemplate): Promise<SurveyEmailTemplate> => {
    const template = await createSurveyEmailTemplate(fields);
    state.surveyEmailTemplates.value.push(template);

    return template;
};

const updateSurveyEmailTemplateNow = (fields: NewSurveyEmailTemplate) => {
    if (!fields.id) {
        // eslint-disable-next-line
        throw new ReferenceError(`The id of the template must be set to update it!`);
    }

    const template = state.surveyEmailTemplates.value.find(byTemplateId, fields.id);

    if (!template) {
        // eslint-disable-next-line
        throw new ReferenceError(`The template with id ${ fields.id } was not found!`);
    }

    if (fields.name) template.name = fields.name;
    if (fields.datasetId) template.datasetId = fields.datasetId;
    if (fields.variables) template.variables = fields.variables;
    if (fields.customizationOptions) Object.assign(template.customizationOptions, fields.customizationOptions);

    return updateSurveyEmailTemplate(fields.id, fields);
};

const updateSurveyEmailTemplateLater = (template: SurveyEmailTemplate): void => {
    state.surveyEmailTemplatesToSaveLater.value.push(template);
};

const updateTemplatePool = [];
const updateDelayedSurveyEmailTemplatesNow = async (): Promise<void> => {
    updateTemplatePool.splice(0, updateTemplatePool.length);

    const templates = state.surveyEmailTemplatesToSaveLater.value;
    for (const template of templates) {
        updateSurveyEmailTemplate(template.id, template);
    }

    await Promise.all(updateTemplatePool);
    templates.splice(0, templates.length);

    await reloadSurveyEmailTemplates();
};

export const useSurveyEmailTemplates = () => {
    return {
        state,
        findSurveyEmailTemplates,
        createSurveyEmailTemplate,
        removeSurveyEmailTemplate,
        updateSurveyEmailTemplate,
        reloadSurveyEmailTemplates,
        createSurveyEmailTemplateNow,
        updateSurveyEmailTemplateNow,
        updateSurveyEmailTemplateLater,
        updateDelayedSurveyEmailTemplatesNow,
    };
};

export const getSurveyQuestionOptions = async ({ datasetId, dimensionId, type, langId }) => {
    const { data } = await api.get(`/vue/survey/${datasetId}/${dimensionId}/${type}/options${ langId ? '/' + langId : ''}`);
    return data;
};

export const saveOptionsFromFile = async ({ datasetId, dimensionId, type, langId, options }) => {
    const { data } = await api.post(
        `vue/survey/${datasetId}/${dimensionId}/${type}/saveDropdownOptionsFromFile${langId ? '/' + langId : ''}`,
        { options }
    );
    return data;
};

export const deleteOptionsFromFile = async ({ datasetId, dimensionId, type, langId }) => {
    await api.delete(`vue/survey/${datasetId}/${dimensionId}/${type}/deleteDropdownOptionsFromFile${langId ? '/' + langId : ''}`);
};

export const getOptionsFromFileCount = async ({ datasetId, dimensionId, type, langId }) => {
    const { data } = await api.get(`vue/survey/${datasetId}/${dimensionId}/${type}/optionsCount${langId ? '/' + langId : ''}`);

    return data;
};
