export default {
    getStatsData(state) {
        let statsData = [...state.statsData].sort((a,b) => {
            if (state.sort.by === 'name') {
                if (a.name.toLowerCase() > b.name.toLowerCase()) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                    return state.sort.direction === 'asc' ? -1: 1;
                }
                return 0;
            }
            else if (state.sort.by === 'access') {
                if (a.access_level > b.access_level) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                } else if (a.access_level < b.access_level) {
                    return state.sort.direction === 'asc' ? -1: 1;
                }
                return 0;
            }
            else if (state.sort.by === 'manager') {
                if (!a.account_manager && !b.account_manager) {
                    return 0;
                }
                else if (!a.account_manager) {
                    return state.sort.direction === 'asc' ? -1 : 1;
                }
                else if (!b.account_manager) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                }
                else if (a.account_manager.full_name > b.account_manager.full_name) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                } else if ((a.account_manager.full_name < b.account_manager.full_name)) {
                    return state.sort.direction === 'asc' ? -1 : 1;
                }
                return 0;
            }
            else if (state.sort.by === 'date') {
                if (a.created_at > b.created_at) {
                    return state.sort.direction === 'asc' ? 1 : -1;
                } else if (a.created_at < b.created_at) {
                    return state.sort.direction === 'asc' ? -1: 1;
                }
                return 0;
            }
        });

        if (state.query) {
            statsData = statsData.filter((stat) => stat.name.toLowerCase().includes(state.query.toLowerCase()));
        }

        if (state.filters.accessLevel) {
            statsData = statsData.filter((stat) => stat.access_level && stat.access_level == state.filters.accessLevel);
        }

        if (state.filters.managerId) {
            statsData = statsData.filter((stat) => stat.account_manager && stat.account_manager.id == state.filters.managerId);
        }

        if (state.filters.date && state.filters.start && state.filters.end) {
            statsData = statsData.filter(stat => stat.created_at.split('T')[0] >= state.filters.start && stat.created_at.split('T')[0] <= state.filters.end);
        }

        return statsData;
    },
};
