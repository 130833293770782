import { RouteConfig } from 'vue-router';
const CreatePdfExtractorPage = () => import('../pages/CreatePdfExtractorPage.vue');
const ReanalyzePdfPage = () => import('../pages/ReanalyzePdfPage.vue');
const AppendPdfPage = () => import('../pages/AppendPdfPage.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/',
    },
    {
        path: 'create',
        component: CreatePdfExtractorPage,
    },
    {
        path: ':id/reanalyze',
        component: ReanalyzePdfPage,
        props: true,
    },
    {
        path: ':id/append',
        component: AppendPdfPage,
        props: true,
    },
];

export default routes;
