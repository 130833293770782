export default {
    namespaced: true,
    state: () => ({
        table: null,
        linkData: [],
    }),
    mutations: {
        setTable: (state, data) => state.table = data,
        setSheets: (state, sheets) => {
            state.table = {
                ...state.table,
                sheets,
            };
        },
        setLinkData: (state, data) => state.linkData = data,
        reset: (state) => {
            state.table = null;
            state.links = [];
        },
    }
};
