import { api } from '@/api/index.js';
import { Filter } from '@/dashboard/common/types/filterInterface';
import { saveAs } from 'file-saver';
import contentDisposition from 'content-disposition';
import {Dataset} from '@/domain/dataset/DatasetInterface';
import { ScheduledExport } from '@/exports/common/types/scheduledExportInterface';

export const getGenericExportsByDatasetId = async (datasetId: string) => {
    const { data } = await api.get('exports?dataset_id=' + datasetId);
    return data;
};
export const startExportGeneration = async (type: string, dataset_id: string, dimension_id: null | string, filters: null | Filter[]) => {
    const { data } = await api.post('exports', {
        type, dataset_id, dimension_id, filters
    });
    return data;
};
export const getDataset = async (id): Promise<Dataset> => {
    const { data } = await api.get('/datasets/' + id);
    return data;
};
export const downloadExportById = async (id) => {
    const { data, headers } = await api.get('exports/download/' + id, {
        responseType: 'blob',
    });
    const { parameters } = contentDisposition.parse(headers['content-disposition']);
    saveAs(data, parameters.filename);
};
export const deleteExportById = async (id) => {
    const { data } = await api.delete('exports/' + id);
    return data;
};
export const getScheduledExports = async (id) => {
    const { data } = await api.get('scheduled-exports/dataset/' + id);
    return data;
};
export const saveScheduledExport = async (scheduledExport: ScheduledExport) => {
    if (scheduledExport.id) {
        const { data } = await api.put('scheduled-exports/' + scheduledExport.id, scheduledExport);
        return data;
    } else {
        const { data } = await api.post('scheduled-exports', scheduledExport);
        return data;
    }
};
export const deleteScheduledExport = async (id) => {
    const { data } = await api.delete('scheduled-exports/' + id);
    return data;
};

export const setActivity = async (id) => {
    await api.put('/scheduled-exports/' + id + '/set-activity');
};
