var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',_vm._l((_vm.columnNames),function(columnName,index){return _c('th',{key:index,staticClass:"align-middle border-b p-2 truncate text-left",class:{
            'bg-primary-300': _vm.isPrimaryColumn(index),
            'border-primary-700': _vm.isPrimaryColumn(index) === 2,
            'border-neutral-600': _vm.isPrimaryColumn(index) !== 2,
            'hidden': _vm.emptyColumns.includes(index),
        }},[_c('label',{staticClass:"block mb-1",class:{
                'text-small font-bold opacity-0': index !== 0 && _vm.columnType !== 'parameter',
                'text-sm font-normal': _vm.columnType === 'parameter'
            }},[_vm._v(" "+_vm._s(_vm.columnType === 'parameter' ? _vm.$t('SURVEYFORMS.CONDITION_URL_PARAM', 'URL Parameter') : _vm.$t('ANALYSIS.COLUMN_NAMES'))+" ")]),(!_vm.columnsWithSelectableName || !_vm.columnsWithSelectableName.includes(index))?_c('z-input',{attrs:{"type":"text","value":columnName,"data-test-id":'column-name-input-' + index},on:{"blur":function($event){return _vm.changeColumnName({index: index, newName: $event.target.value})}}}):(_vm.filteredNameOptions.length > 0)?_c('z-select',{attrs:{"value":columnName},on:{"change":function($event){return _vm.changeColumnName({index: index, newName: $event})}}},_vm._l((_vm.filteredNameOptions),function(option,optionIndex){return _c('option',{key:optionIndex,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])}),0):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }