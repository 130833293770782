var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"selected-labels"},[(_vm.selectedLabels.length !== 0)?_c('h1',{staticClass:"my-1 text-sm"},[_vm._v(" "+_vm._s(_vm.$t('KEYWORD.SELECTED_CUSTOM_LABELS', 'Selected Custom Labels'))+" ("+_vm._s(_vm.selectedLabels.length)+") ")]):_vm._e(),_vm._l((_vm.selectedLabels),function(label){return _c('v-popover',{key:label.id,staticClass:"inline",attrs:{"trigger":"hover","placement":"top","disabled":label.tags.length === 0}},[(!_vm.withOpinionIndex)?_c('z-tag',{staticClass:"mb-1",attrs:{"deletable":"","background-color":(_vm.checkLanguageIsOk(label) || _vm.noLanguageCheck) ? null : '#F06060'},on:{"click":function($event){return _vm.handleClick(label.id)},"remove":function($event){return _vm.handleRemove(label.id)}}},[_vm._v(" "+_vm._s(label.name + (label.category ? ` (${label.category})` : ''))+" ")]):_c('TextUnitLabel',{attrs:{"entity":{
                id: label.id,
                name: label.name,
                type: 'custom-label',
                opinionIndex: label.opinionIndex,
                engName: label.name,
                mentionNumber: 0,
                subtype: label.subtype
            },"no-drilldown":"","not-solid-icon":"","always-show-buttons":"","no-edit-popup":_vm.noEditPopup},on:{"edit":function($event){return _vm.handleClick(label.id)},"changeOI":function($event){return _vm.$emit('change-oi', { id: $event.id, opinionIndex: $event.value })},"removeLabel":function($event){return _vm.handleRemove(label.id)}}}),_c('template',{slot:"popover"},[_c('fa-icon',{attrs:{"icon":['fal', 'hashtag']}}),_vm._v(" "+_vm._s(label.tags.join(', '))+" ")],1)],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }