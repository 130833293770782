import dimensionTypeEnum from '@/domain/dimensionTypeEnum';
import useCustomLabelStore from '@/custom-labels/store';

const { getters } = useCustomLabelStore();

export default {
    connected: (state) => !!state.domoDataset.name,
    customLabelsNotOkay: (state) => {
        return state.columns.some(col =>
            col.type === dimensionTypeEnum.VERBATIM)
            && state.customLabelIds.some(col => !getters.checkPhraseSets(col.customLabelIds, state.languageId).value);
    },
    createIsDisabled: (state, getters) => (
        state.frequency === ''
        || state.incrementColumnName === ''
        || getters.customLabelsNotOkay
        || !getters.connected
    ),
    editIsDisabled: (state, getters) => (
        !state.authChanged
        && !state.dataStructureChanged
        && !state.schedulingChanged
        && !getters.customLabelsChanged
        && !state.analysisSettingsChanged
    ),
    customLabelsChanged: (state) => {
        const allCustomLabelIds = [...new Set<number>(state.customLabelIds.value?.reduce((ids, col) => ids.concat(col.customLabelIds), [])) || []];
        const updatedCls = getters.getUpdatedClsSinceLastAnalysis(allCustomLabelIds, state.domoDataset.updatedAt).value;

        return updatedCls.length > 0 || state.selectedCustomLabelsChanged;
    },
};
