module.exports =  {
    async buildAuthorizeUrl() {
        throw new Error('Not implemented!');
    },
    async loginWithPopup() {
        throw new Error('Not implemented!');
    },
    async getUser() {
        return {
            ['https://zurvey.io/userId']: 1,
            name: 'superadmin@cypress.io',
            given_name: 'Cypress',
            family_name: 'Superadmin',
            picture: 'https://s.gravatar.com/avatar/c3e972cc18fc3ad9fa6e5a1185188cc0?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsu.png',
            email: 'superadmin@cypress.io',
            sub: 'auth0|userid',
            ['https://zurvey.io/isActive']: true,
            ['https://zurvey.io/perm']: 1,
            ['https://zurvey.io/langCode']: 'en',
            ['https://zurvey.io/groupId']: 1,
            ['https://zurvey.io/groupName']: 'Neticle Technologies',
            ['https://zurvey.io/profilePictureOriginal']: null,
            ['https://zurvey.io/emailVerified']: true,
            ['https://zurvey.io/additionalInfo']: true,
            ['https://zurvey.io/announcementsLastSeen']: null,
            ['https://zurvey.io/mfaId']: null,
            ['https://zurvey.io/mfaPhoneNumber']: null,
            ['https://zurvey.io/groupAccessLevel']: 2, // developer
            ['https://zurvey.io/canCreateDatasets']: true,
            ['https://zurvey.io/canManageCustomLabels']: true,
            ['https://zurvey.io/isAdmin']: true,
            ['https://zurvey.io/isSuperAdmin']: true,
        };
    },
    async getIdTokenClaims() {
        throw new Error('Not implemented!');
    },
    async loginWithRedirect() {
        // NOOP
    },
    async handleRedirectCallback() {
        // NOOP
    },
    async checkSession() {
        throw new Error('Not implemented!');
    },
    async getTokenSilently() {
        return 'invalidtoken';
    },
    async getTokenWithPopup() {
        throw new Error('Not implemented!');
    },
    async isAuthenticated() {
        return true;
    },
    logout() {
        // NOOP
    },
};
