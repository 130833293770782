export default (api) => {
    return {
        async getDataSourceForDashboard(dataSourceId) {
            const { data } = await api.get(`/portal/dashboard/${dataSourceId}`);
            return data;
        },
        async getMultiValueCategories(dataSourceId) {
            const { data } = await api.get(`/portal/dashboard/multiValueCategories/${dataSourceId}`);
            return data;
        },
        async getSimpleCategories(dataSourceId) {
            const { data } = await api.get(`/portal/dashboard/simpleCategories/${dataSourceId}`);
            return data;
        },
        async getAllMatrixChartData(dataSourceId) {
            const { data } = await api.get(`/portal/dashboard/allMatrixChartData/${dataSourceId}`);
            return data;
        },
        async getSemanticOverview(dataSourceId) {
            const { data } = await api.get(`/portal/dashboard/semanticOverview/${dataSourceId}`);
            return data;
        },
        async getCategorySemanticStats(postData) {
            const { data } = await api.post('/portal/dashboard/categorySemanticStats', postData);
            return data;
        },
        async getSemanticTimeSeries(postData) {
            const { data } = await api.post('/portal/dashboard/semanticTimeSeries', postData);
            return data;
        },
        async drillDown(postData) {
            const { data } = await api.post('/portal/dashboard/drilldownposts', postData);
            return data;
        },
        async translateTextUnit({ textToTranslate, sourceLanguageId, recordLanguageNickname, recordId, dimensionId, datasetId }) {
            const { data } = await api.post('/portal/dashboard/getTranslatedTextUnit', { textToTranslate, sourceLanguageId, recordLanguageNickname, recordId, dimensionId, datasetId });
            return data;
        },
        async changeScore({ id, templateColumnId, newValue }) {
            const { data } = await api.post('/portal/dashboard/changeScore', { id, templateColumnId, newValue });
            return data;
        },
        async changeLabelOi({ templateColumnId, recordId, id, type, newValue }) {
            const { data } = await api.post('/portal/dashboard/changeLabelOrCLScore', { templateColumnId, recordId, id, type, newValue });
            return data;
        },
        async removeLabel({ templateColumnId, recordId, id, type }) {
            const { data } = await api.post('/portal/dashboard/removeLabelOrCL', { templateColumnId, recordId, id, type });
            return data;
        },
        async addCustomLabels({ datasetId, dimensionId, customLabels, records }) {
            await api.post(`/portal/dashboard/${datasetId}/${dimensionId}/addCustomLabels`, { customLabels, records });
        },
        async deleteRecords({ datasetId, records }) {
            await api.post(`/portal/dashboard/${datasetId}/deleteRecords`, { records });
        },
        async getCustomLabelDetails({ recordId, dimensionId, datasetId }) {
            const { data } = await api.get(`/portal/dashboard/getCustomLabelDetails/${datasetId}/${dimensionId}/${recordId}`);
            return data;
        },
    };
};
