import { storageFactory } from 'storage-factory';

let storage = undefined;

try {
    storage = storageFactory(() => window.localStorage);
} catch (e) {
    storage = storageFactory(() => {});
}

export default storage;
