export default {
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    setStatsData(state, statsData) {
        state.statsData = statsData;
    },
    updateQuery(state, query) {
        state.query = query;
    },
    updateSort(state, sort) {
        state.sort = sort;
    },
    updateAccessLevelFilter(state, level) {
        state.filters.accessLevel = level;
    },
    updateManagerFilter(state, id) {
        state.filters.managerId = id;
    },
    updateDateFilter(state, value) {
        state.filters.date = value;

        if (value) {
            let start, end;
            if (value === 'last7days') {
                end = new Date();
                start = new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000);
            }
            if (value === 'lastmonth') {
                end = new Date();
                start = new Date(new Date().setDate(1));
            }
            if (value === 'prevmonth') {
                const now = new Date();
                start = new Date(now.setMonth(now.getMonth()-1, 1));
                end = new Date(new Date().setDate(0));
            }

            if (value !== 'setdate') {
                state.filters.end = `${end.getFullYear()}-${end.getMonth() < 10 ? '0' : ''}${end.getMonth()+1}-${end.getDate() < 10 ? '0' : ''}${end.getDate()}`;
                state.filters.start = `${start.getFullYear()}-${start.getMonth() < 10 ? '0' : ''}${start.getMonth()+1}-${start.getDate() < 10 ? '0' : ''}${start.getDate()}`;
            } else {
                state.filters.end = '';
                state.filters.start = '';
            }
        }
    },
    updateStartDate(state, date) {
        state.filters.start = date;
    },
    updateEndDate(state, date) {
        state.filters.end = date;
    },
};
