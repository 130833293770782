import api from '@/api';
import gtm from '@/utils/gtm';
import isEmpty from 'lodash/isEmpty';
import {getExcludedLabels} from '../../../../dashboard/api/datasetApi';

export default {
    async loadTeam({ commit, dispatch }) {
        commit('setLoading', true);
        await dispatch('getBrandingSettings');
        await dispatch('getOrgLevelExcludedEntities');
        const response = await api.getOwnGroupData();
        try {
            commit('updateId', response.id);
            commit('updateName', response.name);
            commit('updateLanguageId', response.language_id);
            commit('updateUILanguageId', response.ui_language_id);
            commit('updateSensitiveDataSettings', response.sensitive_data_settings);
            commit('updateAvatar', response.avatar_image);
            commit('updateAuth0Connection', response.auth0_connection_id);
            commit('updateNMIToken', response.nmi_token);
            commit('updateCustomToken', response.custom_token);
        } finally {
            commit('setLoading', false);
        }
    },
    async saveGroupSettings({ getters, dispatch, state }) {
        try {
            await api.editGroupData(getters.groupData, state.id);
            dispatch('refresh');
        } catch (error) {
            if (error.response.status === 422) {
                throw new Error(Object.values(error.response.data.errors)[0][0]);
            }
        }
    },
    async saveGroupImage({ state, dispatch}) {
        const originalImageSource = state.imgSource;
        const croppedImageSource = state.imgCropped;

        let response;
        if (originalImageSource === null || croppedImageSource === null) {
            response = await api.deleteGroupAvatar();
        } else {
            response = await api.editGroupAvatar(originalImageSource, croppedImageSource);
        }
        if (response.status === 'success') {
            dispatch('refresh');
        } else {
            throw new Error(response.data.message);
        }
    },
    async refresh() {
        location.reload();
    },
    async getBrandingSettings({commit}){
        const responseSurveyBrandingSettings = await api.getBrandSettings();
        if(isEmpty(responseSurveyBrandingSettings) === false){
            commit('setSurveyBrandingSettings', {
                primary_color: responseSurveyBrandingSettings.primary_color,
                text_color: responseSurveyBrandingSettings.text_color,
                background_color: responseSurveyBrandingSettings.background_color,
                title_font_name: responseSurveyBrandingSettings.title_font_name,
                title_font_weight: responseSurveyBrandingSettings.title_font_weight,
                title_font_size: responseSurveyBrandingSettings.title_font_size,
                question_font_name: responseSurveyBrandingSettings.question_font_name,
                question_font_weight: responseSurveyBrandingSettings.question_font_weight,
                question_font_size: responseSurveyBrandingSettings.question_font_size,
                answer_font_name: responseSurveyBrandingSettings.answer_font_name,
                answer_font_weight: responseSurveyBrandingSettings.answer_font_weight,
                answer_font_size: responseSurveyBrandingSettings.answer_font_size,
                layout_spacing: responseSurveyBrandingSettings.layout_spacing,
                privacy_policy_title: responseSurveyBrandingSettings.privacy_policy_title,
                privacy_policy_url: responseSurveyBrandingSettings.privacy_policy_url
            });
            commit('setBrandLogo', responseSurveyBrandingSettings.logo_url);
            commit('setBrandColor', responseSurveyBrandingSettings.zurvey_primary_color);
            commit('setBrandEmailSalutation', responseSurveyBrandingSettings.email_salutation);
        }
    },
    async saveSurveyBrandingSettings({dispatch, getters}){
        const response = await api.saveBrandedSurveySettings(getters.surveyBrandingSettings);
        if (response !== 'success') {
            throw new Error('Something went wrong, please try again');
        }
        gtm.track(gtm.events.GROUP_SURVEY_STYLE_UPDATED, gtm.categories.GROUP_SURVEY_STYLE_UPDATED);
        dispatch('refresh');
    },
    async saveBrandLogo({ commit }, file) {
        const url = await api.saveBrandLogo(file);
        commit('setBrandLogo', url);
    },
    async deleteBrandLogo({ dispatch }) {
        const response = await api.deleteBrandLogo();
        if (response !== 'success') {
            throw new Error('Something went wrong, please try again');
        }
        dispatch('refresh');
    },
    async updateBrandingSettings({ dispatch, state }) {
        const response = await api.saveBrandingSettings(state.brand_logo, state.brand_color, state.brand_email_from, state.brand_email_salutation);
        if (response !== 'success') {
            throw new Error('Something went wrong, please try again');
        }
        dispatch('refresh');
    },
    async getOrgLevelExcludedEntities({commit}) {
        try {
            const response = await getExcludedLabels();
            commit('setOrgLevelExcludedEntities', response);
        } catch (e) {
            Sentry.captureException(e);
        }
    },
};
