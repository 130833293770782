<template>
    <div class="custom-label-editor">
        <div
            v-if="showWarning && !noLanguageCheck"
            class="text-small my-3 text-red-500"
        >
            <z-icon icon="exclamation-triangle" /> {{ $t('CUSTOM_LABEL_EDITOR.LANGUAGE_WARNING', 'Some Custom Labels do not include the Analysis language as its Language. Click on the Custom Label to edit, or remove it to proceed.') }}
        </div>
        <selected-labels
            :selected-labels="selectedLabels"
            :language-id="languageId"
            :no-language-check="noLanguageCheck"
            :with-opinion-index="withOpinionIndex"
            :no-edit-popup="noEditPopup"
            @change-oi="changeOI"
            @remove-label="removeLabel"
        />
        <available-labels
            :available-labels="availableLabels"
            :selected-labels="selectedLabels"
            :filter="filter"
            @add-label="addLabel"
            @update-filter="handleUpdateFilter"
        />
        <div class="custom-label-editor-actions flex justify-center mt-2">
            <z-button
                v-if="canManageCustomLabels"
                class="text-sm"
                data-test-id="cl-editor-button"
                @click="open"
            >
                {{ $t('GLOBAL.NEW_KEYWORD', 'Create new Custom Label') }}
            </z-button>
            <z-button
                v-if="availableLabels.length > 0 && !manualCustomLabelCreate"
                variant="secondary"
                class="bg-transparent text-sm"
                data-test-id="cl-editor-button"
                @click="addAllCustomLabels"
            >
                {{ filter.value ? $t('KEYWORD.ADD_FILTERED', 'Add filtered custom labels') : $t('KEYWORD.ADD_ALL', 'Add all custom labels') }}
            </z-button>
            <z-button
                v-if="selectedLabels.length > 0 && !noRemoveAll"
                variant="secondary"
                class="bg-transparent text-sm"
                data-test-id="cl-editor-button"
                @click="removeAllCustomLabels"
            >
                {{ $t('KEYWORD.REMOVE_ALL', 'Remove all') }}
            </z-button>
        </div>
        <popup-container
            :manual-custom-label-create="manualCustomLabelCreate"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive } from 'vue';

import SelectedLabels from './SelectedLabels.vue';
import AvailableLabels from './AvailableLabels.vue';

import CustomLabelPopupContainer from '@/custom-labels/components/CustomLabelPopup/CustomLabelPopupContainer.vue';
import ZIcon from '@/components/ui/Icon.vue';

import useCustomLabelStore from '@/custom-labels/store/';
import useUserStore from '@/user/store';


export default defineComponent({
    components: {
        SelectedLabels,
        AvailableLabels,
        'popup-container': CustomLabelPopupContainer,
        ZIcon,
    },
    model: {
        prop: 'selectedLabelIds',
        event: 'update:selected-label-ids'
    },
    props: {
        selectedLabelIds: { type: Array as () => Array<number|{ id: number, opinionIndex: number }>, required: true },
        selectableLabelIds: { type: Array as () => Array<number>, default: null },
        languageId: { type: Number, required: true },
        noLanguageCheck: { type: Boolean, default: false },
        withOpinionIndex: { type: Boolean, default: false },
        manualCustomLabelCreate: { type: Boolean, default: false },
        noRemoveAll: { type: Boolean, default: false },
        noEditPopup: { type: Boolean, default: true },
    },
    emits: ['update:selected-label-ids', 'new-cl-created'],
    setup(props, context) {
        const filter = reactive<{ value: string | null, type: 'tag' | 'category' | 'creator' | null }>({
            value: null,
            type: null
        });

        const { state, actions, getters } = useCustomLabelStore();
        const { canManageCustomLabels } = useUserStore().getters;

        const selectableLabels = computed(() => state.customLabels.filter(label => props.selectableLabelIds ? props.selectableLabelIds.includes(label.id) : true));

        const selectedLabels = computed(() => props.selectedLabelIds
            .map(cl => {
                if (typeof cl === 'number') return { id: cl, opinionIndex: 0 };

                return cl;
            })
            .filter(cl => Boolean(selectableLabels.value.find(label => label.id === cl.id)))
            .map(cl => ({
                ...selectableLabels.value.find(label => label.id === cl.id)!,
                subtype: 'manual_custom_label',
                opinionIndex: cl.opinionIndex
            }))
        );

        const showWarning = computed<boolean>(() => {
            return !getters.checkPhraseSets(selectedLabels.value.map(cl => cl.id), props.languageId).value;
        });

        function addLabel(id: number) {
            const cl = props.withOpinionIndex ? { id, opinionIndex: 0 } : id;
            context.emit('update:selected-label-ids', props.selectedLabelIds.concat(cl));
        }

        function removeLabel(id: number) {
            context.emit('update:selected-label-ids', props.selectedLabelIds.filter(cl => typeof cl === 'number' ? cl !== id : cl.id !== id));
        }

        function addAllCustomLabels() {
            if (filter.value) {
                context.emit('update:selected-label-ids', props.selectedLabelIds.concat(availableLabels.value.map(label => props.withOpinionIndex ? { id: label.id, opinionIndex: 0 } : label.id)));
            } else {
                context.emit('update:selected-label-ids', selectableLabels.value.map(label => props.withOpinionIndex ? { id: label.id, opinionIndex: 0 } : label.id));
            }
        }

        function removeAllCustomLabels() {
            context.emit('update:selected-label-ids', []);
        }

        function handleUpdateFilter({ value, type }) {
            filter.value = value;
            filter.type = type;
        }

        const filteredLabels = computed(() => {
            return selectableLabels.value.filter(cl => {
                if (filter.value && filter.type === 'tag') {
                    return cl.tags.includes(filter.value);
                } else if (filter.value && filter.type === 'category') {
                    return cl.category === filter.value;
                } else if (filter.value && filter.type === 'creator') {
                    //@ts-ignore
                    return cl.creator && cl.creator.full_name ? cl.creator.full_name === filter.value : cl.creator === filter.value;
                }
                else return true;
            });
        });

        const availableLabels = computed(() => {
            return filteredLabels.value.filter((label) => {
                return !selectedLabels.value.find((selectedLabel) => selectedLabel.id === label.id);
            });
        });

        const { createCustomLabel } = actions;

        async function open() {
            const cl = await createCustomLabel();

            context.emit('new-cl-created', cl);
        }

        const changeOI = ({ id, opinionIndex }) => {
            if (!props.withOpinionIndex) return;

            const updatedCustomLabels = props.selectedLabelIds.map((cl: any) => {
                if (cl.id !== id) {
                    return cl;
                }

                return { id, opinionIndex };
            });

            context.emit('update:selected-label-ids', updatedCustomLabels);
        };

        return {
            state,
            removeLabel,
            addLabel,
            selectedLabels,
            addAllCustomLabels,
            removeAllCustomLabels,
            handleUpdateFilter,
            filter,
            availableLabels,
            showWarning,
            canManageCustomLabels,
            open,
            changeOI,
        };
    }
});
</script>
