import { reactive } from 'vue';

import api from '@/api';
import gtm from '@/utils/gtm';
import dimensionTypeEnum from '@/domain/dimensionTypeEnum';
import excelParser from '@/domain/excel/excelParser';
import BusinessError from '@/domain/errors/BusinessError';
import i18next from 'i18next';

import { setLoading } from '@/utils/loading';
import { Dataset, DimensionDefinition } from '@/datasets/store/types';

interface ExcelAnalysisState {
    uploadedExcel: any,
    reanalysisSettings: any,
    appendedDataSourceId: any,
    hasNewDictionary: boolean,
}

export enum CsvDelimiterEnum {
    COMMA = 'comma',
    SEMICOLON = 'semicolon',
    TABULATOR = 'tabulator',
}

export const getCsvDelimiterCharacter = (delimiter: CsvDelimiterEnum) => {
    switch (delimiter) {
    case CsvDelimiterEnum.COMMA:
        return ',';
    case CsvDelimiterEnum.TABULATOR:
        return '\t';
    default:
        return ';';
    }
};

const state = reactive<ExcelAnalysisState>({
    uploadedExcel: null,
    reanalysisSettings: null,
    appendedDataSourceId: null,
    hasNewDictionary: false,
});

const getters = {};

const actions = {
    uploadExcel: async (file, limit: number|null = null) => {
        const response = await excelParser({ file, guessDimensionTypes: true, limit });
        if (!response.activeSheet || !response.sheets || !Object.keys(response.sheets).length) {
            Sentry.addBreadcrumb({
                message: 'UploadExcel excelParser failed',
                data: {
                    response,
                    file,
                }
            });
            throw new BusinessError(i18next.t('ANALYSIS.EMPTY_FILE_ERROR', 'Uploaded file must have at least one not empty sheet.'));
        }
        state.uploadedExcel = {
            name: response.name,
            sheets: response.sheets,
            activeSheet: response.activeSheet,
            file,
        };
        gtm.track(gtm.events.EXCEL_ANALYSIS_INIT, gtm.categories.EXCEL_ANALYSIS_INIT);
        return response;
    },
    reparseCsv: async (delimiter, file) => {
        const response = await excelParser({ file: file, guessDimensionTypes: true, delimiter: delimiter });
        if (!response.activeSheet || !response.sheets || !Object.keys(response.sheets).length) {
            Sentry.addBreadcrumb({
                message: 'Reparse CSV excelParser failed',
                data: {
                    response,
                    file,
                }
            });
            throw new BusinessError(i18next.t('ANALYSIS.EMPTY_FILE_ERROR', 'Reparsed file must have at least one not empty sheet.'));
        }
        state.uploadedExcel = {
            name: response.name,
            sheets: response.sheets,
            activeSheet: response.activeSheet,
            file,
        };
        return response;
    },
    resetUploadExcel: () => state.uploadedExcel = null,
    reanalyze: async (dataset: Dataset) => {
        Sentry.addBreadcrumb({
            message: 'Reanalyze excel',
            dataset
        });
        setLoading(true);
        let sampleData;
        try {
            sampleData = await api.getSampleColumns(dataset.legacy_id);
        } catch (error) {
            Sentry.addBreadcrumb({
                message: 'Reanalyze getSampleColumns failed',
                dataset,
                error,
            });
        }
        const verbatimTemplateColumn = dataset.dimension_definitions.find(d => d.type === dimensionTypeEnum.VERBATIM) as DimensionDefinition;
        const analysisSettings = {
            id: dataset.legacy_id,
            datasetId: dataset.id,
            name: dataset.name,
            dimensions: sampleData.templateColumns,
            sheets: { 'sheet': {
                rows: dataset.rows,
                headerRows: dataset.header,
                columnNames: sampleData.templateColumns?.map(column => column.name) || [],
                columnTypes: sampleData.templateColumns?.map(column => column.type) || [],
                columnData: sampleData.columnData?.map(column => column.map(cell => cell.value)) || [],
                languageId: dataset.language_id,
            } },
            accent: !!dataset.accent,
            lowercase: !!dataset.lowercase,
            topicRecognition: !!dataset.topic_recognition,
            autoTranslation: !!dataset.auto_translation,
            autoTranslationWithHtml: dataset.auto_translation_with_html,
            inputTranslation: dataset.inputTranslation,
            textSplitting: dataset.text_splitting,
            lastAnalysisAt: dataset.last_analysis_at,
            keywords: verbatimTemplateColumn!.customLabels || []
        };

        state.reanalysisSettings = analysisSettings;
        Sentry.addBreadcrumb({
            message: 'Reanalyze settings',
            analysisSettings
        });

        state.hasNewDictionary = dataset.hasNewDictionary;

        setLoading(false);
        gtm.track(gtm.events.EXCEL_REANALYSIS_INIT, gtm.categories.EXCEL_REANALYSIS_INIT);
    },
    resetReanalysisSettings: () => state.reanalysisSettings = null,
    append: (dataSourceId) => {
        Sentry.addBreadcrumb({
            message: 'Append excel',
            dataSourceId
        });
        state.appendedDataSourceId = dataSourceId;
        gtm.track(gtm.events.EXCEL_APPEND_INIT, gtm.categories.EXCEL_APPEND_INIT);
    },
    resetAppendedDataSourceId: () => state.appendedDataSourceId = null,
    resetHasNewDictionary: () => state.hasNewDictionary = false,
};

export default function useExcelAnalysisStore() {
    return {
        state,
        getters,
        actions,
    };
}
