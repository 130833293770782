<template>
    <tr>
        <td
            v-for="(cell, columnNumber) in row"
            :key="columnNumber"
            :class="isDisabled ? 'bg-neutral-700 cursor-not-allowed' : 'separator'"
            class="bg-primary-700 overflow-visible z-10 cursor-move relative p-0 h-1"
            :data-test-id="'cell-' + rowNumber + '-' + columnNumber"
            colspan="3"
        >
            <span
                v-if="columnNumber === 0"
                class="block text-bg bg-primary-700 absolute text-small leading-normal align-middle font-bold float-right left-0 rounded"
                style="top: -10px; padding: 2px 8px 2px 2px;"
                :class="{'hidden': columnNumber !== 0, 'block': columnNumber === 0, 'bg-neutral-700 cursor-not-allowed': isDisabled}"
            >
                <font-awesome-icon
                    :icon="['fal', 'sort']"
                    class="fa-icons"
                />
                {{ $t('ANALYSIS.DRAG_HEADER') }}
            </span>
            <span
                v-else
                class="hidden"
            >
                {{ $t('ANALYSIS.DRAG_HEADER') }}
            </span>
        </td>
    </tr>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
export default {
    components: {FontAwesomeIcon},
    props: {
        row: { type: Array, default: () => ([]) },
        rowNumber: { type: Number, default: 0 },
        isDisabled: { type: Boolean, default: false }
    },
};
</script>
