export enum FilterTypeEnum {
    text = 'text',
    polarity = 'polarity',
    label = 'label',
    labelPolarity = 'labelPolarity',
    relation = 'relation',
    customLabel = 'customLabel',
    customLabelCategory = 'customLabelCategory',
    dimension = 'dimension',
    parameter = 'parameter',
    metadata = 'metadata',
    relativeDate = 'relativeDate',
    npsScore = 'npsScore',
    npsSegment = 'npsSegment',

    ces5Score = 'ces5Score',
    ces5Segment = 'ces5Segment',

    ces7Score = 'ces7Score',
    ces7Segment = 'ces7Segment',
    phrase = 'phrase',
    csatScore = 'csatScore',
    csatSegment = 'csatSegment',
    language = 'language',
    url = 'url',
    originalLanguage = 'originalLanguage',
}

export enum FilterSubTypeEnum {
    nmiSocialMedia = 'nmiSocialMedia',
    date = 'date'
}

export enum NMISourceTypeEnum {
    socialMedia = '3'
}

export interface RelativeDate {
    action: 'last' | 'prev',
    amount: number,
    interval: 'day' | 'week' | 'month' | 'year'
}

export enum OperatorTypeEnum {
    eq = 'eq',
    neq = 'neq',
    gt = 'gt',
    gte = 'gte',
    lt = 'lt',
    lte = 'lte',
    between = 'between',
    allOf = 'allOf',
    oneOf = 'oneOf',
    noneOf = 'noneOf',
    contains = 'contains',
    startsWith = 'startsWith',
}

export interface Filter {
    dimensionId: string,
    subDimensionId?: number|string,
    isMultiSelect: boolean,
    type: FilterTypeEnum,
    subType?: FilterSubTypeEnum,
    operator: OperatorTypeEnum,
    value: null|number|string|any[]|RelativeDate
}

export interface FilterSet {
    id: string,
    name: string,
    updated_at: number,
    filters: Filter[]
}
