import {api} from '@/api/index.js';
import {Filter} from '@/dashboard/common/types/filterInterface';

export const getAggregation = async (datasetId: string, dimensions: any[], measures: any[], filters: Filter[] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations`, { dimensions, measures, filters });
    return data;
};
export const getDimensionDistribution = async (datasetId: string, dimensionId1: string, dimensionId2: string, filters: Filter[] = []) => {
    const { data } = await api.post(`records/${datasetId}/aggregations/twoAxesDistribution/${dimensionId1}/${dimensionId2}`, { filters });
    return data;
};
