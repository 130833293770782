export default {
    setLoading(state, isLoading) {
        state.loading = isLoading;
    },
    setActivities(state, activities) {
        state.activities = activities;
    },
    setNextUrl(state, url) {
        state.nextUrl = url;
    },
    setIsFinished(state, isFinished) {
        state.isFinished = isFinished;
    },
};