import { RouteConfig } from 'vue-router';
const AudienceSummary = () => import('../components/AudienceSummary.vue');
const CreateAudienceFromFile = () => import('../components/CreateAudienceFromFile.vue');
const AudienceEditor = () => import('../components/AudienceEditor.vue');
const AppendAudienceFromFile = () => import('../components/AppendAudienceFromFile.vue');
const CreateAudienceFromApi = () => import('../pages/CreateAudienceFromApi.vue');

const SegmentList = () => import('../components/Segments/SegmentList.vue');
const SegmentMembersList = () => import('../components/Segments/SegmentMembersList.vue');

const routes: RouteConfig[] = [
    {
        path: 'setup',
        component: AudienceSummary,
        name: 'audience-setup',
    },
    {
        path: 'append',
        component: AppendAudienceFromFile,
        name: 'audience-file-append',
    },
    {
        path: 'upload',
        component: CreateAudienceFromFile,
        name: 'audience-file-upload',
    },
    {
        path: 'create-from-api',
        component: CreateAudienceFromApi,
        name: 'audience-from-api',
    },
    // dynamic paths should be after static ones
    {
        path: ':audienceId',
        redirect: ':audienceId/members',
    },
    {
        path: ':audienceId/members',
        component: AudienceSummary,
        name: 'audience-summary',
        props: true
    },
    {
        path: ':audienceId/edit',
        component: AudienceEditor,
        name: 'audience-editor',
    },
    {
        path: ':audienceId/segments',
        component: SegmentList,
        name: 'audience-segments',
        props: true,
    },
    {
        path: ':audienceId/segments/:segmentId',
        component: SegmentMembersList,
        name: 'audience-segment-members',
        props: true,
    },
];

export default routes;
