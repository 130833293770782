export default {
    id: '',
    lastAnalysisAt: '',
    name: '',
    activeSheet: '',
    sheets: {},
    analysisSettings: {
        languageId: 1,
        textSplitting: 1,
        accent: true,
        lowercase: true,
        topicRecognition: true,
    },
    selectedCustomLabelIds: [],
    originalCustomLabelIds: [],
    file: null,
    columns: [],
    originalUploadedColumns: [],
    configs: {},
};
