import dayjs from 'dayjs';

export default {
    setState: (state, { prop, val }) => {
        state[prop] = val;
    },
    setDomoDataset: (state, dataset) => {
        state.domoDataset = dataset;
    },
    reset: (state) => {
        state.id = '';
        state.datasetId = '';
        state.createdAt = null;
        state.invalidDomoStructure = false;
        state.dataStructureChanged = false;
        state.editing = false;
        state.clientId = '';
        state.clientSecret = '';
        state.domoDatasetId = '';
        state.authChanged = false;
        state.domoDataset = {};
        state.columns = [];
        state.customLabelIds = [];
        state.originalCustomLabelIds = [];
        state.incrementColumnName = '';
        state.dataStructureSaved = false;
        state.languageId = 2;
        state.accent = true;
        state.lowercase = true;
        state.topicRecognition = true;
        state.textSplitting = 1;
        state.analysisSettingsChanged = false;
        state.frequency = '';
        state.time = '12:00';
        state.weekdaysOnly = false;
        state.day = 1;
        state.schedulingChanged = false;
        state.hasNewDictionary = null;
    },
    setDomoIntegration: (state, domoIntegration) => {
        state.id = domoIntegration.id;
        state.datasetId = domoIntegration.datasetId;
        state.createdAt = domoIntegration.createdAt;
        state.editing = true;
        state.clientId = domoIntegration.clientId;
        state.clientSecret = domoIntegration.clientSecret;
        state.domoDatasetId = domoIntegration.domoDatasetId;
        state.columns = domoIntegration.columns;
        state.customLabelIds = domoIntegration.customLabelIds;
        state.originalCustomLabelIds = domoIntegration.customLabelIds;
        state.incrementColumnName = domoIntegration.incrementColumnName;
        state.dataStructureSaved = true;
        state.languageId = domoIntegration.semanticSettings.languageId;
        state.accent = domoIntegration.semanticSettings.accent;
        state.lowercase = domoIntegration.semanticSettings.lowercase;
        state.topicRecognition = domoIntegration.semanticSettings.topicRecognition;
        state.textSplitting = domoIntegration.semanticSettings.textSplitting;

        if (domoIntegration.scheduleCron === null) {
            state.frequency = 0;
        }
        else if (domoIntegration.scheduleCron === '0 * * * *') {
            state.frequency = 1;
        }
        else {
            const [minute, hour, , , day] = domoIntegration.scheduleCron.split(' ');

            if (minute !== '*' && hour !== '*') {
                const localHour = dayjs().utc().hour(+hour).local().hour();
                state.time = `${localHour}:${minute}`;
            }

            if (day === '*') {
                state.frequency = 2;
            }
            else if (day === '1-5') {
                state.frequency = 2;
                state.weekdaysOnly = true;
            }
            else {
                state.frequency = 3;
                state.day = +day;
            }
        }
    },
    setHasNewDictionary: (state, value) => {
        state.hasNewDictionary = value;
    },
};
