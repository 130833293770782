import api from '@/api';

export default {
    async loadAnnouncement({ commit }) {
        try {
            const response = await api.getAnnouncements();
            commit('setAnnouncements', response);
        }
        catch (e) {
            Sentry.captureException(e);
        }
    },

    async saveAnnouncement({ dispatch }, announcementInfo) {
        try {
            if(announcementInfo.announcement.id) {
                await api.saveAnnouncement({announcement: announcementInfo.announcement, picture: announcementInfo.picture});
            }
            else {
                await api.createNewAnnouncement({announcement: announcementInfo.announcement, picture: announcementInfo.picture});
            }
        } finally {
            dispatch('loadAnnouncement');
        }
    },

    async deleteAnnouncement({ dispatch }, announcementId) {
        try {
            await api.deleteAnnouncement(announcementId);

        } finally {
            dispatch('loadAnnouncement');
        }
    },
};
