<template>
    <popup
        :title="$t('GENERAL.FILE_UPLOAD_SIZE_ERROR', 'File size error')"
        :is-closable="false"
        style="z-index: 1010;"
        width-class="w-128"
    >
        <template #default>
            <info-box type="warning">
                <span v-html="body" />
            </info-box>
        </template>
        <template #footer>
            <div class="text-center mt-4">
                <z-button
                    variant="primary"
                    @click="$emit('close')"
                >
                    {{ $t('GENERAL.BACK_TO_HOME', 'Back to Home page') }}
                </z-button>
            </div>
        </template>
    </popup>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import Popup from '@/components/ui/Popup.vue';
import i18next from 'i18next';
import InfoBox from '@/components/ui/InfoBox.vue';


export default defineComponent({
    components: { Popup, InfoBox, },
    props: {
        format: { type: String, default: 'Excel', validator: (value: string): boolean => ['CSV', 'Excel', 'ODS'].includes(value) }
    },
    setup(props) {
        const body = computed(() => {
            return i18next.t('GENERAL.FILE_UPLOAD_SIZE_ERROR_BODY', {
                defaultValue: 'Your {{type}} file size is over {{limit}}MB. Please split the file into smaller pieces and upload them using the Append feature of the file upload.' ,
                limit: props.format === 'CSV' ? 100 : 20,
                type: props.format
            });
        });

        return { body };
    }
});
</script>
