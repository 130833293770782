import {computed, reactive, readonly, UnwrapRef} from 'vue';
import useCommonStore from '@/dashboard/common/store/commonStore';
import useFilterStore, {getFilters} from '@/dashboard/common/store/filterStore';
import useCustomLabelStore from '@/custom-labels/store';
import * as api from '@/dashboard/Rafi/api/rafiApi';
import {aggregateResponse, transformRafiData} from '@/dashboard/Rafi/utils/getRafiChartData';
import {RafiStoreState, rafiTheme, rafiXDimensionType, rafiYDimensionType} from '@/dashboard/Rafi/utils/rafiTypes';
import {Filter, FilterTypeEnum, OperatorTypeEnum} from '@/dashboard/common/types/filterInterface';
import dayjs from 'dayjs';
import {datasetTypeId} from '@/domain/dataset/datasetTypeId';
import {getDimensionsDataForSummaryCharts} from '@/dashboard/Rafi/utils/getRafiChartData';

const { state: commonState, actions: commonActions } = useCommonStore();
const { getters: customLabelGetters, actions: customLabelActions } = useCustomLabelStore();
const { actions: filterActions } = useFilterStore();

export const state: UnwrapRef<RafiStoreState> = reactive({
    initialized: false,
    rafiSpecificDataset: true,
    activeTab: 'summary',
    isLoading: false,
    leftFilter: null,
    dateDimensionId: null,
    ratingDimensionId: null,
    verbatimDimensionId: null,
    versionDimensionId: null,
    interactionCustomLabelIds: [],
    designAndPerformanceCustomLabelIds: [],
    feesCustomLabelIds: [],
    response: [],
    ratingsByDate: [],
    ratingsByDateAndTheme: [],
    ratingsByVersion: [],
    themesByMonths: [],
    themesByVersions: [],
    distributionOfThemes: [],
    ratingsByThemes: [],
    distributionOfCustomLabels: [],
    ratingsByCustomLabels: [],
    recentResponse: [],
    recentDistributionOfCustomLabels: [],
    recentRatingsByCustomLabels: [],
});

export const getters = {
    getDimensionId: computed(() => (dimensionName: string) => {
        return commonState.dataset.dimension_definitions.find(d => d.name === dimensionName)?.id || null;
    }),
    getCustomLabelIds: computed(() => (categoryName: string) => {
        return commonState.dataset.dimension_definitions.find(d => d.id === state.verbatimDimensionId)!.customLabels
            ?.filter(clId => customLabelGetters.customLabelIdMap.value[clId]?.category?.includes(categoryName));
    }),
    getVersionDictionary: computed((): string[] => {
        // @ts-ignore - we have looked up versionDimensionId from the same array and it can only have a string array dictionary
        return commonState.dataset.dimension_definitions.find(d => d.id == state.versionDimensionId)?.dictionary || [];
    }),
    getRecentDateFilter: computed((): Filter => {
        return {
            dimensionId: state.dateDimensionId!,
            isMultiSelect: false,
            type: FilterTypeEnum.dimension,
            operator: OperatorTypeEnum.between,
            value: [
                dayjs().utc().subtract(3, 'month').startOf('day').unix(),
                dayjs().utc().endOf('day').unix()
            ]
        };
    }),
};

export const actions = {
    async init(tab: string) {
        state.isLoading = true;
        state.activeTab = tab;
        if (state.initialized) {
            state.isLoading = false;
            return;
        }
        state.dateDimensionId = commonState.dataset.type === datasetTypeId.nmi ? 'timestamp' : getters.getDimensionId.value('Start Date');
        state.ratingDimensionId = commonState.dataset.type === datasetTypeId.nmi ? 'star_rating_score' : getters.getDimensionId.value('Rating');
        state.verbatimDimensionId = commonState.dataset.type === datasetTypeId.nmi ? 'clean_text' : getters.getDimensionId.value('Reviews Content');
        state.versionDimensionId = commonState.dataset.type === datasetTypeId.nmi ? 'star_rating_version' : getters.getDimensionId.value('Review Product Version Name');
        if (state.dateDimensionId !== null && state.ratingDimensionId !== null && state.verbatimDimensionId !== null && state.versionDimensionId !== null) {
            state.rafiSpecificDataset = true;
            await customLabelActions.init();
            state.interactionCustomLabelIds = getters.getCustomLabelIds.value(rafiTheme.INTERACTION) || [];
            state.designAndPerformanceCustomLabelIds = getters.getCustomLabelIds.value(rafiTheme.DESIGN_AND_PERFORMANCE) || [];
            state.feesCustomLabelIds = getters.getCustomLabelIds.value(rafiTheme.FEES) || [];

            commonActions.setColorScale(['#FBE304', '#FCEB4F', '#FDF181', '#FEF7B4', '#FEFBD9', '#D8DCE8', '#E9EAF2', '#F2F3F8']);
            state.initialized = true;
        } else {
            state.rafiSpecificDataset = false;
        }
        state.isLoading = false;
    },
    getBaseAggregation() {
        if (!state.rafiSpecificDataset) {
            return;
        }
        if (state.response.length === 0 || state.leftFilter !== null) {
            state.leftFilter = null;
            actions.getAggregation();
        } else {
            // We already have the aggregation, we just need to calculate
            switch(state.activeTab) {
            case 'summary':
                actions.initSummary();
                break;
            case 'themes':
                actions.initThemes();
                break;
            }
        }
    },
    handleFilterChange() {
        if (state.initialized) {
            actions.getAggregation(true);
            actions.getAggregation();
        }
    },
    getAggregation(recent: boolean = false) {
        if (!state.rafiSpecificDataset) {
            return;
        }
        state.isLoading = true;
        let filters = getFilters();
        if (state.leftFilter) {
            filters = [...filters, state.leftFilter];
        }
        if (recent) {
            filters = [...filters, getters.getRecentDateFilter.value];
        }
        api.getAggregation(
            commonState.dataset.id,
            [
                { name: 'customlabel', dimensionId: state.verbatimDimensionId, type: 'customLabel' },
                { name: 'rating', dimensionId: state.ratingDimensionId, type: 'value' },
                { name: 'version', dimensionId: state.versionDimensionId, type: 'value' },
                { name: 'date', dimensionId: state.dateDimensionId, type: 'date' },
            ],
            [{name: 'value', type: 'count'}],
            filters
        ).then((response) => {
            if(recent) {
                state.recentResponse = transformRafiData(response, getters.getVersionDictionary.value);
            } else {
                state.response = transformRafiData(response, getters.getVersionDictionary.value);
                actions.calculateData();
            }
        });

        state.isLoading = false;
    },
    reset() {
        commonActions.reset();
        filterActions.reset();
        state.initialized = false;
        state.leftFilter = null;
        state.response = [];
        state.recentResponse = [];
        state.recentDistributionOfCustomLabels = [];
        state.recentRatingsByCustomLabels = [];
        actions.clearCalculatedData();
    },
    clearCalculatedData() {
        state.ratingsByDate = [];
        state.ratingsByDateAndTheme = [];
        state.ratingsByVersion = [];
        state.distributionOfThemes = [];
        state.ratingsByThemes = [];
        state.distributionOfCustomLabels = [];
        state.ratingsByCustomLabels = [];
        state.themesByMonths = [];
        state.themesByVersions = [];
        state.recentDistributionOfCustomLabels = [];
        state.recentRatingsByCustomLabels = [];
    },
    calculateData() {
        actions.clearCalculatedData();
        // (Re)calculate data for active tab
        switch(state.activeTab) {
        case 'summary':
            actions.initSummary();
            break;
        case 'themes':
            actions.initThemes();
            break;
        case 'theme':
            actions.initThemeBreakdown();
            break;
        default:
        // verbatims do not need calculations
        }
    },
    async initSummary() {
        if(state.ratingsByDate.length === 0 || state.ratingsByVersion.length === 0) {
            const filters = getFilters();
            state.isLoading = true;
            const ratingsByDate = await api.getDimensionDistribution(commonState.dataset.id, state.ratingDimensionId!, state.dateDimensionId!, filters);
            state.ratingsByDate = getDimensionsDataForSummaryCharts(ratingsByDate, rafiXDimensionType.DATE, rafiYDimensionType.RATING);
            const ratingsByVersion = await api.getDimensionDistribution(commonState.dataset.id, state.ratingDimensionId!, state.versionDimensionId!, filters);
            state.ratingsByVersion = getDimensionsDataForSummaryCharts(ratingsByVersion, rafiXDimensionType.VERSION, rafiYDimensionType.RATING);
            state.isLoading = false;
        }
    },
    initThemes() {
        if(state.distributionOfThemes.length === 0 || state.ratingsByThemes.length === 0 ||
            state.themesByMonths.length === 0 || state.themesByVersions.length === 0 || state.ratingsByDateAndTheme.length === 0) {
            state.isLoading = true;
            state.distributionOfThemes = aggregateResponse(state.response, rafiXDimensionType.CUSTOM_LABEL_CATEGORY, rafiYDimensionType.VALUE);
            state.ratingsByThemes = aggregateResponse(state.response, rafiXDimensionType.CUSTOM_LABEL_CATEGORY, rafiYDimensionType.RATING);
            state.themesByMonths = aggregateResponse(state.response, rafiXDimensionType.DATE, rafiYDimensionType.CUSTOM_LABEL_CATEGORY);
            state.themesByVersions = aggregateResponse(state.response, rafiXDimensionType.VERSION, rafiYDimensionType.CUSTOM_LABEL_CATEGORY);
            state.ratingsByDateAndTheme = aggregateResponse(state.response, rafiXDimensionType.DATE, rafiYDimensionType.RATING);
            state.isLoading = false;
        }
    },
    setLeftFilter(category: rafiTheme) {
        state.isLoading = true;
        let customLabelIds = [] as number[];
        switch(category) {
        case rafiTheme.INTERACTION:
            customLabelIds = state.interactionCustomLabelIds;
            break;
        case rafiTheme.DESIGN_AND_PERFORMANCE:
            customLabelIds = state.designAndPerformanceCustomLabelIds;
            break;
        }

        state.leftFilter = {
            dimensionId: state.verbatimDimensionId!,
            isMultiSelect: false,
            type: FilterTypeEnum.customLabel,
            operator: OperatorTypeEnum.oneOf,
            value: customLabelIds
        };

        actions.getAggregation();
        actions.getAggregation(true);
        actions.initThemeBreakdown();

        state.isLoading = false;
    },
    initThemeBreakdown() {
        if(state.leftFilter && Array.isArray(state.leftFilter.value)) {
            const customLabelIds = state.leftFilter.value;

            const filteredForTheme = state.response.filter(r => customLabelIds.includes(r.customLabelId));
            state.distributionOfCustomLabels = aggregateResponse(filteredForTheme, rafiXDimensionType.CUSTOM_LABEL, rafiYDimensionType.VALUE);
            state.ratingsByCustomLabels = aggregateResponse(filteredForTheme, rafiXDimensionType.CUSTOM_LABEL, rafiYDimensionType.RATING);

            const recentFilteredForTheme = state.recentResponse.filter(r => customLabelIds.includes(r.customLabelId));
            state.recentDistributionOfCustomLabels = aggregateResponse(recentFilteredForTheme, rafiXDimensionType.CUSTOM_LABEL, rafiYDimensionType.VALUE);
            state.recentRatingsByCustomLabels = aggregateResponse(recentFilteredForTheme, rafiXDimensionType.CUSTOM_LABEL, rafiYDimensionType.RATING);
        }
    },
};

export default function useRafiStore() {
    return {
        state: readonly(state),
        getters,
        actions
    };
}
