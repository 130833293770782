import { api } from '@/api';
import { DomoCredentials, DomoDataset, DomoIntegrationRequestBody, DomoIntegrationDTO } from '../types';

export const getDomoDataset = async (creds: DomoCredentials): Promise<DomoDataset> => {
    const { data } = await api.post('/domo-integration/get-domo-dataset', creds);

    return data;
};

export const createDomoIntegration = async (payload: DomoIntegrationRequestBody): Promise<void> => {
    await api.post('/domo-integration', payload);
};

export const getDomoIntegration = async (id: string): Promise<DomoIntegrationDTO> => {
    const { data } = await api.get('/domo-integration/dataset/' + id);

    return data;
};

export const updateDomoIntegration = async (id: string, payload: DomoIntegrationRequestBody): Promise<void> => {
    await api.put('/domo-integration/' + id, payload);
};
