import { RouteConfig } from 'vue-router';
const EmailConnectorSettings = () => import('../components/EmailConnectorSettings.vue');

const routes: RouteConfig[] = [
    {
        path: ':id',
        redirect: ':id/editor',
    },
    {
        path: ':id/editor',
        component: EmailConnectorSettings,
        name: 'email-connector-editor',
    },
];

export default routes;
