export default {
    setFiles: (state, files) => {
        state.files = files;
    },
    setName: (state, name) => {
        state.name = name;
    },
    setSource: (state, source) => {
        state.source = source;
    },
    setAnalysisSetting: (state, { setting, value }) => {
        state.analysisSettings = {
            ...state.analysisSettings,
            [setting]: value,
        };
    },
    setAnalysisSettings: (state, setting) => {
        state.analysisSettings = setting;
    },
    setCustomLabels: (state, labels) => {
        state.customLabels = labels;
    },
    setOriginalCustomLabels: (state, labels) => {
        state.originalCustomLabels = labels;
    },
    setLastAnalysisAt: (state, date) => {
        state.lastAnalysisAt = date;
    },
    setDatasetId: (state, id) => {
        state.datasetId = id;
    },
    setId: (state, id) => {
        state.id = id;
    },
    setActiveSheet: (state, activeSheet) => {
        state.activeSheet = activeSheet;
    },
    setSheets: (state, sheets) => {
        state.sheets = sheets;
    },
    reset: (state) => {
        state.files = [];
        state.name = '';
        state.id = '';
        state.datasetId = '';
        state.source = null;
        state.analysisSettings = {
            languageId: 1,
            textSplitting: 1,
            accent: true,
            lowercase: true,
            topicRecognition: true,
        };
        state.customLabels = [];
        state.originalCustomLabels = [];
        state.lastAnalysisAt = '';
        state.activeSheet = '';
        state.sheets = {};
    },
};
