export default {
    groupData: state => {
        return {
            name: state.name,
            language_id: state.language_id,
            ui_language_id: state.ui_language_id,
        };
    },

    surveyBrandingSettings: state => {
        return {
            primary_color: state.primary_color,
            text_color: state.text_color,
            background_color: state.background_color,
            title_font_name: state.title_font_name,
            title_font_weight: state.title_font_weight,
            title_font_size: state.title_font_size,
            question_font_name: state.question_font_name,
            question_font_weight: state.question_font_weight,
            question_font_size: state.question_font_size,
            answer_font_name: state.answer_font_name,
            answer_font_weight: state.answer_font_weight,
            answer_font_size: state.answer_font_size,
            layout_spacing: state.layout_spacing,
            privacy_policy_title: state.privacy_policy_title,
            privacy_policy_url: state.privacy_policy_url
        };
    }
};
