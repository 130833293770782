import i18next from 'i18next';

const accessGroupTypeEnum = {
    EDITOR: 3,
    EXPORTER: 2,
    VIEWER: 1,
    NONE: 0,
};

export default accessGroupTypeEnum;

export const accessGroupTypes = Object.values(accessGroupTypeEnum);

export const getDisplayName = (exportType) => {
    switch (exportType) {
    case accessGroupTypeEnum.EDITOR:
        return i18next.t('SETTINGS_USER_MANAGEMENT_PERMISSION_EDITOR', 'Edit');
    case accessGroupTypeEnum.EXPORTER:
        return i18next.t('SETTINGS_USER_MANAGEMENT_PERMISSION_EXPORTER', 'Export');
    case accessGroupTypeEnum.VIEWER:
        return i18next.t('SETTINGS_USER_MANAGEMENT_PERMISSION_VIEWER', 'View');
    case accessGroupTypeEnum.NONE:
        return i18next.t('SETTINGS_USER_MANAGEMENT_PERMISSION_NONE', 'None');
    }
};


