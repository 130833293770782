<template>
    <div>
        <excel-sheet-tabs
            v-if="sheetNames.length > 1"
            v-model="activeSheetName"
            :sheet-names="sheetNames"
            @input="$emit('activeSheetChanged', $event)"
        />
        <excel-table
            :column-types="sheets[activeSheetName].columnTypes"
            :column-names="sheets[activeSheetName].columnNames"
            :column-name-options="possibleColumnNameOptions"
            :data-columns="sheets[activeSheetName].columnData"
            :column-languages="hasColumnLanguages ? sheets[activeSheetName].columnLanguages : []"
            :header="sheets[activeSheetName].headerRows"
            :possible-column-types="possibleColumnTypes"
            :primary-column-type="primaryColumnType"
            :is-column-name-editable="isColumnNameEditable"
            :columns-with-selectable-name="columnsWithSelectableName"
            :are-types-unique="areTypesUnique"
            :default-type="defaultType"
            :append-excel="appendExcel"
            :config="configs[activeSheetName]"
            :original-columns="originalColumns"
            :no-header-separator="noHeaderSeparator"
            :is-preview="isPreview"
            :empty-columns="emptyColumns"
            :column-type="columnType"
            :is-csv="isCsv"
            :is-verbatim-column-disabled="isVerbatimColumnDisabled"
            @headerChanged="changeHeader"
            @columnTypesChanged="changeColumnTypes"
            @columnNamesChanged="changeColumnNames"
            @config-changed="changeConfig"
            @columnLanguagesChanged="changeColumnLanguages"
            @delimiterChanged="$emit('delimiterChanged', $event)"
        />
    </div>
</template>

<script lang="ts">
import {computed, defineComponent, ref, watch} from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import ExcelTable from '@/components/common/ExcelSettings/ExcelTable.vue';
import ExcelSheetTabs from '@/components/common/ExcelSettings/ExcelSheetTabs.vue';
import i18next from 'i18next';

export default defineComponent({
    components: {ExcelSheetTabs, ExcelTable},
    props: {
        initialActiveSheetName: { type: String, default: null },
        initialSheets: { type: Object, default: () => ({}) },
        possibleColumnTypes: { type: Array, default: () => ([]) },
        possibleColumnNameOptions: { type: Array, default: () => ([]) },
        primaryColumnType: { type: [String, Number], default: null },
        isColumnNameEditable: { type: Boolean, default: false },
        columnsWithSelectableName: { type: Array, default: () => ([]) },
        areTypesUnique: { type: Boolean, default: true },
        defaultType: { type: [String, Number], default: null },
        appendExcel: { type: Boolean, default: false },
        initialConfigs: { type: Object, default: () => ({}) },
        originalColumns: { type: Array as () => Array<any>, default: () => ([]) },
        hasColumnLanguages: { type: Boolean, default: false },
        noHeaderSeparator: { type: Boolean, default: false },
        isPreview: { type: Boolean, default: false },
        isCsv: { type: Boolean, default: false },
        isVerbatimColumnDisabled: { type: Boolean, default: false },
        columnType: { type: String, default: 'column' },
    },
    setup(props, context) {
        const activeSheetName = ref(props.initialActiveSheetName || Object.keys(props.initialSheets)[0]);
        const sheets = ref(cloneDeep(props.initialSheets));
        const configs = ref(cloneDeep(props.initialConfigs));

        function changeConfig({ column, newValue }) {
            if (newValue != -1) {
                Object.keys(configs.value[activeSheetName.value]).forEach((key) => {
                    if (configs.value[activeSheetName.value][key].columnNumber == newValue) {
                        configs.value[activeSheetName.value][key] = {
                            columnNumber: -1,
                            name: i18next.t('ANALYSIS.COLUMN_OPTION1'),
                        };
                    }
                });

                configs.value[activeSheetName.value][column] = {...(props.originalColumns.find((origcol: any) => origcol.columnNumber == newValue) || {})};
            } else {
                configs.value[activeSheetName.value][column] = {
                    columnNumber: -1,
                    name: i18next.t('ANALYSIS.COLUMN_OPTION1'),
                };
            }

            context.emit('configs-changed', configs.value);
        }

        const emptyColumns = computed(() => {
            const sheet = sheets.value[activeSheetName.value];

            const columns: number[] = [];

            sheet.columnData.forEach((data, index) => {
                if ((!sheet.columnNames || sheet.columnNames[index] === '') && data.every(row => row === '' || row === null)) {
                    columns.push(index);
                }
            });

            return columns;
        });

        watch(props, ({ initialSheets, initialConfigs }) => {
            sheets.value = initialSheets;
            configs.value = initialConfigs;
        });

        return {
            configs,
            changeConfig,
            activeSheetName,
            sheets,
            sheetNames: computed(() => Object.keys(sheets.value)),
            changeHeader: (header) => {
                const newSheets = {...sheets.value};
                newSheets[activeSheetName.value].headerRows = header;
                if (header === 0) {
                    newSheets[activeSheetName.value].columnNames = newSheets[activeSheetName.value].columnNames.map((name, i) => `Column ${i + 1}`);
                } else {
                    newSheets[activeSheetName.value].columnNames = newSheets[activeSheetName.value].columnData.map(column => column[header - 1]);
                }
                sheets.value = newSheets;
                context.emit('sheetsChanged', sheets.value);
            },
            changeColumnTypes: (types) => {
                sheets.value = {
                    ...sheets.value,
                    [activeSheetName.value]: {
                        ...sheets.value[activeSheetName.value],
                        columnTypes: types,
                        columnLanguages: props.hasColumnLanguages ? sheets.value[activeSheetName.value].columnLanguages?.map((lang, index) => {
                            if (lang !== null && types[index] !== 'synonyms' && types[index] !== 'excludes') {
                                return null;
                            }
                            return lang;
                        }) : []
                    }
                };
                context.emit('sheetsChanged', sheets.value);
            },
            changeColumnNames: (names) => {
                sheets.value = {
                    ...sheets.value,
                    [activeSheetName.value]: { ...sheets.value[activeSheetName.value], columnNames: names}
                };
                context.emit('sheetsChanged', sheets.value);
            },
            changeColumnLanguages: (languages) => {
                sheets.value = {
                    ...sheets.value,
                    [activeSheetName.value]: { ...sheets.value[activeSheetName.value], columnLanguages: languages}
                };
                context.emit('sheetsChanged', sheets.value);
            },
            emptyColumns,
        };
    },
});
</script>
