<template>
    <tr>
        <td
            v-for="(cell, columnNumber) in row"
            :key="columnNumber"
            class="align-middle border-b p-2 truncate"
            :class="{
                'bg-primary-300': isPrimaryColumn(columnNumber),
                'border-primary-700': isPrimaryColumn(columnNumber),
                'border-neutral-600': !isPrimaryColumn(columnNumber),
                'pt-5': isFirst,
                'hidden': emptyColumns.includes(columnNumber),
            }"
            style="min-width: 70px; max-width: 210px;"
            :data-test-id="'cell-' + rowNumber + '-' + columnNumber"
        >
            <span
                :class="isDisabled ? 'text-neutral-700' : ''"
                :title="cell"
            >
                <label
                    v-if="isFirst && !isDataTextHidden"
                    class="block text-small font-bold mb-1"
                    :class="{ 'opacity-0': columnNumber !== 0 }"
                    style="margin-top: -10px;"
                >
                    {{ $t('ANALYSIS.DATA_TO_ANALYZE') }}
                </label>
                {{ cell }}
            </span>
        </td>
    </tr>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        row: { type: [Array], default: () => ([]) },
        rowNumber: { type: Number, default: 0 },
        isFirst: { type: Boolean, default: false },
        isDisabled: { type: Boolean, default: false},
        primaryColumnIndexes: { type: Array, default: () => [] },
        isDataTextHidden: { type: Boolean, default: false },
        emptyColumns: { type: Array as () => Array<number>, default: () => [] },
    },
    setup(props) {
        return {
            isPrimaryColumn: (i) => props.primaryColumnIndexes.indexOf(i) > -1,
        };
    },
});
</script>
