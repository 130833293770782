import { RouteConfig } from 'vue-router';
const NMIIntegrationEditor = () => import('../components/NMIIntegrationEditor.vue');
const NMIIntegrationSetup = () => import('../components/NMIIntegrationSetup.vue');

const routes: RouteConfig[] = [
    {
        path: 'setup',
        component: NMIIntegrationSetup,
        name: 'nmi-integration-setup',
    },
    {
        path: ':id',
        component: NMIIntegrationEditor,
        name: 'nmi-integration-editor',
    },
];

export default routes;
