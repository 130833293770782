const state = {
    id: '',
    datasetId: '',
    createdAt: null,
    editing: false,
    invalidDomoStructure: false,
    dataStructureChanged: false,
    // auth
    clientId: '',
    clientSecret: '',
    domoDatasetId: '',
    authChanged: false,
    // data structure
    domoDataset: {},
    columns: [],
    customLabelIds: [],
    originalCustomLabelIds: [],
    incrementColumnName: '',
    dataStructureSaved: false,
    selectedCustomLabelsChanged: false,
    // analysis settings
    languageId: 2,
    accent: true,
    lowercase: true,
    topicRecognition: true,
    textSplitting: 1,
    analysisSettingsChanged: false,
    // scheduling
    frequency: '',
    time: '12:00',
    weekdaysOnly: false,
    day: 1,
    schedulingChanged: false,
    hasNewDictionary: null,
};

export default state;
