<template>
    <popup
        :title="title"
        :is-closable="true"
        has-backdrop
        @close="$emit('closePopupBar')"
    >
        <div class="w-128 space-y-4">
            <div class="flex space-x-2">
                <z-input
                    ref="nameInput"
                    :value="customLabel.name"
                    class="w-1/3"
                    :placeholder="$t('KEYWORD.NAME_PLACEHOLDER')"
                    autofocus
                    data-test-id="nameInput"
                    :error-message="nameError"
                    @input="changeCustomLabel({type: 'name', value: $event})"
                >
                    <template #label>
                        {{ $t('KEYWORD.NAME') }}
                        <z-icon
                            v-tooltip.top="$t('KEYWORD.NAME_HELP')"
                            icon="question-circle"
                        />
                    </template>
                </z-input>
                <custom-label-category
                    :category-id="customLabel.category_id"
                    class="w-1/3"
                    @update:category-id="changeCustomLabel({type: 'category_id', value: $event})"
                />
                <div class="w-1/3">
                    <z-input
                        :value="customLabel.code"
                        :placeholder="$t('KEYWORD.CODE_PLACEHOLDER')"
                        data-test-id="codeInput"
                        @input="changeCustomLabel({type: 'code', value: $event})"
                    >
                        <template #label>
                            {{ $t('KEYWORD.CODE') }}
                            <z-icon
                                v-tooltip.top="$t('KEYWORD.CODE_HELP')"
                                icon="question-circle"
                            />
                        </template>
                    </z-input>
                </div>
            </div>
            <tags
                :tags="customLabel.tags"
                @change="changeCustomLabel({type: 'tags', value: $event})"
            />
            <z-toggle
                v-model="clWithoutPhraseSets"
                :disabled="Boolean(keywordDetails.id) && keywordDetails.phraseSets.length > 0"
            >
                {{ $t('CUSTOM_LABEL.WITHOUT_PHRASE_SETS.TOGGLE_LABEL', 'Custom Label without synonym ') }}
            </z-toggle>
            <template v-if="clWithoutPhraseSets">
                <info-box>
                    <div class="text-sm">
                        {{ $t('CUSTOM_LABEL.WITHOUT_PHRASE_SETS.INFOBOX', 'Custom Labels without synonyms enable you to categorize verbatims that cannot be categorized or recognized based solely on strict synonyms, but rather on contextualized meaning. Be aware that Custom Labels without synonyms can only be assigned manually; the system will not assist with any automation and will not learn from these manual assignments.') }}
                    </div>
                </info-box>
            </template>
            <template v-else>
                <info-box
                    v-if="showSynonymTooltip && showAgain"
                >
                    <z-icon
                        icon="times"
                        class="cursor-pointer float-right ml-4 mb-4"
                        data-test-id="close-popup"
                        @click="showSynonymTooltip = false; showAgain = false"
                    />
                    <div class="text-sm">
                        <span>{{ $t('KEYWORD.SYNONYMS_TOOLTIP_1', 'Categorise several phrases into Custom Labels. Zurvey.io looks for these phrases in the clauses.') }}</span><br>
                        <span>{{ $t('KEYWORD.SYNONYMS_TOOLTIP_2', 'To make it an even more powerful tool, you can:') }}</span>
                        <ul class="list-disc pl-5">
                            <li>
                                {{ $t('KEYWORD.SYNONYMS_TOOLTIP_3', 'Add multiple phrases, even at the same time by pasting them from your clipboard. Just make sure the individual phrases are divided by a comma “,” character.') }}
                            </li>
                            <li v-html="tooltipText" />
                            <li>
                                {{ $t('KEYWORD.SYNONYMS_TOOLTIP_4', 'If you don’t want to include any suffix for a word, you can use the “]” character for it. (eg. colour] will not include colourful)') }}
                            </li>
                        </ul>
                    </div>
                </info-box>
                <div
                    v-for="(phraseSet, index) in customLabel.phraseSets"
                    :key="index"
                >
                    <accordion
                        has-border-top
                        :text="$t('CUSTOM_LABEL_POPUP_PHRASE_SET', 'Phrase set') + ' - ' + getDisplayName(phraseSet.languageId)"
                        :is-open-by-default="phraseSet.languageId === null || openAllPhraseSets || (customLabel.phraseSets.length - 1 === index)"
                    >
                        <custom-label-phrase-set
                            :phrase-set="phraseSet"
                            :languages="existingLanguages"
                            @phraseSetUpdated="changeCustomLabelPhraseSets($event, index)"
                            @deletePhraseSet="deleteCustomLabelPhraseSets(index)"
                        />
                    </accordion>
                </div>
                <div v-if="newPhraseAdded || phraseSetCount === 0">
                    <accordion
                        has-border-top
                        :text="$t('CUSTOM_LABEL_POPUP_PHRASE_SET', 'Phrase set')"
                        is-open-by-default
                    >
                        <custom-label-phrase-set
                            :phrase-set="newPhrase"
                            :is-new="true"
                            :languages="existingLanguages"
                            :new-phrase-added="newPhraseAdded"
                            @phraseSetUpdated="changeNewPhraseSet"
                        />
                    </accordion>
                </div>
                <div
                    class="text-default flex justify-center"
                    data-test-id="addPhraseSet"
                    @click="newPhrase.languageId === null && newPhraseAdded || addNewPhraseSet()"
                >
                    <span
                        class="mr-2 font-bold"
                        :class="newPhrase.languageId === null && newPhraseAdded ? 'text-neutral-700' : 'text-primary-700 cursor-pointer'"
                    > + {{ $t('CUSTOM_LABEL_POPUP_PHRASE_SET_ADD_NEW', 'Add new language phrase set') }} </span>
                </div>
            </template>
        </div>
        <div
            class="mt-4 flex justify-center"
            data-test-id="saveKeyword"
        >
            <z-button
                :disabled="saveButtonDisabled"
                @click="save"
            >
                {{ buttonText }}
            </z-button>
        </div>
    </popup>
</template>

<script lang="ts">
import i18next from 'i18next';
import accents from 'remove-accents';
import CustomLabelCategory from './CustomLabelCategory/CustomLabelsCategory.vue';
import Popup from '@/components/ui/Popup.vue';
import ZIcon from '@/components/ui/Icon.vue';
import Tags from './CustomLabelTags/CustomLabelTags.vue';
import { toaster } from '@/utils/toaster';
import { defineComponent, ref, computed } from 'vue';
import Accordion from '@/components/ui/Accordion.vue';
import { getDisplayName } from '@/domain/analysisLanguageEnum';
import CustomLabelPhraseSet from '@/custom-labels/components/CustomLabelPopup/CustomLabelPhraseSet.vue';
import { CustomLabel, PhraseSet } from '@/custom-labels/store/types';
import InfoBox from '@/components/ui/InfoBox.vue';

export default defineComponent({
    components: {
        Accordion,
        CustomLabelCategory,
        ZIcon,
        Popup,
        Tags,
        CustomLabelPhraseSet,
        InfoBox,
    },
    props: {
        keywordDetails: { type: Object as () => CustomLabel, required: true },
        title: { type: String, default: '' },
        buttonText: { type: String, default: () => i18next.t('GLOBAL.SAVE') },
        quickClReanalysis: { type: Boolean, default: false },
        manualCustomLabelCreate: { type: Boolean, default: false },
        openAllPhraseSets: { type: Boolean, default: false },
    },
    setup(props, ctx) {
        const nameError = ref('');
        const customLabel = ref<CustomLabel>({ ...props.keywordDetails });
        const showSynonymTooltip = ref(true);
        const showAgain = ref(true);
        const newPhrase = ref({
            languageId: null,
            synonyms: [],
            excludes: []
        });
        const newPhraseAdded = ref(customLabel.value.phraseSets.length === 0);
        const existingLanguages = ref(customLabel.value.languages ? customLabel.value.languages : Object.values(customLabel.value.phraseSets).map(ps => ps.languageId));
        const tooltipText = computed(() =>
            i18next.t('KEYWORD.SYNONYMS_TOOLTIP', {
                tag: '<b>',
                endTag: '</b>',
                interpolation: { escapeValue: false },
                defaultValue: 'Link together different words by putting “;”, “AND” or “&&” between the words. (eg. {{tag}}apple && pie{{endTag}}, {{tag}}apple AND pie{{endTag}}, or {{tag}}apple ; pie{{endTag}}'
            })
        );
        const areSynonymsMissing = computed(() => Object.values(customLabel.value.phraseSets).some(ps => ps.synonyms.length === 0));
        const phraseSetCount = computed(() => Object.keys(customLabel.value.phraseSets).length);
        const clWithoutPhraseSets = ref(props.keywordDetails.id ? props.keywordDetails.phraseSets.length === 0 : props.manualCustomLabelCreate);

        const save = async () => {
            if (clWithoutPhraseSets.value) {
                return ctx.emit('keywordHasBeenAddedOrEdited', {
                    ...customLabel.value,
                    phraseSets: [],
                });
            }

            if (phraseSetCount.value > 0) {
                const phraseSets = customLabel.value.phraseSets.map(set => {
                    const synonyms = set.synonyms.filter(s => {
                        if (s.slice(0, 4) === 'AND ' || s.slice(-4) === ' AND') return false;

                        return true;
                    });

                    return {
                        ...set,
                        synonyms,
                    };
                });

                const cl = {
                    ...customLabel.value,
                    phraseSets,
                };
                ctx.emit('keywordHasBeenAddedOrEdited', cl);
            } else {
                toaster.error(i18next.t('KEYWORD.MISSING_PHRASE_SETS','You need to have at least one valid phrase set in a Custom Label.'));
            }
        };

        const addNewPhraseSet = () => {
            newPhraseAdded.value = true;
        };

        const changeCustomLabel = ({ type, value }) => {
            customLabel.value = {
                ...customLabel.value,
                [type]: value,
            };
            if (type === 'name') {
                customLabel.value = {
                    ...customLabel.value,
                    code: accents.remove(value).split(' ').join('_').toLowerCase().substring(0, 16)
                };
            }
        };

        const changeCustomLabelPhraseSets = (newPhraseSet, index) => {
            const newPhraseSets = [...customLabel.value.phraseSets].map((ps, key) => {
                return key === index ? newPhraseSet : ps;
            });
            customLabel.value = {
                ...customLabel.value,
                phraseSets: newPhraseSets
            };
            existingLanguages.value = newPhraseSets.map(ps => ps.languageId);
        };

        const changeNewPhraseSet = (phraseSetToPush) => {
            if (phraseSetToPush.languageId) {
                let newPhraseSets = [...customLabel.value.phraseSets];
                newPhraseSets[newPhraseSets.length] = phraseSetToPush;
                customLabel.value = {
                    ...customLabel.value,
                    phraseSets: newPhraseSets
                };
                existingLanguages.value = newPhraseSets.map(ps => ps.languageId);
                newPhraseAdded.value = false;
                newPhrase.value = {
                    languageId: null,
                    synonyms: [],
                    excludes: []
                };
            } else {
                newPhrase.value = phraseSetToPush;
            }
        };

        const deleteCustomLabelPhraseSets = (index) => {
            const newPhraseSets = [...customLabel.value.phraseSets].filter((ps, key) => {
                if (key !== index) {
                    return ps;
                }
            }) as PhraseSet[];
            customLabel.value = {
                ...customLabel.value,
                phraseSets: newPhraseSets
            };
            existingLanguages.value = Object.values(customLabel.value.phraseSets).map(ps => ps.languageId);
        };

        const saveButtonDisabled = computed(() => {
            if (!customLabel.value.name) return true;

            if (!clWithoutPhraseSets.value) return areSynonymsMissing.value || (newPhrase.value.languageId === null && newPhraseAdded.value);

            return false;
        });

        return {
            toaster, nameError, customLabel, newPhrase, newPhraseAdded, existingLanguages, tooltipText, showSynonymTooltip, showAgain, phraseSetCount, areSynonymsMissing,
            save, addNewPhraseSet, getDisplayName, changeCustomLabel, changeCustomLabelPhraseSets, changeNewPhraseSet, deleteCustomLabelPhraseSets,
            clWithoutPhraseSets, saveButtonDisabled,
        };
    }
});
</script>
