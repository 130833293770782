<template>
    <div
        data-test-id="excel-sheets"
        class="relative"
        style="top: 4px; left: 4px;"
    >
        <span
            v-for="(sheetName, i) in sheetNames"
            :key="sheetName"
            :data-test-id="'excel-sheet-' + i"
            class="cursor-pointer rounded-t leading-8 shadow mr-1 mb-1 text-default"
            :class="{
                active: value === sheetName,
                'bg-primary-700': value === sheetName,
                'text-white': value === sheetName,
                'font-bold': value === sheetName,
                'bg-bg': value !== sheetName,
                'text-neutral-700': value !== sheetName,
            }"
            style="padding: 4px 20px 4px 10px;"
            @click="$emit('input', sheetName)"
        >
            {{ sheetName.charAt(0).toUpperCase() + sheetName.slice(1) }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: String, required: true },
        sheetNames: { type: Array, default: () => [] },
    },
};
</script>
<style lang="less" scoped>
span:before {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background-color: #EAEBF2;
    margin-right: 8px;
    vertical-align: middle;
    margin-top: -3px;
}

span.active:before {
    background-color: var(--color-primary-700);
    border: 5px white solid;
}
</style>
