import { api } from '@/api';
import { WebCrawler, GetWebsiteCrawlerData, PreviewData, AppendData } from '../types';

export const newWebCrawler = async (webCrawler: WebCrawler & { delimiter: string|null }): Promise<void> => {
    const formData = new FormData();

    Object.keys(webCrawler).forEach(key => {
        if (key === 'columns' || key === 'customLabelIds') {
            formData.append(key, JSON.stringify(webCrawler[key]));
        } else if (key === 'delimiter' && webCrawler.delimiter) {
            formData.append(key, webCrawler.delimiter);
        }
        else {
            formData.append(key, webCrawler[key]);
        }
    });

    await api.post('/web-crawler', formData);
};

export const getWebCrawler = async (datasetId: string): Promise<GetWebsiteCrawlerData> => {
    const { data } = await api.get('/web-crawler/dataset/' + datasetId);

    return data;
};

export const getPreviewData = async (datasetId: string): Promise<PreviewData> => {
    const { data } = await api.get(`/datasets/${datasetId}/getPreviewData`);

    return data;
};

export const updateWebsiteCrawler = async (websiteCrawlerId: string, payload): Promise<void> => {
    const data = Object.keys(payload).reduce((d, key) => {
        if (key !== 'name') {
            d[key] = JSON.stringify(payload[key]);
        } else {
            d[key] = payload[key];
        }
        return d;
    }, {});

    await api.put(`/web-crawler/${websiteCrawlerId}`, data);
};

export const updateColumns = async (datasetId: string, columns: Array<{ name: string, type: number, id: string, isMeta: boolean }>): Promise<void> => {
    await api.put('/datasets/' + datasetId, { columns });
};

export const appendUrl = async (websiteCrawlerId: string, data: AppendData & { delimiter: string|null }): Promise<void> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        if (key === 'columnPairs') {
            formData.append(key, JSON.stringify(data[key]));
        } else if (key === 'delimiter' && data.delimiter) {
            formData.append(key, data.delimiter);
        }
        else {
            formData.append(key, data[key]);
        }
    });

    await api.post('/web-crawler/' + websiteCrawlerId + '/append-url', formData);
};

export const appendContent = async (websiteCrawlerId: string, data: AppendData & { delimiter: string|null }): Promise<void> => {
    const formData = new FormData();

    Object.keys(data).forEach(key => {
        if (key === 'columnPairs') {
            formData.append(key, JSON.stringify(data[key]));
        } else if (key === 'delimiter' && data.delimiter) {
            formData.append(key, data.delimiter);
        }
        else {
            formData.append(key, data[key]);
        }
    });

    await api.post('/web-crawler/' + websiteCrawlerId + '/append-content', formData);
};
