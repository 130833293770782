import { RouteConfig } from 'vue-router';
const DomoIntegrationSetup = () => import('@/domo-integration/pages/DomoIntegrationSetup.vue');
const DomoIntegrationEdit = () => import('@/domo-integration/pages/DomoIntegrationEdit.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: '/',
    },
    {
        path: 'setup',
        component: DomoIntegrationSetup,
    },
    {
        path: ':datasetId',
        component: DomoIntegrationEdit,
        props: true,
    }
];

export default routes;
