import { RouteConfig } from 'vue-router';

const CustomLabels = () => import('../components/CustomLabels.vue');
const CustomLabelSearchBar = () => import('../components/CustomLabelSearchBar/CustomLabelSearchBar.vue');
const CustomLabelUpload = () => import('../components/CustomLabelUpload/CustomLabelUpload.vue');

const routes: RouteConfig[] = [
    {
        path: '/',
        components: {
            default: CustomLabels,
            nav: CustomLabelSearchBar
        }
    },
    {
        path: 'upload',
        component: CustomLabelUpload,
    },
];

export default routes;
