import Vue from 'vue';
import '@/utils/icons';
import '@/utils/sentry';
import './assets/index.css';
import 'simplebar/dist/simplebar.min.css';
import VTooltip from 'v-tooltip';
import VueMoment from 'vue-moment';
import VueI18Next from '@panter/vue-i18next';
import Vue2Filters from 'vue2-filters';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';
import translation from '@/utils/translation';
import LoadingScreen from '@/plugins/LoadingScreen';
import ConfirmPopup from '@/plugins/ConfirmPopup';
import Button from '@/common/components/design-system/Button.vue';
import Input from '@/common/components/design-system/Input.vue';
import Toggle from '@/common/components/design-system/Toggle.vue';
import Checkbox from '@/components/ui/CheckboxInput.vue';
import Radio from '@/components/ui/RadioInput.vue';
import Tag from '@/common/components/design-system/Tag.vue';
import Textarea from '@/common/components/design-system/Textarea.vue';

Vue.config.productionTip = false;
Vue.use(VueI18Next);
Vue.use(VTooltip);
Vue.use(VueMoment);
Vue.use(LoadingScreen);
Vue.use(ConfirmPopup);
Vue.use(Vue2Filters);

Vue.component('ZButton', Button);
Vue.component('ZInput', Input);
Vue.component('ZToggle', Toggle);
Vue.component('ZCheckbox', Checkbox);
Vue.component('ZRadio', Radio);
Vue.component('ZTag', Tag);
Vue.component('ZTextarea', Textarea);

(async function() {
    new Vue({
        store,
        router,
        i18n: new VueI18Next(await translation()),
        render: h => h(App)
    }).$mount('#app');
})();

