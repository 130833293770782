import * as customLabelsApi from '@/custom-labels/api/custom-labels';
import gtm from '@/utils/gtm';

import { setLoading } from '@/utils/loading/index.js';

import { CustomLabel, PopupState, CustomLabelState, CLResponseTypeEnum } from './types';

export const popupState: PopupState = {
    open: false,
    mode: null,
    customLabel: null,
    resolve: () => {},
};

export const makePopupActions = (state: CustomLabelState, actions) => {
    const popupActions = {
        async openPopup(mode: 'edit' | 'clone' | 'new', id?: number) {
            if (mode !== 'new' && !id) throw new Error('wrong mode');

            if (id) {
                let label = null as CustomLabel|null;
                const clFromStore = { ...state.customLabels.find(l => l.id === id) } as CustomLabel;
                try {
                    if (clFromStore.phraseSets.length > 0) {
                        label = clFromStore;
                    } else {
                        label = await actions.getCustomLabel(id);
                    }
                } catch (e) {
                    Sentry.captureException(e);
                    label = clFromStore;
                }
                if (!label) {
                    throw new Error('custom label not found');
                }

                if (mode === 'clone') {
                    label.name = label.name + '_1';
                    label.code = label.code + '_1';
                }

                popupState.customLabel = label;
            } else {
                popupState.customLabel = {
                    category: null,
                    category_id: null,
                    code: '',
                    created_at: '',
                    creator: '',
                    creator_id: 0,
                    deleted_at: null,
                    editor_id: 0,
                    excludes: '',
                    excludesList: [],
                    filters: '',
                    filtersList: [],
                    id: 0,
                    language_id: null,
                    languageId: null,
                    name: '',
                    synonyms: '',
                    synonymsList: [],
                    tags: [],
                    updated_at: '',
                    user_group_id: 0,
                    phraseSets: [],
                    languages: [],
                } as CustomLabel;
            }
            popupState.mode = mode;
            popupState.open = true;
        },
        closePopup() {
            popupState.open = false;
            popupState.customLabel = null;
            popupState.mode = null;
        },
        async saveCustomLabel(label) {
            popupState.open = false;

            setLoading(true);

            const mode = popupState.mode;

            let id;

            try {
                if (mode === 'edit') {
                    await customLabelsApi.updateCustomLabel(label.id, label);
                    id = label.id;
                    gtm.track(gtm.events.CUSTOM_LABEL_UPDATED, gtm.categories.CUSTOM_LABEL_UPDATED, { id: label.id });
                } else {
                    const response = await customLabelsApi.createCustomLabel(label);
                    id = response.id;
                    gtm.track(gtm.events.CUSTOM_LABEL_CREATED, gtm.categories.CUSTOM_LABEL_CREATED, { id: response.id });
                }
            } catch (error) {
                setLoading(false);
                throw error;
            }

            popupState.mode = null;
            popupState.customLabel = null;

            await actions.getCustomLabels(CLResponseTypeEnum.CREATOR_AND_SIMPLE_PHRASE_SET);
            setLoading(false);

            popupState.resolve({ ...label, id });
            popupState.resolve = () => {};

            return mode;
        },
        async createCustomLabel() {
            popupActions.openPopup('new');

            return new Promise((resolve: (any) => any) => {
                popupState.resolve = resolve;
            });
        }
    };

    return popupActions;
};
