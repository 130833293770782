<template>
    <excel-analysis
        v-if="uploadedExcel"
        :initial-sheets="uploadedExcel.sheets"
        :initial-name="uploadedExcel.name"
        :initial-active-sheet="uploadedExcel.activeSheet"
        :file="uploadedExcel.file"
        :account-manager-email="accountManagerEmail || undefined"
        :plan="plan || undefined"
        can-use-unique-id
    />
</template>

<script lang="ts">
import { defineComponent, onUnmounted, toRefs, onMounted } from 'vue';

import useExcelAnalysisStore from '@/excel-analysis/store';
import useSubscriptionStore from '@/subscription/store';
import ExcelAnalysis from '../components/ExcelAnalysis.vue';
import useCustomLabelStore from '@/custom-labels/store';
import {CLResponseTypeEnum} from '@/custom-labels/store/types';
import {setLoading} from '@/utils/loading';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
    components: {
        ExcelAnalysis
    },
    setup() {
        const router = useRouter();
        const { state, actions } = useExcelAnalysisStore();
        const { actions: customLabelActions } = useCustomLabelStore();
        const { uploadedExcel } = toRefs(state);
        const { accountManagerEmail, plan } = toRefs(useSubscriptionStore().state);

        if (!uploadedExcel.value) {
            Sentry.addBreadcrumb({ message: 'Redirected, because lack of uploadedExcel' });
            router.replace('/');
        }

        onUnmounted(() => {
            actions.resetUploadExcel();
        });

        onMounted(async () => {
            try {
                setLoading(true);
                await customLabelActions.getCustomLabels(CLResponseTypeEnum.CREATOR_AND_SIMPLE_PHRASE_SET);
            }
            catch (e) {
                Sentry.captureException(e);
            }
            finally {
                setLoading(false);
            }
        });

        return {
            uploadedExcel,
            accountManagerEmail,
            plan,
        };
    },
});
</script>
