import * as api from '../api';
import * as datasetApi from '@/dashboard/api/datasetApi';
import * as datasetsApi from '@/datasets/api';

export default {
    uploadFiles: async ({ commit, rootState }, files) => {
        commit('setFiles', files);
        commit('setName', files[0].name.slice(0, -4));
        commit('setAnalysisSetting', { setting: 'languageId', value: rootState.settings.teamSettings.language_id });
    },
    create: async ({ state }) => {
        await api.createPdfExtractor({
            name: state.name,
            source: state.source,
            ...state.analysisSettings,
            customLabelIds: state.customLabels,
            files: state.files
        });
    },
    getPdfExtractor: async ({ commit }, id) => {
        const extractor = await api.getPdfExtractor(id);
        commit('setName', extractor.name);
        commit('setId', extractor.id);
        commit('setDatasetId', extractor.datasetId);
        commit('setAnalysisSettings', extractor.semanticSettings);
        commit('setCustomLabels', extractor.customLabelIds);
        commit('setOriginalCustomLabels', extractor.customLabelIds);
    },
    getPreview: async ({ commit }, id) => {
        const preview = await api.getPreview(id);

        commit('setActiveSheet', 'active');
        commit('setSheets', {
            active: {
                columnData: Object.values(preview.dimensions).map((dimension: any) => preview.records.map(record => record[dimension.id])),
                columnNames: Object.values(preview.dimensions).map((col: any) => col.name),
                columnTypes: Object.values(preview.dimensions).map((col: any) => col.type),
                columnIds: Object.values(preview.dimensions).map((col: any) => col.id),
                headerRows: 0,
                rows: preview.records.length,
            }
        });
    },
    getDatasetLastAnalysisAt: async ({ commit }, id) => {
        const dataset = await datasetsApi.getDataset(id);
        commit('setLastAnalysisAt', dataset.last_analysis_at);
    },
    updateName: async ({ state }) => {
        await api.updateName(state.datasetId, state.name);
    },
    updatePdfExtractor: async ({ state }) => {
        await api.updatePdfExtractor(state.id, {
            name: state.name,
            ...state.analysisSettings,
            customLabelIds: state.customLabels,
        });
    },
    clQuickReanalyze: async ({ state, getters }, updatedCls) => {
        const customLabelIds = [...new Set([...state.customLabels, ...updatedCls])];

        await datasetApi.customLabelReanalyses(state.datasetId, 'content', customLabelIds, getters.deletedCustomLabelIds);
    },
    reanalyze: async ({ state }) => {
        await datasetsApi.reanalyze(state.datasetId);
    },
    append: async ({ state }) => {
        await api.append(state.id, { source: state.source, files: state.files });
    },
};
