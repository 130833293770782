<template>
    <popup
        :is-closable="!isWarning ? type !== 'destructive' : !isWarning "
        class="w-128 mx-auto"
        style="z-index: 1010;"
        width-class="w-128"
        @close="onClose"
    >
        <template #header>
            <div class="font-bold truncate">
                {{ title }}
            </div>
        </template>
        <template #default>
            <info-box
                v-if="isWarning"
                type="warning"
            >
                <span
                    class="text-sm"
                    v-html="body"
                />
            </info-box>
            <span
                v-else
                class="text-sm"
                v-html="body"
            />
        </template>
        <template #footer>
            <div class="text-center mt-4">
                <z-button
                    v-if="type !== 'notification'"
                    data-test-id="left-button"
                    :variant="secondaryButtonType || (type === 'destructive' ? 'primary' : 'secondary')"
                    class="mr-4"
                    @click="$emit('cancel')"
                >
                    {{ secondaryText }}
                </z-button>
                <z-button
                    data-test-id="right-button"
                    :variant="type === 'destructive' ? 'secondary' : 'primary'"
                    :destructive="type === 'destructive'"
                    @click="$emit('ok')"
                >
                    {{ primaryText }}
                </z-button>
            </div>
        </template>
    </popup>
</template>

<script>
import Popup from './Popup';
import i18next from 'i18next';
import InfoBox from '@/components/ui/InfoBox.vue';

export default {
    components: {
        Popup,
        InfoBox,

    },
    props: {
        title: { type: String, default: null },
        body: { type: String, default: null },
        primaryButtonText: { type: String, default: null },
        secondaryButtonText: { type: String, default: null },
        // Types:
        // default: two buttons (cancel secondary, OK primary solid), closable
        // destructive: two buttons (cancel solid primary, OK destructive ghost)
        // notification: one button: Primary solid OK
        type: { type: String, default: 'default' },
        isWarning: { type: Boolean, default: false },
        secondaryButtonType: { type: String, default: null },
    },
    emits: ['ok', 'cancel'],
    computed: {
        primaryText() {
            if (!this.primaryButtonText) {
                return this.type === 'destructive' ? i18next.t('GLOBAL.YES_IM_SURE') : i18next.t('GLOBAL.OK', 'Ok');
            }
            return this.primaryButtonText;
        },
        secondaryText() {
            if (!this.secondaryButtonText) {
                return i18next.t('GLOBAL.CANCEL');
            }
            return this.secondaryButtonText;
        },
    },
    methods: {
        onClose() {
            if (this.type !== 'destructive') {
                this.$emit('cancel');
            }
        }
    },
};
</script>
