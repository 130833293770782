import * as api from '@/dashboard/api/datasetApi';
import useCustomLabelStore from '@/custom-labels/store';
import languages from '@/domain/analysisLanguageEnum.js';

interface State {
    originalSelectedCustomLabels: Array<{ guid: string, customLabels: Array<number> }>
}

function getDefaultState(): State {
    return {
        originalSelectedCustomLabels: [],
    };
}

export default {
    namespaced: true,
    state: getDefaultState,
    mutations: {
        setOriginalSelectedCustomLabels(state: State, customLabels: Array<{ guid: string, customLabels: Array<number> }>) {
            state.originalSelectedCustomLabels = customLabels;
        },
        reset(state: State) {
            const defaultState = getDefaultState();

            for (const key in defaultState) {
                state[key] = defaultState[key];
            }
        }
    },
    getters: {
        customLabelsChanged(state: State, _, rootState): boolean {
            const selectedCustomLabels = rootState.survey.selectedCustomLabels;
            const originalSelectedCustomLabels = state.originalSelectedCustomLabels;

            if (selectedCustomLabels.length !== originalSelectedCustomLabels.length) return true;

            for (const originalQuestion of originalSelectedCustomLabels) {
                const question = selectedCustomLabels.find(q => q.guid === originalQuestion.guid);

                if (originalQuestion.customLabels.length !== question.customLabels.length) return true;

                for (const label of originalQuestion.customLabels) {
                    if (!question.customLabels.includes(label)) return true;
                }
            }

            return false;
        },
        reanalysisIsDisabled(_state, _getters, rootState): boolean {
            const langId = rootState.survey.language_id;

            if (langId === languages.MIXED) return false;

            const { state: clState } = useCustomLabelStore();

            for (const question of rootState.survey.selectedCustomLabels) {
                if (question.customLabels.some(id => {
                    const customLabel = clState.customLabels.find(cl => cl.id === id);

                    return !customLabel!.languages.includes(langId);

                })) {
                    return true;
                }
            }

            return false;
        }
    },
    actions: {
        async reanalyiseOnlyCl({ state, rootState, commit }: { state: State, rootState: any, commit: Function }): Promise<void> {
            await Promise.all(rootState.survey.selectedCustomLabels.map(q => {
                const originalQuestion: { guid?: string, customLabels: number[] } = state.originalSelectedCustomLabels.find(o => o.guid === q.guid) || { customLabels: [] };

                const newCls = q.customLabels.filter(cl => !originalQuestion.customLabels.includes(cl));
                const removedCls = originalQuestion.customLabels.filter(cl => !q.customLabels.includes(cl));

                if (newCls.length === 0 && removedCls.length === 0) return null;

                return api.customLabelReanalyses(rootState.survey.datasetId, q.guid, newCls, removedCls);
            }));
            commit('setOriginalSelectedCustomLabels', rootState.survey.selectedCustomLabels);
        },
    },
};
