import { api } from '@/api/index.js';
import {PhraseSet} from '@/custom-labels/store/types';

interface CustomLabelPostData {
    id: number | null;
    name: string;
    code: string | null;
    categoryId?: number | null;
    categoryName?: string | null;
    languageId?: number | null;
    synonymsList?: string[];
    excludesList?: string[];
    phraseSets?: PhraseSet[];
    tags: string[];
}

export async function getCustomLabels(responseType: number|null = null) {
    //See in CLResponseTypeEnum
    const queryParams = responseType ? '?clResponseType=' + responseType : '';
    const { data } = await api.get('/custom-labels' + queryParams);

    return data;
}

export async function createCustomLabel(cl: CustomLabelPostData) {
    const { data } = await api.post('/custom-labels', cl);

    return data;
}

export async function getCustomLabel(id: number) {
    const { data } = await api.get(`/custom-labels/${id}`);
    return data;
}
export async function updateCustomLabel(id: number, cl: CustomLabelPostData) {
    const { data } = await api.put(`/custom-labels/${id}`, cl);

    return data;
}

export async function deleteCustomLabel(id: number) {
    const { data } = await api.delete(`/custom-labels/${id}`);

    return data;
}

export async function deleteCustomLabels(ids: number[]) {
    const { data } = await api.post('/custom-labels/bulkDestroy', { ids });

    return data;
}

export async function getSynonymSuggestions({ words, type, languageId }) {
    const { data } = await api.post('/custom-labels/suggest', {
        words,
        type,
        lang: languageId
    });

    return data;
}

export async function exportCustomLabels(ids?: number[]) {
    const { data } = await api.post('/custom-labels/export', { ids }, {
        responseType: 'blob'
    });
    return data;
}
