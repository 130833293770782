import {RouteConfig} from 'vue-router';
import { handleCallback, signUp } from '@/utils/auth';
import useUserStore from '@/user/store';

const Join = () => import('@/user/components/auth/Join.vue');
const MFA = () => import('@/user/components/auth/MFA.vue');

export default [
    { // Check fo auth0 callback, this will login the user if they come from auth0 after login
        path: '/callback',
        beforeEnter: handleCallback,
        meta: { isAnonymous: true },
    },
    { // Check for signup intent, redirect if needed go on with '/' route if the user is already logged in
        path: '/signup',
        beforeEnter: signUp,
        meta: { isAnonymous: true },
    },
    {
        path: '/invitedmember/:token',
        name: 'join-invited-member',
        component: Join,
        meta: { isAnonymous: true },
    },
    {
        path: '/mfa',
        name: 'mfa',
        component: MFA,
        meta: { isAnonymous: true },
    },
] as RouteConfig[];
