import api from '@/api';

export default {
    async loadActivityFeed({ commit, state }){
        if (state.isFinished) {
            return;
        }
        commit('setLoading', true);
        try {
            const response = await api.getActivities(state.nextUrl);
            try {
                commit('setActivities', [...state.activities, ...response.activities]);
                if (response.next_page_url) {
                    commit('setNextUrl', response.next_page_url);
                } else {
                    commit('setIsFinished', true);
                }
            } finally {
                commit('setLoading', false);
            }
        } catch (e) {
            commit('setLoading', false);
            throw e;
        }
    },
};
