export default {
    files: [],
    name: '',
    id: '',
    datasetId: '',
    source: null,
    analysisSettings: {
        languageId: 2,
        textSplitting: 1,
        accent: true,
        lowercase: true,
        topicRecognition: true,
    },
    customLabels: [],
    originalCustomLabels: [],
    lastAnalysisAt: '',
    activeSheet: '',
    sheets: {},
};
