export const hex2rgb = (hex) => {
    if(hex.length === 7) //hex with 6 characters and #
    {
        return hex.match(/\w\w/g).map(x => parseInt(x, 16));
    }
    else {
        return hex.match(/\w/g).map(x => parseInt(x+x, 16));
    }
};
export const hex2rgba = (hex, alpha = 1) => {
    const [r, g, b] = hex2rgb(hex);
    return `rgba(${r},${g},${b},${alpha})`;
};

// Get the color with pickHex(thx, less.js's mix function!)
// https://lesscss.org/functions/#color-operations-mix
export const pickHex = (color1, color2, ratio) => {
    const w = ratio * 2 - 1;
    const w1 = (w + 1) / 2;
    const w2 = 1 - w1;
    return [Math.round(color1[0] * w1 + color2[0] * w2),
        Math.round(color1[1] * w1 + color2[1] * w2),
        Math.round(color1[2] * w1 + color2[2] * w2)];
};
// Reference: http://jsfiddle.net/vksn3yLL/
export const getHexFromGradientBasedOnValue = (rgbGradient, value, max) => {
    // Color range shows between which 2 gradient color markers is the value
    let colorRange = [];
    for (let i = 0; i < rgbGradient.length; i++) {
        // Second condition is needed for value === max case because it throws an error otherwise
        // While changing < to <= would break the value === min case, so additional check it is
        if (value < rgbGradient[i][0] || value === max && value === rgbGradient[i][0]) {
            colorRange = [i - 1, i];
            break;
        }
    }

    // Get the two closest colors
    const firstColor = rgbGradient[colorRange[0]][1];
    const secondColor = rgbGradient[colorRange[1]][1];

    // Calculate ratio between the two closest colors based on the value and the length of the slider
    const firstColorX = max * (rgbGradient[colorRange[0]][0]/100);
    const secondColorX = max * (rgbGradient[colorRange[1]][0]/100) - firstColorX;
    const sliderX = max * (value / 100) - firstColorX;
    const ratio = sliderX / secondColorX;
    const result = pickHex(secondColor, firstColor, ratio);
    return rgb2hex(result[0], result[1], result[2]);
};

const componentToHex = (c) => parseInt(c).toString(16).length === 1 ? '0' + parseInt(c).toString(16) : parseInt(c).toString(16);
export const rgb2hex = (r, g, b) => '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);

export const rgb2hsl = (r, g, b) => {
    r = r / 255;
    g = g / 255;
    b = b / 255;
    const min = Math.min(r, g, b);
    const max = Math.max(r, g, b);
    const delta = max - min;
    let h;
    let s;

    if (max === min) {
        h = 0;
    } else if (r === max) {
        h = (g - b) / delta;
    } else if (g === max) {
        h = 2 + (b - r) / delta;
    } else if (b === max) {
        h = 4 + (r - g) / delta;
    }

    h = Math.min(h * 60, 360);

    if (h < 0) {
        h += 360;
    }

    const l = (min + max) / 2;

    if (max === min) {
        s = 0;
    } else if (l <= 0.5) {
        s = delta / (max + min);
    } else {
        s = delta / (2 - max - min);
    }

    return [h, s * 100, l * 100];
};
export const hsl2rgb = (h, s ,l) => {
    h = h / 360;
    s = s / 100;
    l = l / 100;
    let t2;
    let t3;
    let val;

    if (s === 0) {
        val = l * 255;
        return [val, val, val];
    }

    if (l < 0.5) {
        t2 = l * (1 + s);
    } else {
        t2 = l + s - l * s;
    }

    const t1 = 2 * l - t2;

    const rgb = [0, 0, 0];
    for (let i = 0; i < 3; i++) {
        t3 = h + 1 / 3 * -(i - 1);
        if (t3 < 0) {
            t3++;
        }

        if (t3 > 1) {
            t3--;
        }

        if (6 * t3 < 1) {
            val = t1 + (t2 - t1) * 6 * t3;
        } else if (2 * t3 < 1) {
            val = t2;
        } else if (3 * t3 < 2) {
            val = t1 + (t2 - t1) * (2 / 3 - t3) * 6;
        } else {
            val = t1;
        }

        rgb[i] = val * 255;
    }

    return rgb;
};

export const lightenHex = (hex, ratio) => {
    const [h, s, l] = rgb2hsl(...hex2rgb(hex));
    const [r, g, b] = hsl2rgb(h, s, Math.max(Math.min(l + (l * ratio), 100), 0));
    return rgb2hex(Math.max(Math.min(r, 255), 0) , Math.max(Math.min(g, 255), 0), Math.max(Math.min(b, 255), 0));
};
export const darkenHex = (hex, ratio) => lightenHex(hex, ratio * -1);

// http://www.w3.org/TR/WCAG20/#relativeluminancedef
export const luminosity = (rgb) => {
    const lum = [];
    for (let i = 0; i < rgb.length; i++) {
        const chan = rgb[i] / 255;
        lum[i] = (chan <= 0.03928) ? chan / 12.92 : Math.pow(((chan + 0.055) / 1.055), 2.4);
    }

    return 0.2126 * lum[0] + 0.7152 * lum[1] + 0.0722 * lum[2];
};

// http://www.w3.org/TR/WCAG20/#contrast-ratiodef
export const contrastForHex = (hex1, hex2) => {
    const lum1 = luminosity(hex2rgb(hex1));
    const lum2 = luminosity(hex2rgb(hex2));

    if (lum1 > lum2) {
        return (lum1 + 0.05) / (lum2 + 0.05);
    }

    return (lum2 + 0.05) / (lum1 + 0.05);
};

// YIQ equation from http://24ways.org/2010/calculating-color-contrast
export const isDark = ([r, g, b]) => {
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq < 168;
};

export const isHexDark = (hex) => {
    if (!hex) return false;

    const rgb = hex2rgb(hex);
    return isDark(rgb);
};

// We assume that the bg and text has high contrast (black and white basically)
// Extremely light means luminosity above 0.5
// If the bg is black and the primary is extremely light, we use the background color (black)
// If the bg is black and the primary is *not* extremely light, we use the text color (white)
// If the bg is white and the primary is extremely light we use the text color (black)
// If the bg is white and the primary is *not* extremely light we use the background color (white)
export const chooseButtonTextColor = (primary, bg, text) => {
    const isBgDark = isDark(hex2rgb(bg));
    const isPrimaryExtremelyHigh  = luminosity(hex2rgb(primary)) > .5;
    if (isBgDark) {
        return isPrimaryExtremelyHigh ? bg : text;
    } else {
        return isPrimaryExtremelyHigh ? text : bg;
    }
};

// Light colours shouldn't be lightened too much or they will become white
export const lightenHexBasedOnLightness = (hex, ratio) => {
    const isColourDark = isDark(hex2rgb(hex));
    if(isColourDark) {
        return lightenHex(hex, ratio);
    } else {
        return lightenHex(hex, ratio/3);
    }
};

export const createColorScale = (hex, length) => {
    if (length === 1) {
        return [hex];
    }

    const [h, s, l] = rgb2hsl(...hex2rgb(hex));
    let maxDeltaL = 90 - l; // it should not reach 100 lightness

    if (maxDeltaL < 0) {
        maxDeltaL = 0; // TODO: instead of lightening the colors, maybe we could darken them?
    }

    const colors = [];

    for (let i = 0; i < length; i++) {
        colors.push(rgb2hex(...hsl2rgb(h, s, l + maxDeltaL / (length - 1) * i )));
    }

    return colors;
};

export function getColorSet(color = '#1cc6ba') {
    return {
        100: lightenHex(color, 0.9),
        200: lightenHex(color, 0.8),
        300: lightenHex(color, 0.6),
        400: lightenHex(color, 0.5),
        500: lightenHex(color, 0.4),
        600: lightenHex(color, 0.2),
        700: color,
        800: darkenHex(color, 0.3),
        900: darkenHex(color, 0.6)
    };
}
