import i18next from 'i18next';

const types = {
    SUBSENTENCE: 1,
    SENTENCE: 2,
    PARAGRAPH: 3,
};

export const getTypeName = (type) => {
    switch (type) {
    case types.SENTENCE:
        return i18next.t('ANALYSIS.TEXT_SPLITTING_SENTENCE', 'Sentence');
    case types.PARAGRAPH:
        return i18next.t('ANALYSIS.TEXT_SPLITTING_PARAGRAPH', 'Paragraph');
    default:
        return i18next.t('ANALYSIS.TEXT_SPLITTING_SUBSENTENCE', 'Subsentence');
    }
};

export default types;
