import api from '@/api';
import useUserStore from '@/user/store';
import { Plan, PlanFeatureUsage, SubscriptionState, AccessGroup, ApiToken } from './types';
import {User} from '@/settings/common/userInterface';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

export default function makeActions(state: SubscriptionState) {
    const actions = {
        setPlan: (plan: Plan) => {
            state.plan = plan;
        },
        setRenewsAt: (setRenewsAt: string|null) => {
            state.renewsAt = setRenewsAt;
        },
        setPlanFeatureUsage: (planFeatureUsages: PlanFeatureUsage[]|null) => {
            state.planFeatureUsages = planFeatureUsages || [];
        },
        setAccountManagerEmail: (accountManagerEmail: string) => {
            state.accountManagerEmail = accountManagerEmail;
        },
        setIsNMITokenSet: (isNMITokenSet: boolean) => {
            state.isNMITokenSet = isNMITokenSet;
        },
        setAccessGroups: (accessGroups: AccessGroup[]) => {
            state.accessGroups = accessGroups;
        },
        setAccessGroup: (groupId, permissionLevel, name) => {
            state.accessGroups =  state.accessGroups.map(g => g.id === groupId ? {...g, name: name, permissionLevel: permissionLevel } : g);
        },
        setUsers: (users: User[]) => {
            state.users =  users.sort((a,b) => a.fullName > b.fullName ? 1 : -1);
        },
        setSensitiveDataSettings: (sensitiveDataSettings) => {
            if(sensitiveDataSettings) {
                state.sensitiveDataSettings =  sensitiveDataSettings;
            }
        },
        setDomains: (domains) => {
            state.domains = domains;
        },
        setTimestamp: (timestamp) => {
            state.timestamp = timestamp;
        },
        setSubscriptionId: (sid) => {
            state.sid = sid;
        },
        setIsActive: (isActive) => {
            state.isActive = isActive;
        },
        setInactivatedAt: (inactivatedAt) => {
            state.inactivatedAt = inactivatedAt;
        },
        logOutUserIfNotSuperAdmin: async () => {
            const { state: user, actions } = useUserStore();
            if (user.isSuperAdmin === false) {
                await actions.logout();
            }
        },
        init: async (force: boolean = true) => {
            // we reload the subscription store once in a minute on route change
            if(!force && state.timestamp && (state.timestamp + 60000) > parseInt(dayjs().format('x'))) {
                return;
            }

            await api.getSubscription().then((subscriptionInfo) => {
                if (subscriptionInfo.isActive === false) {
                    actions.setIsActive(false);
                    actions.setInactivatedAt(subscriptionInfo.inactivatedAt);
                    actions.logOutUserIfNotSuperAdmin();
                }
                actions.setPlan(subscriptionInfo.plan);
                actions.setPlanFeatureUsage(subscriptionInfo.planFeatureUsage);
                actions.setRenewsAt(subscriptionInfo.renewsAt);
                actions.setAccountManagerEmail(subscriptionInfo.accountManagerEmail);
                actions.setIsNMITokenSet(subscriptionInfo.isNMITokenSet);
                actions.setSensitiveDataSettings(subscriptionInfo.sensitiveDataSettings);
                actions.setDomains(subscriptionInfo.domains);
                actions.setSubscriptionId(subscriptionInfo.sid);
            });

            const accessGroupsPromise = api.getAccessGroups().then(groups => actions.setAccessGroups(groups || []));
            const groupUsersPromise = api.getGroupUsers().then(users => actions.setUsers(users || []));
            await Promise.all([accessGroupsPromise, groupUsersPromise, actions.getTokens()]);

            actions.setTimestamp(parseInt(dayjs().format('x')));
        },
        updateAccessGroup: async (groupId, permissionLevel, name) => {
            actions.setAccessGroup(groupId, permissionLevel, name);
            await api.updateAccessGroup(groupId, permissionLevel, name);
        },
        deleteAccessGroup: async (id) => {
            await api.deleteAccessGroup(id);
            await api.getAccessGroups().then(groups => actions.setAccessGroups(groups || []));
        },
        createAccessGroup: async (name, permissionLevel) => {
            await api.createAccessGroup(name, permissionLevel);
            await api.getAccessGroups().then(groups => actions.setAccessGroups(groups || []));
        },
        getUsers: async () => {
            await api.getGroupUsers().then(users => actions.setUsers(users || []));
        },
        updateUser: async (user) => {
            await api.updateGroupUser(user);
            await actions.getUsers();
        },
        deleteUser: async (id) => {
            await api.deleteGroupUser(id);
            await actions.getUsers();
        },
        inviteUser: async (newUser) => {
            return await api.inviteNewMember(newUser);
        },
        deleteInvitedUser: async (id) => {
            await api.deleteInvitedUser(id);
            await actions.getUsers();
        },
        resendInvitationToUser: async (id) => {
            await api.resendInvitationToUser(id);
        },
        assignOwnershipRights: async (previousOwnerId, newOwnerId) => {
            await api.assignOwnershipRights(previousOwnerId, newOwnerId);
        },

        /* Api Tokens */
        setTokens: (tokens: ApiToken[]) => {
            state.apiTokens = tokens;
        },
        getTokens: async () => {
            await api.getTokens().then(tokens => actions.setTokens(tokens || []));
        },
        addNewToken: async (name, writeable) => {
            await api.addToken({ can_write: writeable, name: name });
        },
        deleteToken: async (tokenId) => {
            await api.deleteToken(tokenId);
        },
        /* Data Security*/
        saveDataSecuritySettings: async (settings) => {
            await api.saveDataSecuritySettings(settings);
        }
    };
    return actions;
}
