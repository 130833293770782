import {datasetTypeId} from '@/domain/dataset/datasetTypeId';
import useCommonStore from '@/dashboard/common/store/commonStore';
import useAnalyticsStore from '@/dashboard/analytics/store/store';

const Analytics = () => import('./Analytics.vue');

export default {
    path: ':datasetId/analytics',
    component: Analytics,
    props: true,
    name: 'analytics',
    beforeEnter: async (to, from, next) => {
        if (useCommonStore().state.dataset.type !== datasetTypeId.survey) {
            next({ name: 'overview', params: {datasetId: to.params.datasetId}, query: useCommonStore().state.query });
            return;
        }
        // There is no filtering on analytics tab (yet), so it should only be reloaded if record number not equals with answers
        if (useAnalyticsStore().state.answers < useCommonStore().state.dataset.rows) {
            useAnalyticsStore().actions.load(useCommonStore().state.dataset.rows);
        }
        next({ query: useCommonStore().state.query });
    }
};
