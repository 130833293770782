import { computed } from 'vue';
import * as customLabelsApi from '@/custom-labels/api/custom-labels';
import gtm from '@/utils/gtm';
import excelParser from '@/domain/excel/excelParser';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import PQueue from 'p-queue';

import { PhraseSet, UploadState } from './types';

const columnTypeEnum = {
    DONT_USE: 'dontuse',
    NAME: 'name',
    CODE: 'code',
    CATEGORY: 'category',
    SYNONYMS: 'synonyms',
    EXCLUDES: 'excludes',
    TAGS: 'tags',
};

export const uploadState: UploadState = {
    sheets: {},
    activeSheet: '',
    file: null,
    isUploadInProgress: false,
    completedRows: 0,
    columnTypeEnum,
    languageId: 2,
    hasEmpty: false,
    hasDuplication: false,
};

export const uploadGetters = {
    rowsToUpload: computed(() => {
        if (!uploadState.file) return 0;

        return uploadState.sheets[uploadState.activeSheet].rows - uploadState.sheets[uploadState.activeSheet].headerRows;
    }),
    synonymIndices: computed(() => {
        return uploadState.sheets[uploadState.activeSheet].columnTypes
            .map((t, i) => {
                if (t === columnTypeEnum.SYNONYMS) return i;
                return null;
            })
            .filter(i => Boolean(i)) || [];
    }),
    excludeIndices: computed(() => {
        return uploadState.sheets[uploadState.activeSheet].columnTypes
            .map((t, i) => {
                if (t === columnTypeEnum.EXCLUDES) return i;
                return null;
            })
            .filter(i => Boolean(i)) || [];
    }),
};

const checkForLanguageErrors = () => {
    const languages = uploadState.sheets[uploadState.activeSheet].columnLanguages;

    // Emptiness
    let hasEmpty = false;
    uploadState.sheets[uploadState.activeSheet].columnTypes.forEach((type, index) => {
        if ((type === uploadState.columnTypeEnum.SYNONYMS || type === uploadState.columnTypeEnum.EXCLUDES) && languages[index] === null) {
            hasEmpty = true;
        }
    });
    uploadActions.setHasEmpty(hasEmpty);

    // Duplication
    const synonymLanguages = languages.filter((l, i) => uploadGetters.synonymIndices.value.includes(i));
    const excludeLanguages = languages.filter((l, i) => uploadGetters.excludeIndices.value.includes(i));
    uploadActions.setHasDuplication(new Set(synonymLanguages).size !== synonymLanguages.length || new Set(excludeLanguages).size !== excludeLanguages.length);
};

export const uploadActions = {
    async openExcel(file: File) {
        const excel = await excelParser({ file, guessDimensionTypes: false, limit: null });

        uploadState.activeSheet = excel.activeSheet;
        uploadState.sheets = excel.sheets;
        uploadState.file = file;
        uploadState.isUploadInProgress = false;
        uploadState.completedRows = 0;

        gtm.track(gtm.events.EXCEL_ANALYSIS_INIT, gtm.categories.EXCEL_ANALYSIS_INIT);
    },
    changeUploadLanguageId(id: number) {
        uploadState.languageId = id;
    },
    changeSheet(newSheet: string) {
        uploadState.activeSheet = newSheet;
    },
    updateSheets(newSheets: any) {
        uploadState.sheets = newSheets;
        checkForLanguageErrors();
    },
    setHasEmpty(hasEmpty: boolean) {
        uploadState.hasEmpty = hasEmpty;
    },
    setHasDuplication(hasDuplication: boolean) {
        uploadState.hasDuplication = hasDuplication;
    },
    resetUpload() {
        uploadState.activeSheet = '';
        uploadState.sheets = [];
        uploadState.file = null;
        uploadState.isUploadInProgress = false;
        uploadState.completedRows = 0;
        uploadState.languageId = 2;
        uploadState.hasEmpty = false;
        uploadState.hasDuplication = false;
    },
    async submitUploadForMultilang() {
        uploadState.isUploadInProgress = true;
        const languages: Array<number|null> = uploadState.sheets[uploadState.activeSheet].columnLanguages;
        // @ts-ignore
        const langIds: Array<number> = [...new Set(languages.filter(l => l))];

        const activeSheet = uploadState.sheets[uploadState.activeSheet];
        const nameColumn = activeSheet.columnTypes.indexOf(columnTypeEnum.NAME);
        const codeColumn = activeSheet.columnTypes.indexOf(columnTypeEnum.CODE);
        const categoryColumn = activeSheet.columnTypes.indexOf(columnTypeEnum.CATEGORY);
        const tagsColumn = activeSheet.columnTypes.indexOf(columnTypeEnum.TAGS);
        const synonymsColumns = uploadGetters.synonymIndices.value;
        const excludesColumns = uploadGetters.excludeIndices.value;
        const basePhraseSets: PhraseSet[] = langIds.reduce((phraseSets: PhraseSet[], langId: number) => {
            phraseSets.push({
                'languageId': langId,
                'synonyms': [],
                'excludes': []
            });
            return phraseSets;
        }, []);

        const queue = new PQueue({ concurrency: 1 });
        const headerRows = uploadState.sheets[uploadState.activeSheet].headerRows;
        activeSheet.columnData[nameColumn].forEach((_, i) => {
            const name = activeSheet.columnData[nameColumn][i];
            const code = codeColumn > -1 ? activeSheet.columnData[codeColumn][i] : null;
            const categoryName = categoryColumn > -1 ? activeSheet.columnData[categoryColumn][i] : null;
            const tags = (tagsColumn > -1 && activeSheet.columnData[tagsColumn])[i] ? activeSheet.columnData[tagsColumn][i].split(',') : null;
            let phraseSets: PhraseSet[] = JSON.parse(JSON.stringify(basePhraseSets)); // deep clone array
            languages.forEach((languageId: number|null, index) => {
                if (languageId === null) {
                    return;
                }
                const isSynonymIndex = synonymsColumns.includes(index);
                const isExcludeIndex = excludesColumns.includes(index);
                const phrasesString = activeSheet.columnData[index][i];
                if (isSynonymIndex && (!phrasesString || phrasesString === '')) {
                    // Don't save phrase set without synonyms
                    phraseSets = phraseSets.filter(ps => ps.languageId !== languageId);
                    return;
                }
                // trim phrases and remove empty strings
                const phrases = phrasesString.split(',').map(phrase => phrase.trim()).filter(Boolean) || [];
                // @ts-ignore - remove duplicates
                const uniquePhrases: string[] = [...new Set(phrases)];
                const phraseSet = phraseSets.find(ps => ps.languageId === languageId);
                if (phraseSet) {
                    if (isSynonymIndex) {
                        phraseSet.synonyms = uniquePhrases;
                    }
                    if (isExcludeIndex) {
                        phraseSet.excludes = uniquePhrases;
                    }
                }
            });

            if (!name || !phraseSets || phraseSets.length === 0 || i < headerRows) {
                return;
            }
            queue.add(() => uploadActions.saveCustomLabelFromExcel({ name, code, categoryName, tags, phraseSets }, i));
        });
        return queue.onIdle();
    },
    async saveCustomLabelFromExcel(newCustomLabel, row) {
        try {
            const response = await customLabelsApi.createCustomLabel(newCustomLabel);
            if (response.success === false) {
                toaster.error(i18next.t(
                    'CUSTOM_LABEL.UPLOAD_ERROR_COULD_NOT_SAVE_NEW', {
                        rowNumber: row,
                        defaultValue: 'Something went wrong, could not save custom label at row {{rowNumber}}',
                    }
                ));
            }
        } catch (e: any) {
            if (e.response && e.response.status === 422 && e.response.data.message) {
                toaster.error(e.response.data.message);
            }
            else {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
            }
            Sentry.captureException(e);
        }
        uploadState.completedRows += 1;
    }
};
