<template>
    <div>
        <router-view />
        <user-store-debug />
        <subscription-store-debug />
        <loading-screen-container />
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import UserStoreDebug from '@/user/components/UserStoreDebug.vue';
import SubscriptionStoreDebug from '@/subscription/components/SubscriptionStoreDebug.vue';
import LoadingScreenContainer from '@/utils/loading/LoadingScreenContainer.vue';

export default defineComponent({
    components: {
        UserStoreDebug,
        SubscriptionStoreDebug,
        LoadingScreenContainer,
    },
});
</script>
