<template>
    <scrollbar
        class="p-10 w-full"
    >
        <div
            class="excel-settings-wrapper text-black w-full mx-auto my-2.5"
            data-test-id="excel-analysis"
        >
            <h2 class="truncate">
                {{ $t('ANALYSIS.DATA_FORM_TITLE') }} - {{ name }}
            </h2>
            <excel-settings
                ref="excelSettings"
                :initial-active-sheet-name="initialActiveSheet"
                :initial-sheets="initialSheets"
                :possible-column-types="excelColumnTypeNames"
                :primary-column-type="dimensionTypeEnum.VERBATIM"
                :is-column-name-editable="true"
                :are-types-unique="false"
                :is-csv="isCsv"
                :is-verbatim-column-disabled="isVerbatimColumnChangeDisabled"
                @sheetsChanged="handleSheetChange()"
                @delimiterChanged="reparseCsv($event)"
            />
            <new-analysis-settings
                :name="name"
                :language-id="languageId"
                :is-accent-replace-active="isAccentReplaceActive"
                :is-lowercase-active="isLowercaseActive"
                :is-topic-recognition-active="isTopicRecognitionActive"
                :text-splitting-type="textSplittingType"
                :is-translation-active="datasetTranslation"
                :is-translation-with-html-active="datasetTranslationWithHtml"
                :is-input-translation-active="isInputTranslationActive"
                :is-excel-reanalysis="isReanalysis"
                :is-edit="isReanalysis"
                :is-reanalysis="isReanalysis"
                @inputTranslationUpdated="updateInputTranslation($event)"
                @nameUpdated="updateName($event)"
                @languageUpdated="updateLanguage($event)"
                @accentUpdated="updateAccent($event)"
                @lowercaseUpdated="updateLowercase($event)"
                @topicRecognitionUpdated="updateTopicRecognition($event)"
                @textSplittingUpdated="updateTextSplittingType($event)"
                @translationUpdated="updateDatasetTranslation($event)"
                @translationWithHtmlUpdated="updateDatasetTranslationWithHtml($event)"
                @forceReanalysisUpdated="isForceReanalysisActive = $event"
            />
            <custom-label-settings
                v-if="isCustomLabelEnabled"
                v-model="selectedCustomLabelIds"
                :language-id="languageId"
            />
            <div class="my-4 text-center">
                <z-button
                    data-test-id="submit-excel"
                    :disabled="buttonDisabled"
                    @click="submitButtonClicked"
                >
                    {{ $t('BUTTONS.ANALIZE') }}
                </z-button>
            </div>
        </div>
    </scrollbar>
</template>

<script lang="ts">
import api from '@/api';
import gtm from '@/utils/gtm';
import confirmPopup from '@/utils/confirmPopup';

import Scrollbar from '@/components/ui/Scrollbar.vue';
import planFeatureTypeEnum from '../../domain/planFeatureTypeEnum';
import ExcelSettings from '@/components/common/ExcelSettings/ExcelSettings.vue';
import dimensionTypeEnum from '@/domain/dimensionTypeEnum';
import CustomLabelSettings from '@/custom-labels/components/CustomLabelEditor/CustomLabelSettingsPanel.vue';
import NewAnalysisSettings from '@/datasets/components/AnalysisSettings.vue';

import { getDisplayName } from '@/domain/analysisLanguageEnum';
import { setLoading } from '@/utils/loading/';
import { computed, ref, defineComponent } from 'vue';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';

import useSubscriptionStore from '@/subscription/store';
import useCustomLabelStore from '@/custom-labels/store';
import { customLabelReanalyses } from '@/dashboard/api/datasetApi';
import useExcelAnalysisStore, { CsvDelimiterEnum } from '@/excel-analysis/store';
import { useRouter } from 'vue-router/composables';

import confirm from '@/utils/confirmPopup';
import isEqual from 'lodash/isEqual';

export default defineComponent({
    components: {
        NewAnalysisSettings,
        CustomLabelSettings,
        ExcelSettings,
        Scrollbar,
    },
    props: {
        file: { type: File, default: null },
        initialName: { type: String, default: '' },
        initialActiveSheet: { type: String, default: null },
        initialSheets: { type: Object, default: () => ({}) },
        initialAccentReplace: { type: Boolean, default: true },
        initialLowercase: { type: Boolean, default: true },
        initialTopicRecognition: { type: Boolean, default: true },
        initialTranslation: { type: Boolean, default: false },
        initialTranslationWithHtml: { type: Boolean, default: false },
        initialInputTranslation: { type: Boolean, default: false },
        initialTextSplittingType: { type: Number, default: 1 },
        initialKeywordIds: { type: Array as () => Array<number>, default: () => ([]) },
        plan: { type: Object, default: () => ({
            quota: 1500,
            currency: 'HUF',
        }) },
        isReanalysis: { type: Boolean, default: false },
        reanalysisId: { type: Number, default: null },
        accountManagerEmail: { type: String, default: '' },
        datasetId: { type: String, default: null },
        lastAnalysisAt: { type: String, default: null },
        canUseUniqueId: { type: Boolean, default: false },
    },
    setup(props) {
        const router = useRouter();
        const excelSettings = ref();

        /* Stores */
        const { canUseFeature, featureQuotaLimit, featureUsedQuota, remainingDatasetTranslationQuota } = useSubscriptionStore().getters;
        const isCustomLabelEnabled = computed(() => canUseFeature.value(planFeatureTypeEnum.CUSTOM_LABEL));
        const { checkPhraseSets, getUpdatedClsSinceLastAnalysis } = useCustomLabelStore().getters;
        const { reanalysisSettings } = useExcelAnalysisStore().state;
        const { actions: excelAnalysisActions } = useExcelAnalysisStore();

        /* Quota handling */
        const showWarningPopup = ref(false);
        const quotaLimit = featureQuotaLimit(planFeatureTypeEnum.FILE_UPLOAD_VERBATIM);
        const usedQuota = featureUsedQuota(planFeatureTypeEnum.FILE_UPLOAD_VERBATIM);

        const isUrlColumnValid = ref(true);
        const allTextUnits = ref(0);

        /* Semantic settings */
        const isAccentReplaceActive = ref(props.initialAccentReplace);
        const isLowercaseActive = ref(props.initialLowercase);
        const isTopicRecognitionActive = ref(props.initialTopicRecognition);
        const languageId = ref(Object.values(props.initialSheets)[0] && Object.values(props.initialSheets)[0].languageId ? Object.values(props.initialSheets)[0].languageId : 1);
        const initialLanguageId = ref(Object.values(props.initialSheets)[0] ? Object.values(props.initialSheets)[0].languageId : 1);
        const textSplittingType = ref(props.initialTextSplittingType);
        const isInputTranslationActive = ref<boolean>(props.initialInputTranslation);

        const isAccentChanged = computed(() => isAccentReplaceActive.value !== props.initialAccentReplace);
        const isLowercaseChanged = computed(() => isLowercaseActive.value !== props.initialLowercase);
        const isTopicRecognitionChanged = computed(() => isTopicRecognitionActive.value !== props.initialTopicRecognition);
        const isTextSplittingChanged = computed(() => textSplittingType.value !== props.initialTextSplittingType);
        const isLanguageChanged = computed(() => initialLanguageId.value !== languageId.value);
        const isVerbatimColumnChangeDisabled = computed(() => isInputTranslationActive.value && props.isReanalysis);

        const isSemanticSettingsForFullReanalysisChanged = computed(() =>
            (isAccentChanged.value || isLowercaseChanged.value || isTopicRecognitionChanged.value || isTextSplittingChanged.value || isLanguageChanged.value)
        );

        const updateLanguage = (newLanguage) => languageId.value = parseInt(newLanguage);
        const getLanguageName = (language) => getDisplayName(parseInt(language));
        const isLangSettingsOpen = ref(false);
        const updateAccent = (newAccent) => isAccentReplaceActive.value = newAccent;
        const updateTopicRecognition = (newTR) => isTopicRecognitionActive.value = newTR;
        const updateLowercase = (newLowerCase) => isLowercaseActive.value = newLowerCase;
        const updateTextSplittingType = (newTextSplittingType) =>  textSplittingType.value = newTextSplittingType;

        /* Custom labels */
        const selectedCustomLabelIds = ref(props.initialKeywordIds);
        const customLabelsChanged = computed(() =>
            (JSON.stringify(selectedCustomLabelIds.value) !== JSON.stringify(props.initialKeywordIds) || modifiedCls.value.length > 0)
        );
        const modifiedCls = ref<number[]>([]);
        const isKeywordsOpen = ref(false);

        const shouldStartClReAnalysis = computed(() => {
            if (props.isReanalysis) {
                checkIfOriginalClsWereModifiedOutsideOfScope();
                return !isForceReanalysisActive.value &&
                    !!(
                        customLabelsChanged.value &&
                        !isDatasetTranslationChanged.value &&
                        !isDatasetTranslationWithHtmlChanged.value &&
                        !isSemanticSettingsForFullReanalysisChanged.value &&
                        !sheetsChanged.value &&
                        !isHeaderChanged.value
                    );
            }
            return false;
        });

        const checkIfOriginalClsWereModifiedOutsideOfScope = () => {
            const updatedCls = getUpdatedClsSinceLastAnalysis(selectedCustomLabelIds.value, props.lastAnalysisAt).value;
            if (updatedCls.length > 0) {
                modifiedCls.value = [...new Set([...modifiedCls.value, ...updatedCls])];
            }
        };

        /* Translation */
        const datasetTranslation = ref(props.initialTranslation || false);
        const datasetTranslationWithHtml = ref(props.initialTranslationWithHtml || false);
        const updateDatasetTranslation = (isTranslationActive) => datasetTranslation.value = isTranslationActive;
        const updateDatasetTranslationWithHtml = (isTranslationWithHtmlActive) => datasetTranslationWithHtml.value = isTranslationWithHtmlActive;
        const updateInputTranslation = (isInputTranslationOn: boolean) => isInputTranslationActive.value = isInputTranslationOn;

        const isDatasetTranslationChanged = computed(() => datasetTranslation.value !== props.initialTranslation);
        const isDatasetTranslationWithHtmlChanged = computed(() => datasetTranslationWithHtml.value !== props.initialTranslationWithHtml);

        /* Other */

        const name = ref(props.initialName);
        const updateName = (newName) => name.value = newName;
        const isNameChanged = computed(() => props.initialName !== name.value);

        const activeSheet = computed(() => excelSettings.value?.sheets[excelSettings.value.activeSheetName] || {});

        const sheetsChanged = ref(false);

        const isHeaderChanged = computed(() => activeSheet.value.headerRows !== props.initialSheets.sheet.headerRows);

        const columnTypeChanged = computed(() => !isEqual(props.initialSheets.sheet.columnTypes, activeSheet.value.columnTypes));

        const isForceReanalysisActive = ref(false);

        const dimensions = computed(() => reanalysisSettings?.dimensions);

        const isCsv = computed(() => props.file ? props.file.type === 'text/csv' : false);

        const delimiter = ref(CsvDelimiterEnum.SEMICOLON);

        const buttonDisabled = computed(() => {
            checkIfOriginalClsWereModifiedOutsideOfScope();
            return !checkPhraseSets(selectedCustomLabelIds.value, languageId.value).value ||
                (props.isReanalysis && !isSemanticSettingsForFullReanalysisChanged.value && !isDatasetTranslationChanged.value && !isDatasetTranslationWithHtmlChanged.value && !customLabelsChanged.value && !isForceReanalysisActive.value && !isNameChanged.value && !sheetsChanged.value) ||
                !isUrlColumnValid.value ||
                (quotaLimit.value != null && (quotaLimit.value - usedQuota.value === 0)) ||
                remainingDatasetTranslationQuota.value === 0 && isInputTranslationActive.value;
        });

        const excelColumnTypeNames = computed(() => [
            { value: dimensionTypeEnum.DONT_USE, name: i18next.t('ANALYSIS.COLUMN_OPTION1') },
            { value: dimensionTypeEnum.VERBATIM, name: i18next.t('ANALYSIS.COLUMN_OPTION2') },
            { value: dimensionTypeEnum.ID, name: i18next.t('ANALYSIS.COLUMN_OPTION3') },
            { value: dimensionTypeEnum.URL, name: i18next.t('ANALYSIS.COLUMN_OPTION12', 'URL') },
            { value: dimensionTypeEnum.DATE, name: i18next.t('ANALYSIS.COLUMN_OPTION4') },
            { value: dimensionTypeEnum.CATEGORY, name: i18next.t('ANALYSIS.COLUMN_OPTION6') },
            { value: dimensionTypeEnum.NUMBER, name: i18next.t('ANALYSIS.COLUMN_OPTION8') },
            { value: dimensionTypeEnum.NPS, name: i18next.t('ANALYSIS.COLUMN_OPTION10', 'NPS') },
            { value: dimensionTypeEnum.CSAT, name: i18next.t('ANALYSIS.COLUMN_OPTION11', 'CSAT') },
            { value: dimensionTypeEnum.CES_5_SCALE, name: i18next.t('ANALYSIS.COLUMN_OPTION13', 'CES - 5 scale') },
            { value: dimensionTypeEnum.CES_7_SCALE, name: i18next.t('ANALYSIS.COLUMN_OPTION14', 'CES - 7 scale') },
            { value: dimensionTypeEnum.NON_UNIQUE_ID, name: i18next.t('ANALYSIS.COLUMN_OPTION15', 'Individual marker') },
            { value: dimensionTypeEnum.DELETE, name: i18next.t('ANALYSIS.COLUMN_OPTION9') },
        ]);

        /* Methods */
        const submitButtonClicked = async () => {

            const verbatimColumns = activeSheet.value.columnTypes
                .map(ct => parseInt(ct))
                .filter(type => type === dimensionTypeEnum.VERBATIM)
                .length;
            allTextUnits.value = verbatimColumns * activeSheet.value.rows;

            if (!props.isReanalysis && quotaLimit.value != null && (usedQuota.value + allTextUnits.value) >= quotaLimit.value * 0.8) {
                const confirmed = await confirm.default(
                    i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_QUOTA_POPUP_TITLE', 'Warning: Insufficient Text Analysis Quota'),
                    insufficientQuotaWarningPopupText.value,
                    i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_QUOTA_POPUP_BUTTON', 'Proceed anyway'),
                    null,
                    true
                );
                if (!confirmed) {
                    return;
                }
            }

            if (isInputTranslationActive.value && !props.isReanalysis) {
                const confirmed = await confirm.default(
                    i18next.t('NMI_INTEGRATION.INPUT_DATA_TRANSLATION_POPUP_TITLE', 'Input data translation - Important Considerations'),
                    inputTranslationWarningPopupText.value,
                    i18next.t('ANALYSIS.TRANSLATE_DATASET_POPUP_SAVE', 'Proceed with upload'),
                    null,
                    true
                );
                if (!confirmed) {
                    return;
                }
            }

            await startAnalysis();
        };

        const insufficientQuotaWarningPopupText = computed(() =>
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_FIRST','Based on our estimation (number of rows multiplied by the number of verbatim columns in your file), it appears that you may not have sufficient text analysis quota to complete this analysis.') +
            '<br>' +
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_SECOND', 'If you proceed, the analysis process will halt once your quota is depleted and will remain incomplete until additional quota becomes available. Analysis will resume automatically when text analysis quota is replenished, without requiring manual intervention.') +
            '<br>' +
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_THIRD', 'Please consider upgrading your quota or reducing the size of your dataset to ensure uninterrupted analysis.')
        );

        const inputTranslationWarningPopupText = computed(() =>
            i18next.t('ANALYSIS.TRANSLATE_DATASET_POPUP_TEXT','Before proceeding with uploading your file with the Input Data Translation function activated, please carefully consider the following important points:') +
            '<br><br><ul class="list-disc pl-4">' +
            '<li>' + i18next.t('ANALYSIS.TRANSLATE_DATASET_POPUP_LIST_FIRST', 'Ensure that you have correctly set up the verbatim column and analysis language, as these settings cannot be modified later.') + '</li>' +
            '<li>' + i18next.t('NMI_INTEGRATION.TRANSLATE_DATASET_POPUP_LIST_SECOND', 'Once activated, the Input Data Translation feature cannot be deactivated. ') + '</li>' +
            '<li>' + i18next.t('NMI_INTEGRATION.TRANSLATE_DATASET_POPUP_LIST_THIRD', 'If translation quota is exhausted, data processing will be paused until sufficient quota becomes available.') + '</li></ul>'
        );

        const startFirstAnalysis = async (columns, shouldDownSize = false) => {
            if (activeSheet.value.rows - activeSheet.value.headerRows === 0) {
                toaster.error(i18next.t('ANALYSIS.NO_ROWS_ERROR', 'Please select a sheet with more than 0 body rows.'));
                setLoading(false);
                return;
            }
            const dateColumns = columns.filter((c) => c.type === dimensionTypeEnum.DATE).map(d => ({ ...d, columnNumber: columns.findIndex(c => c.name === d.name) + 1 }) );
            const parsingError = await api.checkIfFileParseable(
                {
                    sheetName: excelSettings.value.activeSheetName,
                    file: props.file,
                    dateColumns: dateColumns,
                    columnCount: columns.length,
                    delimiter: isCsv.value ? delimiter.value : null
                }
            );
            if (parsingError.errorType) {
                setLoading(false);
                if (parsingError.errorType === 'rows') {

                    await confirmPopup.notification(
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_TITLE', 'Upload Unsuccessful'),
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_WARNING', 'We couldn\'t process your file due to incompatible encoding, formatting issues, or special characters. Please ensure your data is clean and try uploading again.'),
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_PRIMARY_BUTTON', 'Got it, back to the home screen'),
                        null,
                        true
                    );

                    await router.replace('/');

                } else if (parsingError.errorType === 'dateColumns') {

                    const isConfirmed = await confirmPopup.default(
                        i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_TITLE', 'Data Format Warning - Date dimension'),
                        i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_WARNING', 'It appears that there is data in your file tagged as \'date\' type which our system cannot parse. This means that if you proceed with the upload, the date data won\'t be filterable on the dashboard, and time series visualizations won\'t be available. Please ensure your data is formatted to fit our accepted ') + ' ' + '<a target="_blank" class="underline" href="https://neticle.com/knowledge-base/zurvey-dimension-types#date-dimension">' + i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_WARNING_ENDING', 'formats') + '</a>.',
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_PRIMARY_BUTTON', 'Got it, back to the home screen'),
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_SECONDARY_BUTTON', 'Proceed with upload'),
                        true
                    );

                    if (isConfirmed) {
                        await router.replace('/');
                    } else {
                        setLoading(true);
                        await analyzeExcel(columns, shouldDownSize);
                    }
                } else if (parsingError.errorType === 'unreadableExcel') {

                    await confirmPopup.notification(
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_TITLE', 'Upload Unsuccessful'),
                        i18next.t('EXCEL_ANALYSIS.PARSING_TRY_CSV', 'We couldn\'t process your file due to unreadable content. Try converting your file to CSV and upload it again.'),
                        i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_PRIMARY_BUTTON', 'Got it, back to the home screen'),
                        null,
                        true
                    );

                    await router.replace('/');

                }
            } else {
                await analyzeExcel(columns, shouldDownSize);
            }
        };

        async function analyzeExcel(columns, shouldDownSize) {
            await api.analyzeExcel({
                sheetName: excelSettings.value.activeSheetName,
                headerRows: activeSheet.value.headerRows,
                rows: activeSheet.value.rows,
                columns: columns,
                accent: isAccentReplaceActive.value,
                lowercase: isLowercaseActive.value,
                topicRecognition: isTopicRecognitionActive.value,
                textSplitting: textSplittingType.value,
                autoTranslation: datasetTranslation.value,
                autoTranslationWithHtml: datasetTranslationWithHtml.value,
                inputTranslation: isInputTranslationActive.value,
                name: name.value,
                languageId: parseInt(languageId.value),
                customLabelIds: selectedCustomLabelIds.value,
                file: props.file,
                shouldDownSize: shouldDownSize,
                delimiter: isCsv.value ? delimiter.value : null
            });
            gtm.track(gtm.events.EXCEL_ANALYSIS_STARTED, gtm.categories.EXCEL_ANALYSIS_STARTED, {
                rows: activeSheet.value.rows,
                columns: columns.length,
                language: languageId.value,
                hasCustomLabels: selectedCustomLabelIds.value.length > 0,
                shouldDownSize: shouldDownSize,
            });
            router.replace({ name: 'finished-page' });
        }

        const startFullExcelReanalysis = async (columns, showWarningPopup): Promise<boolean> => {
            setLoading(false);

            if (showWarningPopup) {
                const confirmed = await confirmPopup.destruction(
                    i18next.t('HOME.REANALYSIS_COLUMN_OR_HEADER_CHANGE_WARNING_TITLE', 'Columns or header are changed'),
                    i18next.t('HOME.REANALYSIS_COLUMN_OR_HEADER_CHANGE_WARNING', 'Reanalyzing your dataset with modified column dimensions or header will overwrite all manual changes and fine-tunes you previously made on your data. Including: manual sentiment changes on verbatim and labels, label removals, manual assignment of custom labels and deletion of records.'),
                    null,
                    null,
                    true
                );
                if (!confirmed) {
                    return false;
                }
            }

            setLoading(true);
            await api.reanalyzeExcel({
                dataSourceId: props.reanalysisId,
                headerRows: activeSheet.value.headerRows,
                accent: isAccentReplaceActive.value,
                lowercase: isLowercaseActive.value,
                topicRecognition: isTopicRecognitionActive.value,
                textSplitting: textSplittingType.value,
                autoTranslation: datasetTranslation.value,
                autoTranslationWithHtml: datasetTranslationWithHtml.value,
                name: name.value,
                languageId: parseInt(languageId.value),
                keywordIds: selectedCustomLabelIds.value,
                columns,
            });
            gtm.track(gtm.events.EXCEL_REANALYSIS_STARTED, gtm.categories.EXCEL_REANALYSIS_STARTED, {
                dataSourceId: props.reanalysisId,
            });
            return true;
        };

        const saveDatasetChanges = async () => {
            await api.saveDataset(props.datasetId, {
                name: name.value,
                languageId: parseInt(languageId.value),
                accent: isAccentReplaceActive.value,
                lowercase: isLowercaseActive.value,
                topicRecognition: isTopicRecognitionActive.value,
                textSplitting: textSplittingType.value,
                auto_translation: datasetTranslation.value,
                auto_translation_with_html: datasetTranslationWithHtml.value,
                customLabelIds: selectedCustomLabelIds.value,
            });
        };

        const startClReanalysis = async () => {
            let newCls = selectedCustomLabelIds.value.filter(ki => props.initialKeywordIds.findIndex(ik => ki === ik) === -1);
            let updatedCls = [...new Set([...modifiedCls.value, ...newCls])];
            let removedCls = props.initialKeywordIds.filter(ki => selectedCustomLabelIds.value.findIndex(ik => ki === ik) === -1);
            for (const i in activeSheet.value.columnTypes) {
                if (activeSheet.value.columnTypes[i] === dimensionTypeEnum.VERBATIM) {
                    await customLabelReanalyses(props.datasetId, dimensions.value[i].source_dimension_id, updatedCls, removedCls);
                }
            }
        };

        const startAnalysis = async (shouldDownSize = false) => {
            if (activeSheet.value.columnTypes.every(t => parseInt(t) !== dimensionTypeEnum.VERBATIM)) {
                toaster.error(i18next.t('ANALYSIS.NO_VERBATIM_ERROR', 'Please select at least one column for text analysis!'));
                return;
            }
            const columns: any[] = [];
            activeSheet.value.columnNames.forEach((name, index) => {
                columns[index] = {
                    name: name,
                    type: parseInt(activeSheet.value.columnTypes[index]),
                };
            });
            try {
                setLoading(true);
                if (!props.isReanalysis) {
                    await startFirstAnalysis(columns, shouldDownSize);
                } else {
                    if (shouldStartClReAnalysis.value) {
                        await saveDatasetChanges();
                        await startClReanalysis();
                    } else if (sheetsChanged.value && (columnTypeChanged.value || isHeaderChanged.value)) {
                        const confirmed = await startFullExcelReanalysis(columns, (columnTypeChanged.value || isHeaderChanged.value));
                        if (!confirmed) return;
                    } else if (isSemanticSettingsForFullReanalysisChanged.value || isForceReanalysisActive.value) {
                        await saveDatasetChanges();
                        await api.reanalyzeDataset(props.datasetId);
                    } else if (datasetTranslation.value && isDatasetTranslationChanged.value || isDatasetTranslationWithHtmlChanged.value) {
                        await api.translateDatasetThroughReanalysis(props.datasetId, datasetTranslation.value, null, datasetTranslationWithHtml.value);
                    } else {
                        await saveDatasetChanges();
                    }
                    router.replace({ name: 'finished-page' });
                }

            } catch (e: any) {
                if (e.response && e.response.status === 402) {
                    toaster.error(i18next.t('ANALYSIS.QUOTA_USAGE_EXCEED_QUOTA_TEXT'));
                } else if (e.response && e.response.status === 400) {
                    toaster.error(i18next.t('ANALYSIS.UNREADABLE_FILE_FORMAT', 'The system is unable to process the file, try converting it to an xlsx file and upload it again.'));
                } else {
                    Sentry.captureException(e);
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                }
            } finally {
                setLoading(false);
            }
        };

        function handleSheetChange() {
            sheetsChanged.value = true;
        }

        async function reparseCsv(separator) {
            delimiter.value = separator;
            setLoading(true);
            await excelAnalysisActions.reparseCsv(delimiter.value, props.file);
            setLoading(false);
        }

        return {
            excelSettings,
            isCustomLabelEnabled, quotaLimit,
            isAccentReplaceActive, isLowercaseActive, isTopicRecognitionActive, textSplittingType, selectedCustomLabelIds, isForceReanalysisActive,
            showWarningPopup, languageId,
            customLabelsChanged, isNameChanged, name, sheetsChanged,
            updateDatasetTranslation, datasetTranslation, datasetTranslationWithHtml, updateDatasetTranslationWithHtml,
            usedQuota, allTextUnits,
            buttonDisabled, excelColumnTypeNames, dimensionTypeEnum, isLangSettingsOpen, isKeywordsOpen,
            getLanguageName, updateName, updateLanguage, updateAccent, updateTopicRecognition, updateLowercase, updateTextSplittingType,
            submitButtonClicked, startAnalysis, handleSheetChange, isCsv, delimiter, reparseCsv,
            isInputTranslationActive, updateInputTranslation, isVerbatimColumnChangeDisabled
        };
    }
});
</script>

<style lang="less" scoped>
    .excel-settings-wrapper {
        max-width: 1000px;

        h2 {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 700;

            &:not(:first-of-type) {
                margin-top: 15px;
            }
        }

        .quota-usage {
            position: absolute;
            width: 400px;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            top: calc(50% - 250px);
            left: 0;
            right: 0;
            margin: 0 auto;
            padding: 10px 15px;
            z-index: 1000;
        }
    }
</style>
