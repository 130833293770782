<template>
    <span
        class="tag space-x-1 whitespace-no-wrap"
        :style="style"
        @mouseenter="hovered = true"
        @mouseleave="hovered = false"
        @click="$emit('click', $event)"
    >
        <span>
            <slot />
        </span>
        <z-icon
            v-if="deletable"
            icon="times"
            type="fal"
            class="text-large -my-1"
            data-test-id="close-icon"
            @click.stop="$emit('remove', $event)"
        />
        <z-icon
            v-else-if="addable"
            icon="plus"
            type="fal"
            class="text-large -my-1"
            data-test-id="plus-icon"
        />
    </span>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { isHexDark, getColorSet } from '@/utils/color';
import ZIcon from '@/components/ui/Icon.vue';
import useUserStore from '@/user/store';

export default defineComponent({
    components: { ZIcon },
    props: {
        addable: { type: Boolean, default: false },
        deletable: { type: Boolean, default: false },
        backgroundColor: { type: String, default: null }
    },
    setup(props) {
        const { state } = useUserStore();

        const hovered = ref(false);

        const backgroundColor = computed(() => {
            if (props.backgroundColor) {
                const set = getColorSet(props.backgroundColor);
                return hovered.value ? set[800]: set[700];
            }

            if (props.deletable) {
                const set = getColorSet(state.primaryColor);
                return hovered.value ? set[800] : set[700];
            }

            const set = getColorSet('#9fa7bc');

            return hovered.value ? set[600] : set[500];
        });

        const color = computed(() => {
            return isHexDark(backgroundColor.value) ? 'var(--color-white)' : 'var(--color-black)';
        });

        const style = computed(() => ({
            backgroundColor: backgroundColor.value,
            color: color.value
        }));

        return { hovered, style };
    },
});
</script>


<style scoped>
.tag {
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 12px;
    cursor: pointer;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
}
</style>
