import { produce } from 'immer';

const nullifyDropdownOptions = (translations, guids: string[] = [], type: string = 'onlyOptions') => produce(translations, (draft) => {
    Object.values(draft).forEach((nickname: any) => {
        nickname.questions
            .filter(question => guids.includes(question.guid))
            .forEach(question => {
                if (type === 'onlyOptions' || type === 'both') {
                    question.options = null;
                }

                if (type === 'onlyIds' || type === 'both') {
                    // during publish, draft options ids should not be copied
                    question.options_id = null;
                }
            });
    });
});

export default nullifyDropdownOptions;
