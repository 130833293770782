<template>
    <confirmation-question
        v-if="isVisible"
        data-test-id="confirmation-popup"
        :title="title"
        :body="body"
        :type="type"
        @cancel="onCancel"
        @ok="onOk"
    />
</template>

<script>
import ConfirmationQuestion from '@/components/ui/ConfirmationQuestion';
export default {
    components: {ConfirmationQuestion},
    props: {
        isVisible: { type: Boolean, default: false },
        callback: { type: Function, default: () => {}},
        title: { type: String, default: null},
        body: { type: String, default: null},
        type: { type: String, default: 'default'},
    },
    methods: {
        onCancel() {
            if (typeof this.callback === 'function') {
                this.callback(false);
            }
        },
        onOk() {
            if (typeof this.callback === 'function') {
                this.callback(true);
            }
        },
    },
};
</script>
