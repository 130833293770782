<template>
    <append
        v-if="appendedDataSourceId"
        :data-source-id="appendedDataSourceId"
        :account-manager-email="accountManagerEmail"
        class="h-full max-w-screen-xl mx-auto"
    />
</template>

<script lang="ts">
import { defineComponent, onUnmounted, toRefs } from 'vue';
import Append from '../components/AppendExcel/AppendExcel.vue';
import useExcelAnalysisStore from '@/excel-analysis/store';
import useSubscriptionStore from '@/subscription/store';
import { useRouter } from 'vue-router/composables';

export default defineComponent({
    components: {
        Append
    },
    setup() {
        const router = useRouter();
        const { state, actions } = useExcelAnalysisStore();
        const { appendedDataSourceId } = toRefs(state);

        const { accountManagerEmail, plan } = toRefs(useSubscriptionStore().state);

        if (!appendedDataSourceId.value) {
            Sentry.addBreadcrumb({ message: 'Redirected, because no appendedDataSourceId was given' });
            router.replace('/');
        }

        onUnmounted(() => {
            actions.resetAppendedDataSourceId();
        });

        return {
            appendedDataSourceId,
            accountManagerEmail,
            plan,
        };
    },
});
</script>
