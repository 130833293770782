import groupSettings from './modules/group_settings';
import groupStats from './modules/group_stats';
import activityFeed from './modules/activity_feed';
import announcements from './modules/announcements';

export default {
    namespaced: true,
    modules: {
        groupSettings,
        groupStats,
        activityFeed,
        announcements,
    }
};