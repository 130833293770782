module.exports = (api) => {
    return {
        async getFeatures() {
            if (typeof window !== 'undefined' && window.FEATURES) {
                return window.FEATURES;
            }
            const { data } = await api.get('/getFeatures');
            return data;
        },
    };
};
