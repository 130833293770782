<template>
    <div class="upload-wrapper">
        <z-button
            data-test-id="upload-excel-to-append-button"
            class="float-none m-0"
            @click="openDialog"
        >
            {{ $t('ANALYSIS.ANALYSIS_UPLOAD_BUTTON') }}
        </z-button>
        <div class="max-file-size-message text-neutral-800">
            {{ $t('ANALYSIS.UPLOAD_FILE_SIZE', 'Analyze any .xls, .xlsx, .ods files up to 20 MB or .csv files with semicolon, comma and tab delimiter up to 100MB.') }}
        </div>
        <input
            ref="file"
            type="file"
            accept=".xls, .xlsx, .csv, .ods"
            style="display: none;"
            data-test-id="upload-excel-to-append-file-input"
            @change="uploadFile()"
        >
        <file-size-warning-popup
            v-if="showWarningPopup"
            :format="fileFormat"
            class="text-left"
            @close="reset"
        />
    </div>
</template>

<script lang="ts">
import api from '@/api';
import { setLoading } from '@/utils/loading';
import { ref, defineComponent } from 'vue';
import FileSizeWarningPopup from '@/components/ui/FileSizeWarningPopup.vue';
import i18next from 'i18next';
import BusinessError from '@/domain/errors/BusinessError';
import { toaster } from '@/utils/toaster';
import useExcelAnalysisStore from '@/excel-analysis/store';
import { useRouter } from 'vue-router/composables';


export default defineComponent({
    components: { FileSizeWarningPopup,  },
    props: {
        dataSourceId: { type: Number, default: null },
    },
    setup(props, context) {
        const router = useRouter();

        const file = ref(null);
        const showWarningPopup = ref(false);
        const fileFormat = ref<string>();
        const { actions: excelAnalysisActions } = useExcelAnalysisStore();
        function openDialog() {
            // @ts-ignore
            file.value.click();
        }

        async function uploadFile() {
            setLoading(true);
            try {
                // @ts-ignore
                const fileToUpload = file.value.files[0];
                const isCSV = fileToUpload.name.slice(-3) === 'csv';
                const isExcel = fileToUpload.name.slice(-3) === 'xls' || fileToUpload.name.slice(-4) === 'xlsx';
                const isOds = fileToUpload.name.slice(-3) === 'ods';
                if ( isExcel || isCSV || isOds ) {
                    // 1024 bytes = 1KB, 1024KB = 1MB
                    if (isCSV && (fileToUpload.size / 1024 / 1024) > 100) { // CSVs can be 100MB in size
                        showWarningPopup.value = true;
                        fileFormat.value = 'CSV';
                    } else if ((isExcel || isOds) && (fileToUpload.size / 1024 / 1024) > 20) { // Excels and ODS files can be 20MB in size
                        showWarningPopup.value = true;
                        fileFormat.value = isExcel ? 'Excel' : 'ODS';
                    } else {
                        const originalData = await api.uploadExcelToAppend( { file: fileToUpload, dataSourceId: props.dataSourceId });
                        const fileData = await excelAnalysisActions.uploadExcel(fileToUpload, 10);
                        const data = [];
                        data['fileName'] = originalData['fileName'];
                        data['sheets'] = fileData.sheets;
                        data['originalColumns'] = originalData['originalColumns'];
                        data['activeSheet'] = fileData.activeSheet;
                        data['originalFileName'] = fileData.name;
                        data['isCSV'] = isCSV;
                        data['isInBlobStorage'] = originalData['isInBlobStorage'];
                        context.emit('upload', data);
                        context.emit('checkFile', fileToUpload);
                    }
                } else {
                    toaster.error(i18next.t('ANALYSIS.DROP_FILE_TYPE_ERROR'));
                }
            } catch (e: any) {
                if (e instanceof BusinessError) {
                    toaster.error(e.message);
                } else {
                    toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG', 'Something went wrong!'));
                }
                file.value = null;
            }
            setLoading(false);
        }

        const reset = () => {
            showWarningPopup.value = false;
            file.value = null;
            router.push('/');
        };

        return {
            file, openDialog, uploadFile,
            showWarningPopup, fileFormat, reset,
        };
    }
});
</script>

<style lang="less" scoped>
.upload-wrapper {
        margin: calc(50vh - 75px) auto;
        width: 300px;
        text-align: center;

        .max-file-size-message{
            font-size: 10px;
            text-align: center;
            text-transform: none;
            padding: 6px 20px;
        }
    }
</style>
