import { hex2rgba } from '@/utils/color';
import elementTypes from './utils/elementTypeEnum';
import features from '@/domain/planFeatureTypeEnum';
import useSubscriptionStore from '@/subscription/store';
import dayjs from 'dayjs';

export default {
    isAutoPublishOn: (state) => {
        if (!state.lastResponseAt) {
            return true;
        }
        return dayjs(state.lastResponseAt).isBefore(dayjs().subtract(1, 'day'));
    },
    editableElements: (state) => state.elements.filter(element => element.type !== elementTypes.META),
    verbatimElements: (state) => state.elements.filter(element => element.type == elementTypes.TEXTAREA),
    builderElements: (_, getters) => {
        const elements = getters.editableElements;

        let questionNumber = 0;

        return elements.map(element => {
            if (element.type !== 1) {
                questionNumber++;
                return {
                    ...element,
                    builderQuestionNumber: questionNumber,
                };
            }

            return { ...element };
        });
    },
    dropdownElementsWithOptionsId: (state) => state.elements
        .filter(element => element.type === elementTypes.DROPDOWN && element.options_id && element.options_from_file)
        .map(element => element.guid),
    elementsWithoutFileUploadedOptions: (state, getters) => state.elements.map(element => {
        if (getters.dropdownElementsWithOptionsId.includes(element.guid)) {
            return {
                ...element,
                options: null
            };
        }
        return element;
    }),
    activeBoxStyle: (state) => {
        return {
            'color': state.text_color,
            'border-color': state.primary_color,
        };
    },
    hoverBoxStyle: (state) => {
        return {
            'color': state.text_color,
            'border-color': state.text_color,
        };
    },
    baseBoxStyle: (state) => {
        return {
            'color': state.text_color,
            'border-color':  hex2rgba(state.text_color, .5),
        };
    },
    urlParameters: (state) => {
        const initialValue = {};
        return state.usedUrlParams.reduce((obj, param) => {
            return {
                ...obj,
                [param['name']]: param['value']
            };
        }, initialValue);
    },
    isSurveyReanalyseToasterShown: (state) => {
        return !!state.lastResponseAt && state.hasCustomLabelChanges;
    },
    newQuestionCount: (state) => {
        return (!state.elements || !state.savedElements) ? 0 :
            state.elements.filter(e => state.savedElements.find(se => se.guid === e.guid) === undefined).length;
    },
    deletedQuestionCount: (state) => {
        return (!state.elements || !state.savedElements) ? 0 :
            state.savedElements.filter(e => state.elements.find(se => se.guid === e.guid) === undefined).length;
    },
    modifiedQuestionCount: (state) => {
        return (!state.elements || !state.savedElements) ? 0 :
            state.elements.filter(e => {
                const savedElement = state.savedElements.find(se => se.guid === e.guid);
                return savedElement && (
                    JSON.stringify(savedElement) !== JSON.stringify(e) ||
                    state.pending.pendingOptionsFromFile.length > 0 && state.pending.pendingOptionsFromFile.find(po => po.guid === e.guid)
                );
            }).length;
    },
    isManualPublishToasterShown: (state, getters) => {
        return !getters.isAutoPublishOn && (
            getters.newQuestionCount > 0
            || getters.deletedQuestionCount > 0
            || getters.modifiedQuestionCount > 0
        );
    },
    hasTranslations: (state) => {
        const { canUseFeature } = useSubscriptionStore().getters;
        return canUseFeature.value(features.MULTILANG_SURVEY) && state.is_multilang && Object.keys(state.translations.draftTranslations || {}).length > 0;
    },
    hasActiveTranslations: (state) => {
        const { canUseFeature } = useSubscriptionStore().getters;
        return canUseFeature.value(features.MULTILANG_SURVEY) && state.is_multilang && state.translations.activeLanguages?.length > 0;
    },
    hasAnswers: (state) => {
        return state.status === 1 && !!state.lastResponseAt;
    },
    embeddableElements: (_state, getters) => {
        return getters.editableElements.filter(element => {
            return (
                [elementTypes.RADIO, elementTypes.RATING, elementTypes.SCALE, elementTypes.NPS, elementTypes.CSAT, elementTypes.CES_5_SCALE, elementTypes.CES_7_SCALE].includes(element.type)
                && !(element.type === elementTypes.SCALE && element.is_decimal_scale)
                && !element.options_parent_question_guid
            );
        });
    },
    hasActiveLanguages: (state) => {
        if (!state.is_multilang || !Array.isArray(state.translations.activeLanguages)) return false;

        return state.translations.activeLanguages.length > 0;
    },
};
