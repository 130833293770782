<template>
    <div
        class="overlay bg-bg opacity-75"
        data-test-id="loading-screen"
    >
        <loading-animation
            class="opacity-100"
            :size="size"
        />
    </div>
</template>

<script>
import LoadingAnimation from '@/components/ui/LoadingAnimation';
export default {
    name: 'LoadingScreen',
    components: {LoadingAnimation},
    props: {
        size: {type: Number, default: 64}
    },
};
</script>

<style lang="less" scoped>
    .overlay {
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
</style>
