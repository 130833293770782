import jwt_decode from 'jwt-decode';

const COOKIE_NAME = '@@PRINTER_TOKEN@@';

function getCookie(c_name) {
    const c_value = ' ' + document.cookie;
    let c_start = c_value.indexOf(' ' + c_name + '=');
    if (c_start == -1) {
        return null;
    }

    c_start = c_value.indexOf('=', c_start) + 1;
    let c_end = c_value.indexOf(';', c_start);
    if (c_end == -1) {
        c_end = c_value.length;
    }
    return unescape(c_value.substring(c_start,c_end));
}

export default {
    async isAuthenticated() {
        return Boolean(await this.getTokenSilently());
    },
    async getTokenSilently() {
        return getCookie(COOKIE_NAME);
    },
    async getUser() {
        const token = await this.getTokenSilently();
        if (!token) {
            return {};
        }
        return {
            ...jwt_decode(token) as Record<string, string>,
            name: 'printer@zurvey.io',
            given_name: 'Printer',
            family_name: 'User',
            picture: 'https://s.gravatar.com/avatar/c3e972cc18fc3ad9fa6e5a1185188cc0?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fsu.png',
            email: 'printer@zurvey.io',
        };
    },
    async loginWithRedirect() {
        // NOOP
    },
    async handleRedirectCallback() {
        // NOOP
    },
    logout() {
        // NOOP
    },
    async getIdTokenClaims() {
        throw new Error('Not implemented!');
    },
    async checkSession() {
        throw new Error('Not implemented!');
    },
    async getTokenWithPopup() {
        throw new Error('Not implemented!');
    },
    async buildAuthorizeUrl() {
        throw new Error('Not implemented!');
    },
    async loginWithPopup() {
        throw new Error('Not implemented!');
    },
};
