<template>
    <scrollbar class="h-screen p-10 w-full">
        <upload
            v-if="!uploaded"
            :data-source-id="dataSourceId"
            @upload="uploadFile"
            @checkFile="fileToCheck = $event"
        />
        <div
            v-else
            class="w-full overflow-hidden"
        >
            <excel-settings
                ref="appendRef"
                :initial-active-sheet-name="initialActiveSheetName"
                :initial-sheets="initialSheets"
                :initial-configs="initialConfigs"
                :original-columns="originalColumns"
                :is-csv="isCsv"
                append-excel
                @delimiterChanged="reparseCsv($event)"
            />
            <div
                v-if="isQuotaUsageVisible"
                class="quota-usage bg-bg text-black z-40"
            >
                <quota-usage
                    :quota-limit="quotaLimit"
                    :all-text-units="allTextUnits"
                    :used-quota="usedQuota"
                    :account-manager-email="accountManagerEmail"
                    @startAnalysis="append($event)"
                    @closePopup="isQuotaUsageVisible = false"
                />
            </div>
            <z-button
                data-test-id="submit-append"
                class="submit-append block my-3 mx-auto"
                @click="submitButtonClicked"
            >
                {{ $t('BUTTONS.APPEND') }}
            </z-button>
        </div>
    </scrollbar>
</template>

<script lang="ts">
import api from '@/api';
import Scrollbar from '@/components/ui/Scrollbar.vue';
import QuotaUsage from '../QuotaUsage.vue';
import gtm from '@/utils/gtm';
import Upload from './Upload.vue';
import ExcelSettings from '@/components/common/ExcelSettings/ExcelSettings.vue';
import useSubscriptionStore from '@/subscription/store';
import { computed, ref, defineComponent } from 'vue';

import { toaster } from '@/utils/toaster';
import i18next from 'i18next';
import useExcelAnalysisStore, { CsvDelimiterEnum } from '@/excel-analysis/store';
import { setLoading } from '@/utils/loading';
import planFeatureTypeEnum from '@/domain/planFeatureTypeEnum';
import confirmPopup from '@/utils/confirmPopup';
import dimensionTypeEnum from '@/domain/dimensionTypeEnum';

import confirm from '@/utils/confirmPopup';

export default defineComponent({
    components: {
        Scrollbar,
        QuotaUsage,
        Upload,
        ExcelSettings,
    },
    props: {
        dataSourceId: { type: Number, default: null },
        accountManagerEmail: { type: String, default: '' },
    },
    setup(props) {
        const { getters } = useSubscriptionStore();
        const { state: excelAnalysisState, actions: excelAnalysisActions } = useExcelAnalysisStore();
        const { featureQuotaLimit, featureUsedQuota } = getters;
        const isCsv = ref(false);
        const file = ref(excelAnalysisState.uploadedExcel ? excelAnalysisState.uploadedExcel.file : null);
        const delimiter = ref(CsvDelimiterEnum.SEMICOLON);
        const originalColumns = ref<any[]>([]);
        const initialSheets = ref<any>();
        const initialConfigs = ref<any>();
        const fileToCheck = ref<any>();
        async function reparseCsv(separator) {
            delimiter.value = separator;
            setLoading(true);
            const response = await excelAnalysisActions.reparseCsv(delimiter.value, excelAnalysisState.uploadedExcel.file);
            setInitialSheets(response['sheets']);
            setInitialConfigs(response['sheets']);
            setLoading(false);
        }

        function setInitialSheets(sheets) {
            initialSheets.value = Object.entries(sheets).reduce((initSheets, [sheetName, sheet]: [string, any]) => {
                const columnData = sheet.columnData;
                initSheets[sheetName] = {
                    columnData,
                    headerRows: sheet.headerRows
                };
                return initSheets;
            }, {});
        }
        function setInitialConfigs(sheets) {
            initialConfigs.value = Object.entries(sheets).reduce((config, [sheetName, sheet]: [string, any]) => {
                config[sheetName] = Object.keys(sheet.columnData).reduce((acc, column) => {
                    const newIndex  = (parseInt(column) + 1);
                    acc[newIndex] = { ...originalColumns.value.find(origCol => origCol.columnNumber == newIndex) };
                    if (!acc[newIndex].columnNumber) {
                        acc[newIndex] = {
                            columnNumber: -1,
                            name: 'Do not use'
                        };
                    }
                    return acc;
                }, {});
                return config;
            }, {});
        }

        const uploaded = ref(false);
        const fileName = ref(null);
        const sheets = ref<any[]>([]);
        const initialActiveSheetName = ref('');
        const isQuotaUsageVisible = ref(false);

        const appendRef = ref();

        const activeSheetName = computed(() => appendRef.value.activeSheetName);
        const config = computed<any[]>(() => appendRef.value.configs[activeSheetName.value]);

        // activeSheet from refs contain changing variables e.g. header rows
        const activeSheet = computed(() => appendRef.value.sheets[activeSheetName.value]);
        // activeSheetDetails set from file upload contain more general props (row count, sheet index)
        const activeSheetDetails = computed(() => sheets.value.find(sheet => sheet.name === activeSheetName.value));

        const allTextUnits = computed(() => Object.values(config.value).filter(column => parseInt(column.type) === 2).length * (activeSheetDetails.value.rows - activeSheet.value.headerRows));

        const quotaLimit = featureQuotaLimit(planFeatureTypeEnum.FILE_UPLOAD_VERBATIM);
        const usedQuota = featureUsedQuota(planFeatureTypeEnum.FILE_UPLOAD_VERBATIM);

        const setSheets = (newSheets) => {
            sheets.value = Object.entries(newSheets).reduce((initSheets: any, [sheetName, sheet]: [string, any]) => {
                const columnData = sheet.columnData;
                initSheets[sheet.index] = {
                    columns: columnData,
                    rows: sheet.rows,
                    headerRows: sheet.headerRows,
                    id: sheet.index,
                    name: sheetName
                };
                return initSheets;
            }, []);
        };
        
        const isInBlobStorage = ref(false);

        const uploadFile = (data) => {
            uploaded.value = true;
            fileName.value = data['fileName'];
            originalColumns.value = data['originalColumns'].filter(column => column.type !== 9);
            initialActiveSheetName.value = data['activeSheet'];
            isCsv.value = data['isCSV'];
            isInBlobStorage.value = data['isInBlobStorage'];
            setInitialSheets(data['sheets']);
            setInitialConfigs(data['sheets']);
            setSheets(data['sheets']);
        };

        const insufficientQuotaWarningPopupText = computed(() =>
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_FIRST','Based on our estimation (number of rows multiplied by the number of verbatim columns in your file), it appears that you may not have sufficient text analysis quota to complete this analysis.') +
            '<br>' +
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_SECOND', 'If you proceed, the analysis process will halt once your quota is depleted and will remain incomplete until additional quota becomes available. Analysis will resume automatically when text analysis quota is replenished, without requiring manual intervention.') +
            '<br>' +
            i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_TEXT_THIRD', 'Please consider upgrading your quota or reducing the size of your dataset to ensure uninterrupted analysis.')
        );

        const submitButtonClicked = async () => {
            if (quotaLimit.value == null || (usedQuota.value + allTextUnits.value) < quotaLimit.value * 0.8) {
                append(false);
            } else {
                if (isInBlobStorage.value) {
                    const confirmed = await confirm.default(
                        i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_QUOTA_POPUP_TITLE', 'Warning: Insufficient Text Analysis Quota'),
                        insufficientQuotaWarningPopupText.value,
                        i18next.t('ANALYSIS.INSUFFICIENT_TEXT_ANALYSIS_QUOTA_POPUP_BUTTON', 'Proceed anyway'),
                        null,
                        true
                    );
                    if (confirmed) {
                        append(false);
                    }
                } else {
                    isQuotaUsageVisible.value = true;
                }
            }
        };

        const append = async (shouldDownSize = false) => {
            setLoading(true);
            // Creates an object, where the keys are the original columnNumbers,
            // and the values are the new column numbers, or null if there's no new column for the original
            const columnPairs = originalColumns.value.reduce((pairs, origcol) => {
                pairs[origcol.columnNumber] = Object.values(config.value).findIndex(config => config.columnNumber === origcol.columnNumber) + 1 || null;
                return pairs;
            }, {});
            const dateColumns = Object.values(config.value).filter((c) => c.type === dimensionTypeEnum.DATE);
            try {
                const parsingError = await api.checkIfFileParseable(
                    {
                        sheetName: activeSheetName.value,
                        file: fileToCheck.value,
                        dateColumns: dateColumns,
                        columnCount: Object.values(config.value).length,
                        delimiter: isCsv.value ? delimiter.value : null
                    }
                );
                if (parsingError.errorType) {
                    setLoading(false);
                    if (parsingError.errorType === 'rows') {
                        await confirmPopup.notification(
                            i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_TITLE', 'Upload Unsuccessful'),
                            i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_WARNING', 'We couldn\'t process your file due to incompatible encoding, formatting issues, or special characters. Please ensure your data is clean and try uploading again.'),
                            i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_PRIMARY_BUTTON', 'Got it, back to the home screen\n'),
                            null,
                            true
                        );
                    } else if (parsingError.errorType === 'dateColumns') {
                        const isConfirmed = await confirmPopup.default(
                            i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_TITLE', 'Data Format Warning - Date dimension'),
                            i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_WARNING', 'It appears that there is data in your file tagged as \'date\' type which our system cannot parse. This means that if you proceed with the upload, the date data won\'t be filterable on the dashboard, and time series visualizations won\'t be available. Please ensure your data is formatted to fit our accepted') + '<a target="_blank" class="underline" href="https://neticle.com/knowledge-base/zurvey-dimension-types#date-dimension">' + i18next.t('EXCEL_ANALYSIS.DATE_PARSING_UNSUCCESSFUL_WARNING_ENDING', 'formats') + '</a>.',
                            i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_PRIMARY_BUTTON', 'Got it, back to the home screen'),
                            i18next.t('EXCEL_ANALYSIS.PARSING_UNSUCCESSFUL_SECONDARY_BUTTON', 'Proceed with upload'),
                            true
                        );
                        if(isConfirmed) {
                            window.location.href = '/';
                        } else {
                            setLoading(true);
                            await appendExcel(columnPairs, shouldDownSize);
                        }
                    }
                } else {
                    await appendExcel(columnPairs, shouldDownSize);
                }


            }
            catch (e) {
                toaster.error(i18next.t('GENERAL.SOMETHING_WENT_WRONG'));
                Sentry.captureException(e);
            }
            finally {
                setLoading(false);
            }
        };

        async function appendExcel(columnPairs, shouldDownSize) {
            await api.appendExcel({
                dataSourceId: props.dataSourceId,
                fileName: fileName.value,
                headerRows: activeSheet.value.headerRows,
                newRows: activeSheetDetails.value.rows - activeSheet.value.headerRows,
                sheetIndex: activeSheetDetails.value.id,
                columnPairs,
                downsize: shouldDownSize,
                delimiter: isCsv.value ? delimiter.value : null
            });
            gtm.track(gtm.events.EXCEL_APPEND_STARTED, gtm.categories.EXCEL_APPEND_STARTED, {dataSourceId: props.dataSourceId});
            window.location.href = '/';
        }

        return {
            appendRef,
            quotaLimit, isCsv, delimiter, file, uploaded,
            originalColumns, initialSheets, initialConfigs, initialActiveSheetName,
            isQuotaUsageVisible, usedQuota, allTextUnits,
            submitButtonClicked, uploadFile, append, reparseCsv, fileToCheck
        };
    },
});
</script>

<style lang="less" scoped>
    .quota-usage {
        position: absolute;
        width: 400px;
        border-radius: 4px;
        box-shadow: 5px 7px 20px -5px rgba(var(--color-shadow), 0.2);
        display: flex;
        flex-direction: column;
        justify-content: center;
        top: calc(50% - 250px);
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 10px 15px;
    }

    .submit-append {
        width: fit-content !important;
    }
</style>
