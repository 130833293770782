import {actions, state} from '@/dashboard/common/store/commonStore';
import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';
import {actions as textAnalysisActions} from '@/dashboard/textAnalysis/store/store';
import {RouteConfig} from 'vue-router';

const TextAnalysis = () => import('@/dashboard/textAnalysis/TextAnalysisDashboard.vue');
const CustomLabels = () => import('@/dashboard/textAnalysis/pages/CustomLabels.vue');
const Categories = () => import('@/dashboard/textAnalysis/pages/Categories.vue');
const Entities = () => import('@/dashboard/textAnalysis/pages/Entities.vue');
const Feed = () => import('@/dashboard/textAnalysis/pages/Feed.vue');
const Relations = () => import('@/dashboard/textAnalysis/pages/Relations.vue');
const Summary = () => import('@/dashboard/textAnalysis/pages/Summary.vue');
const Time = () => import('@/dashboard/textAnalysis/pages/Time.vue');
const NPS = () => import('@/dashboard/textAnalysis/pages/NPS.vue');
const CSAT = () => import('@/dashboard/textAnalysis/pages/CSAT.vue');
const Review = () => import('@/dashboard/textAnalysis/pages/Review.vue');

const IndividualMarkers = () => import('@/dashboard/textAnalysis/pages/IndividualMarkers.vue');

export default {
    path: ':datasetId/text-analysis/:dimensionId?',
    component: TextAnalysis,
    props: true,
    name: 'textAnalysis',
    beforeEnter: async (to, from, next) => {
        await actions.init(to.params.datasetId).catch(() => next('/'));
        let dimensionId = to.params.dimensionId;

        // Redirect to overview if dataset has no verbatim dimension
        const verbatimDimensions = state.dataset?.dimension_definitions?.filter?.(d => d.type === dimensionTypeId.VERBATIM) || [];
        if (verbatimDimensions.length === 0) {
            next({ name: 'overview', params: {datasetId: to.params.datasetId}, query: state.query });
            return;
        }

        // Automatically select dimension if none or invalid is given
        const isValidDimension = verbatimDimensions.map(d => d.id).includes(dimensionId);
        if (!dimensionId || !isValidDimension) {
            dimensionId = verbatimDimensions[0].id;
        }

        textAnalysisActions.setColumnId(dimensionId);

        // Load data if the prev page was not on the same dashboard
        if (from.path.indexOf('/text-analysis/') === -1) {
            textAnalysisActions.resetSummary();
            textAnalysisActions.load(true);
        }

        // Set default page if none given or got invalid dimension
        if (to.name === 'textAnalysis' || !isValidDimension) {
            next({ name: 'summary', params: { datasetId: to.params.datasetId, dimensionId: dimensionId }, query: state.query });
        } else {
            next({ query: state.query });
        }
    },
    children: [
        { path: 'summary', component: Summary, name: 'summary' },
        { path: 'entities', component: Entities, name: 'entities' },
        { path: 'relations', component: Relations, name: 'relations' },
        { path: 'feed', component: Feed, name: 'feed' },
        { path: 'time', component: Time, name: 'time' },
        { path: 'categories', component: Categories, name: 'categories' },
        { path: 'individual-markers', component: IndividualMarkers, name: 'individualMarkers' },
        { path: 'custom-labels', component: CustomLabels, name: 'customLabels' },
        { path: 'nps', component: NPS, name: 'nps' },
        { path: 'csat', component: CSAT, name: 'csat' },
        { path: 'review', component: Review, name: 'review' },
    ]
} as RouteConfig;
