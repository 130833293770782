import { computed, reactive } from 'vue';
import { HomeDatasetsState, User, Dataset, Permissions, AccessRequest, Filter, FilterType, CampaignFilterType } from './types';
import statusEnum from '@/domain/dataSource/statusEnum';
import typeEnum from '@/domain/dataSource/typeEnum';
import api from '@/api';
import { createNewEmailProcessor, getEmailProcessorByDatasetId } from '@/email-connector/api/emailProcessor';
import i18next from 'i18next';
import dayjs from 'dayjs';
import gtm from '@/utils/gtm';
import { EmailConnector } from '@/email-connector/common/emailConnectorInterface';

import { userState } from '@/user/store';

export const homeDatasetsState = reactive<HomeDatasetsState>({
    datasets: [],
    archivedDatasets: [],
    query: '',
    filters: [],
    orderBy: 'desc',
    datasetsLoading: false,
    isArchiveView: false,
    openPermissionPopup: null,
    accessRequests: [],
    limit: 50,
    firstLoading: false,
});

const getters = {
    creators: computed((): User[] => {
        const uniqueCreators = [] as User[];
        getters.datasets.value.forEach((ds) => {
            if (uniqueCreators.findIndex(c => (c.name == ds.creatorName && c.id == ds.creatorId)) <= -1) {
                uniqueCreators.push({
                    id: ds.creatorId,
                    name: ds.creatorName
                });
            }
        });
        return uniqueCreators;
    }),
    datasets: computed<Dataset[]>(() => {
        return homeDatasetsState.isArchiveView ? homeDatasetsState.archivedDatasets : homeDatasetsState.datasets;
    }),
    filteredDatasets: computed((): Dataset[] => {
        return getters.datasets.value
            .filter(ds => {
                if (homeDatasetsState.filters.length === 0) return true;

                const datasetFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.DATASET);

                if (datasetFilters.length > 0) {
                    if (datasetFilters.every(f => f.value !== ds.type)) return false;
                }

                const accessFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.ACCESS);

                if (accessFilters.length > 0) {
                    if (accessFilters.every(f => {
                        if (f.value === 'my-datasets') {
                            return ds.creatorId !== userState.id;
                        }
                        if (f.value === 'has-access') {
                            return ds.permissionLevel === 0;
                        }
                        if (f.value === 'no-access') {
                            return ds.permissionLevel !== 0;
                        }
                    })) {
                        return false;
                    }
                }

                const tagFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.TAG);

                if (tagFilters.length > 0) {
                    if (!ds.tags || ds.tags.length === 0) return false;
                    if (tagFilters.some(f => !ds.tags.includes(f.value))) return false;
                }

                const customLabelFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.CUSTOM_LABEL);

                if (customLabelFilters.length > 0) {
                    const customLabels = ds.dimension_definitions.reduce((labels, def) => {
                        if (def.customLabels) {
                            def.customLabels.forEach(label => {
                                if (!labels.includes(label)) {
                                    labels.push(label);
                                }
                            });
                        }

                        return labels;
                    }, [] as number[]);
                    if (customLabels.length === 0) return false;
                    if (customLabelFilters.some(f => !customLabels.includes(f.value))) return false;
                }

                const creatorFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.CREATOR);

                if (creatorFilters.length > 0) {
                    if (creatorFilters.some(f => f.value !== ds.creatorId)) return false;
                }

                const queryFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.QUERY);

                if (queryFilters.length > 0) {
                    if (queryFilters.some(f => !ds.name.toLowerCase().includes(f.value.toLowerCase()))) return false;
                }

                const embedFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.EMBED);

                if (embedFilters.length > 0) {
                    if (embedFilters.every(f => {
                        if (f.value === 'active-embed') {
                            return ds.hasActiveEmbed !== true;
                        }
                        if (f.value === 'has-embed') {
                            return ds.hasActiveEmbed === null;
                        }
                        if (f.value === 'no-embed') {
                            return ds.hasActiveEmbed !== null;
                        }
                    })) {
                        return false;
                    }
                }

                const campaignFilters = homeDatasetsState.filters.filter(f => f.type === FilterType.CAMPAIGN);

                if (campaignFilters.length > 0) {
                    if (campaignFilters.every(f => {
                        if (f.value === CampaignFilterType.HAS_CAMPAIGN) {
                            return ds.hasCampaign !== true ;
                        }
                        if (f.value === CampaignFilterType.NO_CAMPAIGN) {
                            return ds.hasCampaign !== null;
                        }
                    })) {
                        return false;
                    }
                }

                return true;
            })
            .sort((a, b) => {
                if (homeDatasetsState.orderBy === 'asc') {
                    return dayjs(a.updated_at).valueOf() - dayjs(b.updated_at).valueOf();
                }
                return dayjs(b.updated_at).valueOf() - dayjs(a.updated_at).valueOf();
            });
    }),
    tags: computed(() => getters.datasets.value.reduce((tags, dataset) => {
        if (dataset.tags) {
            for (const tag of dataset.tags) {
                if (!tags.includes(tag)) {
                    tags.push(tag);
                }
            }
        }
        return tags;
    }, [] as string[])),
};

export async function getDatasets(polling: boolean = false, force: boolean = false, returnAfterFirstBatch = false, start = 0): Promise<NodeJS.Timeout|void> {
    if (!homeDatasetsState.datasetsLoading || force) {
        homeDatasetsState.datasetsLoading = true;
        const isArchiveView = homeDatasetsState.isArchiveView;

        const limit = 100;
        let offset = start;
        let loadedDatasets: Dataset[] = [];

        do {
            if (returnAfterFirstBatch) {
                actions.setFirstLoading(true);
            }
            const datasets = await api.getDatasets(offset * limit, limit, isArchiveView);
            const datasetsWithoutNullName = datasets.map(function (dataset) {
                if (dataset.name) return dataset;
                return { ...dataset, name: i18next.t('HOME.UNTITLED', 'Untitled') };
            });
            const mappedDatasets = datasetsWithoutNullName.map(dataset => {
                let status: string;
                if ([typeEnum.EXCEL, typeEnum.WEBSITE_CRAWLER, typeEnum.PDF_EXTRACTOR].includes(dataset.type)) {
                    // File upload types
                    status = dataset.is_analysis_running ? statusEnum.TEXT_ANALYSIS : statusEnum.FINISHED;
                } else if (dataset.type === typeEnum.SURVEY) {
                    status = dataset.isActive ? statusEnum.ACTIVE : (
                        dataset.rows > 0 ? statusEnum.PAUSED : statusEnum.DRAFT
                    );
                } else {
                    // Data stream types e.g. NMI, Email, API, Domo
                    status = dataset.isActive ? statusEnum.ACTIVE : statusEnum.PAUSED;
                }
                return {
                    ...dataset,
                    status,
                    permissions: getPermissionsObject(dataset.permissions)
                };
            });
            loadedDatasets = [...loadedDatasets, ...mappedDatasets];

            // Stop paginating when reached the end and continue if not
            if (datasets.length < limit) {
                break;
            } else {
                offset++;
            }
        } while (!returnAfterFirstBatch);

        if (isArchiveView) {
            if (start > 0) {
                homeDatasetsState.archivedDatasets = homeDatasetsState.archivedDatasets.concat(loadedDatasets);
            }
            else {
                homeDatasetsState.archivedDatasets = loadedDatasets;
            }
        }
        else {
            if (start > 0) {
                homeDatasetsState.datasets = homeDatasetsState.datasets.concat(loadedDatasets);
            }
            else {
                homeDatasetsState.datasets = loadedDatasets;
            }
        }

        homeDatasetsState.datasetsLoading = false;

        if (returnAfterFirstBatch && loadedDatasets.length >= 100) {
            actions.getDatasets(false, false, false, 1).then(() => actions.setFirstLoading(false));
        }
        else {
            actions.setFirstLoading(false);
        }
    }
    if (polling) {
        return setInterval(() => actions.getDatasets().catch(() => {}), 2000);
    }
}

/*
* When permission group or user is not set, backend returns [] and not {}
* For Typescript object check, these empty values should be converted
* */
function convertToObjectIfArray(accessor) {
    return Array.isArray(accessor) ? { ...accessor } : accessor;
}

function getPermissionsObject(permissions) {
    return {
        groups: convertToObjectIfArray(permissions.groups),
        users: convertToObjectIfArray(permissions.users)
    };
}

export async function getAccessRequests() {
    homeDatasetsState.accessRequests = await api.getAccessRequests();
}

const actions = {
    getDatasets,
    getAccessRequests,
    restoreDataset: async (datasetId: string) => {
        await api.restore(datasetId);
        await getDatasets(false, true);
        gtm.track(gtm.events.DATA_SOURCE_RESTORED, gtm.categories.DATA_SOURCE_DELETED);
    },
    deleteDataset: async (datasetLegacyId: number) => {
        await api.deleteDataset(datasetLegacyId);
        await getDatasets(false, true);
        gtm.track(gtm.events.DATA_SOURCE_DELETED, gtm.categories.DATA_SOURCE_DELETED);
    },
    renameAnalysis: async (id, title) => {
        await api.renameAnalysis(id, title);
        await getDatasets();
    },
    setDatasetPermissions: async (datasetId: string, permissions: Permissions) => {
        await api.setDatasetPermissions(datasetId, permissions);
    },
    setDatasetShare: async (datasetId: string, isEnabled: boolean) => {
        await api.setDatasetShare(datasetId, isEnabled);
    },
    setDatasetIpAddresses: async (datasetId: string, ipAddresses: string[] | null) => {
        await api.setDatasetIpAddresses(datasetId, ipAddresses);
    },
    requestAccess: async (datasetId: string) => {
        await api.requestAccess(datasetId);
    },
    setOpenPermissionPopup: (datasetId: string | null) => {
        homeDatasetsState.openPermissionPopup = datasetId;
    },
    getAccessRequestsForDataset: async (datasetId: string): Promise<AccessRequest[]> => {
        return await api.getAccessRequestsForDataset(datasetId);
    },
    acceptAccessRequest: async (datasetId: string, id: number, permissionLevel: number): Promise<Dataset> => {
        return await api.acceptAccessRequest(datasetId, id, permissionLevel);
    },
    rejectAccessRequest: async (datasetId: string, id: number) => {
        await api.rejectAccessRequest(datasetId, id);
    },
    setQuery: (query: string) => {
        homeDatasetsState.query = query;
    },
    setOrderBy: (orderBy: 'desc' | 'asc') => {
        homeDatasetsState.orderBy = orderBy;
    },
    setIsArchiveView: (isArchiveView: boolean) => {
        homeDatasetsState.isArchiveView = isArchiveView;
    },
    toggleFilter: (filter: Filter): void => {
        if (homeDatasetsState.filters.some(f => f.type === filter.type && f.value === filter.value)) {
            if (filter.value === typeEnum.SURVEY) {
                homeDatasetsState.filters = homeDatasetsState.filters.filter(f => f.type !== FilterType.EMBED && f.type !== FilterType.CAMPAIGN);
            }
            homeDatasetsState.filters = homeDatasetsState.filters.filter(f => !(f.type === filter.type && f.value === filter.value));
        }
        else {
            if (filter.type === FilterType.EMBED || filter.type === FilterType.CAMPAIGN) {
                homeDatasetsState.filters = homeDatasetsState.filters.filter(f => f.type !== FilterType.DATASET);
                actions.addFilter({ type: FilterType.DATASET, value: typeEnum.SURVEY });
            }
            if (filter.type === FilterType.DATASET) {
                homeDatasetsState.filters = homeDatasetsState.filters.filter(f => f.type !== FilterType.EMBED && f.type !== FilterType.CAMPAIGN);
            }
            homeDatasetsState.filters.unshift(filter);
        }
    },
    addFilter: (filter: Filter): void => {
        if (homeDatasetsState.filters.some(f => f.type === filter.type && f.value === filter.value)) return;

        homeDatasetsState.filters.push(filter);
    },
    removeFilter: (filter: Filter): void => {
        const index = homeDatasetsState.filters.findIndex(f => f.type === filter.type && f.value === filter.value);

        if (index !== -1) {
            homeDatasetsState.filters.splice(index, 1);
        }
    },
    removeLastFilter: (): void => {
        if (homeDatasetsState.filters.length) {
            actions.toggleFilter(homeDatasetsState.filters[homeDatasetsState.filters.length -1]);
        }
    },
    removeAllFilters: (): void => {
        homeDatasetsState.filters.splice(0);
    },
    async copyDataset(dataset: Dataset) {
        if (dataset.type === typeEnum.SURVEY) {
            const survey = await api.getSurveyByDatasetId(dataset.id);
            const datasetId = await api.copySurvey(survey.id);
            gtm.track(gtm.events.SURVEY_COPIED, gtm.categories.SURVEY_COPIED, { originalSurveyId: survey.id });
            return '/survey/' + datasetId;
        } else if (dataset.type === typeEnum.EMAIL) {
            const emailConnector = Object.values(await getEmailProcessorByDatasetId(dataset.id))[0] as EmailConnector;
            const newEmailConnector = await createNewEmailProcessor({ ...emailConnector, name: `${emailConnector.name} - ${dayjs().format('YYYY-MM-DD')}` });
            return 'email-connector/' + newEmailConnector.id;
        }
        const newDataset = await api.copyDataset(dataset.id);
        return 'api-connector/' + newDataset.id;
    },
    setLimit: (limit: number): void => {
        homeDatasetsState.limit = limit;
    },
    setFirstLoading: (val: boolean): void => {
        homeDatasetsState.firstLoading = val;
    },
};

export default function useHomeDatasetsStore() {
    return {
        state: homeDatasetsState,
        actions,
        getters,
    };
}
