import { api } from '@/api';
import { PdfExtractor } from '../types';

export const createPdfExtractor = async (extractor: PdfExtractor) => {
    const formData = new FormData();

    formData.set('name', extractor.name);
    formData.set('source', extractor.source);
    formData.set('accent', JSON.stringify(extractor.accent));
    formData.set('customLabelIds', JSON.stringify(extractor.customLabelIds));
    formData.set('languageId', extractor.languageId);
    formData.set('lowercase', JSON.stringify(extractor.lowercase));
    formData.set('textSplitting', extractor.textSplitting.toString());
    formData.set('topicRecognition', JSON.stringify(extractor.topicRecognition));

    for (const file of extractor.files) {
        formData.append('files[]', file);
    }

    await api.post('/pdf-extractor', formData);
};

export const getPdfExtractor = async (id: string): Promise<any> => {
    const { data } = await api.get('/pdf-extractor/dataset/' + id);

    return data;
};

export const getPreview = async (id: string): Promise<any> => {
    const { data } = await api.get('/datasets/' + id + '/getPreviewData');

    return data;
};

export const updateName = async (id: string, name: string): Promise<void> => {
    await api.put('/datasets/' + id, { name });
};

export const updatePdfExtractor = async (id: string, payload): Promise<void> => {
    const data = Object.keys(payload).reduce((d, key) => {
        if (key !== 'name') {
            d[key] = JSON.stringify(payload[key]);
        } else {
            d[key] = payload[key];
        }
        return d;
    }, {});

    await api.put(`/pdf-extractor/${id}`, data);
};

export const append = async (id: string, payload): Promise<void> => {
    const formData = new FormData();

    formData.set('source', payload.source);

    for (const file of payload.files) {
        formData.append('files[]', file);
    }

    await api.post(`/pdf-extractor/${id}/append`, formData);
};
