import { ref, computed } from 'vue';

const loadingState = ref(0);

export const isLoading = computed(() => loadingState.value > 0);

export function setLoading(state = true) {
    if (state) {
        loadingState.value++;
    } else if (loadingState.value > 0) {
        loadingState.value--;
    }
}
