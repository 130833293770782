<template>
    <div
        class="p-2 border rounded space-y-2"
        :class="value ? 'border-primary-700' : 'border-neutral-600'"
    >
        <z-toggle
            :value="value"
            :disabled="disabled"
            @input="$emit('valueUpdated', $event)"
        >
            <span v-html="label" />
        </z-toggle>
        <div
            class="text-sm"
            v-html="infoText"
        />
    </div>
</template>

<script lang="ts">
import {  defineComponent } from 'vue';

export default defineComponent({
    model: {
        prop: 'value',
        event: 'input'
    },
    props: {
        label: { type: String, required: true },
        infoText: { type: String, required: true },
        value: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
    }
});
</script>
