import debounce from 'lodash/debounce';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { getFilters } from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';
import { TextUnit } from '@/dashboard/common/types/commonInterface';
import { Filter } from '@/dashboard/common/types/filterInterface';
import { saveAs } from 'file-saver';

const paginationLimit = 25;

export const initialState = {
    isFeedLoading: false,
    hasFeedReachedEnd: false,
    textUnits: [] as TextUnit[],
    feedSearchQuery: '',
    feedSort: 'semantic_analysis_id:asc',
    batchNumber: 0,
};

export const makeActions = (state) => {
    const getTextUnits = async () => {
        state.isFeedLoading = true;
        state.textUnits = [];
        state.textUnits = await datasetApi.getTextUnits(
            commonState.dataset.id,
            state.columnId,
            state.feedSearchQuery,
            state.feedSort.split(':')[0],
            state.feedSort.split(':')[1],
            state.batchNumber,
            paginationLimit,
            getFilters()
        );
        state.batchNumber = 0;
        state.isFeedLoading = false;
        state.hasFeedReachedEnd = state.textUnits.length < paginationLimit;
    };
    return {
        getTextUnits,
        addTranslationToTextUnits(semanticAnalysisId: number, translation: string) {
            state.textUnits = state.textUnits.map((tu) => tu.semantic_analysis_id === semanticAnalysisId ? { ...tu, translation } : tu);
        },
        async exportPosts() {
            const dataSourceName = commonState.dataset.name.split('.xlsx')[0].split('.xls')[0];
            const fileName = `${dataSourceName}_feed_${(new Date()).toISOString().split('T')[0]}.xlsx`;
            const response = await datasetApi.exportTextUnits(
                commonState.dataset.id,
                state.columnId!,
                state.feedSearchQuery,
                getFilters() as Filter[],
            );
            saveAs(response, fileName);
        },
        setFeedSearchQuery: debounce(async (searchQuery ) => {
            state.feedSearchQuery = searchQuery;
            await getTextUnits();
        }, 500),
        async setFeedSort(sort) {
            state.feedSort = sort;
            await getTextUnits();
        },
        getMoreTextUnits: debounce(async () => {
            if (state.textUnits.length >= state.filteredTextUnitCount || state.hasFeedReachedEnd) {
                return;
            }
            if (state.textUnits.length > 0) {
                state.isFeedLoading = true;
                state.batchNumber = state.batchNumber + 1;
                if (!commonState.dataset.id || !state.columnId) {
                    Sentry.addBreadcrumb({
                        message: 'wrong parameters for getTextUnits',
                        data: {
                            datasetId: commonState.dataset.id,
                            columnId: state.columnId
                        }
                    });
                    throw new Error('wrong params');
                }
                const response = await datasetApi.getTextUnits(
                    commonState.dataset.id,
                    state.columnId,
                    state.feedSearchQuery,
                    state.feedSort.split(':')[0],
                    state.feedSort.split(':')[1],
                    state.batchNumber * paginationLimit,
                    paginationLimit,
                    getFilters()
                );
                state.textUnits = [ ...state.textUnits, ...response ];
                if (response.length < paginationLimit) {
                    state.hasFeedReachedEnd = true;
                }
                state.isFeedLoading = false;
            }
        }, 200),
        resetFeedState: () => {
            state.isFeedLoading = false;
            state.hasFeedReachedEnd = false;
            state.textUnits = [] as TextUnit[];
            state.feedSearchQuery = '';
            state.feedSort = 'semantic_analysis_id:asc';
            state.batchNumber = 0;
        },
    };
};
