enum hashedKeys {
    DKNA = '7c516897082665494deccb5c96aeb59b',
    OTHER = 'd77f708d0ad2430214072fb039d3f675',
    INHERITED_OTHER = '042c2c2f8a6f8638128b3596d49b8a26',
}

export default hashedKeys;

export const getHashedKeyIndex = (key: string) => {
    switch (key) {
    case hashedKeys.INHERITED_OTHER:
        return 1;
    case hashedKeys.OTHER:
        return 2;
    case hashedKeys.DKNA:
        return 3;
    default:
        return 0;
    }
};
