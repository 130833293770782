import api from '@/api';
import { toaster } from '@/utils/toaster';
import i18next from 'i18next';

export default {
    async loadGroupStats({ commit }, { postData }) {
        commit('setLoading', true);
        const data = await api.getGroupStats(postData);
        commit('setStatsData', data);
        commit('setLoading', false);
    },
    async checkIfNameExists({ state, dispatch }, { name, id }) {
        await dispatch('loadGroupStats', {});

        const nameExists = state.statsData.some(group => {
            return (
                group.name.toLowerCase().trim() === name.toLowerCase().trim()
                && (group.id !== id || id === undefined)
            );
        });

        if (nameExists) {
            toaster.error(i18next.t('ADMIN.DEMO_GROUP_NAME_ALREADY_EXISTS', 'Group already exists with this name please choose another!'));
        }

        return nameExists;
    }
};
