<template>
    <div
        class="popup-suggestions vue"
        :isSuggestionPopupShown="isSuggestionPopupShown"
    >
        <div class="suggestions-head">
            <div class="suggestion-title">
                {{ $t('KEYWORD.SUGGESTION_TITLE') }}
            </div>
            <div
                v-if="!isLoading && suggestionList.length > 0"
                @click="acceptAllSuggestions"
            >
                <z-button variant="secondary">
                    {{ $t('KEYWORD.SUGGESTION_ADD_ALL') | capitalize }}
                </z-button>
            </div>
        </div>
        <loading-animation
            v-if="isLoading"
            class="mx-auto my-4"
        />
        <div
            v-else
            class="suggestion-buttons"
        >
            <z-tag
                v-for="suggestion in suggestionList"
                :key="suggestion"
                addable
                class="mr-1 mb-1"
                @click="acceptSuggestion(suggestion)"
            >
                {{ suggestion }}
            </z-tag>
            <div
                v-if="suggestionList.length === 0"
                class="m-auto text-neutral-800"
            >
                {{ $t('KEYWORD.NO_SUGGESTION', 'No suggestion found.') }}
            </div>
        </div>
    </div>
</template>

<script>
import LoadingAnimation from '@/components/ui/LoadingAnimation.vue';


export default {
    filters: {
        capitalize(name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }
    },
    components: {
        LoadingAnimation,
    },
    props: {
        isSuggestionPopupShown: { type: Boolean, default: false},
        suggestions: { type: Array, default: () => [] },
        isLoading: { type: Boolean, default: false},
    },
    data() {
        return {
            suggestionList: [],
            synonymsList: [],
            excludesList: [],
            filtersList: [],
        };
    },
    watch: {
        suggestions() {
            if (this.suggestions && this.suggestions instanceof Array) {
                this.suggestionList = this.suggestions;
            }
        },
    },
    methods: {
        acceptSuggestion(suggestion) {
            this.$emit('add', suggestion);
            this.discardSuggestion(suggestion);
        },
        acceptAllSuggestions() {
            this.suggestionList.forEach(suggestion => this.acceptSuggestion(suggestion));
        },
        discardSuggestion(suggestion) {
            this.suggestionList = this.suggestionList.filter((currentSuggestion) => {
                return suggestion !== currentSuggestion;
            });
        },
    },
};
</script>
<style lang="less" scoped>
    .suggestion-buttons {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0;
    }
    .suggestions-head {
        display: flex;
        justify-content: space-between;
    }
</style>
