import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';
import { LayoutSpacing } from '@/survey/store/types';

const state = {
    loading: false,
    id: 0,
    group: '',
    name: '',
    language_id: 1,
    ui_language_id: 1,
    sensitive_data_settings: null,
    avatar: null,
    auth0Connection: null,
    imgSource: '',
    imgCropped: '',
    primary_color: '#1cc6ba',
    text_color: '#212529',
    background_color: '#ffffff',
    title_font_name: 'Open Sans',
    title_font_weight: '400',
    title_font_size: 'text-3xl',
    question_font_name: 'Open Sans',
    question_font_weight: '400',
    question_font_size: 'text-xl',
    answer_font_name: 'Open Sans',
    answer_font_weight: '400',
    answer_font_size: 'text-lg',
    layout_spacing: LayoutSpacing.SPACIOUS,
    privacy_policy_title: null,
    privacy_policy_url: null,
    orgLevelExcludedEntities: [],
    brand_logo: null,
    brand_color: null,
    brand_email_salutation: null,
    nmi_token: null,
    custom_token: null
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
