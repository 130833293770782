import { reactive } from 'vue';
import debounce from 'lodash/debounce';
import { state as commonState } from '@/dashboard/common/store/commonStore';
import { getFilters } from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';
import { CustomLabel } from '@/custom-labels/store/types';
import { makeActions as makeEntityActions, makeGetters as makeEntityGetters, initialState as entityInitialState } from './allEntities';
import { makeActions as makeNotTextActions, initialState as notTextInitialState } from './notText';
import { makeActions as makeFeedActions, initialState as feedInitialState } from './feed';
import { makeActions as makeQuickReanalysisActions, initState as quickReanalysisState } from './quickReanalysis';
import { makeActions as makeCxActions, initialState as cxState } from './cx';
import { makeActions as makeReviewActions, initialState as reviewState } from './review';

import useDataseriesStore from '../../common/store/dataseriesStore';

import { produce } from 'immer';

import PQueue from 'p-queue';
const queue = new PQueue({ concurrency: 6 });

import langEnum from '@/domain/analysisLanguageEnum';

export const state = reactive({
    columnId: null as string|null,
    legacyColumnId: null as number|null,
    analysisEntryId: null,
    isSummaryLoading: true,
    isTotalTextUnitLoading: true,
    totalTextUnitCount: null as number|null,
    summary: { default: [] } as { [ds: string]: any[] },
    filteredTextUnitCount: 0,
    newManuallyAddedCustomLabels: [] as CustomLabel[],
    manuallyAddedCustomLabels: {} as { [textUnitId: string]: Array<{ id: number, opinionIndex: number }> },
    keywordEntities: [] as Array<{ phrase: string, langId: langEnum }>,
    ...notTextInitialState,
    ...entityInitialState,
    ...feedInitialState,
    ...quickReanalysisState,
    ...cxState,
    ...reviewState,
});

export const getters = {
    ...makeEntityGetters(state)
};

const load = debounce(async (initial = false) => {
    actions.resetSummary();
    actions.resetQuickReanalysisStore();

    queue.clear();
    if (initial) {
        // We should reset entities if we change between verbatim columns to recalculate original entities too
        state.allEntities = { default: [] };

        // Some data needs to be loaded only once
        state.isTotalTextUnitLoading = true;
    }

    // switching between tabs will reset cl reanalysis store without remounting text analysis dashboard => active reanalysis toaster disappears
    queue.add(() => actions.getReanalysisStatus());


    queue.add(() => actions.getSummary());
    queue.add(() => actions.getAllEntities()).then(() => {
        queue.add(() => actions.getTextUnits());
        queue.add(() => actions.getAllRelations());
        queue.add(() => actions.getPhraseRelations());
        queue.add(() => actions.getTopAllEntityCorrelations());
        queue.add(() => actions.getTopAllEntityTimeSeries());
        queue.add(() => actions.getUsedCustomLabelCategories());
    });
    queue.add(() => actions.getTopDrivers());
    queue.add(() => actions.getPhrases());
    queue.add(() => actions.getSemanticTimeSeries());
    queue.add(() => actions.getCategorySemanticStats());
    queue.add(() => actions.getSemanticIndividualMarkers());
    queue.add(() => actions.initNps());
    queue.add(() => actions.initCsat());
    queue.add(() => actions.initReviews());

    return queue.onIdle();
}, 500);

export const actions = {
    load,
    setColumnId(newColumnId: string) {
        state.columnId = newColumnId;
        state.totalTextUnitCount = null; // reset total number for new dimension
        const dimension = commonState.dataset.dimension_definitions.find(d => d.id === newColumnId);
        if (dimension) {
            state.legacyColumnId = dimension.legacy_id;
        }
    },
    addNewManualCustomLabel(cl: CustomLabel) {
        state.newManuallyAddedCustomLabels.push(cl);
    },
    addManuallyAddedCustomLabels({ textUnitId, customLabels }: { textUnitId: string, customLabels: Array<{ id: number, opinionIndex: number }> }): void {
        if (!state.manuallyAddedCustomLabels[textUnitId]) {
            state.manuallyAddedCustomLabels = produce(state.manuallyAddedCustomLabels, (draft) => {
                draft[textUnitId] = [];
            });
        }

        const textUnit = state.textUnits.find(el => el.semantic_analysis_id === textUnitId)!;

        state.manuallyAddedCustomLabels = produce(state.manuallyAddedCustomLabels, (draft) => {
            customLabels.forEach(cl => {
                if (JSON.parse(textUnit.entities).some(el => el.id === cl.id && el.type === 'custom-label')) return;

                if (draft[textUnitId].every(el => el.id !== cl.id)) {
                    draft[textUnitId].push(cl);
                }
            });
        });
    },
    changeManuallyAddedCustomLabelOpinionIndex({ textUnitId, customLabelId, opinionIndex }: { textUnitId: string, customLabelId: number, opinionIndex: number} ): void {
        state.manuallyAddedCustomLabels = produce(state.manuallyAddedCustomLabels, (draft) => {
            draft[textUnitId].find(cl => cl.id === customLabelId)!.opinionIndex = opinionIndex;
        });
    },
    deleteManuallyAddedCustomLabel({ textUnitId, customLabelId }: { textUnitId: string, customLabelId: number }): void {
        if (state.manuallyAddedCustomLabels[textUnitId]) {
            state.manuallyAddedCustomLabels = produce(state.manuallyAddedCustomLabels, (draft) => {
                draft[textUnitId] = draft[textUnitId].filter(cl => cl.id !== customLabelId);
            });
        }
    },
    async getSummary() {
        state.isSummaryLoading = true;
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const filters = [getFilters()];

            if (filters[0].length > 0) {
                // to get the total text unit count, we need to get the distribution without filters
                filters.push([]);
            }

            const response = await datasetApi.getPolarityDistribution(commonState.dataset.id, state.columnId!, filters);
            state.summary = { default: response[0] };

            state.filteredTextUnitCount = response[0].find(polarity => polarity.polarity === 'sum').count;

            if (filters.length === 1) {
                state.totalTextUnitCount = state.filteredTextUnitCount;
            }
            else {
                response[1].find(polarity => polarity.polarity === 'sum').count;
            }
        }
        else {
            const dsFilters = [...selectedDataseries.map(ds => ds.filters), []];

            const responses = await datasetApi.getPolarityDistribution(commonState.dataset.id, state.columnId!, dsFilters);

            state.summary = selectedDataseries.reduce((summary, ds, index) => {
                summary[ds.id!] = responses[index];

                return summary;
            }, {});

            if (selectedDataseries.length === 1) {
                state.filteredTextUnitCount = responses[0].find(polarity => polarity.polarity === 'sum').count;
            }
            else {
                state.filteredTextUnitCount = responses.slice(0, responses.length - 1).reduce((max, response) => {
                    return Math.max(max, response.find(polarity => polarity.polarity === 'sum').count);
                }, 0);
            }

            state.totalTextUnitCount = responses.at(-1).find(polarity => polarity.polarity === 'sum').count;
        }

        state.isTotalTextUnitLoading = false;
        state.isSummaryLoading = false;
    },
    addKeywordEntity(newEntity: { phrase: string, langId: langEnum }) {
        state.keywordEntities = state.keywordEntities.concat(newEntity);
    },
    removeKeywordEntity(entityToRemove: { phrase: string, langId: langEnum }) {
        state.keywordEntities = state.keywordEntities.filter(ke => ke.phrase !== entityToRemove.phrase && ke.langId !== entityToRemove);
    },
    resetKeywordEntities() {
        state.keywordEntities = [];
    },
    resetSummary() {
        if (Object.values(state.summary)[0].length > 0) {
            state.isSummaryLoading = true;
            state.isFeedLoading = true;
            state.dailyMentionsLoading = true;
            state.isAllEntitiesLoading = true;
            state.summary = { default: [] };
            state.filteredAllEntities = { default: [] };
            state.keywordEntities = [];
            state.isDriversLoading = true;
            state.topDrivers = { posPhrases: [], negPhrases: [] };
            state.totalTextUnitCount = null;
            state.filteredTextUnitCount = 0;
        }
    },
    resetNewManuallyAddedCustomLabels: () => state.newManuallyAddedCustomLabels = [],
    resetManuallyAddedCustomLabels: () => state.manuallyAddedCustomLabels = {},
    ...makeEntityActions(state, getters),
    ...makeNotTextActions(state),
    ...makeFeedActions(state),
    ...makeQuickReanalysisActions(state),
    ...makeCxActions(state),
    ...makeReviewActions(state),
    resetTextAnalysisState: () => {
        state.columnId = null;
        state.legacyColumnId = null;
        state.analysisEntryId = null;
        state.isSummaryLoading = true;
        state.isTotalTextUnitLoading = true;
        state.totalTextUnitCount = null;
        state.summary = { default: [] };
        state.filteredTextUnitCount = 0;
        state.keywordEntities = [];
        state.newManuallyAddedCustomLabels = [];
        state.manuallyAddedCustomLabels = {};

        actions.resetAllEntitiesState();
        actions.resetCxState();
        actions.resetFeedState();
        actions.resetNotText();
        actions.resetQuickReanalysisStore();
        actions.resetReviews();
    },
};

export default function useTextAnalysisStore() {
    return {
        state,
        getters,
        actions,
    };
}
