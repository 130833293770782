import i18next from 'i18next';

const languages = {
    INTERNATIONAL: 0,
    HUNGARIAN: 1,
    ENGLISH: 2,
    BULGARIAN: 3,
    GERMAN: 4,
    ROMANIAN: 5,
    GEORGIAN: 6,
    RUSSIAN: 7,
    UKRAINIAN: 8,
    POLISH: 9,
    MIXED: 10,
    DUTCH: 11,
    CZECH: 12,
    SLOVAK: 13,
    INDONESIAN: 14,
    ALBANIAN: 15,
    DANISH: 16,
    SWEDISH: 17,
    CROATIAN: 18,
    SERBIAN: 19,
    SLOVENIAN: 20,
    BOSNIAN: 21,
    GREEK: 22,
    NORWEGIAN: 23,
    SPANISH: 24,
    FRENCH: 25,
    ESTONIAN: 26,
    FINNISH: 27,
    ITALIAN: 28,
    LATVIAN: 29,
    PORTUGUESE: 30,
    TURKISH: 31,
    HINDI: 32,
    MALAY: 33,
};

export const getDisplayName = (language) => {
    switch (language) {
    case languages.BULGARIAN:
        return i18next.t('LANGUAGES.BULGARIAN', 'Bulgarian');
    case languages.CZECH:
        return i18next.t('LANGUAGES.CZECH', 'Czech');
    case languages.DUTCH:
        return i18next.t('LANGUAGES.DUTCH', 'Dutch');
    case languages.ENGLISH:
        return i18next.t('LANGUAGES.ENGLISH', 'English');
    case languages.GEORGIAN:
        return i18next.t('LANGUAGES.GEORGIAN', 'Georgian');
    case languages.GERMAN:
        return i18next.t('LANGUAGES.GERMAN', 'German');
    case languages.HUNGARIAN:
        return i18next.t('LANGUAGES.HUNGARIAN', 'Hungarian');
    case languages.INDONESIAN:
        return i18next.t('LANGUAGES.INDONESIAN', 'Indonesian');
    case languages.POLISH:
        return i18next.t('LANGUAGES.POLISH', 'Polish');
    case languages.ROMANIAN:
        return i18next.t('LANGUAGES.ROMANIAN', 'Romanian');
    case languages.RUSSIAN:
        return i18next.t('LANGUAGES.RUSSIAN', 'Russian');
    case languages.SLOVAK:
        return i18next.t('LANGUAGES.SLOVAK', 'Slovak');
    case languages.UKRAINIAN:
        return i18next.t('LANGUAGES.UKRAINIAN', 'Ukrainian');
    case languages.ALBANIAN:
        return i18next.t('LANGUAGES.ALBANIAN', 'Albanian');
    case languages.DANISH:
        return i18next.t('LANGUAGES.DANISH', 'Danish');
    case languages.SWEDISH:
        return i18next.t('LANGUAGES.SWEDISH', 'Swedish');
    case languages.CROATIAN:
        return i18next.t('LANGUAGES.CROATIAN', 'Croatian');
    case languages.SERBIAN:
        return i18next.t('LANGUAGES.SERBIAN', 'Serbian');
    case languages.SLOVENIAN:
        return i18next.t('LANGUAGES.SLOVENIAN', 'Slovenian');
    case languages.BOSNIAN:
        return i18next.t('LANGUAGES.BOSNIAN', 'Bosnian');
    case languages.GREEK:
        return i18next.t('LANGUAGES.GREEK', 'Greek');
    case languages.NORWEGIAN:
        return i18next.t('LANGUAGES.NORWEGIAN', 'Norwegian');
    case languages.SPANISH:
        return i18next.t('LANGUAGES.SPANISH', 'Spanish');
    case languages.FRENCH:
        return i18next.t('LANGUAGES.FRENCH', 'French');
    case languages.ESTONIAN:
        return i18next.t('LANGUAGES.ESTONIAN', 'Estonian');
    case languages.FINNISH:
        return i18next.t('LANGUAGES.FINNISH', 'Finnish');
    case languages.ITALIAN:
        return i18next.t('LANGUAGES.ITALIAN', 'Italian');
    case languages.LATVIAN:
        return i18next.t('LANGUAGES.LATVIAN', 'Latvian');
    case languages.PORTUGUESE:
        return i18next.t('LANGUAGES.PORTUGUESE', 'Portuguese');
    case languages.TURKISH:
        return i18next.t('LANGUAGES.TURKISH', 'Turkish');
    case languages.HINDI:
        return i18next.t('LANGUAGES.HINDI', 'Hindi');
    case languages.MALAY:
        return i18next.t('LANGUAGES.MALAY', 'Malay');
    case languages.MIXED:
        return i18next.t('LANGUAGES.MIXED', 'Mixed');
    }
};

/**
 * Nicknames are needed for language filter because Text Api returns this => Semantic Analysis language
 * */
export const nicknames = {
    HUNGARIAN: 'hu',
    ENGLISH: 'en',
    BULGARIAN: 'bg',
    GERMAN: 'de',
    ROMANIAN: 'ro',
    GEORGIAN: 'ge',
    RUSSIAN: 'ru',
    UKRAINIAN: 'ua',
    POLISH: 'pl',
    DUTCH: 'nl',
    CZECH: 'cz',
    SLOVAK: 'sk',
    INDONESIAN: 'id',
    ALBANIAN: 'al',
    DANISH: 'dk',
    SWEDISH: 'se',
    CROATIAN: 'hr',
    SERBIAN: 'sr',
    SLOVENIAN: 'si',
    BOSNIAN: 'ba',
    GREEK: 'gr',
    NORWEGIAN: 'no',
    SPANISH: 'es',
    FRENCH: 'fr',
    ESTONIAN: 'et',
    FINNISH: 'fi',
    ITALIAN: 'it',
    LATVIAN: 'lv',
    PORTUGUESE: 'pt',
    TURKISH: 'tr',
    HINDI: 'hi',
    MALAY: 'my'
};

export const getDisplayNameFromNickname = (nickname) => {
    switch (nickname) {
    case nicknames.BULGARIAN:
        return i18next.t('LANGUAGES.BULGARIAN', 'Bulgarian');
    case nicknames.CZECH:
        return i18next.t('LANGUAGES.CZECH', 'Czech');
    case nicknames.DUTCH:
        return i18next.t('LANGUAGES.DUTCH', 'Dutch');
    case nicknames.ENGLISH:
        return i18next.t('LANGUAGES.ENGLISH', 'English');
    case nicknames.GEORGIAN:
        return i18next.t('LANGUAGES.GEORGIAN', 'Georgian');
    case nicknames.GERMAN:
        return i18next.t('LANGUAGES.GERMAN', 'German');
    case nicknames.HUNGARIAN:
        return i18next.t('LANGUAGES.HUNGARIAN', 'Hungarian');
    case nicknames.INDONESIAN:
        return i18next.t('LANGUAGES.INDONESIAN', 'Indonesian');
    case nicknames.POLISH:
        return i18next.t('LANGUAGES.POLISH', 'Polish');
    case nicknames.ROMANIAN:
        return i18next.t('LANGUAGES.ROMANIAN', 'Romanian');
    case nicknames.RUSSIAN:
        return i18next.t('LANGUAGES.RUSSIAN', 'Russian');
    case nicknames.SLOVAK:
        return i18next.t('LANGUAGES.SLOVAK', 'Slovak');
    case nicknames.UKRAINIAN:
        return i18next.t('LANGUAGES.UKRAINIAN', 'Ukrainian');
    case nicknames.ALBANIAN:
        return i18next.t('LANGUAGES.ALBANIAN', 'Albanian');
    case nicknames.DANISH:
        return i18next.t('LANGUAGES.DANISH', 'Danish');
    case nicknames.SWEDISH:
        return i18next.t('LANGUAGES.SWEDISH', 'Swedish');
    case nicknames.CROATIAN:
        return i18next.t('LANGUAGES.CROATIAN', 'Croatian');
    case nicknames.SERBIAN:
        return i18next.t('LANGUAGES.SERBIAN', 'Serbian');
    case nicknames.SLOVENIAN:
        return i18next.t('LANGUAGES.SLOVENIAN', 'Slovenian');
    case nicknames.BOSNIAN:
        return i18next.t('LANGUAGES.BOSNIAN', 'Bosnian');
    case nicknames.GREEK:
        return i18next.t('LANGUAGES.GREEK', 'Greek');
    case nicknames.NORWEGIAN:
        return i18next.t('LANGUAGES.NORWEGIAN', 'Norwegian');
    case nicknames.SPANISH:
        return i18next.t('LANGUAGES.SPANISH', 'Spanish');
    case nicknames.FRENCH:
        return i18next.t('LANGUAGES.FRENCH', 'French');
    case nicknames.ESTONIAN:
        return i18next.t('LANGUAGES.ESTONIAN', 'Estonian');
    case nicknames.FINNISH:
        return i18next.t('LANGUAGES.FINNISH', 'Finnish');
    case nicknames.ITALIAN:
        return i18next.t('LANGUAGES.ITALIAN', 'Italian');
    case nicknames.LATVIAN:
        return i18next.t('LANGUAGES.LATVIAN', 'Latvian');
    case nicknames.PORTUGUESE:
        return i18next.t('LANGUAGES.PORTUGUESE', 'Portuguese');
    case nicknames.TURKISH:
        return i18next.t('LANGUAGES.TURKISH', 'Turkish');
    case nicknames.HINDI:
        return i18next.t('LANGUAGES.HINDI', 'Hindi');
    case nicknames.MALAY:
        return i18next.t('LANGUAGES.MALAY', 'Malay');
    }
};

export const getNicknameFromId = (id) => {
    const lang = Object.keys(languages).find(key => languages[key] === id);

    return nicknames[lang];
};

export const getIdFromNickname = (nickname) => languages[Object.keys(nicknames).find(key => nicknames[key] == nickname)];

export default languages;
