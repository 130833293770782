import * as api from '../api';
import * as datasetApi from '@/dashboard/api/datasetApi';
import * as datasetsApi from '@/datasets/api';
import dimensionTypeEnum from '@/domain/dimensionTypeEnum';

import dayjs from 'dayjs';

import useCustomLabelStore from '@/custom-labels/store';

const clGetters = useCustomLabelStore().getters;

export default {
    getDomoDataset: async ({ state, commit }) => {
        const dataset = await api.getDomoDataset({
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            domoDatasetId: state.domoDatasetId,
        });

        commit('setDomoDataset', dataset);
        commit('setState', {
            prop: 'authChanged',
            val: false,
        });

        if (state.editing) {
            if (
                dataset.columnsSchema.length !== state.columns.length
                || dataset.columnsSchema.some(col => {
                    const column = state.columns.find(c => c.originalName === col.name);

                    if (!column || col.type !== column.originalType) return true;

                    return false;
                })
            ) {
                commit('setState', {
                    prop: 'dataStructureChanged',
                    val: true,
                });

                commit('setState', {
                    prop: 'invalidDomoStructure',
                    val: true,
                });

                return;
            }

            if (state.invalidDomoStructure) {
                commit('setState', { prop: 'invalidDomoStructure', val: false });
            }

            return;
        }

        commit('setState', {
            prop: 'columns',
            val: dataset.columnsSchema.map((column, index) => {
                const type = convertToZurveyType(column.type);

                return {
                    id: (index + 1).toString(),
                    originalName: column.name,
                    originalType: column.type,
                    name: column.name,
                    type,
                };
            })
        });
    },
    createDomoIntegration: async ({ state }) => {
        const customLabelIds = state.customLabelIds.filter(column => state.columns.find(col => col.id === column.guid)?.type === dimensionTypeEnum.VERBATIM);

        const scheduleCron = getScheduleCron(state.time, state.frequency, state.day, state.weekdaysOnly);

        await api.createDomoIntegration({
            accent: state.accent,
            lowercase: state.lowercase,
            topicRecognition: state.topicRecognition,
            textSplitting: state.textSplitting,
            languageId: state.languageId,
            name: state.domoDataset.name,
            description: state.domoDataset.description,
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            domoDatasetId: state.domoDatasetId,
            rows: state.domoDataset.rows,
            columns: state.columns,
            incrementColumnName: state.incrementColumnName,
            customLabelIds,
            scheduleCron,
        });
    },
    getDomoIntegration: async ({ commit }, id) => {
        const domoIntegration = await api.getDomoIntegration(id);

        commit('setDomoIntegration', domoIntegration);
    },
    updateDomoIntegration: async ({ state }) => {
        const customLabelIds = state.customLabelIds.filter(column => state.columns.find(col => col.id === column.guid)?.type === dimensionTypeEnum.VERBATIM);

        const scheduleCron = getScheduleCron(state.time, state.frequency, state.day, state.weekdaysOnly);

        await api.updateDomoIntegration(state.id, {
            accent: state.accent,
            lowercase: state.lowercase,
            topicRecognition: state.topicRecognition,
            textSplitting: state.textSplitting,
            languageId: state.languageId,
            name: state.domoDataset.name,
            description: state.domoDataset.description,
            clientId: state.clientId,
            clientSecret: state.clientSecret,
            domoDatasetId: state.domoDatasetId,
            rows: state.domoDataset.rows,
            columns: state.columns,
            incrementColumnName: state.incrementColumnName,
            customLabelIds,
            scheduleCron,
        });
    },
    reanalyze: async ({state}) => {
        await datasetsApi.reanalyze(state.datasetId);
    },
    clQuickReanalyze: async ({state}) => {
        await Promise.all(state.customLabelIds.map(({ guid, customLabelIds }) => {
            const updatedCls = clGetters.getUpdatedClsSinceLastAnalysis(customLabelIds, state.domoDataset.updatedAt).value;

            const cls = [...new Set([...updatedCls, ...customLabelIds])];

            const removedCls = state
                .originalCustomLabelIds
                .find(cls => cls.guid === guid)
                .customLabelIds.
                filter(id => !customLabelIds.includes(id));

            if (cls.length === 0 && removedCls.length === 0) return null;

            return datasetApi.customLabelReanalyses(state.datasetId, guid, cls, removedCls);
        }));
    },
};

function convertToZurveyType(originalType: string): dimensionTypeEnum {
    let type;

    switch (originalType.toLowerCase()) {
    case 'date':
    case 'datetime':
        type = dimensionTypeEnum.DATE;
        break;
    case 'decimal':
    case 'long':
    case 'double':
        type = dimensionTypeEnum.NUMBER;
        break;
    default:
        type = dimensionTypeEnum.DONT_USE;
    }

    return type;
}

function getScheduleCron(time: string, frequency: number, day: number, weekdaysOnly: boolean): string {
    let scheduleCron;

    const [hour, minute] = time.split(':');
    const utcHour = dayjs().hour(+hour).utc().hour();

    switch (frequency) {
    default:
    case 0:
        scheduleCron = null;
        break;
    case 1:
        scheduleCron = '0 * * * *';
        break;
    case 2: {
        scheduleCron = `${minute} ${utcHour} * * ${weekdaysOnly ? '1-5' : '*'}`;
        break;
    }
    case 3: {
        scheduleCron = `${minute} ${utcHour} * * ${day}`;
        break;
    }
    }

    return scheduleCron;
}
