import { RouteConfig } from 'vue-router';
import useUserStore from '@/user/store';
import useRafiStore, {actions} from './store/rafiStore';
import {rafiTheme} from '@/dashboard/Rafi/utils/rafiTypes';
import useCommonStore from '@/dashboard/common/store/commonStore';

const Root = () => import('./RafiDashboard.vue');
const Summary = () => import('./tabs/Summary.vue');
const Themes = () => import('./tabs/Themes.vue');
const ThemeBreakdown = () => import('./tabs/ThemeBreakdown.vue');
const Verbatims = () => import('./tabs/Verbatims.vue');

export default {
    path: ':datasetId/r',
    component: Root,
    name: 'rafiRoot',
    beforeEnter: async (to, from, next) => {
        if (!useUserStore().getters.isRafiUser.value) {
            return next({ name: 'next', params: { datasetId: to.params.datasetId }, query: useCommonStore().state.query });
        }
        next();
    },
    children: [
        {
            path: 'summary',
            component: Summary,
            props: true,
            name: 'rafi-summary',
            beforeEnter: async (to, from, next) => {
                try {
                    const { actions: rafiActions } = useRafiStore();
                    await rafiActions.init('summary');
                    await rafiActions.getBaseAggregation();
                    next();
                } catch {
                    next('/');
                }
            }
        },
        {
            path: 'themes',
            component: Themes,
            props: true,
            name: 'rafi-themes',
            beforeEnter: async (to, from, next) => {
                try {
                    const { actions: rafiActions } = useRafiStore();
                    await rafiActions.init('themes');
                    await rafiActions.getBaseAggregation();
                    next();
                } catch {
                    next('/');
                }
            }
        },
        {
            path: 'interaction',
            component: ThemeBreakdown,
            props: true,
            name: 'rafi-interaction',
            beforeEnter: async (to, from, next) => {
                try {
                    const { actions: rafiActions } = useRafiStore();
                    await rafiActions.init('theme');
                    await rafiActions.setLeftFilter(rafiTheme.INTERACTION);
                    next();
                } catch {
                    next('/');
                }
            }
        },
        {
            path: 'design-and-performance',
            component: ThemeBreakdown,
            props: true,
            name: 'rafi-design-and-performance',
            beforeEnter: async (to, from, next) => {
                try {
                    const { actions: rafiActions } = useRafiStore();
                    await rafiActions.init('theme');
                    await rafiActions.setLeftFilter(rafiTheme.DESIGN_AND_PERFORMANCE);
                    next();
                } catch {
                    next('/');
                }
            }
        },
        {
            path: 'verbatims',
            component: Verbatims,
            props: true,
            name: 'rafi-verbatims',
            beforeEnter: async (to, from, next) => {
                try {
                    await useRafiStore().actions.init('verbatims');
                    next();
                } catch {
                    next('/');
                }
            }
        },
    ],
} as RouteConfig;
