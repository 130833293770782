import {state as commonState} from '@/dashboard/common/store/commonStore';
import {getFilters} from '@/dashboard/common/store/filterStore';
import * as datasetApi from '@/dashboard/api/datasetApi';

import useDataseriesStore from '@/dashboard/common/store/dataseriesStore';
import {dimensionTypeId} from '@/domain/dataset/dimensionTypeId';

export const initialState = {
    filteredCategories: { default: [] as Array<{ name: string, column: number, categories: any[] }> },
    dateDimensions: [] as any[],
    individualMarkerDimensions: {} as {[key: string]: {[key: string]: {positive: [], negative: []}}},
    individualMarkerDimensionsByFrequency: {} as {[key: string]: {[key: string]: {positive: [], negative: []}}},
    dailyMentionsLoading: true,
    isIndividualMarkersLoading: true,
    timeSeriesTabAndDateFilterVisibility: null as boolean | null,
};

export const makeActions = (state) => ({
    async getSemanticTimeSeries() {
        const dateDimensions = commonState.dataset.dimension_definitions.filter(dd => dd.type === dimensionTypeId.DATE) || [];
        if (dateDimensions.length === 0) {
            return;
        }

        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const response = await datasetApi.getSemanticTimeSeries(commonState.dataset.id, state.columnId, getFilters());

            state.dateDimensions = response.map(dateDimension => ({
                name: dateDimension.name,
                column: dateDimension.column,
                range: dateDimension.range,
                dates: {
                    default: dateDimension.dates
                }
            }));
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getSemanticTimeSeries(commonState.dataset.id, state.columnId, ds.filters)));

            const dimensionNames = responses.flat().reduce((dims, dim) => {
                if (!dims.includes(dim.name)) {
                    dims.push(dim.name);
                }

                return dims;
            }, []);

            const dateDimensions: Array<{ name: string, column: number, range: string, dates: { [ds: string]: any[] } }> = [];

            for (const name of dimensionNames) {
                const dimensions = responses.flat().filter(r => r.name === name);

                dateDimensions.push({
                    name,
                    column: dimensions[0].column,
                    range: dimensions[0].range,
                    dates: selectedDataseries.reduce((dates, ds, index) => {
                        dates[ds.id!] = responses[index].find(dim => dim.name === name).dates;

                        return dates;
                    }, {}),
                });
            }

            state.dateDimensions = dateDimensions;
        }

        state.dailyMentionsLoading = false;

        // Only check this on initial load
        if (state.timeSeriesTabAndDateFilterVisibility === null) {
            state.timeSeriesTabAndDateFilterVisibility = state.dateDimensions.some(d => d.dates && Object.values(d.dates).some((dates: any) => dates.length > 1));
        }
    },
    async getCategorySemanticStats() {
        const categoryDimensions = commonState.dataset.dimension_definitions.filter(dd => dd.type === dimensionTypeId.CATEGORY) || [];
        if (categoryDimensions.length === 0) {
            return;
        }

        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const response = await datasetApi.getCategorySemanticStats(commonState.dataset.id, state.columnId, getFilters());

            state.filteredCategories = { default: response };
        }
        else {
            const responses = await Promise.all(selectedDataseries.map(ds => datasetApi.getCategorySemanticStats(commonState.dataset.id, state.columnId, ds.filters)));

            state.filteredCategories = selectedDataseries.reduce((categories, ds, index) => {
                categories[ds.id!] = responses[index];

                return categories;
            }, {});
        }
    },
    async getSemanticIndividualMarkers() {
        state.isIndividualMarkersLoading = true;
        const individualMarkerDimensions = commonState.dataset.dimension_definitions.filter(dd => dd.type === dimensionTypeId.NON_UNIQUE_ID) || [];
        if (individualMarkerDimensions.length === 0) {
            return;
        }
        const selectedDataseries = useDataseriesStore().getters.selectedDataseries.value;

        if (selectedDataseries.length === 0) {
            const responses = {};
            const responsesByFrequency = {};
            for (const d of individualMarkerDimensions) {
                responses[d.id] = {};
                responsesByFrequency[d.id] = {};

                const data = await datasetApi.getSemanticIndividualMarkers(commonState.dataset.id, state.columnId, d.id, [getFilters()]);
                responses[d.id]['default'] = data[0];

                const dataForFrequency = await datasetApi.getSemanticIndividualMarkersByFrequency(commonState.dataset.id, state.columnId, d.id, [getFilters()]);
                responsesByFrequency[d.id]['default'] = dataForFrequency[0];
            }
            state.individualMarkerDimensions = responses;
            state.individualMarkerDimensionsByFrequency = responsesByFrequency;
        }
        else {
            const dsFilters = selectedDataseries.map(ds => ds.filters);
            const responses = {};
            const responsesByFrequency = {};
            for (const d of individualMarkerDimensions) {
                const data = await datasetApi.getSemanticIndividualMarkers(commonState.dataset.id, state.columnId, d.id, dsFilters);
                const dataForFrequency = await datasetApi.getSemanticIndividualMarkersByFrequency(commonState.dataset.id, state.columnId, d.id, dsFilters);

                responses[d.id] = {};
                responsesByFrequency[d.id] = {};

                data.forEach((dsData, i) => {
                    const key = selectedDataseries[i].id;
                    if(key) {
                        responses[d.id][key] = dsData;
                    }
                });
                dataForFrequency.forEach((dsData, i) => {
                    const key = selectedDataseries[i].id;
                    if(key) {
                        responsesByFrequency[d.id][key] = dsData;
                    }
                });
            }
            state.individualMarkerDimensions = responses;
            state.individualMarkerDimensionsByFrequency = responsesByFrequency;

        }
        state.isIndividualMarkersLoading = false;
    },
    resetNotText() {
        state.filteredCategories = { default: [] };
        state.individualMarkerDimensions = {};
        state.dateDimensions = [];
        state.dailyMentionsLoading = true;
        state.isIndividualMarkersLoading = true;
        state.timeSeriesTabAndDateFilterVisibility = null;
    }
});
