<template>
    <div
        v-if="tags.length > 0"
        class="custom-labels-existing-tags"
    >
        <z-tag
            v-for="tag in tags"
            :key="tag"
            deletable
            class="mr-1 mb-1"
            @remove="handleRemove(tag)"
        >
            {{ tag }}
        </z-tag>
    </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    props: {
        tags: { type: Array as () => Array<string>, default: () => ([]) }
    },
    setup(_, context) {
        function handleRemove(tag: string) {
            context.emit('remove-tag', tag);
        }

        return { handleRemove };
    }
});
</script>
