enum levels {
    OFF = 1,
    ALPHA = 2,               // developer
    BETA = 3,                // early access
    PRODUCTION = 4,          // generally available
}

export const getDisplayLevel = (i) => {
    switch (i) {
    case 1:
        return 'Off';
    case 2:
        return 'Alpha';
    case 3:
        return 'Beta';
    case 4:
        return 'Production';
    }
};

export default levels;
