<template>
    <div
        class="mt-3 rounded p-3 bg-bg shadow"
    >
        <div
            class="expand flex justify-between text-right cursor-pointer select-none"
            data-test-id="toggle-language-settings"
            @click="isSettingsOpen = !isSettingsOpen"
        >
            <h3
                class="text-sm mb-1 font-bold"
            >
                {{ $t('ANALYSIS.ANALYSIS_SETTINGS') }}
            </h3>
            <icon
                :icon="isSettingsOpen ? 'angle-up' : 'angle-down'"
                class="text-base ml-1 text-black relative"
            />
        </div>
        <div class="flex h-8">
            <z-input
                :value="name"
                type="text"
                data-test-id="excel-name"
                class="w-96 mr-4"
                @input="$emit('nameUpdated', $event)"
            />
            <z-select
                :value="languageId"
                class="w-40"
                name="language"
                data-test-id="excel-language"
                :disabled="isEdit && isInputTranslationActive"
                @change="updateLang"
            >
                <option
                    v-for="(l, i) in languageNames"
                    :key="i"
                    :value="l"
                >
                    {{ getLanguageName(l) }}
                </option>
            </z-select>
        </div>
        <div
            v-if="isSettingsOpen"
            class="language-settings-extra"
            data-test-id="language-settings"
        >
            <div
                v-if="isSuperAdmin && isReanalysis"
                class="my-2 flex"
            >
                <z-toggle
                    v-model="isForceReanalysisActive"
                    :label-text="$t('ANALYSIS.FORCE_REANALYSIS', 'Force full re-analysis based on updated lexicons')"
                    data-test-id="force-reanalysis"
                    @input="$emit('forceReanalysisUpdated', $event)"
                />
                <icon
                    v-tooltip.top="$t('ANALYSIS.FORCE_REANALYSIS_INFO', 'This reanalysis option applies a full reanalysis on all records in the dataset regardless of which settings have been modified. We suggest to use this only if you would like to give effect to changes and updates made in lexicons. Please consider, this type of reanalysis takes much longer time and can hinder other analyses. This option is only available for users with superadmin status.')"
                    icon="info-circle"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :value="isAccentReplaceActive"
                    :label-text="$t('ANALYSIS.ACCENT_ON')"
                    data-test-id="accent-replace"
                    @input="$emit('accentUpdated', $event)"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :value="isLowercaseActive"
                    :label-text="$t('ANALYSIS.CONVERT_TO_LOWERCASE')"
                    data-test-id="lowercase"
                    @input="$emit('lowercaseUpdated', $event)"
                />
            </div>
            <div class="my-2">
                <z-toggle
                    :value="isTopicRecognitionActive"
                    :label-text="$t('ANALYSIS.TOPIC_RECOGNITION', 'Allow automatic label recognition')"
                    data-test-id="topic"
                    @input="$emit('topicRecognitionUpdated', $event)"
                />
            </div>
            <div class="my-2 flex text-sm leading-8">
                {{ $t('ANALYSIS.TEXT_SPLITTING', 'Text Splitting Type') }}
                <z-select
                    :value="textSplittingType"
                    class="w-40 ml-2"
                    name="text-splitting-type"
                    @change="updateType"
                >
                    <option
                        v-for="type in typeEnums"
                        :key="type"
                        :value="type"
                    >
                        {{ getTypeDisplayName(type) }}
                    </option>
                </z-select>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ZSelect from '@/components/ui/Select.vue';
import Icon from '@/components/ui/Icon.vue';
import {defineComponent, ref} from 'vue';
import languages, { getDisplayName } from '@/domain/analysisLanguageEnum';
import types, { getTypeName } from '@/domain/textSplittingTypeEnum';
import useUserStore from '@/user/store';
export default defineComponent({
    components: {
        ZSelect,
        Icon
    },
    props: {
        name: { type: String, default: null },
        languageId: { type: Number, default: 1 },
        textSplittingType: { type: Number, default: 1 },
        isAccentReplaceActive: { type: Boolean, default: true },
        isLowercaseActive: { type: Boolean, default: true },
        isTopicRecognitionActive: { type: Boolean, default: true },
        isExcelReanalysis: { type: Boolean, default: false },
        isReanalysis: { type: Boolean, default: false },
        isInputTranslationActive: { type: Boolean, default: false },
        isEdit: { type: Boolean, default: false }
    },
    setup(props, ctx) {
        const { isSuperAdmin } = useUserStore().getters;
        const isSettingsOpen = ref(false);
        const isForceReanalysisActive = ref(false);

        const languageNames = ref(Object.values(languages).sort((a,b) => getDisplayName(a) > getDisplayName(b) ? 1 : -1));
        const getLanguageName = (language) => getDisplayName(parseInt(language));
        const updateLang = (id) => {
            ctx.emit('languageUpdated', parseInt(id));
        };
        const typeEnums = ref(Object.values(types));
        const getTypeDisplayName = (type) => getTypeName(parseInt(type));
        const updateType = (type) => ctx.emit('textSplittingUpdated', parseInt(type));

        return {
            isSettingsOpen,
            languageNames,
            getLanguageName,
            updateLang,
            typeEnums,
            getTypeDisplayName,
            updateType,
            isSuperAdmin,
            isForceReanalysisActive,
        };
    }
});
</script>
